import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';

interface ICampoCondicionComercial {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor: string;
}

const CampoCondicionComercial: FunctionComponent<ICampoCondicionComercial> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="FormularioDocumento.CampoCondicionComercial.Nombre" />}
        onChange={(event) => manejarCambioCampo('CondicionComercial', event.target.value)}
        value={valor}
        id={`CampoCondicionComercial-${valor}`}
    />
);

export default CampoCondicionComercial;
