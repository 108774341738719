import React, { FunctionComponent, useEffect, useState } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import { Refresh as RefreshIcon, Remove as RemoveIcon } from '@material-ui/icons';
import moment from 'moment';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaConteoDetalleCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleCompuesto';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';

import CampoCantidad from './Campos/CampoCantidad';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

interface IConteoDetallesTablaProps {
    editarDetallesConteo: (detalles: IAuditoriaConteoDetalleCompuesto[]) => Promise<boolean>;
    detallesConteo: IAuditoriaConteoDetalleCompuesto[];
    recargarTabla: () => any;
    camposAOcultar?: Array<keyof IAuditoriaConteoDetalleCompuesto>;
    conteo: IAuditoriaConteo | null;
}

const ConteoDetallesTabla: FunctionComponent<IConteoDetallesTablaProps> = ({
    editarDetallesConteo,
    detallesConteo: detallesProp,
    recargarTabla,
    camposAOcultar = [],
    conteo,
}) => {
    const [detalles, setDetalles] = useState<IAuditoriaConteoDetalleCompuesto[]>([]);
    const [diccionarioCantidades, setDiccionarioCantidades] = useState<Record<string, string>>({});
    const [sorting, setSorting] = useState({
        field: 'defaultField', // Campo predeterminado para ordenar

        order: 'asc', // Orden predeterminado ('asc' o 'desc')
    });

    useEffect(() => {
        if (detallesProp.length > 0) {
            setDetalles(detallesProp);
            construirDiccionarioCantidades();
        }
    }, [detallesProp]);

    const obtenerColumnas = () => {
        const columnas: Array<Column<IAuditoriaConteoDetalleCompuesto>> = [];
        columnas.push({
            field: 'CodigoBarras',
            title: <Texto id="TablaConteoDetalles.CodigoBarras" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaConteoDetalles.DescripcionItem" />,
        });
        columnas.push({
            field: 'DescripcionDivision',
            title: <Texto id="TablaConteoDetalles.DescripcionDivision" />,
        });
        if (!camposAOcultar.includes('CantidadBase')) {
            columnas.push({
                field: 'CantidadBase',
                title: <Texto id="TablaConteoDetalles.Stock" />,
            });
        }
        columnas.push({
            field: 'Cantidad',
            title: <Texto id="TablaConteoDetalles.CantidadIngresada" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) => (
                <CampoCantidad
                    manejarCambio={(nuevoValor: string) => {
                        setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                            ...diccionarioCantidadesActual,
                            [detalle.AuditoriaConteoDetalleId]: nuevoValor,
                        }));
                    }}
                    valor={diccionarioCantidades[detalle.AuditoriaConteoDetalleId]}
                />
            ),
            title: <Texto id="TablaConteoDetalles.Cantidad" />,
        });

        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) => (detalle.Lote ? detalle.Lote : <RemoveIcon id="Icono-Remove"/>),
            field: 'Lote',
            title: <Texto id="TablaConteoDetalles.Lote" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) =>
                detalle.FechaVencimiento ? moment(detalle.FechaVencimiento).format('DD/MM/YYYY') : <RemoveIcon id="Icono-Remove"/>,
            field: 'FechaVencimiento',
            title: <Texto id="TablaConteoDetalles.FechaVencimiento" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) =>
                detalle.FechaIngreso ? moment(detalle.FechaIngreso).format('DD/MM/YYYY') : <RemoveIcon id="Icono-Remove"/>,
            field: 'FechaIngreso',
            title: <Texto id="TablaConteoDetalles.FechaIngreso" />,
        });
        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) => (detalle.Serial ? detalle.Serial : <RemoveIcon id="Icono-Remove"/>),
            field: 'Serial',
            title: <Texto id="TablaConteoDetalles.Serial" />,
        });

        columnas.push({
            render: (detalle: IAuditoriaConteoDetalleCompuesto) => (
                <BotonAgregar
                    onClick={() => editarDetalle(detalle)}
                    disabled={conteo !== null && conteo.ConteoFinalizado !== null ? conteo.ConteoFinalizado : false}
                />
            ),
        });
        return columnas;
    };

    const construirDiccionarioCantidades = () => {
        const nuevoDiccionarioCantidades: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioCantidades[detalle.AuditoriaConteoDetalleId] = diccionarioCantidades[
                detalle.AuditoriaConteoDetalleId
            ]
                ? diccionarioCantidades[detalle.AuditoriaConteoDetalleId]
                : '';
        });
        setDiccionarioCantidades(nuevoDiccionarioCantidades);
    };

    const validarDetalle = (detalle: IAuditoriaConteoDetalleCompuesto) => {
        const cantidadDetalle = Number(diccionarioCantidades[detalle.AuditoriaConteoDetalleId]);
        if (isNaN(cantidadDetalle) || cantidadDetalle < 0) {
            notificacionGlobal('AuditoriaConteoDetalle.ValidacionNoCantidad', 3000, 'warning', true);
            return false;
        }
        if ((detalle.ProductoSeriado || detalle.ProductoSerialConsecutivo) && cantidadDetalle > 1) {
            notificacionGlobal('AuditoriaConteoDetalle.ValidacionCantidadSerialIncorrecta', 3000, 'warning', true);
            return false;
        }
        return true;
    };

    const handleColumnSort = (field: string, orderDirection: 'desc' | 'asc') => {
        setSorting({
            field: field,

            order: orderDirection,
        });
    };

    const editarDetalle = async (detalle: IAuditoriaConteoDetalleCompuesto) => {
        if (!validarDetalle(detalle)) return;
        let agregadoExitoso;

        const cantidadDetalle = Number(diccionarioCantidades[detalle.AuditoriaConteoDetalleId]);
        agregadoExitoso = await editarDetallesConteo([{ ...detalle, Cantidad: cantidadDetalle }]);

        if (agregadoExitoso) {
            setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                ...diccionarioCantidadesActual,
                [detalle.AuditoriaConteoDetalleId]: '',
            }));
        }
    };

    return (
        <>
            <Card variant="outlined">
                <CardHeader title="Detalles Conteo" titleTypographyProps={{ variant: 'body1' }} />
                <Tabla
                    actions={[
                        { icon: () => <RefreshIcon id="Icono-Refrescar" color="action" />, isFreeAction: true, onClick: recargarTabla },
                    ]}
                    columns={obtenerColumnas()}
                    data={detalles}
                    options={{ padding: 'dense' }}
                    onOrderChange={(orderedColumnId, orderDirection) => {
                        const column = obtenerColumnas().find(col => col.field === orderedColumnId.toString());
                        if (column) {
                          handleColumnSort(column.field!, orderDirection);
                        }
                    }}
                />
            </Card>
        </>
    );
};

export default ConteoDetallesTabla;
