import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaGestionMovimientosVistaProps } from '../interfaces';
import { acciones, columnas, opciones } from './constantes';

const TablaGestionMovimientosVista: React.FunctionComponent<ITablaGestionMovimientosVistaProps & InjectedIntlProps> = ({
    accionValidacion,
    alAbrirFormulario,
    alAnularDocumento,
    alCambiarValor,
    alCambiarValorAutocomplete,
    consultaDocumentos,
    entidadesFiltro,
    filtros,
    intl: { formatMessage },
    recargarTabla,
    refTabla,
}) => (
    <Grid alignItems="center" container spacing={2} justifyContent="center">
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                        <Grid item md={3} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodega: any) => alCambiarValorAutocomplete('Bodega', bodega)}
                                options={entidadesFiltro.bodegas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={formatMessage({ id: 'gestionmovimientos.Bodega' })}
                                        required
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtros.Bodega!}
                                id={`CampoBodega`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="gestionmovimientos.TipoDocumento" />}
                                name="TipoDocumentoId"
                                onChange={alCambiarValor}
                                select
                                value={filtros.TipoDocumentoId}
                                variant="outlined"
                                id={`CampoTipoDocumento-${filtros.TipoDocumentoId}`}
                            >
                                {entidadesFiltro.tipoDocumento.map(({ TipoDocumentoId, TipoDocumentoDescripcion }) => (
                                    <MenuItem key={TipoDocumentoId} value={TipoDocumentoId}>
                                        {TipoDocumentoDescripcion}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="gestionmovimientos.NumeroDocumento" />}
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={filtros.CodigoDocumento}
                                variant="outlined"
                                id={`CampoTipoDocumento-${filtros.CodigoDocumento}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                format="DD/MM/YYYY"
                                fullWidth
                                label={<Texto id="gestionmovimientos.FechaCreacionDocumento" />}
                                name="FechaCreacion"
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaCreacion', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtros.FechaCreacion}
                                inputVariant="outlined"
                                id={`CampoFechaCreacion-${filtros.FechaCreacion}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                fullWidth
                                label={<Texto id="gestionmovimientos.FechaVencimientoDocumento" />}
                                name="FechaVencimiento"
                                format="DD/MM/YYYY"
                                autoOk
                                variant="inline"
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaVencimiento', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtros.FechaVencimiento}
                                inputVariant="outlined"
                                id={`CampoFechaVencimiento-${filtros.FechaVencimiento}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ EtapaDescripcion }) => EtapaDescripcion}
                                onChange={(_: any, etapa: any) => alCambiarValorAutocomplete('Etapa', etapa)}
                                options={entidadesFiltro.etapas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={formatMessage({ id: 'gestionmovimientos.EstadoDocumento' })}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtros.Etapa!}
                                id={`CampoEtapa`}
                            />
                        </Grid>
                        <Grid item md xs={12}>
                            <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
                                <Grid item md="auto" xs={6}>
                                    <BotonLimpiar fullWidth onClick={() => recargarTabla(true)} id="boton-Limpiar"/>
                                </Grid>
                                <Grid item md="auto" xs={6}>
                                    <BotonBuscar fullWidth onClick={() => accionValidacion()} id="boton-buscar"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={acciones(alAbrirFormulario, alAnularDocumento, recargarTabla, formatMessage)}
                columns={columnas(formatMessage)}
                data={consultaDocumentos}
                tableRef={refTabla}
                options={opciones}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaGestionMovimientosVista);
