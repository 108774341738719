import React from 'react';
import TablaMaximosMinimosCV from "./tablaMaximosMinimosCV";
import FormularioMaximosMinimosCV from './formularioMaximosMinimosCV';


const MinimosMaximosCV: React.FunctionComponent = () => (
    <>
        <TablaMaximosMinimosCV />
        <FormularioMaximosMinimosCV/>
    </>
);

export default MinimosMaximosCV;
