import { Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import IDocumentoConsultaGeneral, {
    DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
} from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import { Rowing, Search as SearchIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { ENTIDADES_DOCUMENTO_POR_DEFECTO } from './constantes';
import Tabla, { Column, MTableBody } from '@infotrack/presentacion-componentes/tabla';
import moment from 'moment';
import TablaDocumentoDetallesCliente from './TablaDocumentoDetallesCliente';
import EntradaDevolucionCM from './controladorModelo/EntradaDevolucionCM';
interface IModalTablaDocumentoClienteProps {
    abrirModal: boolean;
    documentosBase: IDocumentoConsultaGeneral[];
    detallesCliente: IDocumentoDetalleConsultaGeneral[];
    cerrar: () => void;
    seleccionarDocumentoBase: (documento: IDocumentoConsultaGeneral) => void;
    consultaDocumentoBaseFiltro: (Serial?: string, producto?: string) => Promise<void>
    consultaDocumentoBase: (codigoDocumentoBase: string, identificacionCliente?: number, factura?: string) => Promise<IDocumentoConsultaGeneral[]>;
    clienteIdentificacion: number | undefined
}

const ModalTablaDocumentoCliente: FunctionComponent<IModalTablaDocumentoClienteProps> = ({
    abrirModal,
    documentosBase,
    detallesCliente,
    cerrar,
    seleccionarDocumentoBase,
    consultaDocumentoBaseFiltro,
    consultaDocumentoBase,
    clienteIdentificacion
}) => {
    const [detallesTabla, setDetallesTabla] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState<IDocumentoConsultaGeneral | null>(null);
    const [documentosTabla, setdocumentosTabla] = useState<IDocumentoConsultaGeneral[]>([]);
    const [filtroProducto, setFiltroProducto] = useState<string>('');
    const [filtroSerial, setFiltroSerial] = useState<string>('');
    const [idDetalles, setIdDetalles] = useState<string[]>([]);

    useEffect(() => {
        setdocumentosTabla(documentosBase);
    }, [documentosBase]);

    useEffect(() => {
        setDetallesTabla(detallesCliente);
        setIdDetalles(detallesCliente.map((x) => x.DocumentoId));
    }, [detallesCliente]);
    const alCambiarCheck = (item: IDocumentoConsultaGeneral, seleccionado: boolean) => {
        if (seleccionado) {
            setDocumentoSeleccionado(item);
        } else {
            setDocumentoSeleccionado(null);
        }
    };

    const AplicarFiltros = () => {
       consultaDocumentoBaseFiltro(filtroSerial,filtroProducto)
    };

    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoConsultaGeneral>> = [];
        columnas.push({
            render: (rowData) => (
                <Checkbox
                    color="primary"
                    checked={documentoSeleccionado ? rowData.DocumentoId === documentoSeleccionado!.DocumentoId : false}
                    onChange={(_, checked) => alCambiarCheck(rowData, checked)}
                />
            ),
        });
        columnas.push({
            field: 'CodigoDocumento',
            title: <Texto id="TablaDocumentos.CodigoDocumento" />,
        });
        columnas.push({
            field: 'DescripcionBodegaOrigen',
            title: <Texto id="TablaDocumentos.DescripcionBodegaOrigen" />,
        });
        columnas.push({
            field: 'FechaEntrega',
            title: <Texto id="TablaDocumentos.FechaEntrega" />,
            render: (rowData) =>
                rowData.FechaEntrega ? moment(rowData.FechaEntrega).format('DD/MM/YYYY/hh:mm A') : '',
        });
        columnas.push({
            field: 'DireccionEntrega',
            title: <Texto id="TablaDocumentos.DireccionEntrega" />,
        });

        return columnas;
    };

    

    return (
        <Dialog open={abrirModal} maxWidth="lg">
            <ModalEncabezado
                cerrar={() => {
                    cerrar();
                    setFiltroSerial('');
                    setFiltroProducto('');
                    setDocumentoSeleccionado(null);
                }}
                titulo={'Documentos'}
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={<Texto id="gestionproducto.Producto" />}
                            onChange={(event) => setFiltroProducto(event.target.value)}
                            value={filtroProducto}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={<Texto id="TablaDetallesPlantilla.Serial" />}
                            onChange={(event) => {
                                setFiltroSerial(event.target.value);
                            }}
                            value={filtroSerial}
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            endIcon={<SearchIcon id="Icono-Buscar" />}
                            onClick={() => AplicarFiltros()}
                            size="small"
                            variant="contained"
                            id="boton-Buscar"
                            style={{ margin: '30' }}
                            disabled={filtroProducto === '' && filtroSerial === ''}
                        >
                            <Texto id="boton.buscar" />
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            color="primary"
                            endIcon={<DeleteIcon id="Icono-Limpiar" />}
                            onClick={() => {
                                setDetallesTabla(detallesCliente);
                                setdocumentosTabla(documentosBase);
                                setFiltroSerial('');
                                setFiltroProducto('');
                                consultaDocumentoBase("", clienteIdentificacion)
                            }}
                            size="small"
                            variant="contained"
                            id="boton-Limpiar"
                            style={{ margin: '10' }}
                        >
                            <Texto id="boton.limpiar" />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Tabla
                            detailPanel={(rowData) => {
                                return (
                                    <div style={{ padding: '0px' }}>
                                        <p>{rowData.description}</p>
                                        <TablaDocumentoDetallesCliente
                                            idDocumento={rowData.DocumentoId}
                                        />
                                    </div>
                                );
                            }}
                            columns={obtenerColumnas()}
                            data={documentosTabla.sort((a, b) => {
                                const fechaA = new Date(a.FechaEntrega || 0);
                                const fechaB = new Date(b.FechaEntrega || 0);
                                return fechaB.getTime() - fechaA.getTime();
                              })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            onClick={() => {
                                cerrar();
                                setFiltroSerial('');
                                setFiltroProducto('');
                                setDocumentoSeleccionado(null);
                            }}
                            color="secondary"
                            variant="contained"
                            id="boton-cancelar"
                        >
                            <Texto id="boton.cancelar" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => seleccionarDocumentoBase(documentoSeleccionado!)}
                            color="primary"
                            variant="contained"
                            id="boton-guardar"
                            disabled={documentoSeleccionado === null}
                        >
                            <Texto id="boton.guardar" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ModalTablaDocumentoCliente;
