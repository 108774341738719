import {
    AppBar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Toolbar,
    withStyles,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IFormularioDivisionesBodegaVistaProps } from '../interfaces';
import { estilosGenerales } from './estilos';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';

const CustomTextField = (props: TextFieldProps) => (
    <TextField
        {...props}
        fullWidth
        required={props.required !== undefined ? props.required : true}
        value={props.value ? props.value : ''}
        variant="outlined"
    />
);

const FormularioDivisionesBodegaVista: React.FunctionComponent<PropsEstilosMaterialUI<
    IFormularioDivisionesBodegaVistaProps
>> = ({
    alCambiarValor,
    alCerrarFormulario,
    confirmarCreacionEdicion,
    divisionBodega,
    entidadesFormulario,
    estadoFormulario,
    tipoFormulario,
    limpiarCampos,
}) => {
    return (
        <Dialog fullWidth maxWidth="lg" open={estadoFormulario} onExited={limpiarCampos}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container justifyContent="center">
                        <Texto
                            align="center"
                            id={
                                tipoFormulario === TipoFormulario.Creacion
                                    ? 'divisionBodega.agregarDivisionBodega'
                                    : 'divisionBodega.editarDivisionBodega'
                            }
                        />
                    </Grid>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Grid alignItems="flex-start" container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Texto id="divisionBodega.datosDivision" fontWeight="bold" />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.DivisionCodigoBarras" />}
                                            name="DivisionCodigoBarras"
                                            required={false}
                                            onChange={alCambiarValor}
                                            value={divisionBodega.DivisionCodigoBarras}
                                            id={`CampoDivisionCodigoBarras-${divisionBodega.DivisionId}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.DivisionDescripcion" />}
                                            name="DivisionDescripcion"
                                            onChange={alCambiarValor}
                                            value={divisionBodega.DivisionDescripcion}
                                            id={`CampoDivisionDescripcion-${divisionBodega.DivisionDescripcion}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.TipoDivision" />}
                                            name="TipoDivisionId"
                                            id={`CampoTipoDivisionId-${divisionBodega.TipoDivisionId}`}
                                            select
                                            onChange={alCambiarValor}
                                            value={divisionBodega.TipoDivisionId}
                                            helperText={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.tiposDivisiones.find(
                                                    (x) =>
                                                        x.TipoDivisionId === parseInt(divisionBodega.TipoDivisionId) &&
                                                        x.Estado === 1
                                                ) ? (
                                                    <Texto id="divisionBodega.TipoDivisionInactiva" />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            error={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.tiposDivisiones.find(
                                                    (x) =>
                                                        x.TipoDivisionId === parseInt(divisionBodega.TipoDivisionId) &&
                                                        x.Estado === 1
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {entidadesFormulario.tiposDivisiones.filter(
                                                (x) => x.Estado === 1 || x.Estado === true
                                            ).length === 0 ? (
                                                <MenuItem value="">
                                                    <Texto id="label.sinRegistros" />
                                                </MenuItem>
                                            ) : (
                                                entidadesFormulario.tiposDivisiones
                                                    .filter((x) => x.Estado === 1 || x.Estado === true)
                                                    .map(({ TipoDivisionId, TipoDivisionDescripcion }) => (
                                                        <MenuItem key={TipoDivisionId} value={TipoDivisionId}>
                                                            {TipoDivisionDescripcion}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.Fila" />}
                                            name="FilaId"
                                            id={`CampoFila-${divisionBodega.FilaId}`}
                                            select
                                            onChange={alCambiarValor}
                                            value={divisionBodega.FilaId}
                                            helperText={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.filas.find(
                                                    (x) =>
                                                        x.FilaId === parseInt(divisionBodega.FilaId) &&
                                                        x.Estado === estadoActivo
                                                ) ? (
                                                    <Texto id="divisionBodega.FilaInactiva" />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            error={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.filas.find(
                                                    (x) =>
                                                        x.FilaId === parseInt(divisionBodega.FilaId) &&
                                                        x.Estado === estadoActivo
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {entidadesFormulario.filas.filter(
                                                (x) => x.Estado === 1 || x.Estado === true
                                            ).length === 0 ? (
                                                <MenuItem value="">
                                                    <Texto id="label.sinRegistros" />
                                                </MenuItem>
                                            ) : (
                                                entidadesFormulario.filas
                                                    .filter((x) => x.Estado === 1 || x.Estado === true)
                                                    .map(({ FilaId, DescripcionFila }) => (
                                                        <MenuItem key={FilaId} value={FilaId}>
                                                            {DescripcionFila}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.Columna" />}
                                            name="ColumnaId"
                                            id={`CampoColumna-${divisionBodega.ColumnaId}`}
                                            select
                                            onChange={alCambiarValor}
                                            value={divisionBodega.ColumnaId}
                                            helperText={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.columnas.find(
                                                    (x) =>
                                                        x.ColumnaId === parseInt(divisionBodega.ColumnaId) &&
                                                        x.Estado === estadoActivo
                                                ) ? (
                                                    <Texto id="divisionBodega.ColumnaInactiva" />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            error={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.columnas.find(
                                                    (x) =>
                                                        x.ColumnaId === parseInt(divisionBodega.ColumnaId) &&
                                                        x.Estado === estadoActivo
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {entidadesFormulario.columnas.filter(
                                                (x) => x.Estado === 1 || x.Estado === true
                                            ).length === 0 ? (
                                                <MenuItem value="">
                                                    <Texto id="label.sinRegistros" />
                                                </MenuItem>
                                            ) : (
                                                entidadesFormulario.columnas.map(
                                                    ({ ColumnaId, DescripcionColumna }) => (
                                                        <MenuItem key={ColumnaId} value={ColumnaId}>
                                                            {DescripcionColumna}
                                                        </MenuItem>
                                                    )
                                                )
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            label={<Texto display="inline" id="divisionBodega.Piso" />}
                                            name="PisoId"
                                            id={`CampoPiso-${divisionBodega.PisoId}`}
                                            select
                                            onChange={alCambiarValor}
                                            value={divisionBodega.PisoId}
                                            helperText={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.pisos.find(
                                                    (x) =>
                                                        x.PisoId === parseInt(divisionBodega.PisoId) &&
                                                        x.Estado === estadoActivo
                                                ) ? (
                                                    <Texto id="divisionBodega.PisoInactivo" />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            error={
                                                tipoFormulario === TipoFormulario.Edicion &&
                                                !entidadesFormulario.pisos.find(
                                                    (x) =>
                                                        x.PisoId === parseInt(divisionBodega.PisoId) &&
                                                        x.Estado === estadoActivo
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {entidadesFormulario.pisos.filter(
                                                (x) => x.Estado === 1 || x.Estado === true
                                            ).length === 0 ? (
                                                <MenuItem value="">
                                                    <Texto id="label.sinRegistros" />
                                                </MenuItem>
                                            ) : (
                                                entidadesFormulario.pisos.map(({ PisoId, DescripcionPiso }) => (
                                                        <MenuItem key={PisoId} value={PisoId}>
                                                            {DescripcionPiso}
                                                        </MenuItem>
                                                    ))
                                            )}
                                        </CustomTextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={Boolean(divisionBodega.Estado)}
                                                    onChange={alCambiarValor}
                                                />
                                            }
                                            label={<Texto id="divisionBodega.Estado" display="inline" />}
                                            name="Estado"
                                            id={`Estado-${divisionBodega.Estado}`}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Texto id="divisionBodega.medidasDivision" fontWeight="bold" />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Texto id="gestionBodega.medida.dimensiones" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={<Texto display="inline" id="divisionBodega.Ancho" />}
                                            name="Ancho"
                                            onChange={(e) =>
                                                esEnteroODecimal(e.target.value, {
                                                    cantidadEnteros: 6,
                                                    cantidadDecimales: 2,
                                                }) && alCambiarValor(e)
                                            }
                                            value={divisionBodega.Ancho}
                                            id={`CampoAncho-${divisionBodega.Ancho}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Texto id="gestionBodega.medida.dimensiones" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={<Texto display="inline" id="divisionBodega.Largo" />}
                                            name="Largo"
                                            onChange={(e) =>
                                                esEnteroODecimal(e.target.value, {
                                                    cantidadEnteros: 6,
                                                    cantidadDecimales: 2,
                                                }) && alCambiarValor(e)
                                            }
                                            value={divisionBodega.Largo}
                                            id={`CampoLargo-${divisionBodega.Largo}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Texto id="gestionBodega.medida.dimensiones" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={<Texto display="inline" id="divisionBodega.Alto" />}
                                            name="Alto"
                                            onChange={(e) =>
                                                esEnteroODecimal(e.target.value, {
                                                    cantidadEnteros: 6,
                                                    cantidadDecimales: 2,
                                                }) && alCambiarValor(e)
                                            }
                                            value={divisionBodega.Alto}
                                            id={`CampoAlto-${divisionBodega.Alto}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Texto id="gestionBodega.medida.peso" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={<Texto display="inline" id="divisionBodega.Peso" />}
                                            name="Peso"
                                            onChange={(e) =>
                                                esEnteroODecimal(e.target.value, {
                                                    cantidadEnteros: 7,
                                                    cantidadDecimales: 5,
                                                }) && alCambiarValor(e)
                                            }
                                            value={divisionBodega.Peso}
                                            id={`CampoPeso-${divisionBodega.Peso}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{ readOnly: true }}
                                            label={<Texto display="inline" id="divisionBodega.VolumenDisponible" />}
                                            name="VolumenDisponible"
                                            onChange={alCambiarValor}
                                            required={false}
                                            value={divisionBodega.VolumenDisponible}
                                            id={`CampoVolumenDisponible-${divisionBodega.VolumenDisponible}`}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            InputProps={{ readOnly: true }}
                                            label={<Texto display="inline" id="divisionBodega.PesoDisponible" />}
                                            name="PesoDisponible"
                                            onChange={alCambiarValor}
                                            required={false}
                                            value={divisionBodega.PesoDisponible}
                                            id={`CampoPesoDisponible-${divisionBodega.PesoDisponible}`}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!estadoFormulario}
                            onClick={alCerrarFormulario}
                            color="secondary"
                            variant="contained"
                            id="boton-cancelar"
                        >
                            <Texto id="boton.cancelar" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!estadoFormulario}
                            onClick={confirmarCreacionEdicion}
                            color="primary"
                            variant="contained"
                            id={tipoFormulario === TipoFormulario.Creacion ? 'boton-guardar' : 'boton-editar'}
                        >
                            <Texto id={tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(estilosGenerales)(FormularioDivisionesBodegaVista);
