export default{
    'cargaMasiva.DescargarArchivo':'Descargar Plantilla',
    'cargaMasiva.CargarArchivo':'Cargar  Plantilla',
    'cargaMasiva.Costos':'Costos',
    'cargaMasiva.Errores':'Error al cargar la platilla',
    'cargaMasiva.Error':'Error',
    'cargaMasiva.Identificador':'Identificador',
    'cargaMasiva.Bodega':'Bodega',
    'cargaMasiva.TipoDocumento':'Tipo Documento'
    
}