import BlockIcon from '@material-ui/icons/Block';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IAlmacenamiento from 'Infotrack@Modelos/smartStock/almacenamiento/entidades/almacenamiento';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const accionesTablaAlmacenamiento = (
    alAnularAlmacenamiento: (almacenamiento: IAlmacenamiento) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    verDetalleAlmacenamiento: (DocumentoId: string) => void
): Array<Action<IAlmacenamiento>> => [
    {
        icon: () => <DescriptionIcon color="action" />,
        tooltip: formatMessage({ id: 'almacenamiento.documentos.verDetalle' }),
        onClick: (_, rowData: any) => verDetalleAlmacenamiento(rowData.DocumentoId),
    },
    {
        icon: () => <BlockIcon color="action" />,
        onClick: (_, valor) => alAnularAlmacenamiento(valor as IAlmacenamiento),
        tooltip: formatMessage({ id: 'boton.anular' }),
    },
];

export const columnasTablaAlmacenamiento = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IAlmacenamiento>> => [
    { title: formatMessage({ id: 'almacenamiento.documento.CodigoDocumento' }), field: 'CodigoDocumento' },
    { title: formatMessage({ id: 'almacenamiento.documento.DocumentoRelacionado' }), field: 'DocumentoRelacionado' },
    { title: formatMessage({ id: 'almacenamiento.documento.DocumentoPadre' }), field: 'CodigoDocumentoPadre' },
    {
        title: formatMessage({ id: 'almacenamiento.documento.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData) => (rowData.FechaCreacion ? moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A') : ''),
    },
    { title: formatMessage({ id: 'almacenamiento.documento.DescripcionProveedor' }), field: 'DescripcionProveedor' },
    { title: formatMessage({ id: 'almacenamiento.documento.EtapaDescripcion' }), field: 'EtapaDescripcion' },
    { title: formatMessage({ id: 'almacenamiento.documento.Observacion' }), field: 'Observacion' },
];

export const opcionesTablaDocumento: Options<IAlmacenamiento> = {
    toolbar: false,
    maxBodyHeight: '45vh',
};

export const opcionesTablaDocumentoPadre: Options<IDocumentoCompuesto> = {
    toolbar: false,
    maxBodyHeight: '20vh',
};

export const accionesTablaDetalleDocumento = (
    abrirFormulario: (detalleDocumento: IDocumentoDetalleCompuesto) => void,
    terminoAlmacenamiento: boolean
): Array<Action<IDocumentoDetalleCompuesto>> => [
    {
        disabled: terminoAlmacenamiento,
        icon: () => <EditIcon id="Icono-Editar" color={terminoAlmacenamiento ? 'disabled' : 'action'} />,
        onClick: (_, rowData) => abrirFormulario(rowData as IDocumentoDetalleCompuesto),
    },
];

export const columnasTablaDetalleDocumento = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoDetalleCompuesto>> => [
    { title: formatMessage({ id: 'almacenamiento.documentoDetalle.CodigoBarras' }), field: 'CodigoBarras' },
    { title: formatMessage({ id: 'almacenamiento.documentoDetalle.DescripcionItem' }), field: 'DescripcionItem' },
    { title: formatMessage({ id: 'almacenamiento.documentoDetalle.CantidadSolicitada' }), field: 'CantidadSolicitada' },
    { title: formatMessage({ id: 'almacenamiento.documentoDetalle.CantidadRecibida' }), field: 'CantidadRecibida' },
    {
        title: formatMessage({ id: 'almacenamiento.documentosdocumentoDetalle.DivisionOrigen' }),
        field: 'DescripcionDivisionOrigen',
    },
    {
        title: formatMessage({ id: 'almacenamiento.documentosdocumentoDetalle.DivisionDestino' }),
        field: 'DescripcionDivisionDestino',
    },
];
