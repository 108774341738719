import { WebHook } from '@infotrack/presentacion-modulosbase';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import React from 'react';
import { apiWebhookToken } from 'Infotrack@Modelos/conexiones';

const Webhook: React.FunctionComponent = () => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    return <WebHook idEmpresa={idEmpresa} idTipoAplicacion={'1'} ServicioWebHook={apiWebhookToken} />;
};

export default Webhook;