import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from 'Infotrack@Modelos/conexiones';
import IAccionEtapaDocumentoCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/administracion/IAccionEtapaDocumentoCompuesto';
import IAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAccionEtapaDocumento';

class ModeloAccionEtapaDocumento {
    private NOMBRE_CONTROLADOR = 'AccionEtapaDocumento';

    public async consultarAccionesDocumento(tipoDocumentoId: number) {
        return manejadorRest<IRespuesta<IAccionEtapaDocumentoCompuesto>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarAccionesDocumento/${tipoDocumentoId}`),
            true
        );
    }

    public async guardarAccionesEtapaDocumento(accionesEtapaDocumento: IAccionEtapaDocumento[]) {
        return manejadorRest<IRespuesta<IAccionEtapaDocumento>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/GuardarAccionesEtapaDocumento`, accionesEtapaDocumento),
            true
        );
    }

    public async consultarAccionesFiltroDocumento(accionEtapaDocumento:Partial<IAccionEtapaDocumento>) {
        return manejadorRest<IRespuesta<IAccionEtapaDocumentoCompuesto>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarPorFiltroAccionesEtapaDocumento`,accionEtapaDocumento),
            true
        );
    }
}

export default ModeloAccionEtapaDocumento;
