import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoConsecutivoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    valor: number;
}

const CampoConsecutivo: FunctionComponent<ICampoConsecutivoProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="TiposDocumento.CampoConsecutivo.Nombre" />}
        onChange={(event) => manejarCambioCampo('Consecutivo', event.target.value)}
        required
        value={valor}
        id={`CampoConsecutivo-${valor}`}
    />
);

export default CampoConsecutivo;
