import {
    AppBar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Toolbar,
} from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { contieneSoloLetras, esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import PropiedadItem from 'Infotrack@Modelos/smartStock/itemTiposPropiedades/entidades/propiedadItem';
import React from 'react';
import { IFormularioPropiedadesProductoVistaProps } from '../interfaces';

const CustomTextField = (props: TextFieldProps) => (
    <TextField
        {...props}
        autoComplete="off"
        fullWidth
        required
        value={props.value ? props.value : ''}
        variant="outlined"
    />
);

const FormularioPropiedadesProductoVista: React.FunctionComponent<IFormularioPropiedadesProductoVistaProps> = ({
    alCambiarValor,
    alCambiarValorAutoComplete,
    alCerrarFormulario,
    alEditarGuardarItemPropiedad,
    estadoFormulario,
    esMovil,
    itemPropiedades,
    itemTipoPropiedades,
    tipoFormulario,
    propiedad,
}) => (
    <Dialog open={estadoFormulario} fullWidth maxWidth={esMovil ? 'lg' : 'sm'}>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={`${
                            tipoFormulario === TipoFormulario.Creacion
                                ? 'gestionproducto.titulo.agregarPropiedades'
                                : 'gestionproducto.titulo.editarPropiedades'
                        }`}
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                getOptionLabel={({ ItemTipoPropiedadDescripcion }) => ItemTipoPropiedadDescripcion}
                                onChange={(_: any, itemPropiedad: any) =>
                                    alCambiarValorAutoComplete(itemPropiedad, 'TipoPropiedad')
                                }
                                options={itemTipoPropiedades}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={
                                            <Texto
                                                display="inline"
                                                id="gestionproducto.Propiedades.ProductoTipoPropiedadDescripcion"
                                            />
                                        }
                                    />
                                )}
                                value={itemPropiedades.TipoPropiedad}
                                id={`CampoTipoPropiedad-${itemPropiedades.TipoPropiedad}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                disabled={propiedad !== PropiedadItem.Texto}
                                label={
                                    <Texto display="inline" id="gestionproducto.Propiedades.ProductoPropiedadTexto" />
                                }
                                name="ItemPropiedadTexto"
                                onChange={(e) => contieneSoloLetras(e.target.value) && alCambiarValor(e)}
                                value={itemPropiedades.ItemPropiedadTexto}
                                id={`CampoItemPropiedad-${itemPropiedades.ItemPropiedadTexto}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomTextField
                                disabled={propiedad !== PropiedadItem.Numero}
                                label={
                                    <Texto
                                        display="inline"
                                        id="gestionproducto.Propiedades.ProductoPropiedadCantidad"
                                    />
                                }
                                name="Cantidad"
                                onChange={(e) => esEnteroODecimal(e.target.value) && alCambiarValor(e)}
                                value={itemPropiedades.Cantidad}
                                id={`CampoCantidad-${itemPropiedades.Cantidad}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={alCambiarValor}
                                        checked={Boolean(itemPropiedades.Estado)}
                                    />
                                }
                                label={<Texto id="gestionproducto.Propiedades.ProductoPropiedadEstado" />}
                                name="Estado"
                                id={`Estado`}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button onClick={alCerrarFormulario} color="secondary" variant="contained" id="boton-cancelar">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!estadoFormulario}
                        onClick={alEditarGuardarItemPropiedad}
                        color="primary"
                        variant="contained"
                        id={`${tipoFormulario === TipoFormulario.Creacion ? 'boton.agregar' : 'boton.editar'}`}
                    >
                        <Texto
                            id={`${tipoFormulario === TipoFormulario.Creacion ? 'boton.agregar' : 'boton.editar'}`}
                        />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);
export default FormularioPropiedadesProductoVista;
