import { Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IMovimientoCompuesto from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoCompuesta';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { IInputsConsultaInventario } from '../interfaces';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IMovimientoCompuesto>> => [
    {
        field: 'BodegaDescripcion',
        title: formatMessage({ id: 'inventario.tablaBodega' }),
    },
    {
        field: 'DivisionDescripcion',
        title: formatMessage({ id: 'inventario.tablaDivision' }),
    },
    {
        field: 'DescripcionItem',
        title: formatMessage({ id: 'inventario.tablaProducto' }),
    },
    {
        field: 'CodigoEmpresa',
        title: formatMessage({ id: 'inventario.CodigoProducto' }),
    },
    {
        field: 'Peso',
        title: formatMessage({ id: 'inventario.tablaPesoFraccion' }),
    },
    {
        field: 'TipoMovimientoDescripcion',
        title: formatMessage({ id: 'inventario.tablaTipoMovimiento' }),
        hidden: true,
    },
    {
        field: 'Cantidad',
        title: formatMessage({ id: 'inventario.tablaCantidad' }),
        hidden: true,
    },
    {
        field: 'CantidadTotal',
        title: formatMessage({ id: 'inventario.tablaCantidadTotal' }),
    },
    {
        field: 'Serial',
        title: formatMessage({ id: 'inventario.tablaSerial' }),
    },
    {
        field: 'Lote',
        title: formatMessage({ id: 'inventario.tablaLote' }),
    },
    {
        field: 'FechaVencimiento',
        title: formatMessage({ id: 'inventario.tablaFechaVencimiento' }),
        render: ({ FechaVencimiento }) =>
            FechaVencimiento != null ? moment(FechaVencimiento).format('YYYY-MM-DD HH:mm') : null,
    },
    {
        field: 'FechaIngreso',
        title: formatMessage({ id: 'inventario.tablaFechaIngreso' }),
        render: ({ FechaIngreso }) => (FechaIngreso != null ? moment(FechaIngreso).format('YYYY-MM-DD HH:mm') : null),
    },
    {
        field: 'Valor',
        title: formatMessage({ id: 'inventario.tablaValor' }),
    },
    {
        field: 'Estado',
        title: formatMessage({ id: 'inventario.tablaEstado' }),
        type: 'boolean',
        hidden: true,
    },
    {
        field: 'CodigoDocumento',
        title: formatMessage({ id: 'inventario.DocumentoAsociado' }),
        render: ({ CodigoDocumento, ProductoBloqueado }) => (ProductoBloqueado ? CodigoDocumento : ''),
    },
    {
        field: 'ProductoBloqueado',
        title: formatMessage({ id: 'inventario.ProductoBloqueado' }),
        render: ({ ProductoBloqueado }) => {
            return formatMessage({ id: `inventario.${ProductoBloqueado ? 'Si' : 'No'}` });
        },
    },
];

export const opciones: Options<IMovimientoCompuesto> = { toolbar: false, maxBodyHeight: '50vh' };
