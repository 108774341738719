import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioProceso } from 'Infotrack@Modelos/conexiones';
import IAuditoriaConteoDetalleComparacion from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleComparacion';
import IAuditoriaConteoDetalleCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleCompuesto';
import IAuditoriaConteoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteoDetalle';

class ModeloAuditoriaConteoDetalle {
    private NOMBRE_CONTROLADOR = 'AuditoriaConteoDetalle';

    public async crearConteoDetalle(conteoDetalle: IAuditoriaConteoDetalle[]) {
        return manejadorRest<IRespuesta<IAuditoriaConteoDetalle>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/CrearDetalle`, conteoDetalle),
            true
        );
    }

    public async consultarListaAuditoriaConteoDetalle(auditoriaConteoId: string) {
        return manejadorRest<IRespuesta<IAuditoriaConteoDetalleCompuesto>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/ConsultarLista/${auditoriaConteoId}`, {}),
            true
        );
    }

    public async editarConteoDetalle(conteoDetalle: IAuditoriaConteoDetalle[]) {
        return manejadorRest<IRespuesta<IAuditoriaConteoDetalle>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/EditarDetalle`, conteoDetalle),
            true
        );
    }

    public async compararConteos(idAuditoria: string) {
        return manejadorRest<IRespuesta<IAuditoriaConteoDetalleComparacion>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/CompararConteos/${idAuditoria}`),
            true
        );
    }

    public async compararConteoUnico(idConteo: string) {
        return manejadorRest<IRespuesta<IAuditoriaConteoDetalleComparacion>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/CompararConteo/${idConteo}`),
            true
        );
    }

    public async descargarComparacionConteo(idConteo: string) {
        return negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/DescargarComparcionConteo/${idConteo}`, undefined, {
            responseType: 'arraybuffer',
        });
    }

    public async descargarComparacionConteos(idPrimerConteo: string, idSegundoConteo: string) {
        return negocioProceso.get(
            `${this.NOMBRE_CONTROLADOR}/DescargarComparcionConteos/primerConteo/${idPrimerConteo}/segundoConteo/${idSegundoConteo}`,
            undefined,
            { responseType: 'arraybuffer' }
        );
    }
}

export default ModeloAuditoriaConteoDetalle;
