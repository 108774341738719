import { Card, CardHeader } from '@material-ui/core';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import RemoveIcon from '@material-ui/icons/Remove';

import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import TablaDetallesPlantillaProducto from './TablaDetallesPlantillaProducto';

interface IEntidadesTablaDetallesPlantilla {
    divisionesOrigen: IBodegaDivisiones[];
    divisionesDestino: IBodegaDivisiones[];
}

interface ITablaDetallesPlantillaProps {
    agregarDetalles: (detalles: IDocumentoDetalleConsultaGeneral[]) => Promise<boolean>;
    alAgregarDetalles?: () => any;
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    camposEditables?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    deshabilitada: boolean;
    detalles: IDocumentoDetalleConsultaGeneral[];
    entidades: Partial<IEntidadesTablaDetallesPlantilla>;
    tipoCantidad: 'CantidadRecibida' | 'CantidadEntregada';
    titulo: ReactNode;
    asignarSerialesVisible?: boolean;
    seriadoConsulta: boolean;
    manejaPeso: boolean;
    mostrarDesplegable: boolean;
    bodegaIdOrigen: number;
}

const TablaDetallesPlantilla: FunctionComponent<ITablaDetallesPlantillaProps> = ({
    agregarDetalles,
    alAgregarDetalles,
    detalles: detallesProp,
    entidades,
    mostrarDesplegable,
    bodegaIdOrigen,
    camposOcultar,
    camposEditables
}) => {
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [diccionarioDivisionDestino, setDiccionarioDivisionDestino] = useState<Record<string, string>>({});
    const [diccionarioCantidades, setDiccionarioCantidades] = useState<Record<string, string>>({});
    const [diccionarioDetalleSeriales, setDiccionarioDetalleSeriales] = useState<Record<string, string[]>>({});

    useEffect(() => {
        setDetalles(detallesProp.map((d) => ({ ...d })));
        construirDiccionarioCantidades();
        construirDiccionarioDivisionesDestino();
    }, [detallesProp]);

  
    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaDetallesPlantilla.DescripcionItem" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                detalle.ManejaPeso ? detalle.PesoFraccionSolicitado : <RemoveIcon id="Icono-Remove"/>,
            field: 'PesoFraccionSolicitado',
            title: <Texto id="TablaDetallesPlantilla.PesoFraccionSolicitado" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                detalle.ManejaPeso ? detalle.PesoFraccionRecibido : <RemoveIcon id="Icono-Remove"/>,
            field: 'PesoFraccionRecibido',
            title: <Texto id="TablaDetallesPlantilla.PesoFraccionEntregado" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                !detalle.ManejaPeso ? detalle.CantidadSolicitada : <RemoveIcon id="Icono-Remove"/>,
            field: 'CantidadSolicitada',
            title: <Texto id="TablaDetallesPlantilla.CantidadSolicitada" />,
        });
        columnas.push({
            field: 'CantidadEntregada',
            title: <Texto id="TablaDetallesPlantilla.CantidadEntregada" />,
        });

        return columnas;
    };

    const construirDiccionarioCantidades = () => {
        const nuevoDiccionarioCantidades: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioCantidades[detalle.DocumentoDetalleId] = diccionarioCantidades[detalle.DocumentoDetalleId]
                ? diccionarioCantidades[detalle.DocumentoDetalleId]
                : '0';
        });
        setDiccionarioCantidades(nuevoDiccionarioCantidades);
    };

    const construirDiccionarioDivisionesDestino = () => {
        const nuevoDiccionarioDivisionesDestino: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioDivisionesDestino[detalle.DocumentoDetalleId] = diccionarioDivisionDestino[
                detalle.DocumentoDetalleId
            ]
                ? diccionarioDivisionDestino[detalle.DocumentoDetalleId]
                : '';
        });
        setDiccionarioDivisionDestino(nuevoDiccionarioDivisionesDestino);
    };

     
    return (
        <>
            <Card variant="outlined">
                <CardHeader title={'Documento Detalle'} titleTypographyProps={{ variant: 'body1' }} />
                <Tabla
                    detailPanel={
                        mostrarDesplegable
                            ? (rowData) => {
                                  return (
                                      <div style={{ padding: '0px' }}>
                                          <p>{rowData.description}</p>
                                          <TablaDetallesPlantillaProducto
                                              agregarDetalles={agregarDetalles}
                                              alAgregarDetalles={alAgregarDetalles}
                                              mostrarDesplegable={false}
                                              camposOcultar={camposOcultar}
                                              camposEditables={camposEditables}
                                              deshabilitada={false}
                                              detalles={[]}
                                              entidades={entidades}
                                              tipoCantidad={'CantidadEntregada'}
                                              titulo={undefined}
                                              asignarSerialesVisible={true}
                                              seriadoConsulta={true}
                                              manejaPeso={false}
                                              idBodegaOrigen={bodegaIdOrigen}
                                              detalleBase={rowData}
                                          />
                                      </div>
                                  );
                              }
                            : undefined
                    }
                    columns={obtenerColumnas()}
                    data={detalles}
                    options={{ padding: 'dense', search: mostrarDesplegable }}
                />
            </Card>
        </>
    );
};

export { IEntidadesTablaDetallesPlantilla };

export default TablaDetallesPlantilla;
