import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral, {
    DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
} from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormularioTabla/DetallesFormulario';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import DocumentoBuscador from 'Infotrack@Transversales/componentes/DocumentoBuscador/DocumentoBuscador';
import DocumentoValorTotal from 'Infotrack@Transversales/componentes/DocumentoValorTotal/DocumentoValorTotal';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';
import FormularioDocumento, {
    IEntidadesFormularioDocumento,
} from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import TablaDetalles from 'Infotrack@Transversales/componentes/TablaDetalles/TablaDetalles';
import TablaDetallesPlantilla from 'Infotrack@Transversales/componentes/TablaDetallesPlantilla/TablaDetallesPlantilla';

import {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_EDITABLES_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_PLANTILLA,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
} from './constantes';
import EntidadesDetalleCM from './controladorModelo/EntidadesDetalleCM';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import EntradaDevolucionCM from './controladorModelo/EntradaDevolucionCM';
import { EstadosModalEntradaDevolucion } from './enumeraciones';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import ModalTablaDocumentoCliente from './ModalTablaDocumentoCliente';
import DocumentoFirma from 'Infotrack@Transversales/componentes/DocumentoFirma/DocumentoFirma';
interface IModalEntradaDevolucionProps {
    cerrar: () => void;
    documentoId: string | null;
    establecerDocumentoId: (documentoId: string) => void;
    estado: EstadosModalEntradaDevolucion;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
    cambiarEstado: () => void;
    cambiarEstadoCreado: () => void;
}

const ModalEntradaDevolucion: FunctionComponent<IModalEntradaDevolucionProps> = ({
    cerrar,
    documentoId,
    establecerDocumentoId,
    estado,
    descargarDocumentoPdf,
    cambiarEstado,
    cambiarEstadoCreado
}) => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const entradaDevolucionCM = useMemo(() => new EntradaDevolucionCM(), []);
    const entidadesDetalleCM = useMemo(() => new EntidadesDetalleCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const  usuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion);
    const idAplicacion = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdAplicacion);
    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesFormularioDocumento>>(
        ENTIDADES_DOCUMENTO_POR_DEFECTO
    );
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [entidadesDetalle, setEntidadesDetalle] = useState<{
        divisionesDestino: IBodegaDivisiones[];
    }>({divisionesDestino: [] });
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [detalleInicialEdicion, setDetalleInicialEdicion] = useState<IDocumentoDetalle | null>(null);
    const [detallesDocumentoBase, setDetallesDocumentoBase] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [abrirModalDocumentosCliente, setAbrirModalDocumentosCliente] = useState<boolean>(false);
    const [detallesDocumentoCliente, setDetallesDocumentoCliente] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [documentosCliente, setDocumentosCliente] = useState<IDocumentoConsultaGeneral[]>([]);
    const [clienteIdentificacion, setClienteIdentificacion] = useState<number>();
    const [abrirModalActa, setAbrirModalActa] = useState<boolean>(false);
    const [etapaSeleccionada, setEtapaSeleccionada] = useState<number>(0);
    useEffect(() => {
        alCambiarEstado();
    }, [estado]);

    useEffect(() => {
        if (documento) alEstablecerDocumento();
    }, [documento]);

    const alCambiarEstado = async () => {
        if (estado === EstadosModalEntradaDevolucion.CERRADO) {
            setDocumento(null);
            setDetalles([]);
            setEntidadesDocumento(ENTIDADES_DOCUMENTO_POR_DEFECTO);
            setEtapasSiguientes([]);
            setEntidadesDetalle({ divisionesDestino: [] });
            setDetalleInicialEdicion(null);
            setDetallesDocumentoBase([]);
        }
        if (estado === EstadosModalEntradaDevolucion.CREACION) {
            await consultarEntidadesDocumento();
            setDetallesDocumentoBase([]);
        }
        if (
            estado === EstadosModalEntradaDevolucion.EDICION ||
            estado === EstadosModalEntradaDevolucion.VISUALIZACION
        ) {
            await consultarDocumento(documentoId!);
        }
    };

    const consultarEntidadesDocumento = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil!!=="Administrador"? idUsuario:"",
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarEntidadesDocumentoEdicion = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoEdicion(
            documento!.BodegaDestino!,
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil!!=="Administrador"? idUsuario:"",
            TiposProceso.ENTRADA_POR_DEVOLUCION,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarDocumento = async (documentoIdConsultar: string) => {
        const documentoConsultado = await entradaDevolucionCM.consultarEncabezadoDocumento({
            DocumentoId: documentoIdConsultar,
        });
        setDocumento(documentoConsultado);
    };


    const consultaDocumentoBase = async (codigoDocumentoBase: string, identificacionCliente?: number, factura?: string) => {
        let documentoBase: IDocumentoConsultaGeneral[] = [];
        if (factura === "" || factura === undefined) {
                      documentoBase = await entradaDevolucionCM.consultarDocumentoBase({
                CodigoDocumento: codigoDocumentoBase,
                IdAgencia:usuario!.NombrePerfil!!=="Administrador"? idAgencia:"", 
                IdentificacionCliente: identificacionCliente,
                IdEmpresa: idEmpresa
            });
        } else {
            documentoBase = await entradaDevolucionCM.consultarDocumentoBaseLamda({
                CodigoDocumento: codigoDocumentoBase,
                IdAgencia: idAgencia,
                IdentificacionCliente: identificacionCliente,
                IdEmpresa: idEmpresa,
                ConsecutivoFactura: factura
            });
        }

        if (documentoBase.length > 1) {
            if (identificacionCliente) setClienteIdentificacion(identificacionCliente)
            setDocumentosCliente(documentoBase.map((x) => mapearDocumentoBase(x,false )));
            setAbrirModalDocumentosCliente(true);
        }
        if (documentoBase.length === 1) {
            setDocumento(mapearDocumentoBase(documentoBase[0],true));
        }

        return documentoBase;
    };



    const consultaDocumentoBaseFiltro = async (Serial?: string, producto?: string) => {
        let documentoBase: IDocumentoConsultaGeneral[] = [];
        documentoBase = await entradaDevolucionCM.consultarDocumentoBaseFiltro({
            Serial: Serial,
            IdAgencia: idAgencia,
            Producto: producto
        });
        
const a =documentoBase.map((x) => mapearDocumentoBase(x,false))
            setDocumentosCliente([...a]);
            console.log(documentosCliente,"documento cliente")
    };

    const mapearDestallesBase = (detalles: IDocumentoDetalleConsultaGeneral[]): IDocumentoDetalleConsultaGeneral[] => {
        const detallesBase: IDocumentoDetalleConsultaGeneral[] = detalles.map((d) => ({
            DocumentoDetalleId: d.DocumentoDetalleId,
            DocumentoId: d.DocumentoId,
            ItemId: d.ItemId,
            CantidadSolicitada: d.CantidadSolicitada,
            CantidadRecibida: d.CantidadRecibida,
            CantidadEntregada: d.CantidadEntregada,
            Valor: d.Valor,
            Serial: d.Serial,
            Lote: d.Lote,
            FechaVencimiento: d.FechaVencimiento,
            FechaIngreso: d.FechaIngreso,
            DocumentoDetallePredecesorId: d.DocumentoDetallePredecesorId,
            EmpresaId: d.EmpresaId,
            AgenciaId: d.AgenciaId,
            Estado: d.Estado,
            CodigoBarras: d.CodigoBarras,
            DescripcionDivisionDestino: d.DescripcionDivisionOrigen,
            DescripcionDivisionOrigen: d.DescripcionDivisionOrigen,
            DescripcionItem: d.DescripcionItem,
            CodigoDocumento: d.CodigoDocumento,
            DescripcionEstadoDetalle: d.DescripcionEstadoDetalle,
            FEFO: d.FEFO,
            FIFO: d.FIFO,
            Lotes: d.Lotes,
            CodigoEmpresa: d.CodigoEmpresa,
        }));
        return detallesBase;
    };

    const mapearDocumentoBase = (documentoBase: IDocumentoConsultaGeneral,consultarPorCodigo:boolean): IDocumentoConsultaGeneral => {
        return {
            ...DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
            BodegaOrigen: documentoBase.BodegaOrigen,
            ClienteId: documentoBase.ClienteId,
            CodigoDocumentoPredecesor: documentoBase.CodigoDocumento,
            CondicionComercial: documentoBase.CondicionComercial,
            DireccionEntrega: documentoBase.DireccionEntrega,
            DocumentoPredecesorId: documentoBase.DocumentoId,
            DocumentoRelacionado: documentoBase.DocumentoRelacionado,
            FechaEntrega: documentoBase.FechaEntrega,
            Observacion: documentoBase.Observacion,
            ProveedorId: documentoBase.ProveedorId,
            CodigoDocumento: documentoBase.CodigoDocumento,
            DescripcionBodegaOrigen: documentoBase.DescripcionBodegaOrigen,
            DocumentoId: consultarPorCodigo?"":documentoBase.DocumentoId,
        };
    };

    const alEstablecerDocumento = async () => {
        if (estado === EstadosModalEntradaDevolucion.CREACION) {
            if (documento!.DocumentoId) {
                establecerDocumentoId(documento!.DocumentoId);
                await consultarEtapasSiguientes();
                await consultarEntidadesDetalle();
            }
        }
        if (estado === EstadosModalEntradaDevolucion.EDICION) {
            await consultarEtapasSiguientes();
            await consultarEntidadesDetalle();
            await consultarDetallesDocumentoBase();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
        if (estado === EstadosModalEntradaDevolucion.VISUALIZACION) {
            await consultarEntidadesDetalle();
            await consultarDetallesDocumentoBase();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesDetalleCM.consultarEntidadesDetalle(
            documento!.BodegaDestino!,
            documento!.TipoDocumentoId,
            idEmpresa,
            idAgencia,
            idUsuario
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const consultarDetalles = async () => {
        const detallesConsultados = await entradaDevolucionCM.consultarDetallesDocumento({ DocumentoId: documentoId! });
        setDetalles(detallesConsultados);
    };

    const consultarDetallesDocumentoBase = async () => {
        const detallesDocumentoBaseConsultados = await entradaDevolucionCM.consultarDetallesDocumento({
            DocumentoId: documento!.DocumentoPredecesorId,
        });
        setDetallesDocumentoBase(mapearDestallesBase(detallesDocumentoBaseConsultados));
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguientesConsultadas = await entradaDevolucionCM.consultarEtapas(documento!.DocumentoId);
        setEtapasSiguientes(etapasSiguientesConsultadas);
    };

    const crearDocumento = async (documentoCrear: IDocumento) => {
        documentoCrear.DocumentoId = "";
        const respuesta = await entradaDevolucionCM.guardarEncabezado(mapearDocumentoCrear(documentoCrear));
        if (respuesta.Resultado) {
            await consultarDocumento(respuesta.Entidades[0].DocumentoId);
            await consultarDetallesDocumentoBase();
            cambiarEstado();
        }
    };

    const mapearDocumentoCrear = (documentoCrear: IDocumento): IDocumento => {
        return {
            ...documentoCrear,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
    };

    const editarDocumento = async (documentoEditar: IDocumento) => {
        const respuesta = await entradaDevolucionCM.editarEncabezado(documentoEditar);
        if (respuesta.Resultado) await consultarDocumento(documento!.DocumentoId);
    };


    const cambiarEtapa = async (etapaId: number) => {
        if ( await consultasAccionesTipoDocumento(documento!.TipoDocumentoId,etapaId) === false ) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await entradaDevolucionCM.cambiarEtapa({ ...documento!, EtapaId: etapaId }, idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
            }
        } else {
            setEtapaSeleccionada(etapaId)
        }

    };

    const consultasAccionesTipoDocumento = async (idTipoDocumento: number, idEtapa: number) => {
        debugger
        const acciones = await entidadesDocumentoCM.consultarAccionEtapaDocumentoFiltro(idTipoDocumento, idEtapa);
        if (acciones[0].EtapaDescripcion ==="Finalizado" && acciones[0].GenerarActa) {
            setAbrirModalActa(true)
            return true;
        }
        else {
            return false;
        }
    }


    const confirmarCambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => cambiarEtapa(etapaId),
            estado: true,
            mensaje: <Texto id={'etapas.CambioEtapa'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const agregarDetalles = async (detallesAgregar: IDocumentoDetalle[]) => {
        return entradaDevolucionCM.guardarDetalle(
            detallesAgregar.map((detalle) => ({
                ...detalle,
                DocumentoDetalleId: '',
                DocumentoDetallePredecesorId: detalle.DocumentoDetalleId,
                DocumentoId: documento!.DocumentoId,
                Serial: detalle!.Serial !== null?detalle!.Serial:"",
                Lote: detalle.Lote !== null?detalle.Lote:""
            }))
        );
    };

    const editarDetalle = async (detalle: IDocumentoDetalle) => {
        return entradaDevolucionCM.editarDetalle(detalle);
    };

    const eliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await entradaDevolucionCM.eliminarDetalle(detalle);
        if (respuesta) {
            alEliminarDetalle();
        }
    };

    const confirmarEliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarDetalle(detalle),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const alEliminarDetalle = async () => {
        setDetalleInicialEdicion(null);
        await consultarDetallesDocumentoBase();
        await consultarDetalles();
    };

    const consultarTipoDocumento = async (idBodega: number, nombreNodega?: string) => {
        if (nombreNodega === 'BodegaDestino') {
            const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega, estadoActivo);
            setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
        }
    };

    const consultarItemsTabla = async (idItem: number) => {
        const items = await entidadesDetalleCM.consultarItem(
            idItem,
            idEmpresa
        );
        return items.itemsConsulta;
    };

    

    const guardarFirmas = async (firma: File) => {

        const respuesta = await entidadesDocumentoCM.guardarFirmaArchivo({ IdAplicacion: idAplicacion, ExtensionArchivo: 2, NombreArchivo: firma.name, RutaAlmacenamiento: `${idAplicacion}/Firma/${documentoId}`, Identificador: documentoId! }, firma)
        if (respuesta) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await entradaDevolucionCM.cambiarEtapa({ ...documento!, EtapaId: etapaSeleccionada,PersonaRelacionada:idUsuario }, idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
                setAbrirModalActa(false);
            }
        }

    }

    const cerrarDocumentoFirma = () => {
        setAbrirModalActa(false)
    }

    const consultarFirmas = () => { }
    return (
        <Dialog open={estado !== EstadosModalEntradaDevolucion.CERRADO} maxWidth="lg">
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalEntradaDevolucion.CREACION ? (
                        <Texto id="ModalEntradaDevolucion.TituloCreacion" />
                    ) : estado === EstadosModalEntradaDevolucion.EDICION ? (
                        <Texto id="ModalEntradaDevolucion.TituloEdicion" />
                    ) : estado === EstadosModalEntradaDevolucion.VISUALIZACION ? (
                        <Texto id="ModalEntradaDevolucion.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {(!documento || !documento.DocumentoId) && (
                            <DocumentoBuscador
                                consultaDocumentoBase={consultaDocumentoBase}
                                esEntradaDevolucion={true}
                            />
                        )}
                    </Grid>
                    {documento && (
                        <Grid item xs={12}>
                            <FormularioDocumento
                                cambiarEtapa={confirmarCambiarEtapa}
                                camposDeshabilitar={
                                    estado === EstadosModalEntradaDevolucion.CREACION
                                        ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO
                                        : CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION
                                }
                                camposOcultar={CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO}
                                documento={documento}
                                editarDocumento={editarDocumento}
                                entidadesDocumento={entidadesDocumento}
                                estado={
                                    !documento || !documento.DocumentoId
                                        ? EstadosFormularioDocumento.CREACION
                                        : (estado === EstadosModalEntradaDevolucion.CREACION &&
                                            documento &&
                                            documento.DocumentoId) ||
                                            estado === EstadosModalEntradaDevolucion.EDICION
                                            ? EstadosFormularioDocumento.EDICION
                                            : EstadosFormularioDocumento.VISUALIZACION
                                }
                                etapasSiguientes={etapasSiguientes}
                                guardarDocumento={crearDocumento}
                                descargarDocumentoPdf={descargarDocumentoPdf}
                                consultarTipoDocumento={consultarTipoDocumento}
                                mostrarModalEvidencia={true}
                                esTraslado={false}
                            />
                        </Grid>
                    )}
                    {documento && documento.DocumentoId && (
                        <Grid item xs={12}>
                            <TablaDetallesPlantilla
                                agregarDetalles={agregarDetalles}
                                alAgregarDetalles={async () => {
                                    await consultarDetallesDocumentoBase();
                                    await consultarDetalles();
                                }}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_PLANTILLA}
                                deshabilitada={
                                    !documento ||
                                    !documento.ListaAcciones.PermiteAgregarDetalle ||
                                    estado === EstadosModalEntradaDevolucion.VISUALIZACION
                                }
                                detalles={detallesDocumentoBase}
                                entidades={entidadesDetalle}
                                tipoCantidad={'CantidadRecibida'}
                                titulo={
                                    <Texto
                                        fontWeight="bold"
                                        id="ModalEntradaDevolucion.TituloTablaDetallesDocumentoBase"
                                    />
                                }
                                seriadoConsulta={false}
                                manejaPeso={false}
                                esEntradaDevolucion={true}
                            />
                        </Grid>
                    )}
                    {estado !== EstadosModalEntradaDevolucion.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesFormulario
                                alEditarDetalle={async () => {
                                    setDetalleInicialEdicion(null);
                                    await consultarDetalles();
                                    await consultarDetallesDocumentoBase();
                                }}
                                camposDeshabilitar={CAMPOS_DESHABILITAR_DETALLES_FORMULARIO}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_FORMULARIO}
                                deshabilitado={!detalleInicialEdicion}
                                detalleInicialEdicion={detalleInicialEdicion}
                                documentoId={documento ? documento.DocumentoId : ''}
                                editarDetalle={editarDetalle}
                                entidades={entidadesDetalle}
                                estado={EstadosDetallesFormulario.EDICION}
                                tipoCantidad="CantidadRecibida"
                                seriadoConsulta={false}
                                esEntradaInventario={false}
                                documentoDetallesBase={detallesDocumentoBase}
                                bloquearCantidadSerial={true}
                                esOrdenTraslado={false}
                                consultarItems={consultarItemsTabla}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TablaDetalles
                            camposOcultar={CAMPOS_OCULTAR_DETALLES_TABLA}
                            detalles={detalles}
                            editarDetalle={(detalle: IDocumentoDetalleConsultaGeneral) =>
                                setDetalleInicialEdicion(detalle)
                            }
                            editarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEditarDetalle : false
                            }
                            eliminarDetalle={confirmarEliminarDetalle}
                            eliminarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEliminarDetalle : false
                            }
                            mostrarAcciones={
                                estado === EstadosModalEntradaDevolucion.CREACION ||
                                estado === EstadosModalEntradaDevolucion.EDICION
                            }
                            recargarTabla={consultarDetalles}
                            titulo={
                                <Texto fontWeight="bold" id="ModalEntradaDevolucion.TituloTablaDetallesDocumento" />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentoValorTotal detalles={detalles} />
                    </Grid>
                    {abrirModalActa && (
                        <Grid item xs={12}>
                            <DocumentoFirma
                                consultarFirmas={consultarFirmas}
                                guardarFirmas={guardarFirmas}
                                idDocumento={documento ? documento.DocumentoId : ""}
                                cerrarDocumentoFirma={cerrarDocumentoFirma}
                            />
                        </Grid>)

                    }
                    <Grid item>
                        <ModalTablaDocumentoCliente
                            abrirModal={abrirModalDocumentosCliente}
                            documentosBase={documentosCliente}
                            detallesCliente={detallesDocumentoCliente}
                            cerrar={() => setAbrirModalDocumentosCliente(false)}
                            seleccionarDocumentoBase={(documentoSeleccionado: IDocumentoConsultaGeneral) => {
                                documentoSeleccionado.DocumentoPredecesorId = documentoSeleccionado.DocumentoId;
                                documentoSeleccionado.DocumentoId = "";
                                setDocumento(documentoSeleccionado);
                                setAbrirModalDocumentosCliente(false);
                                cambiarEstadoCreado();
                            }}
                            consultaDocumentoBaseFiltro={consultaDocumentoBaseFiltro}
                            consultaDocumentoBase={consultaDocumentoBase}
                            clienteIdentificacion={clienteIdentificacion}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEntradaDevolucion;
