const mensajes = {
    'sidebar.tiposdocumento': 'Document types',

    'sidebar.ordenes': 'Orders',

    'sidebar.ordenentrada': 'Entry Order',
    'sidebar.ordensalida': 'Departure Order',

    'sidebar.entradainventario': 'Inventory Entry',
    'sidebar.salidainventario': 'Inventory Departure',
    'sidebar.entradadevolucion': 'Entry by Devolution',
    'sidebar.salidadevolucion': 'Departure By Return',
    'sidebar.traslado': 'Transfer',

    'sidebar.auditoria': 'Audit',
    'sidebar.conteo': 'Count Mmanagement',

    'sidebar.flujoetapas': 'Stages Flow ',

    'sidebar.permisosperfil': 'Permissions',

    'sidebar.plantillaimpresion': 'Printing Templates',

    'sidebar.webhook' : 'Webhook',
    'sidebar.integracion' : 'Integration',
};

export default mensajes;
