import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocioConfiguracion } from '../../conexiones';
import IItemImagenAccion from './entidades/itemImagenAccion';
export default class ModeloItemImagenes {
    public consultarItemImagenes(ItemId: number) {
        return manejadorRest<IRespuesta<IItemImagenAccion>>(
            () => negocioConfiguracion.post('ItemImagen/ConsultarItemImagenCompuestaFiltro', { ItemId }),
            true
        );
    }
}
