import React, { ChangeEvent, createRef } from 'react';
import FormularioCostosVista from '../vista/formularioCostosVista';
import ICostos from 'Infotrack@Modelos/smartStock/costos/entidades/ICostos';
import * as XLSX from 'xlsx';
import { IFormularioCostosCVProps } from '../interfaces';
import CostosCM from '../controladorModelo/CostosCM';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import IItems from 'Infotrack@Modelos/smartStock/items/entidades/IItems';
import IItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/item';

const estadoInicialCostos: ICostos = {
    ProductoId: 0,
    Producto: '',
    BodegaId: 0,
    Bodega: '',
    Costo: 0,
    CostoPromedio: 0,
    CostoMAUC: 0,
};

const defaultItem: IItems = {
    ItemId: 0,
    EmpresaId: '',
    FamiliaId: 0,
    UnidadId: 0,
    CodigoBarras: '',
    CodigoEmpresaItem: '',
    DescripcionItem: '',
    Abreviatura: '',
    Lotes: false,
    Seriado: false,
    ClasificacionId: 0,
    ClaseTecnicaId: 0,
    ReferenciaId: 0,
    MarcaId: 0,
    FabricanteId: 0,
    Modelo: '',
    Ancho: 0,
    Largo: 0,
    Alto: 0,
    Peso: 0,
    Contenedor: false,
    Serialconsecutivo: false,
    Importado: false,
    ManejaPeso: false,
    Estado: 0,
    FIFO: false,
    FEFO: false,
    RFId: '',
    Imagen: '',
    Clasificacion: '',
    Unidad: ''
};

const costosCM = new CostosCM();

const FormularioCostosCV: React.FunctionComponent<IFormularioCostosCVProps> = ({ estadoFormularioCostos, formularioEstadoCostos, itemCosto }) => {
    const [estadoCostos, setEstadoCostos] = React.useState<ICostos[]>([]);
    const [filtro, setFiltro] = React.useState<IItems>(defaultItem);
    const referenciaTabla = createRef<any>();

;

    const consultarListaCostosPorFiltro = paginacionRemota(
        costosCM.consultarListaCostosPorfiltro,
        filtro,
        'Producto'
    )

    

    const exportarExcelCostos = () => {
        const datos = estadoCostos.map((costo) => {
            return {
                "Producto": costo.Producto,
                "Bodega": costo.Bodega,
                "Costo": costo.Costo,
                "CostoPromedio": costo.CostoPromedio,
                "CostoMAUC": costo.CostoMAUC,
            }
        });

        const ws = XLSX.utils.json_to_sheet(datos);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Costos');
        XLSX.writeFile(wb, 'costos.xlsx');
    };

    const alCambiarValorFiltro = (nuevoValor: IItem[keyof IItem], nombreValor: keyof IItem) => {

        setFiltro((filtro) => ({ ...filtro, [nombreValor]: nuevoValor }));
    };

    const reiniciarFiltrosCostos = async () => {
        setFiltro(defaultItem);
    };

    const consultarDocumentos = () => {
        referenciaTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const consultaDocumentos = paginacionRemota<ICostos>(
        costosCM.consultarListaCostosPorfiltro,
        { itemCosto },
        'NombreProducto'
    );

    return (
        <FormularioCostosVista
            consultarListaCostosPorFiltro={consultarListaCostosPorFiltro}
            estadoFormularioCostos={estadoFormularioCostos}
            alCerrarFormulario={formularioEstadoCostos}
            consultarCostos={estadoCostos}
            alCambiarValorFiltro={alCambiarValorFiltro}
            valor={filtro}
            reiniciarFiltrosCostos={reiniciarFiltrosCostos}
            exportarExcelCostos={exportarExcelCostos}
            referenciaTabla={referenciaTabla}
            consultarDocumentos={consultarDocumentos}
            consulta={consultaDocumentos}
            itemCosto={itemCosto}
        />
    )
};

export default FormularioCostosCV;
