import React from 'react';
import Tabla from "@infotrack/presentacion-componentes/tabla";
import { ITablaMaximosMinimosVistaProps } from "../interfaces";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { accionesTablaMaximosMinimos, columnasTablaMaximosMinimos, opcionesMaximosMinimos } from "./constantes";

const TablaMaximosMinimosVista: React.FunctionComponent<ITablaMaximosMinimosVistaProps & InjectedIntlProps> = ({
    intl,
    maximosMinimos,
    recargarTablaMaxMin,
    confirmarEliminacionMaxMin,
    abrirFormularioMaximosMinimos,
    descargarPlantilla,
    cargarArchivoExcel,
}) => (
    <Tabla
        actions={accionesTablaMaximosMinimos(
            intl.formatMessage, 
            maximosMinimos, 
            recargarTablaMaxMin, 
            confirmarEliminacionMaxMin, 
            abrirFormularioMaximosMinimos, 
            descargarPlantilla, 
            cargarArchivoExcel
            )}
        columns={columnasTablaMaximosMinimos(intl.formatMessage)}
        data={maximosMinimos}
        options={opcionesMaximosMinimos}
        style={{ width: '100%' }}
        title={''}
    />
);

export default injectIntl(TablaMaximosMinimosVista);



