import { Catalogo } from '@infotrack/presentacion-modulosbase';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import React from 'react';
import { apiCatalogoToken } from 'Infotrack@Modelos/conexiones';

const CatalogoDatos: React.FunctionComponent = () => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    return <Catalogo idEmpresa={idEmpresa} idTipoAplicacion={1} servicioCatalogo={apiCatalogoToken} />;
};
 
export default CatalogoDatos