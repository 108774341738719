import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

interface ICampoFechaFinalProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor?: string;
}

const CampoFechaFinal: FunctionComponent<ICampoFechaFinalProps> = ({ manejarCambioFiltro, valor }) => (
    <KeyboardDatePicker
        format="DD/MM/YYYY"
        fullWidth
        inputVariant="outlined"
        label={<Texto id="Documentos.CampoFechaFinal.Nombre" />}
        onChange={(date) => {
            if (date) {
                manejarCambioFiltro('FechaFinal', date.endOf('day').format('YYYY-MM-DD HH:mm:ss'));
            }
        }}
        value={valor ? moment(valor, 'YYYY-MM-DD') : null}
        id={`CampoFechaFinal-${valor ? moment(valor, 'YYYY-MM-DD') : null}`}
    />
);

export default CampoFechaFinal;
