import IArchivo from 'Infotrack@Modelos/administracion/archivo/entidades/archivo';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import ModeloTipoDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import ModeloCliente from 'Infotrack@Modelos/smartStock/clientes';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';
import ModeloArchivo from 'Infotrack@Modelos/administracion/archivo';
import ModeloAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloAccionEtapaDocumento';

class EntidadesDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloEtapa: ModeloEtapas;
    private modeloCliente: ModeloCliente;
    private modeloBodega: ModeloBodegas;
    private modeloArchivo: ModeloArchivo;
    private modeloAccionEtapaDocumento: ModeloAccionEtapaDocumento
    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloEtapa = new ModeloEtapas();
        this.modeloCliente = new ModeloCliente();
        this.modeloBodega = new ModeloBodegas();
        this.modeloArchivo = new ModeloArchivo();
        this.modeloAccionEtapaDocumento = new ModeloAccionEtapaDocumento();
    }

    public async consultarEntidadesDocumento(idEmpresa: string, idAgencia: string, idUsuario: string, estado?: number) {
        const respuestas = await Promise.all([
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloCliente.consultarClientesPorFiltro({
                EmpresaId: idEmpresa,
                Estado: estado
            }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado
            }),
        ]);
        return {
            etapas: respuestas[0].data.Entidades,
            clientes: respuestas[1].data.Entidades,
            bodegasOrigen: respuestas[2].data.Entidades,
        };
    }

    public async consultarEntidadesDocumentoEdicion(
        idBodega: number,
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                Estado: estado,
                TipoProcesoId:tipoProcesoId
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa, Estado: estado }),
            this.modeloCliente.consultarClientesPorFiltro({
                EmpresaId: idEmpresa,
                Estado: estado
            }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado
            }),
        ]);
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            clientes: respuestas[2].data.Entidades,
            bodegasOrigen: respuestas[3].data.Entidades,
        };
    }

    public async consultarTipoDocumento(idBodega: number, estado?: number) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                TipoProcesoId: TiposProceso.SALIDA_DE_INVENTARIO,
                Estado: estado
            }),
        ]);
        return respuestas[0].data.Entidades;
    }

    public async guardarFirmaArchivo(archivoFirma: Partial<IArchivo>,
        archivo: File) {
                const respuesta = await this.modeloArchivo.GuardarDocumentoEvidencia(archivoFirma, archivo);
         return respuesta;
    }

    public async consultarAccionEtapaDocumentoFiltro(idTipoDocumento: number, idEtapa: number) {
        const respuesta = await this.modeloAccionEtapaDocumento.consultarAccionesFiltroDocumento({ TipoDocumentoId: idTipoDocumento, EtapaId: idEtapa });
        return respuesta.data.Entidades;
    }
}

export default EntidadesDocumentoCM;
