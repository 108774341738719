const mensajes = {
    // DetallesInventario
    'DetallesInventario.Titulo': 'Inventory',
    // CampoItemCodigoBarras
    'DetallesInventario.CampoItemCodigoBarras.Nombre': 'Barcode',
    // CampoItemDescripcion
    'DetallesInventario.CampoItemDescripcion.Nombre': 'Description',
    // DetallesInventarioFiltroTabla
    'DetallesInventarioFiltroTabla.Filtros': 'Filters',
    'DetallesInventarioFiltroTabla.Lote': 'Lot',
    'DetallesInventarioFiltroTabla.FechaVencimiento': 'Expiration date',
    'DetallesInventarioFiltroTabla.DivisionId': 'Origin division',
    // DetallesInventarioTabla
    'DetallesInventarioTabla.DescripcionItem': 'Item',
    'DetallesInventarioTabla.DescripcionDivision': 'Origin division',
    'DetallesInventarioTabla.CantidadActual': 'Quantity',
    'DetallesInventarioTabla.Lote': 'Lot',
    'DetallesInventarioTabla.FechaVencimiento': 'Expiration date',
    'DetallesInventarioTabla.Peso': 'The item needs a weight value',
    'DetallesInventario.CampoItemCodigoEmpresa':'Product code'
};

export default mensajes;
