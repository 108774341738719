import React from 'react';
import { useSelector } from 'react-redux';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaCompuesta from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaCompuesta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import BodegasCM from '../controladorModelo/bogedaCM';
import AgenciasCM from '../controladorModelo/agenciaCM';
import { acciones, IEstadoGestionBodegas } from '../reductorGestionBodegas';
import TablaGestionBodegasVista from '../vista/tablaGestionBodegasVista';
import IAgencia from 'Infotrack@Modelos/administracion/agencia/entidades/agencia';

const TablaGestionBodegasCV: React.FunctionComponent = () => {
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const bodegasCM = new BodegasCM();
    const agenciasCM = new AgenciasCM();
    const nombrePerfil = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.NombrePerfil);

    const [bodegas, setBodegas] = React.useState<IBodegaCompuesta[]>([]);
    const [{ recargarTabla }, dispatch] = useProveedor<IEstadoGestionBodegas>();

    React.useEffect(() => {
        if (recargarTabla) {
            cargarBodegas();
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    }, [recargarTabla]);

    const alAbrirFormulario = (bodega?: IBodegaCompuesta) => {
        const bodegaAEditar = bodega ? { ...bodega, Estado: Boolean(bodega.Estado) } : null;
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO, payload: bodegaAEditar });
        dispatch({
            type: acciones.MODIFICAR_TIPO_FORMULARIO,
            payload: bodega ? TipoFormulario.Edicion : TipoFormulario.Creacion,
        });
        if (bodega) dispatch({ type: acciones.CAMBIAR_ESTADO_BODEGA_CREADA });
    };

    const cargarBodegas = async () => {
        const bodegas = await bodegasCM.consultarListaBodegasCompuestaFiltro({
            AgenciaId: nombrePerfil!!=="Administrador"? idAgencia:"" ,
            IdUsuario: undefined,
            EmpresaId: idEmpresa,
        });
        const agencias = await agenciasCM.consultarAgenciasFiltro(idEmpresa);
        let bodegaMapeada = mapearBodegaAgencia(bodegas, agencias);
        setBodegas(bodegaMapeada);
    };

    const confirmarEliminacion = (bodega: IBodega) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarBodega(bodega),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };
    
    
    const eliminarBodega = (bodega: IBodega) => {
        bodegasCM.eliminarBodegaCompuesto(bodega).then(() => {
            cargarBodegas();
        });
        manejadorDialogoGlobal({ estado: false });
    };

    const mapearBodegaAgencia = (bodegasMapear: IBodegaCompuesta[], agencias: IAgencia[]) => {
        let bodegasCompuesta: IBodegaCompuesta[] = [];
        bodegasMapear.map((bodega) => {
            agencias.map((agencia) => {
                if (bodega.AgenciaId === agencia.IdAgencia) {
                    bodega.DescripcionAgencia = agencia.NombreAgencia;
                }
            });
            bodegasCompuesta.push(bodega);
        });
        return bodegasCompuesta;
    };

    return (
        <TablaGestionBodegasVista
            alAbrirFormulario={alAbrirFormulario}
            alEliminarBodega={confirmarEliminacion}
            bodegas={bodegas}
            recargarTabla={cargarBodegas}
        />
    );
};

export default TablaGestionBodegasCV;
