import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocioConfiguracion } from 'Infotrack@Modelos/conexiones';
import IDocumento from '../documentos/entidades/documento';
import IDocumentoCompuesto from '../documentos/entidades/documentoCompuesto';
import IDocumentoPedidoFiltrosDashboard from './entidades/documentoPedidoFiltrosDashboard';
import IEstadisticasDashboard from './entidades/estadisticasDashboard';
import IFiltrosConsultasDashboard from './entidades/filtrosConsultasDashboard';

export default class ModeloEstadisticasDashboard {
    public ConsultarRotacionItems(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarRotacionItems', filtros),
            true
        );
    }

    public consultarItemsEnStockMinino(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarItemsEnStockMinino', filtros),
            true
        );
    }

    public consultarItemsMasAntiguos(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarItemsMasAntiguos', filtros),
            true
        );
    }

    public consultarUtilizacionBodega(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarUtilizacionBodega', filtros),
            true
        );
    }

    public consultarEstadosPedidos(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarEstadosPedidos', filtros),
            true
        );
    }

    public consultarCumplimientoPedidosVigentes(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarCumplimientoPedidosVigentes', filtros),
            true
        );
    }

    public consultarCumplimientoPedidosHistorico(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarCumplimientoPedidosHistorico', filtros),
            true
        );
    }

    public consultarTop10PedidosClientes(filtros: IFiltrosConsultasDashboard) {
        return manejadorRest<IRespuesta<IEstadisticasDashboard>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarTop10PedidosClientes', filtros),
            true
        );
    }

    public consultarListaDocumentoEstadoPedidos(filtros: IDocumento) {
        return manejadorRest<IRespuesta<IDocumentoCompuesto>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarListaDocumentoEstadoPedidos', filtros),
            true
        );
    }

    public consultarListaCumplimientoPedidos(filtros: IDocumentoPedidoFiltrosDashboard) {
        return manejadorRest<IRespuesta<IDocumentoCompuesto>>(
            () => negocioConfiguracion.post('ConsultasDashboard/ConsultarListaCumplimientoPedidos', filtros),
            true
        );
    }
}
