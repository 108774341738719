import React, { FunctionComponent } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Texto from '@infotrack/presentacion-componentes/texto';

interface IExportToExcel {
  fileName: string;
  ejecutarConsulta: () => Promise<any[]>;
}

const ExportToExcel: FunctionComponent<IExportToExcel> = ({
  fileName,
  ejecutarConsulta,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = async () => {
    const valor = await ejecutarConsulta();
    const ws = XLSX.utils.json_to_sheet(valor);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, fileName + fileExtension);
  };

  return (
    <Tooltip title={<Texto id="Comunes.ExportarXLSX" />} arrow>
      <Button onClick={exportToCSV}>
        <DownloadIcon />
      </Button>
    </Tooltip>
  );
};

export default ExportToExcel;
