import { Card, Grid, IconButton, MenuItem, TextField, withStyles } from '@material-ui/core';
import FlechaIzquierda from '@material-ui/icons/KeyboardArrowLeft';
import FlechaDerecha from '@material-ui/icons/KeyboardArrowRight';
import { ConsistentWith } from '@material-ui/types';
import React, { PropsWithChildren } from 'react';
import estilos from './estilos';

export interface IPaginadorProps {
  className?: string;
  cambiarPagina: (siguiente: boolean) => void;
  cambiarOpcionesRegistros: (opcion: number) => void;
  pagina: number;
  registrospagina?: number;
  opcionesRegistros?: number[];
  totalRegistros: number;
}

const Paginador: React.FunctionComponent<PropsWithChildren<
  ConsistentWith<PropsWithChildren<IPaginadorProps>, { classes: Record<string, string> }> & {
    classes: Record<string, string>;
  }
>> = ({
  classes,
  className,
  cambiarPagina,
  cambiarOpcionesRegistros,
  pagina,
  registrospagina: registrospaginaProps,
  opcionesRegistros: opcionesRegistrosProps,
  totalRegistros,
}) => {
    const registrosXpagina = registrospaginaProps ? registrospaginaProps : 10;
    const opcionesRegistros = opcionesRegistrosProps ? opcionesRegistrosProps : [10, 20, 30];
    const registroInicialPagina = registrosXpagina * pagina + 1;
    const registroFinalPagina =
      (pagina + 1) * registrosXpagina < totalRegistros ? (pagina + 1) * registrosXpagina : totalRegistros;
    const esPaginaInicial = registroInicialPagina === 1;
    const esPaginaFinal = registroFinalPagina === totalRegistros;
    return (
      <Card className={`${classes.contenedorPaginador} ${className}`}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <span>
              {registroInicialPagina} - {registroFinalPagina} | {totalRegistros}
            </span>
          </Grid>
          <Grid item className={classes.elementosPaginador}>
            <TextField
              select
              onChange={({ target: { value } }) => cambiarOpcionesRegistros(Number(value))}
              value={registrosXpagina}
            >
              {opcionesRegistros.map((opcion, indice, opciones) => {
                const opcionAnterior = opciones[indice - 1];
                const deshabilitarOpcion = opcionAnterior ? totalRegistros - opcionAnterior < 0 : false;
                return (
                  <MenuItem disabled={deshabilitarOpcion} key={opcion + indice} value={opcion}>
                    {opcion}
                  </MenuItem>
                );
              })}
            </TextField>
            <IconButton id="boton-izquierda" onClick={() => cambiarPagina(false)} disabled={esPaginaInicial}>
              <FlechaIzquierda />
            </IconButton>
            <IconButton id="boton-derecha" onClick={() => cambiarPagina(true)} disabled={esPaginaFinal}>
              <FlechaDerecha />
            </IconButton>

          </Grid>
        </Grid>
      </Card>
    );
  };

export default withStyles(estilos)(Paginador);
