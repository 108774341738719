import React, { useEffect } from 'react';
import Tabla, { Column, Query, QueryResult } from '@infotrack/presentacion-componentes/tabla';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { AppBar,  Card, CardContent, Checkbox, Dialog, DialogContent, Grid, TextField } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';

export interface ITablaProductosProps {
    abrirTablaProducto: boolean;
    cerrar: () => void;
    consulta: ({ page, pageSize, orderBy, orderDirection }: Query<any>) => Promise<QueryResult<any>>;
    referenciaTabla: React.RefObject<any>;
    consultarDocumentos: () => void;
    seleccionarProducto: (item: IItemsConsulta) => void;
    manejarCambioFiltro: (nombreFiltro: keyof IFiltrosItem, nuevoValor: IFiltrosItem[keyof IFiltrosItem]) => void;
    filtrosItem: IFiltrosItem;
    limpiarFiltros: () => void;
}
const TablaProductos: React.FunctionComponent<ITablaProductosProps &
    InjectedIntlProps> = ({
        intl,
        abrirTablaProducto,
        referenciaTabla,
        consultarDocumentos,
        consulta,
        cerrar,
        manejarCambioFiltro,
        filtrosItem,
        limpiarFiltros,
        seleccionarProducto
    }) => {
        useEffect(() => {
            if (referenciaTabla.current) {
                consultarDocumentos();
            }
        }, [abrirTablaProducto]);

        {
            const columnasTablaProducto = (
                formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
            ): Array<Column<IItemsConsulta>> => [
                    {
                        title: formatMessage({ id: 'DetallesFormulario.Seleccionar' }),
                        render: (producto: IItemsConsulta) => {
                            return (
                                <Checkbox
                                    size="small"
                                    onChange={() => seleccionarProducto(producto)}
                                />
                            );
                        },
                    },
                    {
                        field: 'CodigoEmpresa',
                        title: formatMessage({ id: 'DetallesFormulario.CodigoEmpresa.Nombre' }),
                    },
                    {
                        field: 'DescripcionItem',
                        title: formatMessage({ id: 'DetallesFormulario.CampoDescripcionItem.Nombre' }),
                    },
                ];


            return (
                <Dialog fullWidth open={abrirTablaProducto} maxWidth="lg">
                    <AppBar position="static">
                        <ModalEncabezado
                            cerrar={cerrar}
                            titulo={<Texto align="center" color="inherit" id={'DetallesFormulario.Productos'} />}
                        />
                    </AppBar>
                    <DialogContent>
                        <Card>
                            <CardContent>
                                <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                                    <Grid item md={true} xs={6}>
                                        <TextField
                                            fullWidth
                                            label={<Texto id='DetallesFormulario.CodigoEmpresa.Nombre' />}
                                            name="CodigoEmpresa"
                                            onChange={(event) => manejarCambioFiltro("CodigoEmpresa", event.target.value)}
                                            variant="outlined"
                                            value={filtrosItem.CodigoEmpresa}
                                            id={`CampoCodigoEmpresa-${filtrosItem.CodigoEmpresa}`}
                                        />
                                    </Grid>

                                    <Grid item md={true} xs={6}>
                                        <TextField
                                            fullWidth
                                            label={<Texto id='DetallesFormulario.CampoDescripcionItem.Nombre' />}
                                            name="DescripcionItem"
                                            onChange={(event) => manejarCambioFiltro("DescripcionItem", event.target.value)}
                                            variant="outlined"
                                            value={filtrosItem.DescripcionItem}
                                            id={`CampoCodigoEmpresa-${filtrosItem.CodigoEmpresa}`}
                                        />
                                    </Grid>
                                    <Grid item md="auto" xs={6}>
                                        <BotonBuscar onClick={consultarDocumentos} id="boton-limpiar" />
                                    </Grid>
                                    <Grid item md="auto" xs={6}>
                                        <BotonLimpiar onClick={(event) => { limpiarFiltros() }} id="boton-limpiar" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Tabla
                                    actions={[]}
                                    columns={columnasTablaProducto(
                                        intl.formatMessage,
                                    )}
                                    data={consulta}
                                    options={{ search: true }}
                                    style={{ width: '100%' }}
                                    title={''}
                                    tableRef={referenciaTabla}
                                />
                            </CardContent>
                        </Card>
                    </DialogContent>
                </Dialog>
            );
        }



    };

export default injectIntl(TablaProductos);
