import { Fab } from '@material-ui/core';
import { CropFree as CropFreeIcon } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

interface IBotonAbrirModalSerialesProps {
    abrirModalSeriales: () => void;
    deshabilitado: boolean;
}

const BotonAbrirModalSeriales: FunctionComponent<IBotonAbrirModalSerialesProps> = ({
    abrirModalSeriales,
    deshabilitado,
}) => (
    <Fab color="primary" disabled={deshabilitado} onClick={abrirModalSeriales} size="small" id="Icono-CropFree">
        <CropFreeIcon />
    </Fab>
);

export default BotonAbrirModalSeriales;
