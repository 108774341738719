import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Texto from '@infotrack/presentacion-componentes/texto';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';

interface ICampoCodigoBarrasItemProps {
    deshabilitado: boolean;
    manejarCambioFiltro: (nombreFiltro: keyof IFiltrosItem, nuevoValor: IFiltrosItem[keyof IFiltrosItem]) => void
    valor: string | undefined;
    abrirTablaProductos: () => void;
}

const CampoCodigoBarrasItem: FunctionComponent<ICampoCodigoBarrasItemProps> = ({
    manejarCambioFiltro,
    valor,
    abrirTablaProductos,
    deshabilitado
}) => {

    return (
        <TextField
            fullWidth
            label={<Texto id='DetallesFormulario.CodigoEmpresa.Nombre' />}
            name="CodigoEmpresa"
            onChange={(event) => manejarCambioFiltro("CodigoEmpresa", event.target.value)}
            variant="outlined"
            value={valor}
            id={`CodigoEmpresa`}
            InputProps={{
                endAdornment: (
                    !deshabilitado?
                    <InputAdornment position="end"  >
                        <IconButton edge="end" onClick={abrirTablaProductos} disabled={valor ? valor.length < 3 : true}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>:null
                ),
            }}
            disabled={deshabilitado}
            InputLabelProps={{ shrink: true }}  
            helperText={(valor === ""|| valor === undefined|| valor.length < 3 ) ?(
                <Texto id="DetallesFormulario.CaracteresMinimos" />) : ('')
            }
        />

    );
};

export default CampoCodigoBarrasItem;
