import { TextField } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

interface ICampoIdentificacionClienteProps {
    manejarCambioCliente: (nuevoValor: number) => any;
    valor?: number;
    refrescar?: boolean;
}

const CampoIdentificacionCliente: FunctionComponent<ICampoIdentificacionClienteProps> = ({
    manejarCambioCliente,
    valor,
    refrescar,
}) => {

    useEffect(() => {
        manejarCambioCliente(0);
    }, [refrescar]);
    
    return (
        <>
            <TextField
                fullWidth
                label={<Texto id="DocumentoBuscador.CampoIdentificacionCliente.Nombre" />}
                onChange={(event) => {
                    debugger;
                    console.log(/^\d*$/.test(event.target.value), 'regex');
                    if (/^\d*$/.test(event.target.value) && event.target.value !== undefined) {
                        console.log(event.target.value, 'Value');
                        manejarCambioCliente(event.target.value !== '' ? parseInt(event.target.value) : 0);
                        console.log(valor, 'Valor');
                    }
                }}
                value={valor === 0 ? '' : valor}
                id={`CampoIdentificacionCliente-${valor}`}
            />
        </>
    );
};

export default CampoIdentificacionCliente;
