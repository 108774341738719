import React, { useMemo } from 'react';
import { reportes } from '../../../../modelos/conexiones';
import { IEstadoAPropsReportesPowerCV } from '../interfaces';
import ReporteEncuestasVista from '../vista/reportesPowerVista';
import ReporteCM from '../controladorModelo/reporteCM';
import IParametrosReporte from 'Infotrack@Modelos/smartStock/reportes/entidades/IParametrosReporte';
import { useSelector } from 'react-redux';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

const PARAMETROS_REPORTE_DEFECTO: IParametrosReporte = {
    EmbedToken: '',
    EmbedUrl: '',
    ReportId: '',
};

const ReportesPowerCV: React.FunctionComponent<IEstadoAPropsReportesPowerCV> = ({ nombreReporte, filtro,segundoFiltro }) => {
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const idAplicacion = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.IdAplicacion
    );
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const [parametrosReporte, setParametrosReporte] = React.useState<IParametrosReporte>(PARAMETROS_REPORTE_DEFECTO);
    const [esPantallaCompleta, setEsPantallaCompleta] = React.useState<boolean>(false);
    const reporteCM = useMemo(() => new ReporteCM(reportes), []);

    React.useEffect(() => {
        establecerUrlReporte();
    }, []);

    const establecerUrlReporte = async () => {
        const parametrosReporteConsulta = await reporteCM.consultarParametrosReportes(nombreReporte, idAplicacion);
        if (parametrosReporteConsulta) {
            parametrosReporteConsulta.EmbedUrl =
                filtro !== ''               
                    ?
                    segundoFiltro?`${parametrosReporteConsulta.EmbedUrl}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&$filter=${filtro} eq '${idUsuario}' and ${segundoFiltro} eq '${idEmpresa}'`
                    :
                                       
                    `${parametrosReporteConsulta.EmbedUrl}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXNvdXRoLWNlbnRyYWwtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&$filter=${filtro} in ('${idUsuario}')`
                    : parametrosReporteConsulta.EmbedUrl;
            setParametrosReporte(parametrosReporteConsulta);
        }

        console.log(parametrosReporte.EmbedUrl,"Url reporte");
    };

    const alCambiarPantallaCompleta = () => {
        setEsPantallaCompleta((estadoAnterior) => !estadoAnterior);
    };
    return (
        <ReporteEncuestasVista
            alCambiarPantallaCompleta={alCambiarPantallaCompleta}
            esPantallaCompleta={esPantallaCompleta}
            parametrosReporte={parametrosReporte}
            filtros={[
                {
                    $schema: 'http://powerbi.com/product/schema#basic',
                    target: { table: 'BodegaUsuario', column: 'UsuarioId' },
                    operator: 'In',
                    values: [idUsuario],
                    filterType: 1,
                },
            ]}
        />
    );
};

export default ReportesPowerCV;

