
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, TextField } from "@material-ui/core";
import ModalEncabezado from "Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado";
import Texto from "@infotrack/presentacion-componentes/texto";
import React, { useEffect } from 'react'
import { BotonBuscar, BotonLimpiar } from "Infotrack@Transversales/componentes/botonesAcciones";
import Tabla from "@infotrack/presentacion-componentes/tabla";
import {  columnasTablaCostos, opciones } from "./constantes";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Autocomplete } from "@material-ui/lab";
import ICostos from "Infotrack@Modelos/smartStock/costos/entidades/ICostos";
import { IFormularioCostosVistaProps } from "../interfaces";

const FormularioCostosVista: React.FunctionComponent<IFormularioCostosVistaProps & InjectedIntlProps> = ({
    estadoFormularioCostos,
    alCerrarFormulario,
    intl: { formatMessage },
    consultarCostos,
    alCambiarValorFiltro,
    valor,
    reiniciarFiltrosCostos,
    exportarExcelCostos,
    consultarListaCostosPorFiltro,
    referenciaTabla,
    consultarDocumentos,
    consulta,
    itemCosto
}) => {
    useEffect(() => {
        if (referenciaTabla.current) {
            consultarDocumentos();
        }
    }, [estadoFormularioCostos]);

    return (
        <Dialog open={estadoFormularioCostos!} fullWidth maxWidth='lg'>
            <ModalEncabezado
                cerrar={alCerrarFormulario}
                titulo={
                    <Texto
                        align="center"
                        color="inherit"
                        id={'gestionproducto.TituloCostos'}
                    />
                }
            />
            <DialogContent>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                                <Grid item md={true} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto display="inline" id="gestionproducto.Producto" />}
                                        onChange={(event) => alCambiarValorFiltro(event.target.value, 'DescripcionItem')}
                                        value={itemCosto? itemCosto.DescripcionItem:""}
                                        inputProps={{ maxLength: 100 }}
                                        id={`DescripcionItemCosto`}
                                    />


                                </Grid>
                                <Grid item md={true} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto display="inline" id="CodigoEmpresa" />}
                                        onChange={(event) => alCambiarValorFiltro(event.target.value, 'CodigoEmpresa')}
                                        value={itemCosto? itemCosto.CodigoEmpresa:""}
                                        inputProps={{readOnly: true }}
                                        id={`CodigoEmpresaItemCosto`}
                                    />
                                </Grid>
                                <Grid item md={true} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto display="inline" id="gestionproducto.CostoPromedio" />}
                                        onChange={(event) => alCambiarValorFiltro(event.target.value, 'CodigoEmpresa')}
                                        value={itemCosto? itemCosto.Valor:""}
                                        inputProps={{readOnly: true }}
                                        id={`CostoPromedioItemCosto`}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid item xs={12}>
                        <Tabla
                            actions={[]}
                            columns={columnasTablaCostos(formatMessage)}
                            data={consulta}
                            options={opciones}
                            tableRef={referenciaTabla}
                            
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => { alCerrarFormulario(), reiniciarFiltrosCostos() }}
                            style={{ marginRight: '3px' }}
                            variant="contained"
                            id={'boton-cancelar'}
                        >
                            <Texto id={'boton.cancelar'} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default injectIntl(FormularioCostosVista);