import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion, middlewareCoimpresores,urlNegocioCoimpresores } from 'Infotrack@Modelos/conexiones';

import IItems from './entidades/IItems';
import IItemsConsulta from './entidades/IItemsConsulta';
import IItemEtiqueta from './entidades/IItemDetalleCompuesto';
import IEtiquetaItem from './entidades/IEtiquetaItem';
import IFiltroImpresion from '../impresora/entidades/filtro';

class ModeloItems {
    private NOMBRE_CONTROLADOR = 'Item';

    public async consultarListaItemsCompuestosFiltro(filtro: Partial<IItems>) {
                return manejadorRest<IRespuesta<IItemsConsulta>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaItemCompuestosFiltro`, filtro),
            true
        );
    }

    public async consultarListaItemsBodega(idBodega: number) {
        return manejadorRest<IRespuesta<IItemsConsulta>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarItemsBodega/${idBodega}`),
            true
        );
    }

    public async consultarListaItemsAuditoria(idAuditoria: string) {
        return manejadorRest<IRespuesta<IItemsConsulta>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarItemsAuditoria/${idAuditoria}`),
            true
        );
    }

    public async consultarItemsDocumento(idDocumento: string) {
        return manejadorRest<IRespuesta<IItemsConsulta>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarItemsDocumento/${idDocumento}`, []),
            true
        );
    }

    public async consultarItemsMiddleware(idEtiqueta: string) {
        return manejadorRest<IRespuesta<IItems>>(
            () => middlewareCoimpresores.get(`ItemsEtiqueta/ConsultaGeneral?idEtiqueta=${idEtiqueta}`),
            true
        );
    }

    public async consultarItemsEtiquetaMiddleware(filtro: IFiltroImpresion) {
        return manejadorRest<IRespuesta<IItemEtiqueta>>(
            () => middlewareCoimpresores.post(`ItemsEtiqueta/ConsultaDetalle`, filtro),
            true
        );
    }

    public async consultarItemsSinEtiqueta(idEmpresa: string, idsItem: number[]) {
        return manejadorRest<IRespuesta<IItems>>(
            () =>
                negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarItemsExcluyentes`, {
                    Items: idsItem,
                    EmpresaId: idEmpresa,
                }),
            true
        );
    }

    public async guardarItemsEtiqueta(etiquetaItem: IEtiquetaItem[]) {
        return manejadorRest<IRespuesta<IEtiquetaItem>>(
            () => urlNegocioCoimpresores.post(`ItemsEtiqueta/GuardarItemsEtiqueta`, etiquetaItem),
            true
        );
    }
}

export default ModeloItems;
