import React, { FunctionComponent } from 'react';
import { Delete as DeleteIcon, Edit as EditIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import Texto from '@infotrack/presentacion-componentes/texto';
import { Card } from '@material-ui/core';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import IFlujoEtapas from '../../../../modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumento';
import IflujoEtapaDocumentoCompuesto from 'Infotrack@Modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumentoCompuesto';

interface IFlujoEtapasTablaProps {
    listaFlujoEtapas: IflujoEtapaDocumentoCompuesto[];
    eliminarFlujoEtapas: (flujo: IFlujoEtapas) => void;
    editarFlujoEtapas: (flujo: IFlujoEtapas) => void;
}

const FlujoEtapasTabla: FunctionComponent<IFlujoEtapasTablaProps> = ({
    editarFlujoEtapas,
    eliminarFlujoEtapas,
    listaFlujoEtapas,
}) => {
    const obtenerColumnas = () => {
        const columnas: Array<Column<IflujoEtapaDocumentoCompuesto>> = [
            {
                title: <Texto id="FlujoEtapasTabla.FlujoDescripcion" />,
                field: 'FlujoDescripcion',
            },
            {
                title: <Texto id="FlujoEtapasTabla.EtapaActual" />,
                field: 'EtapaActualDescripcion',
            },
            {
                title: <Texto id="FlujoEtapasTabla.EtapaSiguiente" />,
                field: 'EtapaSiguienteDescripcion',
            },
            {
                title: <Texto id="FlujoEtapasTabla.Estado" />,
                field: 'Estado',
                type: 'boolean',
            },
        ];
        return columnas;
    };

    return (
        <Card variant="outlined">
            <Tabla
                actions={[
                    {
                        icon: () => <EditIcon id="Icono-Editar" color={'action'} />,
                        onClick: (_, data) => editarFlujoEtapas(data),
                    },
                    {
                        icon: () => <DeleteIcon id="Iconoe-Eliminar" color={'action'} />,
                        onClick: async (_, data) => {
                            const eliminacionExitosa = await eliminarFlujoEtapas(data);
                        },
                    },
                ]}
                columns={obtenerColumnas()}
                data={listaFlujoEtapas}
                options={{ padding: 'dense' }}
            />
        </Card>
    );
};
export default FlujoEtapasTabla;
