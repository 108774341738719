import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import ModeloMovimiento from 'Infotrack@Modelos/smartStock/movimientos';
import IMovimientoFechaFiltro from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoFechaFiltro';

export default class MovimientoCM {
    private modeloMovimiento: ModeloMovimiento;

    constructor() {
        this.modeloMovimiento = new ModeloMovimiento();
    }

    public consultarMovimientosCompuestoFiltro = (paginador: IPaginador, filtros: IMovimientoFechaFiltro, IdUsuario?: string) => {
        if (!!filtros.FechaInicial) filtros.FechaInicial = filtros.FechaInicial; 
        if (!!filtros.FechaFinal) filtros.FechaFinal = filtros.FechaFinal; 
        if (!!filtros.Serial) filtros.Serial = filtros.Serial.toUpperCase();
        if (!!filtros.Lote) filtros.Lote = filtros.Lote.toUpperCase();
        return this.modeloMovimiento
            .consultarMovimientosCompuestoFiltro({ paginador, filtro: filtros })
            .then(({ data }) => data);
    };
}
