import React from 'react';
import { IFormularioMaximosMinimosCVProps, IInputsMaximosMinimos } from '../interfaces';
import FormularioMaximosMinimosVista from '../vista/formularioMaximosMinimosVista';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { IEstadoGestionBodegas, acciones } from '../reductorGestionBodegas';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import MaximosMinimosCM from '../controladorModelo/maximosMinimosCM';
import {
    manejadorDialogoGlobal,
    manejadorIndicadorCargaGlobal,
} from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import Texto from '@infotrack/presentacion-componentes/texto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { connect } from 'react-redux';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';

const estadoInicial: IInputsMaximosMinimos = {
    BodegaId: 0,
    ItemId: 0,
    CodigoProducto: '',
    NombreProducto: '',
    NivelMinimo: 0,
    NivelMaximo: 0,
};

const maximosMinimosCM = new MaximosMinimosCM();

const FormularioMaximosMinimosCV: React.FunctionComponent<IFormularioMaximosMinimosCVProps> = ({ IdEmpresa }) => {
    const [
        { bodega, estadoFormularioMaximosMinimos, tipoFormularioMaximosMinimos, entidadMaximosMinimos },
        dispatch,
    ] = useProveedor<IEstadoGestionBodegas>();
    const { alCambiarValor, setValor, reiniciar, valor } = useInputState<IInputsMaximosMinimos>(estadoInicial);
    const [productos, setProductos] = React.useState<IProductoPrincipal[]>([]);

    React.useEffect(() => {
        reiniciar();
        if (!estadoFormularioMaximosMinimos) {
        }
        if (entidadMaximosMinimos) {
            setValor(entidadMaximosMinimos!);
        }
    }, [estadoFormularioMaximosMinimos]);
    const cerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_MAXIMOS_MINIMOS, payload: null });
    };

    const consultarProductos = async () => {
        const filtros = {
            EmpresaId: IdEmpresa,
        };
        const respuesta = await maximosMinimosCM.consultarListaProductoPrincipal(filtros);
        setProductos(respuesta.Entidades);
    };

    const confirmarCreacionMaximosMinimos = () => {
        if (maximosMinimosCM.validarCampos(mapearDatos())) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => {
                    crearEditarMaximosMinimos().then(() => {
                        manejadorDialogoGlobal({ estado: false });
                    });
                },
                estado: true,
                mensaje: (
                    <Texto
                        id={
                            tipoFormularioMaximosMinimos === TipoFormulario.Creacion
                                ? 'alerta.confirmacionCreacion'
                                : 'alerta.confirmacionEdicion'
                        }
                    />
                ),
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const crearEditarMaximosMinimos = () => {
        manejadorIndicadorCargaGlobal(true);

        const promesaMaximoMinimo =
            tipoFormularioMaximosMinimos === TipoFormulario.Creacion
                ? maximosMinimosCM.crearMaximoMinimo(mapearDatos())
                : maximosMinimosCM.editarMaximoMinimo(mapearDatos());

        return promesaMaximoMinimo.then(() => {
            cerrarFormulario();
            manejadorIndicadorCargaGlobal(false);
            dispatch({ type: acciones.RECARGAR_TABLA_MAXIMOS_MINIMOS });
        });
    };

    const mapearDatos = (): IInputsMaximosMinimos => ({
        BodegaId: bodega.BodegaId,
        ItemId: valor.ItemId,
        CodigoProducto: valor.CodigoProducto,
        NombreProducto: valor.NombreProducto,
        NivelMinimo: valor.NivelMinimo,
        NivelMaximo: valor.NivelMaximo,
    });

    const alCambiarValorAutocomplete = (nuevoValor: any, nombreValor: string, propiedadCodigo?: string) => {
        const nuevoItemId = nuevoValor ? nuevoValor.ItemId : 0;
        const nuevoNombreProducto = nuevoValor ? nuevoValor.DescripcionItem : '';
        const nuevoCodigoProducto = nuevoValor ? nuevoValor.CodigoEmpresa : '';

        const nuevoValorObj = {
            ...valor,
            ItemId: nuevoItemId,
            NombreProducto: nuevoNombreProducto,
            CodigoProducto: nuevoCodigoProducto,
        };

        setValor(nuevoValorObj);
    };

    return (
        <FormularioMaximosMinimosVista
            alCambiarValor={alCambiarValor}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            estadoFormulario={estadoFormularioMaximosMinimos}
            tipoFormulario={tipoFormularioMaximosMinimos}
            cerrarFormulario={cerrarFormulario}
            crearEditarMaximosMinimos={confirmarCreacionMaximosMinimos}
            valor={valor}
            listaItem={productos}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioMaximosMinimosCVProps => ({ IdEmpresa: usuarioInformacion!.IdEmpresa, IdBodega: '' });

export default connect<IFormularioMaximosMinimosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioMaximosMinimosCV);
