import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaDespachosVistaProps } from '../interfaces';
import { accionesTablaDespachos, columnasTablaDespachos } from './constantes';

const TablaDespachosVista: React.FunctionComponent<ITablaDespachosVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alAnularDocumento,
    alCambiarValor,
    cargarDocumentos,
    consultaDocumentos,
    entidadesFiltro,
    intl: { formatMessage },
    filtrosPedidos,
    refTabla,
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    fullWidth
                                    label={<Texto id="despachos.Bodega" />}
                                    name="BodegaId"
                                    onChange={alCambiarValor}
                                    select
                                    required
                                    value={filtrosPedidos.BodegaId}
                                    variant="outlined"
                                    id={`CampoBodega-${filtrosPedidos.BodegaId}`}
                                >
                                    {entidadesFiltro.bodegas.map(({ BodegaId, BodegaDescripcion }) => (
                                        <MenuItem key={BodegaId} value={BodegaId}>
                                            {BodegaDescripcion}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    label={<Texto id="despachos.NumeroDocumento" />}
                                    fullWidth
                                    name="CodigoDocumento"
                                    onChange={alCambiarValor}
                                    value={filtrosPedidos.CodigoDocumento}
                                    variant="outlined"
                                    id={`CampoCodigoDocumento-${filtrosPedidos.CodigoDocumento}`}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <KeyboardDatePicker
                                    autoOk
                                    label={<Texto id="despachos.FechaCreacionDocumento" />}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    variant="inline"
                                    onChange={(fecha: any) =>
                                        alCambiarValor({
                                            target: { name: 'FechaCreacion', value: fecha },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    value={filtrosPedidos.FechaCreacion}
                                    inputVariant="outlined"
                                    id={`CampoFechaCreacion-${filtrosPedidos.FechaCreacion}`}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <KeyboardDatePicker
                                    autoOk
                                    label={<Texto id="despachos.FechaVencimientoDocumento" />}
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    variant="inline"
                                    onChange={(fecha: any) =>
                                        alCambiarValor({
                                            target: { name: 'FechaVencimiento', value: fecha },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    value={filtrosPedidos.FechaVencimiento}
                                    id={`CampoFechaVencimiento-${filtrosPedidos.FechaVencimiento}`}
                                    inputVariant="outlined"
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Autocomplete
                                    disableClearable
                                    getOptionLabel={({ DescripcionCliente, DescripcionSucursal }) =>
                                        DescripcionSucursal
                                            ? `${DescripcionCliente} - ${DescripcionSucursal}`
                                            : DescripcionCliente
                                    }
                                    onChange={(_: any, cliente: any) =>
                                        alCambiarValor({ target: { name: 'Cliente', value: cliente } } as any)
                                    }
                                    options={entidadesFiltro.clientes}
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth label={<Texto id="despachos.Cliente" />} />
                                    )}
                                    noOptionsText={<Texto id="label.sinRegistros" />}
                                    value={filtrosPedidos.Cliente!== null?filtrosPedidos.Cliente:undefined}
                                    id={'CampoCliente'}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    label={<Texto id="despachos.EstadoDocumento" />}
                                    fullWidth
                                    select
                                    name="EtapaId"
                                    onChange={alCambiarValor}
                                    value={filtrosPedidos.EtapaId}
                                    variant="outlined"
                                    id={`CampoEtapa-${filtrosPedidos.EtapaId}`}
                                >
                                    {entidadesFiltro.etapas.map(({ EtapaDescripcion, EtapaId }) => (
                                        <MenuItem key={EtapaId} value={EtapaId}>
                                            {EtapaDescripcion}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item md xs={12}>
                                <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
                                    <Grid item md="auto" xs={6}>
                                        <BotonLimpiar onClick={() => cargarDocumentos(true)} />
                                    </Grid>
                                    <Grid item md="auto" xs={6}>
                                        <BotonBuscar onClick={() => cargarDocumentos()} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Tabla
                    actions={accionesTablaDespachos(
                        alAbrirFormulario,
                        alAnularDocumento,
                        formatMessage,
                        cargarDocumentos
                    )}
                    columns={columnasTablaDespachos(formatMessage)}
                    data={consultaDocumentos}
                    tableRef={refTabla}
                />
            </Grid>
        </Grid>
    );
};

export default injectIntl(TablaDespachosVista);
