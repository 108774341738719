const mensajes = {
    // TiposDocumentoTabla
    'TiposDocumentoTabla.Acciones': 'Actions',
    'TiposDocumentoTabla.VerTipoDocumento': 'View document type',
    'TiposDocumentoTabla.EditarTipoDocumento': 'Edit document type',
    'TiposDocumentoTabla.EliminarTipoDocumento': 'Delete document type',
    'TiposDocumentoTabla.TipoDocumentoDescripcion': 'Document type',
    'TiposDocumentoTabla.Abreviatura': 'Abbreviation',
    'TiposDocumentoTabla.Consecutivo': 'Consecutive',
    'TiposDocumentoTabla.SincronizaMovil': 'Syncs mobile',
    'TiposDocumentoTabla.Estado': 'Status',
    'TiposDocumento.PlantillaImpresion.Nombre':'Template',
    // ModalTipoDocumento
    'ModalTipoDocumento.TituloCreacion': 'Create Document Type',
    'ModalTipoDocumento.TituloEdicion': 'Edit Document Type',
    'ModalTipoDocumento.TituloVisualizacion': 'View Document Type',
    'ModalTipoDocumento.BotonGuardar': 'Save',
    'ModalTipoDocumento.EtapaDescripcion': 'Stage',
    'ModalTipoDocumento.AfectaInventario': 'Affects inventory',
    'ModalTipoDocumento.PermiteEditarDocumento': 'Allows to edit document',
    'ModalTipoDocumento.PermiteAnularDocumento': 'Allows to void document',
    'ModalTipoDocumento.PermiteAgregarDetalle': 'Allows to add detail',
    'ModalTipoDocumento.PermiteEditarDetalle': 'Allows to edit detail',
    'ModalTipoDocumento.PermiteEliminarDetalle': 'Allows to delete detail',
    'ModalTipoDocumento.PermiteCambiarEtapa': 'Allows to change stage',
    'ModalTipoDocumento.AccionMovil': 'Allows mobile management',
    'ModalTipoDocumento.EtapaMovil': 'Allows to change the stage from mobile app',
    'ModalTipoDocumento.BotonEditar': 'Edit',
    'ModalTipoDocumento.Cancelar': 'Unsaved data will be lost, do you want to close the window?',
    'ModalTipoDocumento.GenerarActa':'Generate Minutes',
    // CampoTipoProcesoId
    'TiposDocumento.CampoTipoProcesoId.Nombre': 'Process type',
    // CampoTipoDocumento
    'TiposDocumento.CampoTipoDocumentoDescripcion.Nombre': 'Description',
    // CampoAbreviatura
    'TiposDocumento.CampoAbreviatura.Nombre': 'Abbreviation',
    // CampoConsecutivo
    'TiposDocumento.CampoConsecutivo.Nombre': 'Consecutive',
    // CampoFlujoId
    'TiposDocumento.CampoFlujoId.Nombre': 'Path',
    // CampoEstado
    'TiposDocumento.CampoEstado.Nombre': 'Status',
    // CampoSincronizacionMovil
    'TiposDocumento.CampoSincronizacionMovil.Nombre': 'Mobile sync',
};

export default mensajes;
