import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, Toolbar } from '@material-ui/core';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { ITablaConsultaInventarioVistaProps } from '../interfaces';
import { columnas, opciones } from './constantes';
import ExportToExcel from 'Infotrack@Transversales/componentes/downloadExcel';

const TablaConsultaInventarioVista: React.FunctionComponent<ITablaConsultaInventarioVistaProps & InjectedIntlProps> = ({
    consultaMovimientosConSaldo,
    estadoDialogo,
    intl: { formatMessage },
    refTabla,
    alCerrarDialogo,
    consultarMovimientosParaDescarga,
}) => (
    <Dialog open={estadoDialogo} fullWidth maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto align="center" color="inherit" id="inventario.titulo" />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid alignItems="center" container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <Grid alignItems="center" container spacing={2} justifyContent="flex-end">
                        <ExportToExcel fileName={'ConsultaInventarios'} ejecutarConsulta={consultarMovimientosParaDescarga} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Tabla
                        columns={columnas(formatMessage)}
                        data={consultaMovimientosConSaldo}
                        tableRef={refTabla}
                        options={opciones}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="primary" variant="contained" onClick={alCerrarDialogo}  id="boton-cerrar">
                <Texto id="boton.cerrar" />
            </Button>
        </DialogActions>
    </Dialog>
);

export default injectIntl(TablaConsultaInventarioVista);
