import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, Toolbar } from '@material-ui/core';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaPedidosEstadosVistaProps } from '../interfaces';
import { columnas } from './constantes';

const TablaPedidosEstadosVista: React.FunctionComponent<ITablaPedidosEstadosVistaProps & InjectedIntlProps> = ({
    alCerrarFormulario,
    documentos,
    formularioEstadoPedidos,
    intl: { formatMessage },
}) => (
    <Dialog open={formularioEstadoPedidos} fullWidth maxWidth="md">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto align="center" color="inherit" id="monitorPedidos.documentos" />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Tabla columns={columnas(formatMessage)} data={documentos} />
        </DialogContent>
        <DialogActions>
            <Button color="primary" onClick={alCerrarFormulario} variant="contained" id="boton-cerrar">
                <Texto id="boton.cerrar" />
            </Button>
        </DialogActions>
    </Dialog>
);

export default injectIntl(TablaPedidosEstadosVista);
