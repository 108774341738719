import { Checkbox, Radio, colors } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import IDocumentosUsuariosCompuesto from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentosUsuariosCompuesto';
import IDocumentoUsuarioLista from 'Infotrack@Modelos/smartStock/gestionOperadores/entidades/documentoUsuarioLista';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentosUsuariosCompuesto>> => [
    { title: formatMessage({ id: 'gestionmovimientos.NumeroDocumento' }), field: 'CodigoDocumento' },
    { title: formatMessage({ id: 'gestionmovimientos.DocumentoRelacionado' }), field: 'DocumentoRelacionado' },
    { title: formatMessage({ id: 'gestionmovimientos.TipoDocumento' }), field: 'TipoDocumentoDescripcion' },
    {
        title: formatMessage({ id: 'gestionmovimientos.TipoMovimiento' }),
        field: 'TipoMovimientoDescripcion',
        hidden: true,
    },
    { title: formatMessage({ id: 'gestionmovimientos.FechaCreacion' }), field: 'FechaCreacion' },
    { title: formatMessage({ id: 'gestionmovimientos.BodegaOrigenDescripcion' }), field: 'BodegaOrigenDescripcion' },
    {
        title: formatMessage({ id: 'gestionmovimientos.BodegaDestinoDescripcion' }),
        field: 'BodegaDestinoDescripcion',
    },
    { title: formatMessage({ id: 'gestionmovimientos.PersonaCreacion' }), field: 'PersonaCreacion', hidden: true },
    {
        title: formatMessage({ id: 'gestionOperadores.Asignacion' }),
        field: 'Usuarios',
        render: (rowData: IDocumentosUsuariosCompuesto) =>
            rowData.Usuarios.length === 0
                ? ''
                : rowData.Usuarios.length === 1
                ? rowData.Usuarios[0].NombreUsuario
                : formatMessage({ id: 'gestionOperadores.Varios' }),
    },
    { title: formatMessage({ id: 'gestionmovimientos.EstadoDocumento' }), field: 'EtapaDescripcion' },
];

export const acciones = (
    alAbrirFormulario: (documento?: IDocumentosUsuariosCompuesto) => void,
    alAnularDocumento: (documento?: IDocumentosUsuariosCompuesto) => void,
    recargarTabla: () => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentosUsuariosCompuesto>> => [
    {
        icon: () => <EditIcon id="Icono-Editar" color="action" />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_: any, rowData: any) => alAbrirFormulario(rowData as IDocumentosUsuariosCompuesto),
    },
];

export const opciones: Options<IDocumentoCompuestoMovimiento> = {
    search: false,
    maxBodyHeight: '50vh',
};

export const accionesTablaDocumentoDetalle = (
    alAbrirFormulario: (documentoDetalle?: IDocumentoUsuarioLista) => void,
    alEliminarDetalle: (documentoDetalle: IDocumentoUsuarioLista) => void,
    deshabilitarEdicion: boolean
): Array<Action<IDocumentoUsuarioLista>> => [
    {
        component: BotonAgregar,
        isFreeAction: true,
        icon: () => <AddIcon id="Icono-Agregar"/>,
        onClick: () => alAbrirFormulario(),
        disabled: deshabilitarEdicion,
    },
    {
        icon: () => <DeleteIcon id="Icono-Eliminar" color={deshabilitarEdicion ? 'disabled' : 'action'} />,
        onClick: (_, value) => alEliminarDetalle(value as IDocumentoUsuarioLista),
        disabled: deshabilitarEdicion,
    },
];

export const columnasTablaDocumentoDetalle = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    manejadorSeleccionDocumentoDetalle: (documentoDetalle: IDocumentoUsuarioLista) => void
): Array<Column<IDocumentoUsuarioLista>> => [
    {
        field: 'FinalizacionProceso',
        title: formatMessage({ id: 'Responsable Finalizacion' }),
        render: (documentoDetalle) => (
            <Radio
                checked={documentoDetalle.FinalizacionProceso}
                color={'primary'}
                onChange={() => manejadorSeleccionDocumentoDetalle(documentoDetalle)}
            ></Radio>
        ),
    },
    { field: 'DocumentoUsuarioId', hidden: true },
    { field: 'UsuarioId', hidden: true },
    {
        field: 'NombreUsuario',
        title: formatMessage({ id: 'gestionoperadores.NombreUsuario' }),
    },
    { field: 'Login', hidden: true },
];

export const opcionesTablaDocumentoDetalle: Options<IDocumentoUsuarioLista> = {
    maxBodyHeight: '50vh',
    showTitle: true,
    // fixedColumns: {
    //   left: 1,
    //   right: 0,
    // },
};

export const columnasAlmacenamientoDetallesMasivo = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuestoMovimiento>> => [
    { title: formatMessage({ id: 'Item' }), field: 'ItemId' },
    { title: formatMessage({ id: 'Division Origen' }), field: 'DivisionOrigenId' },
    { title: formatMessage({ id: 'Division Destino' }), field: 'DivisionDestinoId' },
    { title: formatMessage({ id: 'Cantidad Disponible' }), field: 'CantidadDisponible' },
    { title: formatMessage({ id: 'Cantidad Solicitada' }), field: 'CantidadSolicitada' },
];

export const accionesAlmacenamientoDetallesMasivo = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Action<IDocumentoDetalleCompuesto>> => [
    {
        icon: () => <Checkbox />,
        tooltip: formatMessage({ id: 'recepcion.documentos.verDetalle' }),
        onClick: () => {},
    },
];
