import React from 'react';
import { IFormularioMaximosMinimosVistaProps } from '../interfaces';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    TextFieldProps,
    Toolbar,
} from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { Autocomplete } from '@material-ui/lab';

const CustomTextField = (props: TextFieldProps) => (
    <TextField
        {...props}
        fullWidth
        required={props.required !== undefined ? props.required : true}
        value={props.value ? props.value : ''}
        variant="outlined"
    />
);

const FormularioMaximosMinimosVista: React.FunctionComponent<IFormularioMaximosMinimosVistaProps> = ({
    alCambiarValor,
    estadoFormulario,
    tipoFormulario,
    cerrarFormulario,
    crearEditarMaximosMinimos,
    valor,
    listaItem,
    alCambiarValorAutocomplete,
}) => {
    return (
        <Dialog fullWidth open={estadoFormulario} maxWidth="sm">
            <AppBar position="static">
                <Toolbar>
                    <Grid container justifyContent="center">
                        <Texto
                            align="center"
                            color="inherit"
                            id={
                                tipoFormulario === TipoFormulario.Creacion
                                    ? 'maximosMinimos.agregarMaximosMinimos'
                                    : 'maximosMinimos.editarMaximosMinimos'
                            }
                        />
                    </Grid>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    label={<Texto id="maximosMinimos.NombreProducto" display="inline" />}
                                    value={valor.NombreProducto}
                                    name="NombreProducto"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    label={<Texto id="maximosMinimos.NivelMinimo" display="inline" />}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                            alCambiarValor(e);
                                        }
                                    }}
                                    value={valor.NivelMinimo}
                                    name="NivelMinimo"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth
                                    label={<Texto id="maximosMinimos.NivelMaximo" display="inline" />}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                            alCambiarValor(e);
                                        }
                                    }}
                                    value={valor.NivelMaximo}
                                    name="NivelMaximo"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <DialogActions>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button
                                disabled={!estadoFormulario}
                                color="secondary"
                                variant="contained"
                                onClick={cerrarFormulario}
                            >
                                <Texto id="boton.cancelar" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={!estadoFormulario}
                                onClick={crearEditarMaximosMinimos}
                                color="primary"
                                variant="contained"
                            >
                                <Texto
                                    id={tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default FormularioMaximosMinimosVista;
