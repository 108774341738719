import React, { ChangeEvent, createRef } from 'react';
import MaximosMinimosCM from '../controladorModelo/maximosMinimosCM';
import TablaMinimosMaximosVista from '../vista/tablaMinimosMaximosVista';
import TablaMaximosMinimosProductosVista from '../vista/tablaMinimosMaximosProductosVista';
import IMaximosMinimos from 'Infotrack@Modelos/smartStock/maximosMinimos/entidades/IMaximosMinimos';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { IEstadoGestionBodegas, acciones } from '../reductorGestionBodegas';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import {
    manejadorDialogoGlobal,
    manejadorIndicadorCargaGlobal,
} from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModeloPlantillaServicioCM from '../../../../modulos/smartStock/EntradaInventario/controladorModelo/plantillaServicioCM';
import IPlantilla from 'Infotrack@Modelos/smartStock/plantilla/entidades/IPlantilla';
import * as XLSX from 'xlsx';
import ModeloCargarPlantilla from 'Infotrack@Modelos/smartStock/procesarArchivo';
import TablaErrores from 'Infotrack@Transversales/componentes/CargaMasiva/TablaErrores/TablaErrores';
import { IInputsMaximosMinimos, ITablaMaximosMinimosCVProps } from '../interfaces';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { connect } from 'react-redux';
import IProductoPrincipal from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/productoPrincipal';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

const maximosMinimosCM = new MaximosMinimosCM();
const modeloPlantillaServicioCM = new ModeloPlantillaServicioCM();
const modeloCargarPlantilla = new ModeloCargarPlantilla();

const TablaMaximosMinimosCV: React.FunctionComponent<ITablaMaximosMinimosCVProps> = ({ IdEmpresa, IdAgencia }) => {
    const [maximosMinimos, setMaximosMinimos] = React.useState<IMaximosMinimos[]>([]);
    const [{ bodega, recargarTablaMaximosMinimos }, dispatch] = useProveedor<IEstadoGestionBodegas>();
    const [archivoMaxMin, setArchivoMaxMin] = React.useState<File>();
    const [productosMinMax, setProductosMinMax] = React.useState<IMaximosMinimos[]>([]);
    const [filtrosItem, setFiltrosItem] = React.useState<IFiltrosItem>({});
    const [abrirTablaMaxMinProductos, setAbrirTablaMaxMinProductos] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (archivoMaxMin) CargarPlantillaMaximosMinimos(archivoMaxMin);
    }, [archivoMaxMin]);

    React.useEffect(() => {
        ConsultarListaMaximoMinimo();

        recargarTablaMaximosMinimos && dispatch({ type: acciones.RECARGAR_TABLA_MAXIMOS_MINIMOS });
    }, [recargarTablaMaximosMinimos]);

    const abrirFormularioMaximosMinimos = (tipoFormulario: TipoFormulario, MaxMin?: IMaximosMinimos) => {
        if (tipoFormulario === TipoFormulario.Edicion) {
            dispatch({ type: acciones.ESTABLECER_MAXIMOS_MINIMOS, payload: MaxMin });
            dispatch({ type: acciones.MODIFICAR_TIPO_FORMULARIO_MAXIMOS_MINIMOS, payload: tipoFormulario });
            dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_MAXIMOS_MINIMOS });
        } else {
            setAbrirTablaMaxMinProductos(true);
        }
    };

    const ConsultarListaMaximoMinimo = async () => {
        const BodegaId = Number(bodega.BodegaId);
        const divisionesMaxMin = await maximosMinimosCM.ConsultarListaMaximoMinimoPorBodega(BodegaId);
        setMaximosMinimos(divisionesMaxMin);
    };

    const eliminarMaximoMinimo = (maxMin: IMaximosMinimos) => {
        manejadorIndicadorCargaGlobal(true);
        manejadorDialogoGlobal({ estado: false });

        maximosMinimosCM.eliminarMaximoMinimo(maxMin).then(() => {
            manejadorIndicadorCargaGlobal(false);
            dispatch({ type: acciones.RECARGAR_TABLA_MAXIMOS_MINIMOS });
        });
    };

    const confirmarEliminacionMaxMin = (maxMin: IMaximosMinimos) => {
        manejadorDialogoGlobal({
            estado: true,
            tipoDialogo: 'Advertencia',
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            titulo: <Texto id="titulo.advertencia" />,
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarMaximoMinimo(maxMin),
        });
    };

    const cargarArchivoExcel = (event: ChangeEvent<HTMLInputElement>) => {
        const archivo = event.target.files![0];
        setArchivoMaxMin(archivo);
        const lector = new FileReader();

        lector.onload = function(e) {
            const contenidoArchivo = e.target!.result;
            const libro = XLSX.read(contenidoArchivo, { type: 'array' });
            const hoja = libro.SheetNames[0];
            const datos = XLSX.utils.sheet_to_json(libro.Sheets[hoja]);
            const datosBinarios = XLSX.write(libro, { type: 'array', bookType: 'xlsx' });
            const archivoBinario = new Blob([datosBinarios], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
        };
        lector.readAsBinaryString(archivo);
    };

    const descargarArchivoExcel = async () => {
        const parametros: IPlantilla = {
            Extension: 'xlsx',
            NombreArchivo: 'PlantillaMaximoMinimo',
        };
        const respuesta = await modeloPlantillaServicioCM.DescargarPlantillaMaximoMinimo(parametros);
        const blob = new Blob([respuesta], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const urlArchivo = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlArchivo;
        link.download = 'FormatoInventario.xlsx';
        link.click();
        URL.revokeObjectURL;
    };

    const CargarPlantillaMaximosMinimos = async (archivo: File) => {
        const formData = new FormData();
        formData.append('Archivo', archivo, archivo.name);
        formData.append(
            'Datos',
            JSON.stringify({
                BodegaId: bodega.BodegaId,
            })
        );
       
        const validacionesCargaProducto = (await modeloCargarPlantilla.CargarPlantillaMaximoMinimo(formData)).data
            .Entidades;
        dispatch({ type: acciones.RECARGAR_TABLA_MAXIMOS_MINIMOS });

        if (validacionesCargaProducto.length >= 1) {
            manejadorDialogoGlobal({
                estado: true,
                mostrarCancelar: false,
                accionConfirmar: () => manejadorDialogoGlobal({ estado: false }),
                mensaje: (
                    <div>
                        <TablaErrores listaErrores={validacionesCargaProducto} />
                    </div>
                ),
                tipoDialogo: 'Error',
                titulo: <Texto id="cargaMasiva.Errores" style={{ textAlign: 'center' }} />,
            });
        } else {
            dispatch({ type: acciones.RECARGAR_TABLA });
        }
    };
    const crearMaximosMinimos = (producto: IMaximosMinimos) => {
        if (maximosMinimosCM.validarCampos(producto)) {
            manejadorIndicadorCargaGlobal(true);
            const promesaMaximoMinimo = maximosMinimosCM.crearMaximoMinimo(producto);
            return promesaMaximoMinimo.then(() => {
                manejadorIndicadorCargaGlobal(false);
                dispatch({ type: acciones.RECARGAR_TABLA_MAXIMOS_MINIMOS });
            });
        }
    };

    const guardarMinimosMaximos = (idItem: number) => {
        let productos = productosMinMax.filter((x) => x.ItemId === idItem);
        if (productos.length > 0) {
            crearMaximosMinimos(productos[0]);
        } else {
            notificacionGlobal('comunes.alerta.camposIncompletos', 6000, 'warning', true);
        }
    };

    const manejarCambioFiltro = (nombreFiltro: keyof IFiltrosItem, nuevoValor: IFiltrosItem[keyof IFiltrosItem]) => {
        setFiltrosItem((filtrosActuales) => ({ ...filtrosActuales, [nombreFiltro]: nuevoValor }));
    };

    const mapearFiltros = (): IFiltrosItem => {
        return {
            ...filtrosItem,
            EmpresaId: IdEmpresa,
        };
    };

    const consultaDocumentos = paginacionRemota<IProductoPrincipal>(
        maximosMinimosCM.consultarListaItems,
        mapearFiltros(),
        'DescripcionItem'
    );

    const mapearDatos = (producto: IProductoPrincipal): IInputsMaximosMinimos => ({
        BodegaId: bodega.BodegaId,
        ItemId: producto.ItemId!,
        CodigoProducto: producto.CodigoEmpresa!,
        NombreProducto: producto.DescripcionItem,
        NivelMinimo: 0,
        NivelMaximo: 0,
    });
    const referenciaTabla = createRef<any>();
    const consultarDocumentos = () => {
        referenciaTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const alCambiarValorMinMax = (item: IProductoPrincipal, valor: number, nombreValor: string) => {
        let copiaProductos = productosMinMax.filter((x) => x.ItemId === item.ItemId);

        if (copiaProductos.length > 0) {
            let producto = copiaProductos[0];
            nombreValor === 'ValorMaximo' ? (producto.NivelMaximo = valor) : (producto.NivelMinimo = valor);
            let productos = productosMinMax;
            productos.filter((x) => x.ItemId !== item.ItemId);
            productos.push(producto);
            setProductosMinMax([...productos]);
        } else {
            copiaProductos = productosMinMax;
            copiaProductos.push({
                ItemId: item.ItemId!,
                NivelMaximo: nombreValor === 'ValorMaximo' ? valor : 0,
                NivelMinimo: nombreValor === 'ValorMinimo' ? valor : 0,
                BodegaId: bodega.BodegaId!,
                CodigoProducto: item.CodigoEmpresa!,
                NombreProducto: item.DescripcionItem,
            });
            setProductosMinMax([...copiaProductos]);
        }
    };

    const limpiarFiltros =()=>{
        setFiltrosItem({CodigoEmpresa:"",DescripcionItem:""})
        consultarDocumentos();
    }
    return (
        <>
            <TablaMinimosMaximosVista
                maximosMinimos={maximosMinimos}
                recargarTablaMaxMin={ConsultarListaMaximoMinimo}
                confirmarEliminacionMaxMin={confirmarEliminacionMaxMin}
                abrirFormularioMaximosMinimos={abrirFormularioMaximosMinimos}
                descargarPlantilla={descargarArchivoExcel}
                cargarArchivoExcel={cargarArchivoExcel}
            />
            <TablaMaximosMinimosProductosVista
                crearMaxMin={guardarMinimosMaximos}
                abrirFormularioMaximosMinimos={abrirTablaMaxMinProductos}
                cerrar={() => {
                    setAbrirTablaMaxMinProductos(false), setProductosMinMax([]),setFiltrosItem({});
                }}
                consulta={consultaDocumentos}
                referenciaTabla={referenciaTabla}
                consultarDocumentos={consultarDocumentos}
                alCambiarValorMinMax={alCambiarValorMinMax}
                productosMinMax={productosMinMax}
                manejarCambioFiltro={manejarCambioFiltro}
                filtrosItem={filtrosItem}
                limpiarFiltros={limpiarFiltros}
                maximosMinimosGuardados={maximosMinimos}
            />
        </>
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): ITablaMaximosMinimosCVProps => ({
    IdEmpresa: usuarioInformacion!.IdEmpresa,
    IdAgencia: agenciaActual!.IdAgencia,
});

export default connect<ITablaMaximosMinimosCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(TablaMaximosMinimosCV);
