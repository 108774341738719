import { Card, CardHeader, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon, Refresh as RefreshIcon } from '@material-ui/icons';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import IFlujo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFlujo';
import React, { FunctionComponent } from 'react';

interface ItablaFlujoProps {
    listaFlujo: IFlujo[];
    eliminarFlujo: (flujo: IFlujo) => void;
    editarFlujo: (flujo: IFlujo) => void;
    recargarTabla: () => void;
    agregarFlujo: () => void;
}

const TablaFlujo: FunctionComponent<ItablaFlujoProps> = ({
    listaFlujo,
    eliminarFlujo,
    recargarTabla,
    agregarFlujo,
    editarFlujo,
}) => {
    const obtenerColumnas = () => {
        const columnas: Array<Column<IFlujo>> = [];
        columnas.push({
            render: (flujoEtapas: IFlujo) => (
                <Grid container wrap="nowrap">
                    <Grid item>
                        <Tooltip title={<Texto id="TablaFlujo.AnularDocumento" />}>
                            <IconButton onClick={() => eliminarFlujo(flujoEtapas)} size="small" id="boton-Eliminar">
                                <DeleteIcon id="Icono-Eliminar"/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={<Texto id="TablaFlujo.AnularDocumento" />}>
                            <IconButton onClick={() => editarFlujo(flujoEtapas)} size="small" id="boton-Editar">
                                <EditIcon id="Icono-Editar" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            ),
            title: <Texto id="TablaFlujo.Acciones" />,
        });

        columnas.push({
            title: <Texto id="TablaFlujo.Descripcion" />,
            field: 'FlujoDescripcion',
        });
        columnas.push({
            title: <Texto id="TablaFlujo.Estado" />,
            field: 'Estado',
            type: 'boolean',
        });

        return columnas;
    };

    return (
        <Card variant="outlined">
            <Tabla
                actions={[
                    {
                        icon: () => <RefreshIcon id="Icono-Refrescar" color="action" />,
                        isFreeAction: true,
                        onClick: recargarTabla,
                    },
                    {
                        icon: 'add',
                        isFreeAction: true,
                        onClick: agregarFlujo,
                    },
                ]}
                columns={obtenerColumnas()}
                data={listaFlujo}
                options={{ padding: 'dense' }}
            />
        </Card>
    );
};

export default TablaFlujo;
