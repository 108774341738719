const mensajes = {
    // Titulos
    'ModalConteo.TituloConteo': 'Gestión Conteo',
    'AuditoriaConteo.Titulo': 'Conteo',
    // Campos
    'AuditoriaConteo.CampoDescripcion.Nombre': 'Descripción',
    'AuditoriaConteo.CampoObservacion.Nombre': 'Observación',
    'AuditoriaConteo.CampoConteoPredecesor.Nombre': 'Conteo predecesor',
    'AuditoriaConteo.CampoConteoSeleccionado.Nombre': 'Conteo seleccionado',
    'AuditoriaConteo.CampoConteoAComparar.Nombre': 'Conteo a comparar',
    'AuditoriaConteo.CampoConteoAFinalizar.Nombre': 'Conteo a finalizar',
    // Auditoria Conteo Detalle
    'AuditoriaConteoDetalle.ValidacionCantidadIncorrecta': 'Por favor validar la cantidad ingresada',
    'AuditoriaConteoDetalle.ValidacionCantidadSerialIncorrecta':
        'La cantidad de un producto serial no puede ser mayor a 1',

    // ConteoTabla
    'AuditoriaConteoTabla.DescripcionConteo': 'Descripción Conteo',
    'AuditoriaConteoTabla.Observacion': 'Observación',
    'AuditoriaConteoTabla.Conteo': 'Gestión Conteo',
    'AuditoriaConteoTabla.BotonGestionConteo': 'Gestionar Conteo',
    'AuditoriaConteoTabla.BotonCompararConteo': 'Comparar Conteo',
    'AuditoriaConteoTabla.EstadoConteo':'Estado conteo',
    'AuditoriaConteoTabla.ConteoFinalizado':'Conteo Finalizado',
    // Tabla Conteo Detalles
    'TablaConteoDetalles.DetallesConteo': 'Detalles Conteo',
    'TablaConteoDetalles.CodigoBarras': 'Código Barras Ítem',
    'TablaConteoDetalles.DescripcionItem': 'Ítem',
    'TablaConteoDetalles.DescripcionDivision': 'División',
    'TablaConteoDetalles.Stock': 'Cantidad en Stock',
    'TablaConteoDetalles.CantidadIngresada': 'Cantidad Ingresada',
    'TablaConteoDetalles.Cantidad': 'Cantidad',
    'TablaConteoDetalles.Lote': 'Lote',
    'TablaConteoDetalles.FechaVencimiento': 'Fecha Vencimiento',
    'TablaConteoDetalles.FechaIngreso': 'Fecha Ingreso',
    'TablaConteoDetalles.IngresarSeriales': 'Ingresar Seriales',
    'TablaConteoDetalles.Serial': 'Serial',
    // Tabla Conteo Detalles Comparacion
    'TablaComparacionConteoDetalles.DetallesConteo': 'Comparación Detalles Conteo',
    'TablaComparacionConteoDetalles.CodigoBarras': 'Código Barras',
    'TablaComparacionConteoDetalles.DescripcionItem': 'Ítem',
    'TablaComparacionConteoDetalles.DescripcionBodega': 'Bodega',
    'TablaComparacionConteoDetalles.DescripcionDivision': 'División',
    'TablaComparacionConteoDetalles.Stock': 'Cantidad en Stock',
    'TablaComparacionConteoDetalles.CantidadPrimerConteo': 'Cantidad Primer Conteo',
    'TablaComparacionConteoDetalles.CantidadSegundoConteo': 'Cantidad Segundo Conteo',
    'TablaComparacionConteoDetalles.DiferenciaStockPrimerConteo': 'Diferencia Primer Conteo vs Stock',
    'TablaComparacionConteoDetalles.DiferenciaStockSegundoConteo': 'Diferencia Segundo Conteo vs Stock',
    //'TablaComparacionConteoDetalles.DiferenciaConteos': 'Diferencia entre conteos',
    'TablaComparacionConteoDetalles.Serial': 'Serial',
    'TablaComparacionConteoDetalles.Lote': 'Lote',
    'TablaComparacionConteoDetalles.FechaVencimiento': 'Fecha Vencimiento',
    'TablaComparacionConteoDetalles.FechaIngreso': 'Fecha Ingreso',
    'TablaComparacionConteoDetalles.CodigoEmpresa':'Código producto',
    'TablaComparacionConteoDetalles.CantidadConteo':'Cantidad conteo',
    'TablaComparacionConteoDetalles.DiferenciaConteos':'Diferencia conteos',
    'TablaComparacionConteoDetalles.Peso':'Peso',
    // Alertas
    'AuditoriaConteo.ValidacionNoDescripcionConteo': 'Debe ingresar la descripción del conteo para su creación.',
    'AuditoriaConteo.ValidacionNoIdTipoConteo': 'Debe seleccionar un tipo de conteo',
    'AuditoriaConteo.ValidacionNoConteoPredecesor': 'Debe seleccionar un conteo predecesor',
    'AuditoriaConteo.ValidacionCantidadNegativa': 'La cantidad no puede ser menor a cero',
};

export default mensajes;
