import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';

interface ICampoFechaEntregaProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor?: string;
}

const CampoFechaEntrega: FunctionComponent<ICampoFechaEntregaProps> = ({
    deshabilitado,
    manejarCambioCampo,
    valor,
}) => (
    <KeyboardDatePicker
        disabled={deshabilitado}
        disablePast
        format="DD/MM/YYYY"
        fullWidth
        inputVariant="outlined"
        label={<Texto id="FormularioDocumento.CampoFechaEntrega.Nombre" />}
        onChange={(date) => {
            if (date) manejarCambioCampo('FechaEntrega', date.format('YYYY-MM-DD'));
        }}
        value={valor ? moment(valor, 'YYYY-MM-DD') : null}
        minDateMessage={""}
        id={`CampoFechaEntrega-${valor ? moment(valor, 'YYYY-MM-DD') : null}`}
    />
);

export default CampoFechaEntrega;
