import { MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IFamilia from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFamilia';
import IFamiliaAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IFamiliaAuditoria';

interface ICampoFamiliaIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    familias: IFamilia[];
    familiasSeleccionadas: IFamiliaAuditoria[];
}

const CampoFamiliaId: FunctionComponent<ICampoFamiliaIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    familias,
    familiasSeleccionadas,
}) => (
    <Autocomplete
    noOptionsText={<Texto id="Auditoria.SeleccioneBodega" />}
        multiple
        disabled={deshabilitado}
        getOptionLabel={(d: IFamilia) => d.FamiliaDescripcion}
        onChange={(_: ChangeEvent<{}>, familias: IFamilia[] | null) => {
            if (familias) {
                manejarCambioCampo(
                    'Familias',
                    familias.map((familia) => ({
                        FamiliaId: familia.FamiliaId,
                        DescripcionFamilia: familia.FamiliaDescripcion,
                    }))
                );
            }
        }}
        options={familias}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoFamiliaId.Nombre" />} />
        )}
        value={familias.filter((familia) =>
            familiasSeleccionadas.find((familiaSeleccionada) => familiaSeleccionada.FamiliaId === familia.FamiliaId)
        )}
        id={`CampoFamilia`}
    />
);

export default CampoFamiliaId;
