import React, { FunctionComponent, RefObject } from 'react';

import { TextField } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';

interface IInputSerialProps {
    alEnviar: (indice: number) => void;
    deshabilitado: boolean;
    indice: number;
    manejarCambio: (indice: number, nuevoValor: string) => void;
    referencia: RefObject<unknown>;
    serialRepetido: boolean;
    valor: string;
}

const InputSerial: FunctionComponent<IInputSerialProps> = ({
    alEnviar,
    deshabilitado,
    indice,
    manejarCambio,
    referencia,
    serialRepetido,
    valor,
}) => {
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                alEnviar(indice);
            }}
        >
            <TextField
                error={serialRepetido}
                disabled={deshabilitado}
                fullWidth
                helperText={serialRepetido ? <Texto id="InputSerial.ErrorSerialRepetido" /> : undefined}
                inputRef={referencia}
                label={<Texto id="InputSerial.Serial" />}
                onChange={(event) => {
                    if (/^\S*$/.test(event.target.value)) {
                        manejarCambio(indice, event.target.value.toUpperCase());
                    }
                }}
                value={valor}
                inputProps={{ maxLength: 25 }}
                id={`CampoInputSerial-${valor}`}
            />
        </form>
    );
};

export default InputSerial;
