import { CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IItemInventario from 'Infotrack@Modelos/negocioRefactor/entidades/negocio/IItemInventario';

import useStyles from './detallesInventarioFiltroTablaEstilos';

interface IDetallesInventarioFiltroTablaProps {
    deshabilitados: boolean;
    divisiones: Array<{ divisionId: number; divisionDescripcion: string }>;
    filtro: IItemInventario;
    manejadorCambioFiltro: (nombre: keyof IItemInventario, nuevoValor: any) => any;
}

const DetallesInventarioFiltroTabla: FunctionComponent<IDetallesInventarioFiltroTablaProps> = ({
    deshabilitados,
    divisiones,
    filtro,
    manejadorCambioFiltro,
}) => {
    const classes = useStyles();

    return (
        <CardContent classes={{ root: classes.contenedorFiltrosEstilos }}>
            <Grid alignItems="center" container spacing={1}>
                <Grid item>
                    <Texto fontWeight="bold" id="DetallesInventarioFiltroTabla.Filtros" mensaje=": " />
                </Grid>
                <Grid item>
                    <TextField
                        classes={{ root: classes.campo }}
                        disabled={deshabilitados}
                        fullWidth
                        label={<Texto id="DetallesInventarioFiltroTabla.Lote" />}
                        onChange={(event) => manejadorCambioFiltro('Lote', event.target.value)}
                        value={filtro.Lote}
                        id={`CampoLote-${filtro.Lote}`}
                    />
                </Grid>
                <Grid item>
                    <KeyboardDatePicker
                        className={classes.campo}
                        disabled={deshabilitados}
                        format="DD/MM/YYYY"
                        fullWidth
                        label={<Texto id="DetallesInventarioFiltroTabla.FechaVencimiento" />}
                        onChange={(date) => {
                            if (date) manejadorCambioFiltro('FechaVencimiento', date.format('YYYY-MM-DD'));
                        }}
                        value={filtro.FechaVencimiento !== '' ? moment(filtro.FechaVencimiento, 'YYYY-MM-DD') : null}
                        id={`CampoFechaVencimiento-${filtro.FechaVencimiento !== '' ? moment(filtro.FechaVencimiento, 'YYYY-MM-DD') : null}`}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        classes={{ root: classes.campo }}
                        disabled={deshabilitados}
                        fullWidth
                        label={<Texto id="DetallesInventarioFiltroTabla.DivisionId" />}
                        onChange={(event) => manejadorCambioFiltro('DivisionId', event.target.value)}
                        select
                        value={filtro.DivisionId}
                        id={`CampoDivision-${filtro.DivisionId}`}
                    >
                        <MenuItem value={0}>Todas</MenuItem> {/* TODO: Agregar recurso */}
                        {divisiones.map((division) => (
                            <MenuItem key={division.divisionId} value={division.divisionId}>
                                {division.divisionDescripcion}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </CardContent>
    );
};

export default DetallesInventarioFiltroTabla;
