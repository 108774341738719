import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import useStyles from '../DetallesInventarioEstilos';

interface ICampoDivisionOrigenProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalleConsultaGeneral;
    divisiones: IBodegaDivisiones[];
    manejarCambioCampo: (
        documentoDetalleId: string,
        nombreCampo: keyof IDocumentoDetalleConsultaGeneral,
        nuevoValor: any
    ) => void;
}

const CampoDivisionOrigen: FunctionComponent<ICampoDivisionOrigenProps> = ({
    deshabilitado,
    detalle,
    divisiones,
    manejarCambioCampo,
}) => {
    const classes = useStyles();

    return (
        <TextField
            classes={{ root: classes.campo }}
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.DivisionOrigen" />}
            onChange={(event) => manejarCambioCampo(detalle.DocumentoDetalleId, 'DivisionOrigen', event.target.value)}
            select
            size="small"
            value={detalle.DivisionOrigen || ''}
            id={`CampoDivisionOrigen-${detalle.DivisionOrigen || ''}`}
        >
            {divisiones.map((division) => (
                <MenuItem key={division.DivisionId} value={division.DivisionId}>
                    {division.DivisionDescripcion}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default CampoDivisionOrigen;
