import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import IFiltrosItem from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/filtrosItem';

interface ICampoDescripcionItemProps {
    deshabilitado: boolean;
       manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IItemsConsulta | null
    ) => any;
    valor: string | undefined;
    manejarCambioFiltro: (nombreFiltro: keyof IFiltrosItem, nuevoValor: IFiltrosItem[keyof IFiltrosItem]) => void;
    abrirTablaProductos:()=> void;
}

const CampoDescripcionItem: FunctionComponent<ICampoDescripcionItemProps> = ({
    valor,
    manejarCambioFiltro,
    abrirTablaProductos,
    deshabilitado
}) => (
    <TextField
        fullWidth
        label={<Texto id='DetallesFormulario.CampoDescripcionItem.Nombre' />}
        name="CodigoEmpresa"
        onChange={(event)=>manejarCambioFiltro("DescripcionItem",event.target.value)}
        variant="outlined"
        value={valor}
        InputProps={{
            endAdornment: (
                !deshabilitado?
                <InputAdornment position="end"  >
                    <IconButton edge="end" onClick={abrirTablaProductos} disabled={  valor ? valor.length < 3 : true ||deshabilitado}>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>:null
            ),
        }}
        helperText={(valor === ""|| valor === undefined|| valor.length < 3 ) ?(
            <Texto id="DetallesFormulario.CaracteresMinimos" />) : ('')
        }
    id={`CampoCodigoEmpresa-${valor}`}
    disabled={deshabilitado}
    InputLabelProps={{ shrink: true }}  
    />
);

export default CampoDescripcionItem;
