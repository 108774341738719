import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import TablaEdicionDetallesAuditoriasCV from '../controladorVista/tablaEdicionDetallesAuditoriasCV';
import { ITablaGestionAuditoriasVistaProps } from '../interfaces';
import { accionesGestionAuditorias, columnasGestionAuditorias } from './constantes';

const TablaAuditoriasVista: React.FunctionComponent<ITablaGestionAuditoriasVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alAnularAuditoria,
    alCambiarValor,
    cargarDocumentos,
    consultaDocumentos,
    entidadesFiltros,
    filtrosDocumentos,
    intl: { formatMessage },
    refTabla,
}) => (
    <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <TextField
                                label={<Texto id="gestionauditorias.Bodegas" />}
                                fullWidth
                                select
                                name="BodegaConsulta"
                                required
                                onChange={alCambiarValor}
                                value={filtrosDocumentos.BodegaConsulta}
                                variant="outlined"
                                id={`CampoBodega-${filtrosDocumentos.BodegaConsulta}`}
                            >
                                {entidadesFiltros.bodegas.map(({ BodegaDescripcion, BodegaId }) => (
                                    <MenuItem key={BodegaId} value={BodegaId}>
                                        {BodegaDescripcion}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                label={<Texto id="gestionauditorias.NumeroDocumento" />}
                                fullWidth
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={filtrosDocumentos.CodigoDocumento}
                                variant="outlined"
                                id={`CampoCodigoDocumento-${filtrosDocumentos.CodigoDocumento}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                label={<Texto id="gestionauditorias.FechaInicial" />}
                                fullWidth
                                format="DD/MM/YYYY"
                                autoOk
                                variant="inline"
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaInicial', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtrosDocumentos.FechaInicial}
                                inputVariant="outlined"
                                id={`CampoFechaInicial-${filtrosDocumentos.FechaInicial}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                label={<Texto id="gestionauditorias.FechaFinal" />}
                                fullWidth
                                format="DD/MM/YYYY"
                                autoOk
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaFinal', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtrosDocumentos.FechaFinal}
                                variant="inline"
                                inputVariant="outlined"
                                id={`CampoFechaFinal-${filtrosDocumentos.FechaFinal}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                disabled
                                label={<Texto id="gestionauditorias.PersonaCreacion" />}
                                fullWidth
                                value=""
                                variant="outlined"
                                id={`CampoPersonaCreacion`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ EtapaDescripcion }) => EtapaDescripcion}
                                onChange={(_: any, etapa: any) =>
                                    alCambiarValor({ target: { name: 'Etapa', value: etapa } } as any)
                                }
                                options={entidadesFiltros.etapas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={formatMessage({ id: 'gestionauditorias.EstadoDocumento' })}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtrosDocumentos.Etapa!}
                                id={`CampoEstadoDocumento`}
                            />
                        </Grid>
                        <Grid item md xs={12}>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item md="auto" xs={6}>
                                    <BotonLimpiar fullWidth onClick={() => cargarDocumentos(true)} id="boton-Limpiar" />
                                </Grid>
                                <Grid item md="auto" xs={6}>
                                    <BotonBuscar fullWidth onClick={() => cargarDocumentos()} id="boton-Buscar" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={accionesGestionAuditorias(
                    alAbrirFormulario,
                    alAnularAuditoria,
                    cargarDocumentos,
                    formatMessage
                )}
                columns={columnasGestionAuditorias(formatMessage)}
                data={consultaDocumentos}
                tableRef={refTabla}
            />
            <TablaEdicionDetallesAuditoriasCV />
        </Grid>
    </Grid>
);

export default injectIntl(TablaAuditoriasVista);
