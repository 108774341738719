import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoPlantillas from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import { Autocomplete } from '@material-ui/lab';

interface ITipoPlantillaIdProps {
    deshabilitado: boolean;
    flujos: ITipoPlantillas[];
    valor: number;
    alCambiarValorAutocomplete: (nuevoValor: ITipoPlantillas) => void;
}

const TipoPlantillaId: FunctionComponent<ITipoPlantillaIdProps> = ({
    deshabilitado,
    flujos,
    valor,
    alCambiarValorAutocomplete,
}) => (
    <Autocomplete
        getOptionLabel={({ PlantillaImpresionDescripcion }: any) => PlantillaImpresionDescripcion}
        onChange={(_: any, plantilla: any) => alCambiarValorAutocomplete(plantilla)}
        options={flujos}
        renderInput={(params: any) => (
            <TextField {...params} fullWidth required label={<Texto id="TiposDocumento.PlantillaImpresion.Nombre" />} />
        )}
        noOptionsText={<Texto id="label.sinRegistros" />}
        value={flujos.filter((x) => x.PlantillaImpresionId === valor)[0]}
        id={`CampoPlantilla`}
    />
);

export default TipoPlantillaId;
