import { Button, Card, CardContent, Grid, List, ListItem, ListSubheader, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import EstadoCumplimientoPedido from 'Infotrack@Modelos/smartStock/estadisticasDashboard/entidades/estadoCumplimientoPedido';
import { BotonBuscar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { IMonitorPedidosVistaProps } from '../interfaces';

const MonitorPedidosVista: React.FunctionComponent<IMonitorPedidosVistaProps> = ({
    alAbrirFormularioCumplimientoPedidos,
    alAbrirFormularioEstadoPedidos,
    alCambiarValor,
    alFiltrarRegistros,
    dataEstadoPedidos,
    dataCumplimientoPedidos,
    dataCumplimientoPedidosHistorico,
    dataTop10PedidosClientes,
    entidadesFiltros,
    filtros,
}) => (
    <Grid alignItems="flex-start" container spacing={2}>
        <Grid item xs={12}>
            <Grid alignItems="flex-start" container spacing={2} justifyContent="flex-end">
                <Grid item md={3} xs={12}>
                    <Autocomplete
                        disableClearable
                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                        onChange={(_: any, bodega: any) => alCambiarValor('Bodega', bodega)}
                        options={entidadesFiltros.bodegas}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label={<Texto id="monitorPedidos.Bodegas" />} />
                        )}
                        noOptionsText={<Texto id="label.sinRegistros" />}
                        value={filtros.Bodega!==null? filtros.Bodega:undefined}
                        id={'CampoBodegas'}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <Autocomplete
                        getOptionLabel={({ DescripcionCliente }) => DescripcionCliente}
                        onChange={(_: any, cliente: any) => alCambiarValor('Cliente', cliente)}
                        options={entidadesFiltros.clientes}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label={<Texto id="monitorPedidos.Clientes" />} />
                        )}
                        noOptionsText={<Texto id="label.sinRegistros" />}
                        value={filtros.Cliente}
                        id={'CampoCliente'}
                    />
                </Grid>
                <Grid item md="auto" xs={12}>
                    <BotonBuscar fullWidth onClick={alFiltrarRegistros} />
                </Grid>
            </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <List
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorPedidos.estadoPedidos" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataEstadoPedidos.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataEstadoPedidos.map(({ Cantidad, Descripcion, Id }) => (
                                <ListItem disableGutters key={`${Id}#${Descripcion}`}>
                                    <Grid alignItems="center" container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto id={`monitorPedidos.${Descripcion}`} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={`${Cantidad.toFixed(2)}%`} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button
                                                color="primary"
                                                onClick={() => alAbrirFormularioEstadoPedidos(Id)}
                                                size="small"
                                                variant="text"
                                                id="boton-VerReporte"
                                            >
                                                <Texto id="monitorPedidos.reporte" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <List
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorPedidos.cumplimientoPedidos" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataCumplimientoPedidos.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataCumplimientoPedidos.map(({ Cantidad, Descripcion }, indice) => (
                                <ListItem disableGutters key={`${indice}#${Descripcion}#${Cantidad}`}>
                                    <Grid alignItems="center" container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto id={`monitorPedidos.${Descripcion}`} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={`${Cantidad.toFixed(2)}%`} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    alAbrirFormularioCumplimientoPedidos(
                                                        EstadoCumplimientoPedido[
                                                            Descripcion as keyof typeof EstadoCumplimientoPedido
                                                        ]
                                                    )
                                                }
                                                size="small"
                                                variant="text"
                                            >
                                                <Texto id="monitorPedidos.reporte" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <List
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorPedidos.cumplimientoPedidosHistorico" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataCumplimientoPedidosHistorico.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataCumplimientoPedidosHistorico.map(({ Cantidad, Descripcion }, indice) => (
                                <ListItem disableGutters key={`${Descripcion}#${indice}`}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={`${Cantidad.toFixed(2)}%`} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card>
                <CardContent>
                    <List
                        subheader={
                            <ListSubheader disableGutters>
                                <Texto id="monitorPedidos.top10PedidosClientes" fontWeight="bold" />
                            </ListSubheader>
                        }
                    >
                        {dataTop10PedidosClientes.length === 0 ? (
                            <ListItem disableGutters>
                                <Texto id="label.sinRegistros" />
                            </ListItem>
                        ) : (
                            dataTop10PedidosClientes.map(({ Cantidad, Descripcion }, indice) => (
                                <ListItem disableGutters key={`${Descripcion}#${indice}`}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs="auto">
                                            <Texto mensaje={Descripcion} />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Texto mensaje={Cantidad} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        )}
                    </List>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default MonitorPedidosVista;
