import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import SelectItems from 'Infotrack@Transversales/componentes/selectItems';
import React from 'react';
import { IFormularioDetalleAuditoriaVistaProps } from '../interfaces';

const FormularioDetalleAuditoriaVista: React.FunctionComponent<IFormularioDetalleAuditoriaVistaProps> = ({
    alCambiarValor,
    alCerrarReiniciarFormulario,
    alGuardarDocumentoDetalle,
    alSeleccionarItem,
    divisiones,
    divisionPorDefecto,
    documento,
    documentoDetalle,
    estadoFormulario,
    esMovil,
    item,
    tipoFormularioDocumentoDetalle,
}) => (
    <Dialog fullWidth maxWidth={esMovil ? 'lg' : 'sm'} open={estadoFormulario}>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                ? 'auditoriasinventario.Detalles.crearDetalleDocumento'
                                : 'auditoriasinventario.Detalles.editarDetalleDocumento'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item xs={12}>
                            <SelectItems alSeleccionarItem={alSeleccionarItem} item={item} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto display="inline" id="auditoriasinventario.Detalles.CantidadRecibida" />}
                                name={
                                    documento && documento.EtapaDescripcion === TiposEtapas.Conteo
                                        ? 'CantidadRecibida'
                                        : 'CantidadEntregada'
                                }
                                required
                                onChange={(e) =>
                                    esEnteroODecimal(
                                        e.target.value,
                                        { cantidadDecimales: 5, cantidadEnteros: 7 },
                                        true
                                    ) && alCambiarValor(e)
                                }
                                value={
                                    documento && documento.EtapaDescripcion === TiposEtapas.Conteo
                                        ? documentoDetalle.CantidadRecibida
                                        : documentoDetalle.CantidadEntregada
                                }
                                variant="outlined"
                                id={
                                    documento && documento.EtapaDescripcion === TiposEtapas.Conteo
                                        ? `CampoCantidadRecibida-${documentoDetalle.CantidadRecibida}`
                                        : `CampoCantidadEntregada-${documentoDetalle.CantidadEntregada}`
                                }
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                disabled={divisionPorDefecto !== undefined}
                                label={<Texto display="inline" id="auditoriasinventario.Detalles.Division" />}
                                fullWidth
                                name="DivisionOrigen"
                                required
                                onChange={alCambiarValor}
                                select
                                value={divisionPorDefecto ? divisionPorDefecto : documentoDetalle.DivisionOrigen}
                                variant="outlined"
                                id={`CampoDivisionOrigen`}
                            >
                                {divisiones.map(({ DivisionDescripcion, DivisionId }) => (
                                    <MenuItem key={DivisionId} value={DivisionId}>
                                        {DivisionDescripcion}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {item && item.Lotes && (
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label={<Texto display="inline" id="auditoriasinventario.Detalles.Lote" />}
                                    name="Lote"
                                    required
                                    onChange={alCambiarValor}
                                    value={documentoDetalle.Lote}
                                    variant="outlined"
                                    id={`CampoLote-${documentoDetalle.Lote}`}
                                />
                            </Grid>
                        )}
                        {item && item.Seriado && (
                            <Grid item md={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label={<Texto display="inline" id="auditoriasinventario.Detalles.Serial" />}
                                    name="Serial"
                                    required
                                    onChange={alCambiarValor}
                                    value={documentoDetalle.Serial}
                                    variant="outlined"
                                    id={`CampoSerial-${documentoDetalle.Serial}`}
                                />
                            </Grid>
                        )}
                        {item && item.FEFO && (
                            <Grid item md={6} xs={12}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    required
                                    label={
                                        <Texto display="inline" id="auditoriasinventario.Detalles.FechaVencimiento" />
                                    }
                                    onChange={(fecha: any) =>
                                        alCambiarValor({
                                            target: { name: 'FechaVencimiento', value: fecha },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    value={documentoDetalle.FechaVencimiento}
                                    inputVariant="outlined"
                                    id={`CampoFechaVencimiento-${documentoDetalle.FechaVencimiento}`}
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={() => alCerrarReiniciarFormulario(true)} variant="contained" id="boton-cancelar">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        onClick={alGuardarDocumentoDetalle}
                        variant="contained"
                        id={
                            tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                ? 'boton-agregar'
                                : 'boton-editar'
                        }
                    >
                        <Texto
                            id={
                                tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                    ? 'boton.agregar'
                                    : 'boton.editar'
                            }
                        />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDetalleAuditoriaVista;
