import { Card, CardHeader } from '@material-ui/core';
import {  Remove as RemoveIcon } from '@material-ui/icons';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { MTableToolbar } from 'material-table';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import BotonAgregar from '../BotonAgregar/BotonAgregar';
import ModalSeriales from '../ModalSeriales/ModalSeriales';
import BotonAbrirModalSeriales from './BotonAbrirModalSeriales';
import CampoCantidad from './Campos/CampoCantidad';
import CampoDivisionDestino from './Campos/CampoDivisionDestino';
import CampoDivisionOrigen from './Campos/CampoDivisionOrigen';
import CampoFechaVencimiento from './Campos/CampoFechaVencimiento';
import CampoLote from './Campos/CampoLote';
import MovimientoCM from './controladorModelo/MovimientoCM';
import { v4 as uuidv4 } from 'uuid';

interface IEntidadesTablaDetallesPlantilla {
    divisionesOrigen: IBodegaDivisiones[];
    divisionesDestino: IBodegaDivisiones[];
}

interface ITablaDetallesPlantillaProps {
    agregarDetalles: (detalles: IDocumentoDetalleConsultaGeneral[]) => Promise<boolean>;
    alAgregarDetalles?: () => any;
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    camposEditables?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    deshabilitada: boolean;
    detalles: IDocumentoDetalleConsultaGeneral[];
    entidades: Partial<IEntidadesTablaDetallesPlantilla>;
    tipoCantidad: 'CantidadRecibida' | 'CantidadEntregada';
    titulo: ReactNode;
    asignarSerialesVisible?: boolean;
    seriadoConsulta: boolean;
    manejaPeso: boolean;
    mostrarDesplegable: boolean;
    idBodegaOrigen: number;
    detalleBase: IDocumentoDetalleConsultaGeneral;
}

const TablaDetallesPlantilla: FunctionComponent<ITablaDetallesPlantillaProps> = ({
    agregarDetalles,
    alAgregarDetalles,
    camposOcultar = [],
    camposEditables = [],
    deshabilitada,
    detalles: detallesProp,
    entidades,
    tipoCantidad,
    titulo,
    asignarSerialesVisible,
    seriadoConsulta,
    manejaPeso,
    mostrarDesplegable,
    idBodegaOrigen,
    detalleBase,
}) => {
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [diccionarioDivisionDestino, setDiccionarioDivisionDestino] = useState<Record<string, string>>({});
    const [diccionarioCantidades, setDiccionarioCantidades] = useState<Record<string, string>>({});
    const [detalleModalSeriales, setDetalleModalSeriales] = useState<IDocumentoDetalleConsultaGeneral | null>(null);
    const [diccionarioDetalleSeriales, setDiccionarioDetalleSeriales] = useState<Record<string, string[]>>({});
    const [pesoSerial, setPesoSerial] = useState<string>('');
    const movimientoCM = useMemo(() => new MovimientoCM(), []);

    useEffect(() => {
        construirDiccionarioCantidades();
        construirDiccionarioDivisionesDestino();
    }, [detalles]);

    useEffect(() => {
        construirDiccionarioDetalleSeriales();
    }, [diccionarioCantidades]);

    useEffect(() => {
        consultarInventatioItemBodega1(detalleBase.ItemId!);
    }, [detalleBase]);

    const consultarInventatioItemBodega1 = async (itemId: number) => {
        const detallesInventario = await movimientoCM.consultarItemBodegaConsulta({
            BodegaId: idBodegaOrigen,
            IdItems: [itemId],
        });
        setDetalles(
            detallesInventario.map((d) => ({
                ...d,
                DocumentoDetalleId: uuidv4(),
                PesoFraccionSolicitado: detalleBase.PesoFraccionSolicitado,
                CantidadSolicitada: detalleBase.CantidadSolicitada,
                EmpresaId: detalleBase.EmpresaId,
                DocumentoDetallePredecesorId: detalleBase.DocumentoDetalleId,
            }))
        );
    };

    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];
        columnas.push({
            field: 'CodigoEmpresa',
            title: <Texto id="TablaDetallesPlantilla.CodigoEmpresa" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaDetallesPlantilla.DescripcionItem" />,
        });
        if (!camposOcultar.includes('DivisionOrigen'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    !camposEditables.includes('DivisionOrigen') ? (
                        detalle.DescripcionDivisionOrigen
                    ) : (
                        <CampoDivisionOrigen
                            deshabilitado={deshabilitada}
                            detalle={detalle}
                            divisiones={entidades.divisionesOrigen || []}
                            manejarCambioCampo={manejarCambioCampo}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionOrigen" />,
            });
        if (!camposOcultar.includes('DivisionDestino'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    !camposEditables.includes('DivisionDestino') ? (
                        detalle.DescripcionDivisionDestino
                    ) : (
                        <CampoDivisionDestino
                            deshabilitado={deshabilitada}
                            detalle={detalle}
                            divisiones={entidades.divisionesDestino || []}
                            manejarCambioCampo={manejarCambioCampo}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionDestino" />,
            });
        if (!camposOcultar.includes('PesoDisponible'))
            columnas.push({
                field: 'PesoDisponible',
                title: <Texto id="TablaDetallesPlantilla.PesoDisponible" />,
            });
        if (!camposOcultar.includes('PesoFraccionRecibido'))
            columnas.push({
                field: 'PesoFraccionRecibido',
                title: <Texto id="TablaDetallesPlantilla.PesoFraccionEntregado" />,
            });
        if (!camposOcultar.includes('CantidadDisponible'))
            columnas.push({
                field: 'CantidadDisponible',
                title: <Texto id="TablaDetallesPlantilla.CantidadDisponible" />,
            });
        if (!camposOcultar.includes('CantidadEntregada'))
            columnas.push({
                field: 'CantidadEntregada',
                title: <Texto id="TablaDetallesPlantilla.CantidadEntregada" />,
            });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => (
                <CampoCantidad
                    manejarCambio={(nuevoValor: string) =>
                        setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                            ...diccionarioCantidadesActual,
                            [detalle.DocumentoDetalleId]: nuevoValor,
                        }))
                    }
                    valor={diccionarioCantidades[detalle.DocumentoDetalleId]}
                />
            ),
            title: <Texto id="TablaDetallesPlantilla.Cantidad" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.Lotes) {
                    return <RemoveIcon id="boton-Remove"/>;
                } else {
                    if (!camposEditables.includes('Lote')) return detalle.Lote;
                    else
                        return (
                            <CampoLote
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                manejarCambioCampo={manejarCambioCampo}
                            />
                        );
                }
            },
            title: <Texto id="TablaDetallesPlantilla.Lote" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.FEFO) {
                    return <RemoveIcon id="boton-Remove"/>;
                } else {
                    if (!camposEditables.includes('FechaVencimiento'))
                        return moment(detalle.FechaVencimiento).format('DD/MM/YYYY');
                    else
                        return (
                            <CampoFechaVencimiento
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                manejarCambioCampo={manejarCambioCampo}
                            />
                        );
                }
            },
            title: <Texto id="TablaDetallesPlantilla.FechaVencimiento" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.FIFO || detalle.FechaIngreso === null) {
                    return <RemoveIcon id="boton-Remove"/>;
                } else {
                    if (!camposEditables.includes('FechaIngreso'))
                        return moment(detalle.FechaIngreso).format('DD/MM/YYYY');
                }
            },
            title: <Texto id="TablaDetallesPlantilla.FechaIngreso" />,
        });
        if (asignarSerialesVisible)
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.Seriado || detalle.Serialconsecutivo ? (
                        <BotonAbrirModalSeriales
                            abrirModalSeriales={() => setDetalleModalSeriales(detalle)}
                            deshabilitado={deshabilitada || !Boolean(diccionarioCantidades[detalle.DocumentoDetalleId])}
                        />
                    ) : (
                        <RemoveIcon id="boton-Remove"/>
                    ),
                title: <Texto id="TablaDetallesPlantilla.AsignarSeriales" />,
            });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => (
                <BotonAgregar disabled={deshabilitada} onClick={() => agregarDetalleODetalles(detalle)} id="boton-agregar" />
            ),
        });
        return columnas;
    };

    const construirDiccionarioCantidades = () => {
        const nuevoDiccionarioCantidades: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioCantidades[detalle.DocumentoDetalleId] = diccionarioCantidades[detalle.DocumentoDetalleId]
                ? diccionarioCantidades[detalle.DocumentoDetalleId]
                : '0';
        });
        setDiccionarioCantidades(nuevoDiccionarioCantidades);
    };

    const construirDiccionarioDivisionesDestino = () => {
        const nuevoDiccionarioDivisionesDestino: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioDivisionesDestino[detalle.DocumentoDetalleId] = diccionarioDivisionDestino[
                detalle.DocumentoDetalleId
            ]
                ? diccionarioDivisionDestino[detalle.DocumentoDetalleId]
                : '';
        });
        setDiccionarioDivisionDestino(nuevoDiccionarioDivisionesDestino);
    };

    const construirDiccionarioDetalleSeriales = () => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        Object.keys(diccionarioCantidades).forEach((detalleId) => {
            const detalle = detalles.find((d) => d.DocumentoDetalleId === detalleId)!;
            if (detalle &&( detalle.Seriado || detalle.Serialconsecutivo)) {
                const serialesDetalle = copiaDiccionarioDetalleSeriales[detalleId];
                const cantidadDetalle = Number(diccionarioCantidades[detalleId]);
                if (!serialesDetalle || serialesDetalle.length !== cantidadDetalle)
                    copiaDiccionarioDetalleSeriales[detalleId] = new Array(cantidadDetalle).fill('');
            }
        });
        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const guardarSerialesDetalle = (seriales: string[]) => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        copiaDiccionarioDetalleSeriales[detalleModalSeriales!.DocumentoDetalleId] = seriales;
        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const manejarCambioCampo = (
        detalleId: string,
        nombreCampo: keyof IDocumentoDetalleConsultaGeneral,
        nuevoValor: any
    ) => {
        const copiaDetalles = [...detalles];
        const detalle = copiaDetalles.find((d) => d.DocumentoDetalleId === detalleId)!;
        detalle[nombreCampo] = (nuevoValor as unknown) as never;
        setDetalles(copiaDetalles);
    };

    const validarDetalle = (detalle: IDocumentoDetalleConsultaGeneral) => {
        const cantidadDetalle = Number(diccionarioCantidades[detalle.DocumentoDetalleId]);
        if (isNaN(cantidadDetalle) || cantidadDetalle <= 0) {
            notificacionGlobal('ModalRecepcion.ValidacionNoCantidad', 3000, 'warning', true);
            return false;
        }

        if (
            (detalle.Seriado || detalle.Serialconsecutivo) &&
            asignarSerialesVisible &&
            diccionarioDetalleSeriales[detalle.DocumentoDetalleId].every((serial) => serial === '')
        ) {
            notificacionGlobal('ModalRecepcion.ValidacionSerialesIncompletos', 3000, 'warning', true);
            return false;
        }
        if (
            manejaPeso &&
            detalle.ManejaPeso! === false &&
            cantidadDetalle + detalle.CantidadRecibida! > detalle.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoRecibido', 3000, 'warning', true);
            return false;
        }

        if (detalle.ManejaPeso === false && detalle.PesoDisponible! > detalle.PesoFraccionSolicitado!) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado', 3000, 'warning', true);
            return false;
        }

        if (camposEditables.includes('DivisionDestino') && detalle.DivisionDestino === null) {
            notificacionGlobal('DetallesFormulario.ValidacionNoDivisionDestino', 3000, 'warning', true);
            return false;
        }
        if (camposEditables.includes('DivisionOrigen') && detalle.DivisionOrigen === null) {
            notificacionGlobal('DetallesFormulario.ValidacionNoDivisionOrigen', 3000, 'warning', true);
            return false;
        }


        if (
            detalle.ManejaPeso! === false &&
            !camposOcultar.includes('PesoDisponible') &&
            cantidadDetalle > detalle.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado', 3000, 'warning', true);
            return false;
        }

        if (
            detalle.ManejaPeso! === false &&
            !camposOcultar.includes('PesoDisponible') &&
            cantidadDetalle > detalle.CantidadDisponible!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadStock', 3000, 'warning', true);
            return false;
        }

        if (
            manejaPeso &&
            detalle.ManejaPeso &&
            (pesoSerial === null ||
                pesoSerial === undefined ||
                pesoSerial.toString() === '0' ||
                pesoSerial.toString() === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionNoPeso', 3000, 'warning', true);
            return false;
        }
        if (detalle.ManejaPeso && detalle.PesoDisponible! > detalle.PesoFraccionSolicitado!) {
            notificacionGlobal('DetallesFormulario.ValidacionPeso', 3000, 'warning', true);
            return false;
        }
        return true;
    };

    const agregarDetalleODetalles = async (detalle: IDocumentoDetalleConsultaGeneral) => {
        if (!validarDetalle(detalle)) return;
        let agregadoExitoso;
        if (!detalle.Seriado && !detalle.Serialconsecutivo) {
            const cantidadDetalle = Number(diccionarioCantidades[detalle.DocumentoDetalleId]);
            agregadoExitoso = await agregarDetalles([
                {
                    ...detalle,
                    [tipoCantidad]: cantidadDetalle,
                    DocumentoId: detalleBase.DocumentoId,
                },
            ]);
        } else {
            agregadoExitoso = await agregarDetalles(
                diccionarioDetalleSeriales[detalle.DocumentoDetalleId].map((serial) => ({
                    ...detalle,
                    [tipoCantidad]: 1,
                    Serial: serial,
                    PesoFraccionRecibido: pesoSerial === '' ? detalle.PesoDisponible : parseFloat(pesoSerial),
                    DocumentoId: detalleBase.DocumentoId,
                }))
            );
        }
        if (agregadoExitoso) {
            const copiaDetalles = [...detalles];
            const indiceDetalleTabla = detalles.findIndex((d) => d.DocumentoDetalleId === detalle.DocumentoDetalleId);
            copiaDetalles[indiceDetalleTabla] = { ...detallesProp[indiceDetalleTabla] };
            setDetalles(copiaDetalles);
            setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                ...diccionarioCantidadesActual,
                [detalle.DocumentoDetalleId]: '0',
            }));
            setPesoSerial('');
            if (alAgregarDetalles) alAgregarDetalles();
        }
    };

    return (
        <>
            <Card variant="outlined" style={{ padding: '0px', marginLeft: '20px' }}>
                <CardHeader
                    title={titulo}
                    titleTypographyProps={{ variant: 'body1' }}
                    style={{ padding: '0px', minHeight: '0px' }}
                />
                <Tabla
                    components={{
                        Toolbar: (props) => (
                            <div style={{ padding: '0px', height: '0px' }}>
                                <MTableToolbar {...props} />
                            </div>
                        ),
                    }}
                    onTreeExpandChange={() => {}}
                    columns={obtenerColumnas()}
                    data={detalles}
                    options={{ padding: 'dense', search: mostrarDesplegable }}
                />
            </Card>
            <ModalSeriales
                abierto={Boolean(detalleModalSeriales)}
                cerrar={() => {
                    setDetalleModalSeriales(null);
                }}
                guardarSeriales={guardarSerialesDetalle}
                serialesConsecutivos={
                    detalleModalSeriales && detalleModalSeriales.Serialconsecutivo
                        ? detalleModalSeriales.Serialconsecutivo
                        : false
                }
                serialesIniciales={
                    detalleModalSeriales ? diccionarioDetalleSeriales[detalleModalSeriales.DocumentoDetalleId] : []
                }
                seriadoConsulta={seriadoConsulta}
                mostrarPeso={manejaPeso && detalleModalSeriales !== null ? detalleModalSeriales.ManejaPeso! : false}
                guardarPesoSerial={setPesoSerial}
                pesoSerial={pesoSerial}
                detalle={detalleModalSeriales}
            />
        </>
    );
};

export { IEntidadesTablaDetallesPlantilla };

export default TablaDetallesPlantilla;
