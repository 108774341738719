import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';

interface ICampoComandoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IPlantillaImpresion,
        nuevoValor: IPlantillaImpresion[keyof IPlantillaImpresion]
    ) => any;
    valor: string;
}

const CampoComando: FunctionComponent<ICampoComandoProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="PlantillaImpresion.CampoComando.Nombre" />}
        onChange={(event) => manejarCambioCampo('Comando', event.target.value)}
        required
        value={valor}
        id={`CampoComando-${valor}`}
        multiline
    />
);

export default CampoComando;
