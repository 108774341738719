import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import ModeloOrdenSalida from 'Infotrack@Modelos/negocioRefactor/modelos/OrdenSalida/ModeloOrdenSalida';

class OrdenSalidaCM {
    private modeloOrdenSalida: ModeloOrdenSalida;

    constructor() {
        this.modeloOrdenSalida = new ModeloOrdenSalida();
    }

    public async guardarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloOrdenSalida.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloOrdenSalida.editarEncabezado(documento);
        return respuesta.data;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        const respuesta = await this.modeloOrdenSalida.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloOrdenSalida.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloOrdenSalida.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async cambiarEtapa(documento: IDocumento,  idUsuario:string) {
        const respuesta = await this.modeloOrdenSalida.cambiarEtapa(documento, idUsuario);
        return respuesta.data.Resultado;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloOrdenSalida.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        const respuesta = await this.modeloOrdenSalida.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloOrdenSalida.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloOrdenSalida.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloOrdenSalida.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async generarArchivoPDF(documentoId: string, idAplicacion: string) {
        const respuesta = await this.modeloOrdenSalida.generarArchivoPDF(documentoId, idAplicacion);
        return respuesta.data.Entidades[0] ;
    }

    private validarDocumento(documento: IDocumento) {
        if (!documento.TipoDocumentoId) {
            notificacionGlobal('OrdenSalida.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        if (!documento.BodegaOrigen) {
            notificacionGlobal('OrdenSalida.ValidacionNoBodegaOrigen', 3000, 'warning', true);
            return false;
        }
        if (!documento.ClienteId) {
            notificacionGlobal('OrdenSalida.ValidacionNoClienteId', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}

export default OrdenSalidaCM;
