import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

interface ICampoCodigoDocumentoBaseProps {
    manejarCambio: (nuevoValor: string) => any;
    valor?: string;
    refrescar?: boolean;
}

const CampoCodigoDocumentoBase: FunctionComponent<ICampoCodigoDocumentoBaseProps> = ({
    manejarCambio,
    valor,
    refrescar,
}) => {
    if (refrescar) {
        manejarCambio('');
    }
    return (
        <>
            <TextField
                fullWidth
                label={<Texto id="DocumentoBuscador.CampoCodigoDocumentoBase.Nombre" />}
                onChange={(event) => manejarCambio(event.target.value)}
                value={valor}
                id={`CampoCodigoDocumentoBase-${valor}`}
            />
        </>
    );
};

export default CampoCodigoDocumentoBase;
