import ModeloItem from 'Infotrack@Modelos/smartStock/items';
import IItems from 'Infotrack@Modelos/smartStock/items/entidades/IItems';

class ItemCM {
    private modeloItem: ModeloItem;

    constructor() {
        this.modeloItem = new ModeloItem();
    }

    public async consultarListaItemsCompuestosFiltro(filtro: Partial<IItems>) {
               const respuesta = await this.modeloItem.consultarListaItemsCompuestosFiltro(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarListaItemsBodega(idBodega: number) {
        const respuesta = await this.modeloItem.consultarListaItemsBodega(idBodega);
        return respuesta.data.Entidades;
    }
}

export default ItemCM;
