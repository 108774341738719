export default{
    "DocumentoFirma.CargarFirma":"Firma Documento",
    "DocumentoFirma.BotonFirmar":"Firmar Documento",
    "DocumentoFirma.CargarFirmar":"Cargar Firma",
    "DocumentoFirma.FirmaCargada":"Firma Cargada",
    "DocumentoFirma.FirmaObligatoria":"Por favor ingrese una firma.",
    "DocumentoFirma.ArchivoNoValido":"Archivo no valido",
    "DocumentoFirma.BorrarFirma":"Borrar firma"
    

}