import { TextField } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

interface ICampoFacturaProps {
    manejarCambioFactura: (nuevoValor: string) => any;
    valor?: string;
    refrescar?: boolean;
}

const CampoFactura: FunctionComponent<ICampoFacturaProps> = ({
    manejarCambioFactura,
    valor,
    refrescar,
}) => {

    useEffect(() => {
        manejarCambioFactura("");
    }, [refrescar]);
    
    return (
        <>
            <TextField
                fullWidth
                label={<Texto id="DocumentoBuscador.CampoFactura.Factura" />}
                onChange={(event) => {
                    debugger;
                    // console.log(/^\d*$/.test(event.target.value), 'regex');
                    // if (/^\d*$/.test(event.target.value) && event.target.value !== undefined) {
                        console.log(event.target.value, 'Value');
                        manejarCambioFactura(event.target.value );
                        console.log(valor, 'Valor');
                    //}
                }}
                value={valor}
                id={`CampoIdentificacionCliente-${valor}`}
            />
        </>
    );
};

export default CampoFactura;
