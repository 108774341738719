import React, { useEffect } from 'react';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import { ITablaMaximosMinimosProductosVistaProps } from '../interfaces';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { accionesTablaMaximosMinimos, columnasTablaMaximosMinimosProducto, opcionesMaximosMinimos } from './constantes';
import { AppBar, Button, Card, CardContent, Dialog, DialogContent, Grid, TextField, Toolbar } from '@material-ui/core';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';

const TablaMaximosMinimosProductosVista: React.FunctionComponent<ITablaMaximosMinimosProductosVistaProps &
    InjectedIntlProps> = ({
    intl,
    abrirFormularioMaximosMinimos,
    crearMaxMin,
    referenciaTabla,
    consultarDocumentos,
    consulta,
    cerrar,
    alCambiarValorMinMax,
    productosMinMax,
    manejarCambioFiltro,
    filtrosItem,
    limpiarFiltros,
    maximosMinimosGuardados
}) => {
    useEffect(() => {
        if (referenciaTabla.current) {
            consultarDocumentos();
        }
    }, [abrirFormularioMaximosMinimos]);
    return (
        <Dialog fullWidth open={abrirFormularioMaximosMinimos} maxWidth="lg">
            <AppBar position="static">
                <ModalEncabezado
                    cerrar={cerrar!}
                    titulo={<Texto align="center" color="inherit" id={''} />}
                />
            </AppBar>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid alignItems="center" container spacing={2} justifyContent="space-between">
                            <Grid item md={true} xs={6}>
                                <TextField
                                    fullWidth
                                    label={<Texto id='maximosMinimos.CodigoProducto' />}
                                    name="CodigoEmpresa"
                                    onChange={(event)=>manejarCambioFiltro("CodigoEmpresa",event.target.value)}
                                    variant="outlined"
                                    value={filtrosItem.CodigoEmpresa}
                                    id={`CampoCodigoEmpresa-${filtrosItem.CodigoEmpresa}`}
                                />
                            </Grid>

                            <Grid item md={true} xs={6}>
                                <TextField
                                    fullWidth
                                    label={<Texto id='maximosMinimos.NombreProducto' />}
                                    name="DescripcionItem"
                                    onChange={(event)=>manejarCambioFiltro("DescripcionItem",event.target.value)}
                                    variant="outlined"
                                    value={filtrosItem.DescripcionItem}
                                    id={`DescripcionItem-${filtrosItem.DescripcionItem}`}
                                />
                            </Grid>
                            <Grid item md="auto" xs={6}>
                                <BotonBuscar  onClick={consultarDocumentos} id="boton-limpiar" />
                            </Grid>
                            <Grid item md="auto" xs={6}>
                                <BotonLimpiar onClick={limpiarFiltros} id="boton-limpiar" />
                            </Grid>
                        </Grid>
                        </CardContent>
                        <CardContent>
                        <Tabla
                            actions={[]}
                            columns={columnasTablaMaximosMinimosProducto(
                                intl.formatMessage,
                                () => {},
                                alCambiarValorMinMax,
                                productosMinMax,
                                crearMaxMin,
                                maximosMinimosGuardados
                            )}
                            data={consulta}
                            options={{ search: true }}
                            style={{ width: '100%' }}
                            title={''}
                            tableRef={referenciaTabla}
                        />
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
};

export default injectIntl(TablaMaximosMinimosProductosVista);
