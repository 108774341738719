import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from 'Infotrack@Modelos/conexiones';
import ITipoDocumentoCompuesto from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/administracion/ITipoDocumentoCompuesto';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

class ModeloTipoDocumento {
    private NOMBRE_CONTROLADOR = 'TipoDocumento';

    public async consultarListaTipoDocumentoCompuesto(filtro: Partial<ITipoDocumento>) {
        return manejadorRest<IRespuesta<ITipoDocumentoCompuesto>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaTipoDocumentoCompuesto`, filtro),
            true
        );
    }

    public async guardarTipoDocumento(tipoDocumento: ITipoDocumento) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/GuardarTipoDocumento`, tipoDocumento),
            true
        );
    }

    public async editarTipoDocumento(tipoDocumento: ITipoDocumento) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocioConfiguracion.put(`${this.NOMBRE_CONTROLADOR}/EditarTipoDocumento`, tipoDocumento),
            true
        );
    }

    public async eliminarTipoDocumento(filtro: Partial<ITipoDocumento>) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocioConfiguracion.delete(`${this.NOMBRE_CONTROLADOR}/EliminarTipoDocumento`, filtro),
            true
        );
    }

    public async consultarTiposDocumentoFiltro(filtro: Partial<ITipoDocumento>) {
        return manejadorRest<IRespuesta<ITipoDocumento>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, filtro),
            true
        );
    }
}

export default ModeloTipoDocumento;
