import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IFlujo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFlujo';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoFlujoIdProps {
    deshabilitado: boolean;
    flujos: IFlujo[];
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    valor: number;
}

const CampoFlujoId: FunctionComponent<ICampoFlujoIdProps> = ({ deshabilitado, flujos, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="TiposDocumento.CampoFlujoId.Nombre" />}
        onChange={(event) => manejarCambioCampo('FlujoId', event.target.value)}
        required
        select
        value={valor || ''}
        id={`CampoFlujo`}
    >
        {flujos.map((flujo) => (
            <MenuItem key={flujo.FlujoId} value={flujo.FlujoId}>
                {flujo.FlujoDescripcion}
            </MenuItem>
        ))}
    </TextField>
);

export default CampoFlujoId;
