import { Button, Card, CardContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { EstadosModalTipoDocumento } from '../enumeraciones';
import CampoAbreviatura from '../ModalTipoDocumento/Campos/CampoAbreviatura';
import CampoConsecutivo from '../ModalTipoDocumento/Campos/CampoConsecutivo';
import CampoEstado from '../ModalTipoDocumento/Campos/CampoEstado';
import CampoPlantilla from '../ModalTipoDocumento/Campos/CampoPlantilla';
import CampoFlujoId from '../ModalTipoDocumento/Campos/CampoFlujoId';
import CampoTipoDocumentoDescripcion from '../ModalTipoDocumento/Campos/CampoTipoDocumentoDescripcion';
import CampoTipoProcesoId from '../ModalTipoDocumento/Campos/CampoTipoProcesoId';
import CampoSincronizacionMovil from '../ModalTipoDocumento/Campos/CampoSincronizacionMovil';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import { IEntidadesTipoDocumento } from '../ModalTipoDocumento/ModalTipoDocumento';
import Texto from '@infotrack/presentacion-componentes/texto';
import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';

interface IFormularioTiposDocumentosProps {
    estado: EstadosModalTipoDocumento;
    entidadesTipoDocumento: IEntidadesTipoDocumento;
    tipoDocumento: ITipoDocumento;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => void;
    crearTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
    editarTipoDocumento: (tipoDocumento: ITipoDocumento) => any;
    alCambiarValorAutocomplete: (nuevoValor: IPlantillaImpresion) => void;
}

const FormularioTiposDocumentos: FunctionComponent<IFormularioTiposDocumentosProps> = ({
    estado,
    entidadesTipoDocumento,
    tipoDocumento,
    manejarCambioCampo,
    crearTipoDocumento,
    editarTipoDocumento,
    alCambiarValorAutocomplete,
}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CampoTipoProcesoId
                                    deshabilitado={
                                        estado === EstadosModalTipoDocumento.VISUALIZACION ||
                                        estado === EstadosModalTipoDocumento.EDICION
                                    }
                                    manejarCambioCampo={manejarCambioCampo}
                                    tiposProceso={entidadesTipoDocumento.tiposProceso}
                                    valor={tipoDocumento.TipoProcesoId}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CampoFlujoId
                                    deshabilitado={
                                        estado === EstadosModalTipoDocumento.VISUALIZACION ||
                                        estado === EstadosModalTipoDocumento.EDICION
                                    }
                                    flujos={entidadesTipoDocumento.flujos}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.FlujoId}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <CampoPlantilla
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    flujos={entidadesTipoDocumento.plantillaImpresion}
                                    valor={Number(tipoDocumento.PlantillaImpresionId)}
                                    alCambiarValorAutocomplete={alCambiarValorAutocomplete}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CampoTipoDocumentoDescripcion
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.TipoDocumentoDescripcion}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CampoAbreviatura
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.Abreviatura}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CampoConsecutivo
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.Consecutivo}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={6} xs={12}>
                                <CampoEstado
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.Estado}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={6} xs={12}>
                                <CampoSincronizacionMovil
                                    deshabilitado={estado === EstadosModalTipoDocumento.VISUALIZACION}
                                    manejarCambioCampo={manejarCambioCampo}
                                    valor={tipoDocumento.SincronizaMovil}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        {estado === EstadosModalTipoDocumento.CREACION && (
                                            <Button
                                                color="primary"
                                                onClick={() => crearTipoDocumento(tipoDocumento)}
                                                size="small"
                                                variant="contained"
                                                id="boton-guardar"
                                            >
                                                <Texto id="ModalTipoDocumento.BotonGuardar" />
                                            </Button>
                                        )}
                                        {estado === EstadosModalTipoDocumento.EDICION && (
                                            <Button
                                                color="primary"
                                                onClick={() => editarTipoDocumento(tipoDocumento)}
                                                size="small"
                                                variant="contained"
                                                id="boton-editar"
                                            >
                                                <Texto id="ModalTipoDocumento.BotonEditar" />
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default FormularioTiposDocumentos;
