import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, Block as BlockIcon, Visibility as VisibilityIcon, Create } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';

interface IAuditoriaTablaProps {
    crearAuditoria: () => any;
    anularAuditoria: (documentoId: string) => void;
    recargarTabla: () => any;
    listaAuditoria: IAuditoriaCompuesta[];
    verAuditoria: (auditoria: IAuditoriaCompuesta) => any;
    gestionarAuditoria: (auditoria: IAuditoriaCompuesta) => any;
}

const AuditoriaTabla: FunctionComponent<IAuditoriaTablaProps> = ({
    crearAuditoria,
    anularAuditoria,
    recargarTabla,
    listaAuditoria,
    verAuditoria,
    gestionarAuditoria,
}) => {
    return (
        <Tabla
            actions={[
                { icon: 'refresh', isFreeAction: true, onClick: recargarTabla },
                { icon: 'add', isFreeAction: true, onClick: crearAuditoria },
            ]}
            columns={[
                {
                    render: (auditoria: IAuditoriaCompuesta) => (
                        <Grid container wrap="nowrap">
                            <Grid item>
                                <Tooltip title={<Texto id="AuditoriaTabla.VerAuditoria" />}>
                                    <IconButton onClick={() => verAuditoria(auditoria)} size="small" id="boton-visualizar">
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid>
                                <Tooltip title={<Texto id="AuditoriaTabla.GestionarAuditoria" />}>
                                    <IconButton
                                        disabled={[2, 3, 4].includes(auditoria.EtapaId) ? true : false}
                                        onClick={() => gestionarAuditoria(auditoria)}
                                        size="small"
                                        id="boton-crear"
                                    >
                                        <Create 
                                        id="boton-crear"/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title={<Texto id="AuditoriaTabla.AnularAuditoria" />}>
                                    <IconButton
                                        disabled={[2, 3, 4].includes(auditoria.EtapaId) ? true : false}
                                        onClick={() => anularAuditoria(auditoria.DocumentoId!)}
                                        size="small"
                                        id="boton-bloquear"
                                    >
                                        <BlockIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ),
                    title: <Texto id="AuditoriaTabla.Acciones" />,
                },
                {
                    field: 'CodigoDocumento',
                    title: <Texto id="AuditoriaTabla.CodigoDocumento" />,
                },
                {
                    field: 'DescripcionBodega',
                    title: <Texto id="AuditoriaTabla.DescripcionBodega" />,
                },
                {
                    render: (auditoria: IAuditoriaCompuesta) =>
                        auditoria.Divisiones.map((d) => d.DescripcionDivision).join(', '),
                    title: <Texto id="AuditoriaTabla.DescripcionDivision" />,
                },
                {
                    render: (auditoria: IAuditoriaCompuesta) =>
                        auditoria.Familias.map((f) => f.DescripcionFamilia).join(', '),
                    title: <Texto id="AuditoriaTabla.DescripcionFamilia" />,
                },
                {
                    render: (auditoria: IAuditoriaCompuesta) =>
                        auditoria.Items.map((i) => i.DescripcionItem).join(', '),
                    title: <Texto id="AuditoriaTabla.DescripcionItem" />,
                },
                {
                    field: 'DescripcionEtapaDocumento',
                    title: <Texto id="AuditoriaTabla.DescripcionEtapaDocumento" />,
                },
            ]}
            data={listaAuditoria}
            options={{ padding: 'dense' }}
        />
    );
};

export default AuditoriaTabla;
