import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

interface ICampoEtapaIdProps {
    deshabilitado: boolean;
    etapas: IEtapas[];
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    valor: number;
}

const CampoEtapaId: FunctionComponent<ICampoEtapaIdProps> = ({ deshabilitado, etapas, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="FormularioDocumento.CampoEtapaId.Nombre" />}
        onChange={(event) => manejarCambioCampo('EtapaId', event.target.value)}
        select
        value={valor !== 0 ? valor : ''}
        id={`CampoEtapa-${valor !== 0 ? valor : ''}`}
    >
        {etapas.map((etapa) => (
            <MenuItem key={etapa.EtapaId} value={etapa.EtapaId}>
                {etapa.EtapaDescripcion}
            </MenuItem>
        ))}
    </TextField>
);

export default CampoEtapaId;
