import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoAbreviaturaProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    valor: string;
}

const CampoAbreviatura: FunctionComponent<ICampoAbreviaturaProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="TiposDocumento.CampoAbreviatura.Nombre" />}
        onChange={(event) => manejarCambioCampo('Abreviatura', event.target.value)}
        required
        value={valor}
        id={`CampoAbreviatura-${valor}`}
    />
);

export default CampoAbreviatura;
