import { AppBar, Grid, IconButton, Toolbar } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { FunctionComponent, ReactNode } from 'react';

interface IModalEncabezadoProps {
    cerrar: () => void;
    titulo: ReactNode;
}

const ModalEncabezado: FunctionComponent<IModalEncabezadoProps> = ({ cerrar, titulo }) => (
    <AppBar color="primary" position="static">
        <Toolbar variant="dense">
            <Grid alignItems="center" container spacing={1}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <Grid container justifyContent="center">
                        <Grid item>{titulo}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <IconButton color="inherit" onClick={cerrar} id="boton-cerrar">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>
);

export default ModalEncabezado;
