import { Button, Dialog, DialogContent, Grid, TextField } from '@material-ui/core';
import React, { createRef, FunctionComponent, useEffect, useMemo, useState } from 'react';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import CampoConteoPredecesor from 'Infotrack@Transversales/componentes/Auditoria/AuditoriaConteo/Campos/CampoConteoPredecesor';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import useStyles from './ModalComparacionEstilos';

interface IModalComparacionConteoProps {
    abierto: boolean;
    cerrar: () => void;
    primerConteo: string;
    conteos: IAuditoriaConteo[];
}

const ModalComparacionConteo: FunctionComponent<IModalComparacionConteoProps> = ({
    abierto,
    cerrar: cerrarProp,
    primerConteo,
    conteos,
}) => {
    const classes = useStyles();

    const [idPrimerConteo, setPrimerConteo] = useState<IAuditoriaConteo[keyof IAuditoriaConteo]>(undefined);
    const [idSegundoConteo, setSegundoConteo] = useState<IAuditoriaConteo[keyof IAuditoriaConteo]>(undefined);

    const cerrar = () => {
        cerrarProp();
    };

    const manejarCambioPrimerConteo = (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => {
        setPrimerConteo(nuevoValor);
    };

    const manejarCambioSegundoConteo = (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => {
        setSegundoConteo(nuevoValor);
    };

    const comparaConteos = () => {
        window.open(`Conteo/ComparacionConteo?primerConteo=${primerConteo}&segundoConteo=${idSegundoConteo}`, '_blank');
    };

    return (
        <Dialog open={abierto}>
            <ModalEncabezado cerrar={cerrar} titulo={'Comparar conteos'} />
            <DialogContent classes={{ root: classes.dialogoComparacionConteo }}>
                <Grid container spacing={2}>
                    {conteos && (
                        <Grid item xs={12}>
                            <CampoConteoPredecesor
                                deshabilitado={true}
                                manejarCambio={manejarCambioPrimerConteo}
                                conteos={conteos}
                                valor={primerConteo}
                                idRecursoTitulo={'AuditoriaConteo.CampoConteoSeleccionado.Nombre'}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={2}>
                    {conteos && (
                        <Grid item xs={12}>
                            <CampoConteoPredecesor
                                deshabilitado={conteos.length === 0}
                                manejarCambio={manejarCambioSegundoConteo}
                                conteos={conteos.filter(
                                    (x) => x.AuditoriaConteoId != primerConteo //&& x.IdTipoConteo != 2
                                )}
                                valor={''}
                                idRecursoTitulo={'AuditoriaConteo.CampoConteoAComparar.Nombre'}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalComparacionConteo;
