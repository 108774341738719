const mensajes = {
    // Titulo
    'Auditoria.Titulo': 'Auditoría',
    //Auditoria
    'Auditoria.ValidacionNoBodegaId': 'Debe seleccionar una bodega',
    'Auditoria.ValidacionNoTipoDocumentoId': 'Debe seleccionar un tipo de documento',
    'Auditoria.SeleccioneBodega':'Seleccione una bodega',
    'Auditoria.SinConteos':'No se encontraron conteos para la auditoria',
    'Auditoria.SeleccioneBodegaTipoDocumento':'Seleccione una bodega y un tipo documento',
    //AuditoriaTabla
    'AuditoriaTabla.Acciones': 'Acciones',
    'AuditoriaTabla.VerAuditoria': 'Ver auditoría',
    'AuditoriaTabla.GestionarAuditoria': 'Gestionar auditoría',
    'AuditoriaTabla.AnularAuditoria': 'Anular auditoría',
    'AuditoriaTabla.CodigoDocumento': 'Código documento',
    'AuditoriaTabla.DescripcionBodega': 'Bodega',
    'AuditoriaTabla.DescripcionDivision': 'División',
    'AuditoriaTabla.DescripcionFamilia': 'Familia',
    'AuditoriaTabla.DescripcionItem': 'Ítem',
    'AuditoriaTabla.DescripcionEtapaDocumento': 'Etapa documento',
    // ModalAuditoria
    'ModalAuditoria.TituloCreacion': 'Crear Auditoría',
    'ModalAuditoria.TituloGestion': 'Gestionar Auditoría',
    'ModalAuditoria.TituloVisualizacion': 'Ver Auditoría',
    // CampoBodegaId
    'Auditoria.CampoBodegaId.Nombre': 'Bodega',
    // CampoTipoDocumentoId
    'Auditoria.CampoTipoDocumentoId.Nombre': 'Tipo Documento',
    // CampoDivisionId
    'Auditoria.CampoDivisionId.Nombre': 'División',
    // CampoFamiliaId
    'Auditoria.CampoFamiliaId.Nombre': 'Familia',
    // CampoItemId
    'Auditoria.CampoItemId.Nombre': 'Ítem',
    // Campo EtapaId
    'Auditoria.CampoEtapaId.Nombre': 'Etapa',
    // Campo Cambio Etapa
    'Auditoria.CampoCambioEtapaId.Nombre': 'Cambiar Etapa',
    // Campos CodigoDocumento
    'Auditoria.CampoCodigoDocumento.Nombre': 'Código Documento',
    // mensajes
    'MensajeAuditoria.confirmarAnularDocumento': '¿Está seguro que desea anular el documento?',
    'MensajeAuditoria.SinConteos':'Auditoria sin conteos creados'
};

export default mensajes;
