import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';

interface ICampoBodegaDestinoProps {
    bodegasDestino: IBodega[];
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor?: number;
}

const CampoBodegaDestino: FunctionComponent<ICampoBodegaDestinoProps> = ({
    bodegasDestino,
    manejarCambioFiltro,
    valor,
}) => (
    <Autocomplete
        getOptionLabel={(b) => b.BodegaDescripcion}
        onChange={(_: object, value: IBodega | null) =>
            manejarCambioFiltro('BodegaDestino', value ? value.BodegaId : 0)
        }
        options={bodegasDestino}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoBodegaDestino.Nombre" />} />
        )}
        value={bodegasDestino.find((b) => b.BodegaId === valor) || null}
        id={'CampoBodegaDestino'}
    />
);

export default CampoBodegaDestino;
