import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';
import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

interface ICampoConteoPredecesorProps {
    deshabilitado?: boolean;
    conteos: IAuditoriaConteo[];
    manejarCambio: (nombreCampo: keyof IAuditoriaConteo, nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]) => void;
    valor: string | undefined;
    idRecursoTitulo: string;
}

const CampoConteoPredecesor: FunctionComponent<ICampoConteoPredecesorProps> = ({
    deshabilitado,
    conteos,
    manejarCambio,
    valor,
    idRecursoTitulo,
}) => {
    return (
        <Autocomplete
            disabled={deshabilitado}
            onChange={(_: ChangeEvent<{}>, value: IAuditoriaConteo | null) =>
                manejarCambio('ConteoPredecesorId', value ? value.AuditoriaConteoId : '')
            }
            options={conteos}
            getOptionLabel={(i) => i.DescripcionConteo}
            renderInput={(params) => (
                <TextField {...params} fullWidth label={<Texto id={idRecursoTitulo} />} required />
            )}
            value={conteos ? conteos.find((d) => d.AuditoriaConteoId === valor) || null : null}
            id={`CampoConteoPredecesor`}
        />
    );
};

export default CampoConteoPredecesor;
