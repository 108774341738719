import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { IFiltrosItemMovimientosVistaProps } from '../interfaces';

const FiltrosItemMovimientosVista: React.FunctionComponent<IFiltrosItemMovimientosVistaProps> = ({
    alCambiarValor,
    alCambiarValorDivisionDestino,
    consultarItemMovimientos,
    classes,
    desactivarDivisionDestino,
    divisiones,
    divisionesDestino,
    divisionDestino,
    enEdicion,
    filtrosItem,
}) => (
    <>
        <Grid item md={9} xs={12}>
            <Card>
                <CardContent className={classes.contendorTarjetasFiltros}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item md xs={12}>
                            <Autocomplete
                                disabled={enEdicion}
                                disableClearable
                                getOptionLabel={({ DivisionDescripcion }) => DivisionDescripcion}
                                onChange={(_: any, division: any) =>
                                    alCambiarValor({ target: { name: 'Division', value: division } } as any)
                                }
                                options={divisiones}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        fullWidth
                                        label={<Texto id="selectItems.divisionOrigen" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtrosItem.Division!==null?filtrosItem.Division:undefined}
                                id={`CampoDivicion-${filtrosItem.Division!==null?filtrosItem.Division:undefined}`}
                            />
                        </Grid>
                        <Grid item md xs={12}>
                            <TextField
                                disabled={enEdicion}
                                fullWidth
                                label={<Texto id="selectItems.descripcionProducto" />}
                                name="DescripcionItem"
                                onChange={alCambiarValor}
                                value={filtrosItem.DescripcionItem}
                                variant="outlined"
                                id={`CampoDescripcionItem-${filtrosItem.DescripcionItem}`}
                            />
                        </Grid>
                        <Grid item md xs={12}>
                            <TextField
                                disabled={enEdicion}
                                fullWidth
                                label={<Texto id="selectItems.codigoBarasProducto" />}
                                name="CodigoBarras"
                                onChange={alCambiarValor}
                                value={filtrosItem.CodigoBarras}
                                id={`CampoCodigoBarras-${filtrosItem.CodigoBarras}`}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <BotonBuscar
                                color="primary"
                                disabled={enEdicion}
                                onClick={() => consultarItemMovimientos()}
                                variant="contained"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={3}>
            <Card>
                <CardContent className={classes.contendorTarjetasFiltros}>
                    <Autocomplete
                        disabled={enEdicion || desactivarDivisionDestino}
                        disableClearable
                        getOptionLabel={({ DivisionDescripcion }) => DivisionDescripcion}
                        onChange={(_: any, division: any) => alCambiarValorDivisionDestino(division)}
                        options={divisionesDestino}
                        renderInput={(params) => (
                            <TextField {...params} fullWidth label={<Texto id="selectItems.divisionDestino" />} />
                        )}
                        noOptionsText={<Texto id="label.sinRegistros" />}
                        value={divisionDestino!== null?divisionDestino:undefined}
                        id={`CampoDivisionDestino-${divisionDestino!== null?divisionDestino:undefined}`}
                    />
                </CardContent>
            </Card>
        </Grid>
    </>
);

export default FiltrosItemMovimientosVista;
