import { Button, Card, CardContent, CardHeader, Fab, Grid, Tooltip } from '@material-ui/core';
import { CropFree as CropFreeIcon, Edit as EditIcon, ModeCommentOutlined } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import BotonAgregar from 'Infotrack@Transversales/componentes/BotonAgregar/BotonAgregar';
import ModalSeriales from 'Infotrack@Transversales/componentes/ModalSeriales/ModalSeriales';

import CampoCantidadEntregada from './Campos/CampoCantidadEntregada';
import CampoCantidadRecibida from './Campos/CampoCantidadRecibida';
import CampoCantidadSolicitada from './Campos/CampoCantidadSolicitada';
import CampoCodigoBarrasItem from './Campos/CampoCodigoEmpresaItem';
import CampoDescripcionItem from './Campos/CampoDescripcionItem';
import CampoDivisionDestino from './Campos/CampoDivisionDestino';
import CampoDivisionOrigen from './Campos/CampoDivisionOrigen';
import CampoFechaVencimiento from './Campos/CampoFechaVencimiento';
import CampoLote from './Campos/CampoLote';
import CampoSerial from './Campos/CampoSerial';
import CampoValor from './Campos/CampoValor';
import useStyles from './detallesFormularioEstilos';
import EstadosDetallesFormulario from './enumeraciones';
import moment from 'moment';
import CampoPeso from './Campos/CampoPeso';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';

interface IEntidadesDetallesFormulario {
    divisionesDestino: IBodegaDivisiones[];
    divisionesOrigen: IBodegaDivisiones[];
    items: IItemsConsulta[];
}

interface IDetallesFormularioProps {
    agregarDetalles?: (detalles: IDocumentoDetalle[]) => Promise<boolean>;
    alAgregarDetalles?: () => any;
    alEditarDetalle?: () => any;
    camposDeshabilitar?: Array<keyof IDocumentoDetalle>;
    camposOcultar?: Array<keyof IDocumentoDetalle>;
    estado: EstadosDetallesFormulario;
    deshabilitado?: boolean;
    detalleInicialEdicion: IDocumentoDetalle | null;
    documentoId: string;
    editarDetalle?: (detalle: IDocumentoDetalle) => Promise<boolean>;
    entidades: Partial<IEntidadesDetallesFormulario>;
    tipoCantidad: 'CantidadRecibida' | 'CantidadEntregada' | 'CantidadSolicitada';
    seriadoConsulta: boolean;
    documentoDetallesBase?: IDocumentoDetalleConsultaGeneral[];
    esEntradaInventario: boolean;
    bloquearCantidadSerial: boolean;
    esOrdenTraslado: boolean;
}

const DOCUMENTO_DETALLE_POR_DEFECTO: IDocumentoDetalle = {
    CantidadEntregada: 0,
    CantidadRecibida: 0,
    CantidadSolicitada: 0,
    DocumentoDetalleId: '',
    DocumentoId: '',
    EmpresaId: '',
    Estado: 0,
    FechaVencimiento: '',
    Lote: '',
    Serial: '',
    Valor: 0,
    PesoFraccion: 0,
    PesoFraccionRecibido: 0,
};

const DetallesFormulario: FunctionComponent<IDetallesFormularioProps> = ({
    agregarDetalles: agregarDetallesProp,
    alAgregarDetalles,
    alEditarDetalle,
    camposDeshabilitar = [],
    camposOcultar = [],
    deshabilitado = false,
    detalleInicialEdicion,
    documentoId,
    entidades,
    editarDetalle: editarDetalleProp,
    estado,
    tipoCantidad,
    seriadoConsulta,
    documentoDetallesBase,
    esEntradaInventario,
    bloquearCantidadSerial,
    esOrdenTraslado,
}) => {
    const idEmpresa = useSelector(
        (estadoGlobal: IEstadoGlobal) => estadoGlobal.estadoAutenticacion.usuarioInformacion!.IdEmpresa
    );

    const [detalle, setDetalle] = useState<IDocumentoDetalle>(DOCUMENTO_DETALLE_POR_DEFECTO);
    const [itemSeleccionado, setItemSeleccionado] = useState<IItemsConsulta | null>(null);
    const [modalItemSeriadoAbierto, setModalItemSeriadoAbierto] = useState<boolean>(false);
    const [valorGuardado, setValorGuardado] = useState(0);
    const [serialesItemSeriado, setSerialesItemSeriado] = useState<string[]>(['']);
    const [detalleBase, setDetalleBase] = useState<IDocumentoDetalleConsultaGeneral | null>(null);
    const classes = useStyles();

    useEffect(() => {
        if (entidades.items && detalle.ItemId) {
            setItemSeleccionado(entidades.items.find((i) => i.ItemId === detalle.ItemId)!);
        } else {
            setItemSeleccionado(null);
        }
    }, [detalle.ItemId]);

    useEffect(() => {
        if (documentoDetallesBase) {
            const documentoBase = documentoDetallesBase.find((x) => {
                if (x.DocumentoDetalleId === detalle.DocumentoDetallePredecesorId) {
                    return x;
                }
            });
            setDetalleBase(documentoBase!);
        }
    }, [detalle.DocumentoDetallePredecesorId]);

    useEffect(() => {
        let cantidad = 0;
        if (detalle.CantidadRecibida) {
            cantidad = Number(detalle.CantidadRecibida);
            setDetalle({ ...detalle, Valor: valorGuardado != 0 ? cantidad * valorGuardado : detalle.Valor });
        }
        if (detalle.CantidadEntregada) {
            cantidad = Number(detalle.CantidadEntregada);
            setDetalle({ ...detalle, Valor: valorGuardado != 0 ? cantidad * valorGuardado : detalle.Valor });
        }
        if (isNaN(cantidad) || cantidad <= 0) return;
        setSerialesItemSeriado(new Array(cantidad).fill(''));
    }, [detalle.CantidadRecibida, detalle.CantidadEntregada]);

    useEffect(() => {
        let cantidad = 0;
        if (detalle.CantidadSolicitada) {
            cantidad = Number(detalle.CantidadSolicitada);
            setDetalle({ ...detalle, Valor: valorGuardado != 0 ? cantidad * valorGuardado : detalle.Valor });
        }
    }, [detalle.CantidadSolicitada]);

    useEffect(() => {
        if (detalleInicialEdicion) setDetalle(detalleInicialEdicion);
    }, [detalleInicialEdicion]);

    const manejarCambioCampo = async (nombre: keyof IDocumentoDetalle, nuevoValor: any) => {
        if (nombre === 'ItemId') {
            setDetalle({
                ...detalle,
                [nombre]: nuevoValor !== null ? nuevoValor.ItemId : 0,
                Valor: nuevoValor !== null ? nuevoValor.Valor : 0,
            });
            const serialesPosiciones = serialesItemSeriado.length;
            setSerialesItemSeriado(new Array(serialesPosiciones).fill(''));
            setValorGuardado(nuevoValor.Valor);
        } else setDetalle({ ...detalle, [nombre]: nuevoValor });
    };

    const abrirModalItemSeriado = () => {
        const cantidadNumerica = parseInt((detalle.CantidadRecibida as unknown) as string, 10);
        if (isNaN(cantidadNumerica) || cantidadNumerica <= 0) {
            notificacionGlobal('DetallesFormulario.ValidacionNoCantidad', 3000, 'warning', true);
            return;
        }
        setModalItemSeriadoAbierto(true);
    };

    const agregarDetalles = async () => {
        if (!agregarDetallesProp) return;
        if (!validarDetalles()) return;
        let agregadoExitoso;
        let listaDocumentoDetalle = [];
        if (serialesItemSeriado.some((serial) => serial)) {
            listaDocumentoDetalle = serialesItemSeriado.map((serial) => obtenerDetalle(serial));
            agregadoExitoso = await agregarDetallesProp(listaDocumentoDetalle);
        } else {
            listaDocumentoDetalle = [obtenerDetalle()];
            agregadoExitoso = await agregarDetallesProp(listaDocumentoDetalle);
        }
        if (agregadoExitoso) {
            limpiarFormulario();
            if (alAgregarDetalles) alAgregarDetalles();
        }
    };

    const editarDetalle = async () => {
        if (!validarDetalles()) return;
        if (!editarDetalleProp) return;
        const edicionExitosa = await editarDetalleProp(obtenerDetalle());
        if (edicionExitosa) {
            limpiarFormulario();
            if (alEditarDetalle) alEditarDetalle();
        }
    };

    const validarDetalles = () => {
        if (!itemSeleccionado) {
            notificacionGlobal('DetallesFormulario.ValidacionNoItem', 3000, 'warning', true);
            return false;
        }
        if (!camposOcultar.includes('DivisionOrigen') && !esOrdenTraslado && !detalle.DivisionOrigen) {
            notificacionGlobal('DetallesFormulario.ValidacionNoDivisionOrigen', 3000, 'warning', true);
            return false;
        }

        if (
            !camposOcultar.includes('CantidadSolicitada') &&
            !itemSeleccionado.ManejaPeso &&
            (detalle.CantidadSolicitada === undefined ||
                detalle.CantidadSolicitada === 0 ||
                detalle.CantidadSolicitada.toString() === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionNoCantidad', 3000, 'warning', true);
            return false;
        }
        if (!camposOcultar.includes('DivisionDestino') && !detalle.DivisionDestino) {
            notificacionGlobal('DetallesFormulario.ValidacionNoDivisionDestino', 3000, 'warning', true);
            return false;
        }
        if (!camposOcultar.includes('Lote') && itemSeleccionado.Lotes && detalle.Lote === '') {
            notificacionGlobal('DetallesFormulario.ValidacionNoLote', 3000, 'warning', true);
            return false;
        }
        if (
            !camposOcultar.includes('PesoFraccionSolicitado') &&
            itemSeleccionado.ManejaPeso &&
            (detalle.PesoFraccionSolicitado === null ||
                detalle.PesoFraccionSolicitado === undefined ||
                detalle.PesoFraccionSolicitado.toString() === '0' ||
                detalle.PesoFraccionSolicitado.toString() === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionNoPeso', 3000, 'warning', true);
            return false;
        }
        if (
            !camposOcultar.includes('PesoFraccionRecibido') &&
            itemSeleccionado.ManejaPeso &&
            (detalle.PesoFraccionRecibido === null ||
                detalle.PesoFraccionRecibido === undefined ||
                detalle.PesoFraccionRecibido.toString() === '0' ||
                detalle.PesoFraccionRecibido.toString() === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionNoPeso', 3000, 'warning', true);
            return false;
        }
        if (!camposOcultar.includes('FechaVencimiento') && itemSeleccionado.FEFO && detalle.FechaVencimiento === '') {
            notificacionGlobal('DetallesFormulario.ValidacionNoFechaVencimiento', 3000, 'warning', true);
            return false;
        }
        if (
            !camposOcultar.includes('Serial') &&
            (itemSeleccionado.Seriado || itemSeleccionado.Serialconsecutivo) &&
            detalle.Serial === '' &&
            serialesItemSeriado.every((serial) => serial === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionSerialesIncompletos', 3000, 'warning', true);
            return false;
        }

        if (
            documentoDetallesBase &&
            tipoCantidad === 'CantidadEntregada' &&
            detalleBase!.CantidadEntregada! +
                parseInt(detalle!.CantidadEntregada!.toString()) -
                detalleInicialEdicion!.CantidadEntregada! >
                detalleBase!.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado', 3000, 'warning', true);
            return false;
        }

        if (
            documentoDetallesBase &&
            detalleBase!.ManejaPeso === false &&
            tipoCantidad === 'CantidadRecibida' &&
            detalleBase!.CantidadRecibida! +
                parseInt(detalle!.CantidadRecibida!.toString()) -
                detalleInicialEdicion!.CantidadRecibida! >
                detalleBase!.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoRecibido', 3000, 'warning', true);
            return false;
        }

        if (
            esEntradaInventario &&
            detalleBase!.ManejaPeso === true &&
            detalleBase!.PesoFraccionRecibido! +
                parseInt(detalle!.PesoFraccionRecibido!.toString()) -
                detalleInicialEdicion!.PesoFraccionRecibido! >
                detalleBase!.PesoFraccionSolicitado!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionPeso', 3000, 'warning', true);
            return false;
        }
        return true;
    };

    const obtenerDetalle = (serial?: string) => {
        const detalleDocumento: IDocumentoDetalle = {
            ...detalle,
            DocumentoId: documentoId,
            EmpresaId: idEmpresa,
            FechaVencimiento: itemSeleccionado!.FEFO ? detalle.FechaVencimiento : undefined,
            FechaIngreso: itemSeleccionado!.FIFO ? moment().format('YYYY-MM-DD') : undefined,
            ItemId: itemSeleccionado!.ItemId,
            Lote: itemSeleccionado!.Lotes ? detalle.Lote : '',
            Serial: serial
                ? serial
                : itemSeleccionado!.Seriado || itemSeleccionado!.Serialconsecutivo
                ? detalle.Serial
                : '',
            [tipoCantidad]: serial ? 1 : detalle[tipoCantidad],
        };
        return detalleDocumento;
    };

    const limpiarFormulario = () => {
        setDetalle(DOCUMENTO_DETALLE_POR_DEFECTO);
        setItemSeleccionado(null);
    };

    return (
        <>
            <Card variant="outlined">
                <CardHeader
                    classes={{ root: classes.encabezado }}
                    title={
                        estado === EstadosDetallesFormulario.CREACION ? (
                            <Texto fontWeight="bold" id="DetallesFormulario.TituloCreacion" variant="body1" />
                        ) : estado === EstadosDetallesFormulario.EDICION ? (
                            <Texto fontWeight="bold" id="DetallesFormulario.TituloEdicion" variant="body1" />
                        ) : (
                            undefined
                        )
                    }
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {!camposOcultar.includes('ItemId') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoCodigoBarrasItem
                                            deshabilitado={deshabilitado || camposDeshabilitar.includes('ItemId')}
                                            items={entidades.items}
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.ItemId}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('ItemId') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoDescripcionItem
                                            deshabilitado={deshabilitado || camposDeshabilitar.includes('ItemId')}
                                            items={entidades.items}
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.ItemId}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('DivisionOrigen') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoDivisionOrigen
                                            deshabilitado={
                                                deshabilitado || camposDeshabilitar.includes('DivisionOrigen')
                                            }
                                            divisionesOrigen={entidades.divisionesOrigen}
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.DivisionOrigen}
                                            requerido={!esOrdenTraslado}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('DivisionDestino') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoDivisionDestino
                                            deshabilitado={
                                                deshabilitado || camposDeshabilitar.includes('DivisionDestino')
                                            }
                                            divisionesDestino={entidades.divisionesDestino}
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.DivisionDestino}
                                        />
                                    </Grid>
                                )}
                                {(itemSeleccionado ? !itemSeleccionado.ManejaPeso : false) &&
                                    !camposOcultar.includes('CantidadSolicitada') && (
                                        <Grid item lg={2} md={3} sm={6} xs={12}>
                                            <CampoCantidadSolicitada
                                                deshabilitado={
                                                    deshabilitado || camposDeshabilitar.includes('CantidadSolicitada')
                                                }
                                                manejarCambioCampo={manejarCambioCampo}
                                                valor={detalle.CantidadSolicitada}
                                            />
                                        </Grid>
                                    )}
                                {!camposOcultar.includes('CantidadRecibida') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoCantidadRecibida
                                            deshabilitado={
                                                deshabilitado ||
                                                camposDeshabilitar.includes('CantidadRecibida') ||
                                                (bloquearCantidadSerial &&
                                                    detalle.Serial !== null &&
                                                    detalle.Serial !== '')
                                            }
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.CantidadRecibida}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('CantidadEntregada') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoCantidadEntregada
                                            deshabilitado={
                                                deshabilitado ||
                                                camposDeshabilitar.includes('CantidadEntregada') ||
                                                (bloquearCantidadSerial &&
                                                    detalle.Serial !== null &&
                                                    detalle.Serial !== '')
                                            }
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.CantidadEntregada}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('Valor') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoValor
                                            deshabilitado={deshabilitado || camposDeshabilitar.includes('Valor')}
                                            valor={detalle.Valor}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('PesoFraccionSolicitado') &&
                                    (itemSeleccionado ? itemSeleccionado.ManejaPeso : false) && (
                                        <Grid item lg={2} md={3} sm={6} xs={12}>
                                            <CampoPeso
                                                deshabilitado={
                                                    deshabilitado ||
                                                    camposDeshabilitar.includes('PesoFraccionSolicitado')
                                                }
                                                manejarCambioCampo={manejarCambioCampo}
                                                valor={detalle.PesoFraccionSolicitado}
                                                esPesoSolicitado={true}
                                            />
                                        </Grid>
                                    )}
                                {!camposOcultar.includes('PesoFraccionRecibido') &&
                                    (itemSeleccionado ? itemSeleccionado.ManejaPeso : false) && (
                                        <Grid item lg={2} md={3} sm={6} xs={12}>
                                            <CampoPeso
                                                deshabilitado={
                                                    deshabilitado || camposDeshabilitar.includes('PesoFraccionRecibido')
                                                }
                                                manejarCambioCampo={manejarCambioCampo}
                                                valor={detalle.PesoFraccionRecibido}
                                                esPesoSolicitado={false}
                                            />
                                        </Grid>
                                    )}
                                {!camposOcultar.includes('Lote') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoLote
                                            deshabilitado={
                                                deshabilitado ||
                                                camposDeshabilitar.includes('Lote') ||
                                                !itemSeleccionado ||
                                                !itemSeleccionado.Lotes
                                            }
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.Lote}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('FechaVencimiento') && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoFechaVencimiento
                                            deshabilitado={
                                                deshabilitado ||
                                                camposDeshabilitar.includes('FechaVencimiento') ||
                                                !itemSeleccionado ||
                                                !itemSeleccionado.FEFO
                                            }
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.FechaVencimiento}
                                        />
                                    </Grid>
                                )}
                                {!camposOcultar.includes('Serial') && estado === EstadosDetallesFormulario.CREACION && (
                                    <Grid item>
                                        <Tooltip title={<Texto id="DetallesFormulario.TooltipAsignarSeriales" />}>
                                            <span>
                                                <Fab
                                                    color="primary"
                                                    disabled={
                                                        deshabilitado ||
                                                        camposDeshabilitar.includes('Serial') ||
                                                        !itemSeleccionado ||
                                                        (!itemSeleccionado.Seriado &&
                                                            !itemSeleccionado.Serialconsecutivo)
                                                    }
                                                    onClick={abrirModalItemSeriado}
                                                    size="small"
                                                    id="Icono-CropFree"
                                                >
                                                    <CropFreeIcon id="Icono-CropFree"/>
                                                </Fab>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {!camposOcultar.includes('Serial') && estado === EstadosDetallesFormulario.EDICION && (
                                    <Grid item lg={2} md={3} sm={6} xs={12}>
                                        <CampoSerial
                                            deshabilitado={
                                                deshabilitado ||
                                                camposDeshabilitar.includes('Serial') ||
                                                !itemSeleccionado ||
                                                (!itemSeleccionado.Seriado && !itemSeleccionado.Serialconsecutivo) ||
                                                (detalle.Serial !== null && detalle.Serial !== '')
                                            }
                                            manejarCambioCampo={manejarCambioCampo}
                                            valor={detalle.Serial}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                                {estado === EstadosDetallesFormulario.CREACION && (
                                    <Grid item>
                                        <BotonAgregar disabled={deshabilitado} onClick={agregarDetalles} id="boton-agregar"/>
                                    </Grid>
                                )}
                                {estado === EstadosDetallesFormulario.EDICION && (
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            disabled={deshabilitado}
                                            endIcon={<EditIcon id="Icono-Editar"/>}
                                            onClick={editarDetalle}
                                            size="small"
                                            variant="contained"
                                            id="boton-editar"
                                        >
                                            <Texto id="boton.editar" />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {itemSeleccionado && (itemSeleccionado.Seriado || itemSeleccionado.Serialconsecutivo) ? (
                <ModalSeriales
                    abierto={modalItemSeriadoAbierto}
                    cerrar={() => setModalItemSeriadoAbierto(false)}
                    guardarSeriales={(seriales: string[]) => setSerialesItemSeriado(seriales)}
                    serialesConsecutivos={itemSeleccionado.Serialconsecutivo}
                    serialesIniciales={serialesItemSeriado}
                    seriadoConsulta={seriadoConsulta}
                    mostrarPeso={false}
                />
            ) : (
                undefined
            )}
        </>
    );
};

export { IEntidadesDetallesFormulario };

export default DetallesFormulario;
