import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConsulta';
import IFiltroGeneralDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/filtroGeneralDocumentoConsulta';
import ITipoDocumentoCompuesto from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/tipoDocumentoCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoCM from '../controladorModelo/documentoCM';
import EntidadesReversionCM from '../controladorModelo/entidadesReversionCM';
import { IEstadoAPropiedades, IInputsFiltros } from '../interfaces';
import TablaReversionMovimientosVista from '../vista/tablaReversionMovimientosVista';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';

const documentoCM = new DocumentoCM();
const entidadesReversionCM = new EntidadesReversionCM();

const filtros: IInputsFiltros = {
    Bodega: null,
    CodigoDocumento: '',
    TipoDocumento: null,
};

const TablaReversionMovimientosCV: React.FunctionComponent<IEstadoAPropiedades> = ({
    AgenciaId,
    EmpresaId,
    UsuarioId,
}) => {
    const [entidadesFiltros, setEntidadesFiltros] = React.useState<{
        bodegas: IBodega[];
        tiposDocumentos: ITipoDocumentoCompuesto[];
    }>({ bodegas: [], tiposDocumentos: [] });
    const [consultar, setConsultar] = React.useState<boolean>(false);
    const { alCambiarValor, setValor, reiniciar, valor } = useInputState(filtros);
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        consultarEntidades();
    }, []);

    const consultarEntidades = async () => {
        setEntidadesFiltros(
            await entidadesReversionCM.consultarEntidadesReversion({ AgenciaId, UsuarioId, EmpresaId }, { EmpresaId })
        );
    };

    const alCambiarValorAutocomplete = (nombreValor: string, nuevoValor: IInputsFiltros[keyof IInputsFiltros]) => {
        setValor({ ...valor, [nombreValor]: nuevoValor });
    };

    const mapearFiltros = (): IFiltroGeneralDocumentoConsulta => {
        const bodegaAConsultar =
            valor.TipoDocumento &&
            (valor.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.REC ||
                valor.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.RE ||
                valor.TipoDocumento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.OC)
                ? 'BodegaDestino'
                : 'BodegaOrigen';
        return {
            [bodegaAConsultar]: valor.Bodega ? valor.Bodega.BodegaId : undefined,
            CodigoDocumento: valor.CodigoDocumento,
            TipoDocumentoId: valor.TipoDocumento ? valor.TipoDocumento.TipoDocumentoId : undefined,
        };
    };

    const consultaDocumentos = paginacionRemota(documentoCM.consultarDocumentos, mapearFiltros(), 'FechaCreacion');

    const cargarDocumentos = (limpiarFiltros?: boolean) => {
        if (limpiarFiltros) {
            reiniciar();
            setConsultar(false);
        } else {
            if (documentoCM.validarFiltros(mapearFiltros())) {
                setConsultar(true);
                refTabla.current.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
            }
        }
    };

    const confirmarReversionDocumento = (documento: IDocumentoConsulta) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => revertirDocumento(documento),
            estado: true,
            mensaje: <Texto id="reversion.alerta.confirmarReversion" />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const revertirDocumento = async (documento: IDocumentoConsulta) => {
        manejadorDialogoGlobal({ estado: false });
        await documentoCM.revertirDocumento(documento);
        cargarDocumentos();
    };

    return (
        <TablaReversionMovimientosVista
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            alCambiarValor={alCambiarValor}
            cargarDocumentos={cargarDocumentos}
            consultar={consultar}
            consultaDocumentos={consultaDocumentos}
            entidadesFiltros={entidadesFiltros}
            filtros={valor}
            refTabla={refTabla}
            revertirDocumento={confirmarReversionDocumento}
        />
    );
};

const estadoAPropiedades = ({ estadoAutenticacion }: IEstadoGlobal): IEstadoAPropiedades => ({
    AgenciaId: estadoAutenticacion.agenciaActual!.IdAgencia,
    EmpresaId: estadoAutenticacion.usuarioInformacion!.IdEmpresa,
    UsuarioId: estadoAutenticacion.usuarioInformacion!.IdUsuario,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(
    estadoAPropiedades,
)(TablaReversionMovimientosCV);
