import { Button, Card, CardContent, Fab, Grid } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FunctionComponent, useState, useEffect } from 'react';

import CampoCodigoDocumentoBase from './CampoCodigoDocumentoBase';
import CampoIdentificacionCliente from './CampoIdentificacionCliente';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModalTablaDocumentoCliente from '../../../modulos/smartStock/EntradaDevolucion/ModalTablaDocumentoCliente';
import CampoFactura from './CampoFactura';

interface IDocumentoBuscadorProps {
    consultaDocumentoBase: (codigoDocumentoBase: string, identificacionCliente?: number, factura?: string) => void;
    refrescarCampo?: boolean;
    esEntradaDevolucion: boolean;
}

const DocumentoBuscador: FunctionComponent<IDocumentoBuscadorProps> = ({
    consultaDocumentoBase,
    refrescarCampo,
    esEntradaDevolucion,
}) => {
    const [codigoDocumentoBase, setCodigoDocumentoBase] = useState<string>('');
    const [factura, setFactura] = useState<string>('');
    const [identificacionCliente, setidentificacionCliente] = useState<number | undefined>(0);
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <CampoCodigoDocumentoBase
                            manejarCambio={(nuevoValor) => {
                                setCodigoDocumentoBase(nuevoValor.replace(/ /g, ''));
                            }}
                            valor={codigoDocumentoBase}
                            refrescar={refrescarCampo}  
                        />
                    </Grid>
                    {esEntradaDevolucion && (
                        <Grid item>
                            <CampoIdentificacionCliente
                                manejarCambioCliente={(nuevoValor) => {
                                    setidentificacionCliente(Number.isNaN(nuevoValor) ? 0 : nuevoValor);
                                }}
                                valor={identificacionCliente}
                                refrescar={refrescarCampo}
                            />
                        </Grid>
                    )}

                    {esEntradaDevolucion && (
                        <Grid item>
                            <CampoFactura
                                manejarCambioFactura={(nuevoValor) => {
                                    setFactura(nuevoValor);
                                }}
                                valor={factura}
                                refrescar={refrescarCampo}
                            />
                        </Grid>
                    )}
                    {esEntradaDevolucion && (
                        <Grid item>
                            <Button
                                color="primary"
                                disabled={
                                    identificacionCliente === 0 &&
                                    (codigoDocumentoBase === '' || codigoDocumentoBase === undefined)
                                }
                                endIcon={<SearchIcon id="Icono-Buscar" />}
                                onClick={() => consultaDocumentoBase(codigoDocumentoBase, identificacionCliente!, factura!)}
                                size="small"
                                variant="contained"
                                id="boton-Buscar"
                            >
                                <Texto id="boton.buscar" />
                            </Button>
                        </Grid>
                    )}
                    {esEntradaDevolucion && (
                        <Grid item>
                            <Button
                                color="primary"
                                disabled={
                                    identificacionCliente === 0 &&
                                    (codigoDocumentoBase === '' || codigoDocumentoBase === undefined)
                                }
                                endIcon={<DeleteIcon id="Icono-Limpiar" />}
                                onClick={() => {
                                    setidentificacionCliente(0), setCodigoDocumentoBase('');
                                }}
                                size="small"
                                variant="contained"
                                id="boton-Limpiar"
                            >
                                <Texto id="boton.limpiar" />
                            </Button>
                        </Grid>
                    )}

                    {!esEntradaDevolucion && (
                        <Grid item>
                            <Fab
                                color="primary"
                                onClick={() => consultaDocumentoBase(codigoDocumentoBase, identificacionCliente!,factura!)}
                                size="small"
                                disabled={codigoDocumentoBase === ''}
                                id="boton-buscar"
                            >
                                <SearchIcon id="boton-Buscar" />
                            </Fab>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
export default DocumentoBuscador;
