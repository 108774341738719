import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Dialog, Button } from '@material-ui/core';
import { Document, Page } from 'react-pdf';
import { saveAs } from 'file-saver';
import Texto from '@infotrack/presentacion-componentes/texto';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import { EstadosModalArchivoPDF } from './enumeraciones';
import { pdfjs } from 'react-pdf';
import { Add as AddIcon, CloudDownload as CloudDownloadIcon, ZoomOut as ZoomOutIcon, ZoomIn as ZoominIcon } from '@material-ui/icons';


interface IArchivoPDFProps {
    archivoPDF: string;
    cerrar: () => void;
    estado: EstadosModalArchivoPDF;
    nombreDocumento?: string
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const GenerarPDF: FunctionComponent<IArchivoPDFProps> = ({ archivoPDF, cerrar, estado, nombreDocumento }) => {
    const convertirBase64aBlob = (base64: string) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: 'application/pdf' });
    };
    const [nivelZoom, setNivelZoom] = useState<number>(1.0);
    const blobPDF = useMemo(() => convertirBase64aBlob(archivoPDF), [archivoPDF]);
    const url = useMemo(() => URL.createObjectURL(blobPDF), [blobPDF]);


    const handleDownload = () => {
        saveAs(blobPDF, `${nombreDocumento}.pdf`);
    };


    const acercarZoomIn = () => setNivelZoom(prev => Math.min(prev + 0.25, 3.0));


    const alejarZoomOut = () => setNivelZoom(prev => Math.max(prev - 0.25, 0.5));
    return (
        <Dialog open={estado === EstadosModalArchivoPDF.VISUALIZACION} maxWidth="xl" fullWidth>
            <ModalEncabezado cerrar={cerrar} titulo={<Texto id='GenerarPDF.VisualizadorPDF' />} />
            <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', height: '80vh' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    <Button endIcon={<ZoominIcon id="Icono-Acercar" />} onClick={acercarZoomIn} >
                        <Texto id='GenerarPDF.Acercar' />
                    </Button>
                    <Button endIcon={<ZoomOutIcon id="Icono-Alejar" />} onClick={alejarZoomOut} style={{ marginLeft: '8px' }}>
                        <Texto id='GenerarPDF.Alejar' />
                    </Button>
                </div>
                <div style={{ flex: 1, overflow: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <Document file={url}>
                        <div style={{ border: '1px solid #000' }}>
                            <Page pageNumber={1} scale={nivelZoom} />
                        </div>
                    </Document>
                </div>
                <Button color="primary" variant="contained" endIcon={<CloudDownloadIcon id="Icono-DescargarPDF" />} onClick={handleDownload} style={{ marginTop: '16px', marginBottom: '24px', alignSelf: 'center' }}>
                    <Texto id='GenerarPDF.Descargar' />
                </Button>
            </div>
        </Dialog>);
};

export default GenerarPDF;
