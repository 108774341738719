import { Card, CardHeader } from '@material-ui/core';
import { Remove as RemoveIcon } from '@material-ui/icons';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import BotonAgregar from '../BotonAgregar/BotonAgregar';
import ModalSeriales from '../ModalSeriales/ModalSeriales';
import BotonAbrirModalSeriales from './BotonAbrirModalSeriales';
import CampoCantidad from './Campos/CampoCantidad';
import CampoDivisionDestino from './Campos/CampoDivisionDestino';
import CampoDivisionOrigen from './Campos/CampoDivisionOrigen';
import CampoFechaVencimiento from './Campos/CampoFechaVencimiento';
import CampoLote from './Campos/CampoLote';

interface IEntidadesTablaDetallesPlantilla {
    divisionesOrigen: IBodegaDivisiones[];
    divisionesDestino: IBodegaDivisiones[];
}

interface ITablaDetallesPlantillaProps {
    agregarDetalles: (detalles: IDocumentoDetalleConsultaGeneral[]) => Promise<boolean>;
    alAgregarDetalles?: () => any;
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    camposEditables?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    deshabilitada: boolean;
    detalles: IDocumentoDetalleConsultaGeneral[];
    entidades: Partial<IEntidadesTablaDetallesPlantilla>;
    tipoCantidad: 'CantidadRecibida' | 'CantidadEntregada';
    titulo: ReactNode;
    asignarSerialesVisible?: boolean;
    seriadoConsulta: boolean;
    manejaPeso: boolean;
    esEntradaDevolucion:boolean
}

const TablaDetallesPlantilla: FunctionComponent<ITablaDetallesPlantillaProps> = ({
    agregarDetalles,
    alAgregarDetalles,
    camposOcultar = [],
    camposEditables= [],
    deshabilitada,
    detalles: detallesProp,
    entidades,
    tipoCantidad,
    titulo,
    asignarSerialesVisible,
    seriadoConsulta,
    manejaPeso,
    esEntradaDevolucion
}) => {
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [diccionarioDivisionDestino, setDiccionarioDivisionDestino] = useState<Record<string, string>>({});
    const [diccionarioCantidades, setDiccionarioCantidades] = useState<Record<string, string>>({});
    const [detalleModalSeriales, setDetalleModalSeriales] = useState<IDocumentoDetalleConsultaGeneral | null>(null);
    const [diccionarioDetalleSeriales, setDiccionarioDetalleSeriales] = useState<Record<string, string[]>>({});
    const [pesoSerial, setPesoSerial] = useState<string>('');

    useEffect(() => {
        setDetalles(detallesProp.map((d) => ({ ...d })));
        construirDiccionarioCantidades();
        construirDiccionarioDivisionesDestino();
    }, [detallesProp]);

    useEffect(() => {
        construirDiccionarioDetalleSeriales();
    }, [diccionarioCantidades]);

    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];
        columnas.push({
            field: 'CodigoEmpresa',
            title: <Texto id="TablaDetallesPlantilla.CodigoEmpresa" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaDetallesPlantilla.DescripcionItem" />,
        });
        if (!camposOcultar.includes('DivisionOrigen'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    camposEditables? !camposEditables.includes('DivisionOrigen'):false ? (
                        detalle.DescripcionDivisionOrigen
                    ) : (
                        <CampoDivisionOrigen
                            deshabilitado={deshabilitada}
                            detalle={detalle}
                            divisiones={entidades.divisionesOrigen || []}
                            manejarCambioCampo={manejarCambioCampo}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionOrigen" />,
            });
        if ( !esEntradaDevolucion&&!camposOcultar.includes('DivisionDestino'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    esEntradaDevolucion|| camposEditables?  !camposEditables.includes('DivisionDestino'):false ? (
                        detalle.DescripcionDivisionDestino
                    ) : (
                        <CampoDivisionDestino
                            deshabilitado={deshabilitada}
                            detalle={detalle}
                            divisiones={entidades.divisionesDestino || []}
                            manejarCambioCampo={manejarCambioCampo}
                            esRequerido={false}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionDestino" />,
            });

            if ( esEntradaDevolucion)
                columnas.push({
                    render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                        (
                            <CampoDivisionDestino
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                divisiones={entidades.divisionesDestino || []}
                                manejarCambioCampo={manejarCambioCampo}
                                esRequerido={true}
                            />
                        ),
                    title: <Texto id="TablaDetallesPlantilla.DivisionDestino" />,
                });
        if (!camposOcultar.includes('PesoFraccionSolicitado'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.ManejaPeso ? detalle.PesoFraccionSolicitado : <RemoveIcon id="Icono-Remove"/>,
                title: <Texto id="TablaDetallesPlantilla.PesoFraccionSolicitado" />,
            });
        if (!camposOcultar.includes('PesoFraccionRecibido'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.ManejaPeso ? detalle.PesoFraccionRecibido : <RemoveIcon id="Icono-Remove"/>,
                title: <Texto id="TablaDetallesPlantilla.PesoFraccionRecibido" />,
            });
        if (!camposOcultar.includes('CantidadSolicitada'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.ManejaPeso ? <RemoveIcon id="Icono-Remove"/> : detalle.CantidadSolicitada,
                field: 'CantidadSolicitada',
                title: <Texto id="TablaDetallesPlantilla.CantidadSolicitada" />,
            });
        if (!camposOcultar.includes('CantidadRecibida'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.ManejaPeso ? <RemoveIcon id="Icono-Remove"/> : detalle.CantidadRecibida,
                title: <Texto id="TablaDetallesPlantilla.CantidadRecibida" />,
            });
        if (!camposOcultar.includes('CantidadEntregada'))
            columnas.push({
                field: 'CantidadEntregada',
                title: <Texto id="TablaDetallesPlantilla.CantidadEntregada" />,
            });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => (
                <CampoCantidad
                    manejarCambio={(nuevoValor: string) =>
                        setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                            ...diccionarioCantidadesActual,
                            [detalle.DocumentoDetalleId]: nuevoValor,
                        }))
                    }
                    valor={diccionarioCantidades[detalle.DocumentoDetalleId]}
                />
            ),
            title: <Texto id="TablaDetallesPlantilla.Cantidad" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.Lotes) {
                    return <RemoveIcon id="Icono-Remove"/>;
                } else {
                    if (camposEditables? !camposEditables.includes('Lote'):false) return detalle.Lote;
                    else
                        return (
                            <CampoLote
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                manejarCambioCampo={manejarCambioCampo}
                            />
                        );
                }
            },
            title: <Texto id="TablaDetallesPlantilla.Lote" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.FEFO) {
                    return <RemoveIcon id="Icono-Remove"/>;
                } else {
                    if (camposEditables?!camposEditables.includes('FechaVencimiento'):false)
                        return moment(detalle.FechaVencimiento).format('DD/MM/YYYY');
                    else
                        return (
                            <CampoFechaVencimiento
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                manejarCambioCampo={manejarCambioCampo}
                            />
                        );
                }
            },
            title: <Texto id="TablaDetallesPlantilla.FechaVencimiento" />,
        });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                if (!detalle.FIFO || detalle.FechaIngreso === null) {
                    return <RemoveIcon id="Icono-Remove"/>;
                } else {
                    if (camposEditables?!camposEditables.includes('FechaIngreso'):false)
                        return moment(detalle.FechaIngreso).format('DD/MM/YYYY');
                }
            },
            title: <Texto id="TablaDetallesPlantilla.FechaIngreso" />,
        });
        if (asignarSerialesVisible)
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.Seriado || detalle.Serialconsecutivo ? (
                        <BotonAbrirModalSeriales
                            abrirModalSeriales={() => setDetalleModalSeriales(detalle)}
                            deshabilitado={deshabilitada}
                        />
                    ) : (
                        <RemoveIcon id="Icono-Remove"/>
                    ),
                title: <Texto id="TablaDetallesPlantilla.AsignarSeriales" />,
            });
        if (!camposOcultar.includes('Serial')) {
            columnas.push({
                field: 'Serial',
                title: <Texto id="TablaDetallesPlantilla.Serial" />,
            });
        }
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => (
                <BotonAgregar disabled={deshabilitada} onClick={() => agregarDetalleODetalles(detalle)} />
            ),
        });
        return columnas;
    };

    const construirDiccionarioCantidades = () => {
        const nuevoDiccionarioCantidades: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioCantidades[detalle.DocumentoDetalleId] = diccionarioCantidades[detalle.DocumentoDetalleId]
                ? diccionarioCantidades[detalle.DocumentoDetalleId]
                : '0';
        });
        setDiccionarioCantidades(nuevoDiccionarioCantidades);
    };

    const construirDiccionarioDivisionesDestino = () => {
        const nuevoDiccionarioDivisionesDestino: Record<string, string> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioDivisionesDestino[detalle.DocumentoDetalleId] = diccionarioDivisionDestino[
                detalle.DocumentoDetalleId
            ]
                ? diccionarioDivisionDestino[detalle.DocumentoDetalleId]
                : '';
        });
        setDiccionarioDivisionDestino(nuevoDiccionarioDivisionesDestino);
    };

    const construirDiccionarioDetalleSeriales = () => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        Object.keys(diccionarioCantidades).forEach((detalleId) => {
            const detalle = detalles.find((d) => d.DocumentoDetalleId === detalleId)!;
            if (detalle.Seriado || detalle.Serialconsecutivo) {
                const serialesDetalle = copiaDiccionarioDetalleSeriales[detalleId];
                const cantidadDetalle = Number(diccionarioCantidades[detalleId]);
                if (!serialesDetalle || serialesDetalle.length !== cantidadDetalle)
                    copiaDiccionarioDetalleSeriales[detalleId] = new Array(cantidadDetalle).fill('');
            }
        });
        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const guardarSerialesDetalle = (seriales: string[]) => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        copiaDiccionarioDetalleSeriales[detalleModalSeriales!.DocumentoDetalleId] = seriales;
        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const manejarCambioCampo = (
        detalleId: string,
        nombreCampo: keyof IDocumentoDetalleConsultaGeneral,
        nuevoValor: any
    ) => {
        const copiaDetalles = [...detalles];
        const detalle = copiaDetalles.find((d) => d.DocumentoDetalleId === detalleId)!;
        detalle[nombreCampo] = (nuevoValor as unknown) as never;
        setDetalles(copiaDetalles);
    };

    const validarDetalle = (detalle: IDocumentoDetalleConsultaGeneral) => {
        const cantidadDetalle = Number(diccionarioCantidades[detalle.DocumentoDetalleId]);
        if (isNaN(cantidadDetalle) || cantidadDetalle <= 0) {
            notificacionGlobal('ModalRecepcion.ValidacionNoCantidad', 3000, 'warning', true);
            return false;
        }
        if (detalle.Lotes && detalle.Lote === '') {
            notificacionGlobal('ModalRecepcion.ValidacionNoLote', 3000, 'warning', true);
            return false;
        }
        if (detalle.FEFO && !detalle.FechaVencimiento) {
            notificacionGlobal('TablaDetallesPlantilla.ValidacionNoFechaVencimiento', 3000, 'warning', true);
            return false;
        }
        if (
            (detalle.Seriado || detalle.Serialconsecutivo) &&
            asignarSerialesVisible &&
            diccionarioDetalleSeriales[detalle.DocumentoDetalleId].every((serial) => serial === '')
        ) {
            notificacionGlobal('ModalRecepcion.ValidacionSerialesIncompletos', 3000, 'warning', true);
            return false;
        }
        if (
            !esEntradaDevolucion &&  manejaPeso &&
            detalle.ManejaPeso! === false &&
            !camposOcultar.includes('PesoFraccionRecibido') &&
            cantidadDetalle + detalle.CantidadRecibida! > detalle.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoRecibido', 3000, 'warning', true);
            return false;
        }

        if (
            !esEntradaDevolucion &&  manejaPeso === false &&
            !camposOcultar.includes('PesoFraccionRecibido') &&
            cantidadDetalle + detalle.CantidadRecibida! > detalle.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado', 3000, 'warning', true);
            return false;
        }

        if (
            detalle.ManejaPeso! === false &&
            !camposOcultar.includes('PesoFraccionSolicitado') &&
            cantidadDetalle + detalle.CantidadEntregada! > detalle.CantidadSolicitada!
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionCantidadNoValidaPesoSolicitado', 3000, 'warning', true);
            return false;
        }

        if (
            manejaPeso &&
            detalle.ManejaPeso &&
            (pesoSerial === null ||
                pesoSerial === undefined ||
                pesoSerial.toString() === '0' ||
                pesoSerial.toString() === '')
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionNoPeso', 3000, 'warning', true);
            return false;
        }
        if (
            manejaPeso &&
            !camposOcultar.includes('PesoFraccionRecibido') &&
            detalle.ManejaPeso &&
            validarPesoDetalle(detalle)
        ) {
            notificacionGlobal('DetallesFormulario.ValidacionPeso', 3000, 'warning', true);
            return false;
        }
        return true;
    };

    const agregarDetalleODetalles = async (detalle: IDocumentoDetalleConsultaGeneral) => {
        if (!validarDetalle(detalle)) return;
        let agregadoExitoso;
        if (!detalle.Seriado && !detalle.Serialconsecutivo) {
            const cantidadDetalle = Number(diccionarioCantidades[detalle.DocumentoDetalleId]);
            agregadoExitoso = await agregarDetalles([
                {
                    ...detalle,
                    [tipoCantidad]: cantidadDetalle,
                },
            ]);
        } else {
            agregadoExitoso = await agregarDetalles(
                diccionarioDetalleSeriales[detalle.DocumentoDetalleId].map((serial) => ({
                    ...detalle,
                    [tipoCantidad]: 1,
                    Serial: serial,
                    PesoFraccionRecibido: pesoSerial === '' ? detalle.PesoFraccionRecibido : parseFloat(pesoSerial),
                }))
            );
        }
        if (agregadoExitoso) {
            const copiaDetalles = [...detalles];
            const indiceDetalleTabla = detalles.findIndex((d) => d.DocumentoDetalleId === detalle.DocumentoDetalleId);
            copiaDetalles[indiceDetalleTabla] = { ...detallesProp[indiceDetalleTabla] };
            setDetalles(copiaDetalles);
            setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                ...diccionarioCantidadesActual,
                [detalle.DocumentoDetalleId]: '0',
            }));
            setPesoSerial('');
            if (alAgregarDetalles) alAgregarDetalles();
        }
    };

    const validarPesoDetalle = (detalle: IDocumentoDetalleConsultaGeneral) => {
        let pesoRecibido =
            parseInt(pesoSerial) * diccionarioDetalleSeriales[detalle.DocumentoDetalleId].length +
            detalle.PesoFraccionRecibido!;
        if (pesoRecibido <= detalle.PesoFraccionSolicitado!) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <Card variant="outlined">
                <CardHeader title={titulo} titleTypographyProps={{ variant: 'body1' }} />
                <Tabla columns={obtenerColumnas()} data={detalles} options={{ padding: 'dense' }} />
            </Card>
            <ModalSeriales
                abierto={Boolean(detalleModalSeriales)}
                cerrar={() => {
                    setDetalleModalSeriales(null);
                }}
                guardarSeriales={guardarSerialesDetalle}
                serialesConsecutivos={
                    detalleModalSeriales && detalleModalSeriales.Serialconsecutivo
                        ? detalleModalSeriales.Serialconsecutivo
                        : false
                }
                serialesIniciales={
                    detalleModalSeriales ? diccionarioDetalleSeriales[detalleModalSeriales.DocumentoDetalleId] : []
                }
                seriadoConsulta={seriadoConsulta}
                mostrarPeso={manejaPeso && detalleModalSeriales !== null ? detalleModalSeriales.ManejaPeso! : false}
                guardarPesoSerial={setPesoSerial}
                pesoSerial={pesoSerial}
                detalle={detalleModalSeriales}
            />
        </>
    );
};

export { IEntidadesTablaDetallesPlantilla };

export default TablaDetallesPlantilla;
