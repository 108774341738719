import {
    AppBar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';
import enumTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/enumTiposDocumentos';
import React from 'react';
import TablaDocumentoDetallesCV from '../controladorVista/tablaDocumentoDetallesCV';
import { IFormularioDocumentosVistaProps } from '../interfaces';

const FormularioDocumentosVista: React.FunctionComponent<IFormularioDocumentosVistaProps> = ({
    alCambiarEtapa,
    alCerrarFormulario,
    alCambiarValor,
    alCambiarValorAutocomplete,
    alGuardarDocumento,
    documento,
    entidadesDocumento,
    estadoFormulario,
    tipoFormularioDocumento,
    tipoDocumento: abreviaturaTipoDocumento,
}) => (
    <Dialog open={estadoFormulario} fullWidth maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumento === TipoFormulario.Creacion
                                ? 'gestionmovimientos.documento.crearDocumento'
                                : tipoFormularioDocumento === TipoFormulario.Edicion
                                ? 'gestionmovimientos.documento.editarDocumento'
                                : 'gestionmovimientos.documento.visualizarDocumento'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="gestionmovimientos.documento.datosDocumento" fontWeight="bold" />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        disableClearable
                                        getOptionLabel={({ TipoDocumentoDescripcion }) => TipoDocumentoDescripcion}
                                        onChange={(_: any, tipoDocumento: any) =>
                                            alCambiarValorAutocomplete('TipoDocumento', tipoDocumento)
                                        }
                                        disabled={tipoFormularioDocumento !== TipoFormulario.Creacion}
                                        options={entidadesDocumento.tiposDocumentos}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="gestionmovimientos.documento.TipoDocumentoId"
                                                    />
                                                }
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.TipoDocumento!}
                                        id={`CampoTipoDocumento`}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        disabled={
                                            abreviaturaTipoDocumento === AbreviaturasTiposDocumentos.ENT ||
                                            !documento.TipoDocumento ||
                                            tipoFormularioDocumento !== TipoFormulario.Creacion
                                        }
                                        disableClearable
                                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                        onChange={(_: any, bodegaOrigen: any) =>
                                            alCambiarValorAutocomplete('BodegaOrigen', bodegaOrigen)
                                        }
                                        options={entidadesDocumento.bodegas}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="gestionmovimientos.documento.BodegaOrigen"
                                                    />
                                                }
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.BodegaOrigen!}
                                        id={`CampoBodegaOrigen-${documento.BodegaOrigen!}`}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        disableClearable
                                        disabled={
                                            abreviaturaTipoDocumento === AbreviaturasTiposDocumentos.ALM ||
                                            abreviaturaTipoDocumento === AbreviaturasTiposDocumentos.SAL ||
                                            !documento.TipoDocumento ||
                                            tipoFormularioDocumento !== TipoFormulario.Creacion
                                        }
                                        getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                        onChange={(_: any, bodegaDestino: any) =>
                                            alCambiarValorAutocomplete('BodegaDestino', bodegaDestino)
                                        }
                                        options={entidadesDocumento.bodegas}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                fullWidth
                                                label={
                                                    <Texto
                                                        display="inline"
                                                        id="gestionmovimientos.documento.BodegaDestino"
                                                    />
                                                }
                                            />
                                        )}
                                        noOptionsText={<Texto id="label.sinRegistros" />}
                                        value={documento.BodegaDestino!}
                                        id={`CampoBodegaDestino-${documento.BodegaDestino}`}
                                    />
                                </Grid>
                                {tipoFormularioDocumento !== TipoFormulario.Creacion && (
                                    <Grid item md={3} xs={12}>
                                        <TextField
                                            label={<Texto id="gestionmovimientos.documento.EtapaDescripcion" />}
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            value={documento.EtapaDescripcion}
                                            variant="outlined"
                                            id={`CampoEtapaDescripcion-${documento.EtapaDescripcion}`}
                                        />
                                    </Grid>
                                )}
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        label={<Texto id="gestionmovimientos.documento.DocumentoRelacionado" />}
                                        name="DocumentoRelacionado"
                                        InputProps={{ readOnly: tipoFormularioDocumento !== TipoFormulario.Creacion }}
                                        onChange={alCambiarValor}
                                        fullWidth
                                        value={documento.DocumentoRelacionado}
                                        variant="outlined"
                                        id={`CampoDocumentoRelacionado-${documento.DocumentoRelacionado}`}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        label={<Texto id="gestionmovimientos.documento.Observacion" />}
                                        name="Observacion"
                                        InputProps={{ readOnly: tipoFormularioDocumento !== TipoFormulario.Creacion }}
                                        onChange={alCambiarValor}
                                        fullWidth
                                        value={documento.Observacion}
                                        variant="outlined"
                                        id={`CampoObservacion-${documento.Observacion}`}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        disabled={
                                            documento.DocumentoPadre ||
                                            tipoFormularioDocumento !== TipoFormulario.Creacion
                                        }
                                        label={
                                            <Texto
                                                display="inline"
                                                id="gestionmovimientos.documento.DocumentoPadreId"
                                            />
                                        }
                                        name="DocumentoPadreId"
                                        onChange={alCambiarValor}
                                        fullWidth
                                        select
                                        value={documento.DocumentoPadreId}
                                        variant="outlined"
                                        id={`CampoDocumentoPadre-${documento.DocumentoPadreId}`}
                                    >
                                        <MenuItem value="">
                                            <Texto id="label.select.ninguno" />
                                        </MenuItem>
                                        {entidadesDocumento.documentosPadre.map(({ CodigoDocumento, DocumentoId }) => (
                                            <MenuItem key={DocumentoId} value={DocumentoId}>
                                                {CodigoDocumento}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={tipoFormularioDocumento !== TipoFormulario.Creacion}
                                                checked={Boolean(documento.DocumentoPadre)}
                                                color="primary"
                                                onChange={(e) =>
                                                    tipoFormularioDocumento !== TipoFormulario.Visualizacion &&
                                                    alCambiarValor(e)
                                                }
                                            />
                                        }
                                        label={
                                            <Texto id="gestionmovimientos.documento.DocumentoPadre" display="inline" />
                                        }
                                        name="DocumentoPadre"
                                        id={`DocumentoPadre-${documento.DocumentoPadreId}`}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <TablaDocumentoDetallesCV />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained" id="boton-cerrar">
                        <Texto id="boton.cerrar" />
                    </Button>
                </Grid>
                {documento.TipoDocumento &&
                    tipoFormularioDocumento !== TipoFormulario.Creacion &&
                    tipoFormularioDocumento !== TipoFormulario.Visualizacion &&
                    (documento.TipoDocumento.TipoDocumentoDescripcion === enumTiposDocumentos.EntradaDeInventario ||
                        documento.TipoDocumento.TipoDocumentoDescripcion === enumTiposDocumentos.SalidaDeInventario) &&
                    documento.EtapaDescripcion !== TiposEtapas.Cerrada &&
                    documento.EtapaDescripcion !== TiposEtapas.Anulado &&
                    documento.EtapaDescripcion !== TiposEtapas.Revertido && (
                        <Grid item>
                            <Button color="primary" onClick={alCambiarEtapa} variant="contained" id="boton-cambiarEtapa">
                                <Texto id="gestionmovimientos.documento.cambiarEtapa" />
                            </Button>
                        </Grid>
                    )}
                <Grid item>
                    {documento.TipoDocumento &&
                        (documento.EtapaDescripcion === TiposEtapas.Creada ||
                            tipoFormularioDocumento === TipoFormulario.Creacion) &&
                        tipoFormularioDocumento !== TipoFormulario.Visualizacion && (
                            <Button 
                            color="primary" 
                            onClick={alGuardarDocumento} 
                            variant="contained"
                            id={
                                tipoFormularioDocumento === TipoFormulario.Creacion
                                    ? 'boton-guardar'
                                    : 'boton-editar'
                            }
                            >
                                <Texto
                                    id={
                                        tipoFormularioDocumento === TipoFormulario.Creacion
                                            ? 'boton.guardar'
                                            : 'boton.editar'
                                    }
                                />
                            </Button>
                        )}
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDocumentosVista;
