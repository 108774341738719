import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';

interface ICampoLoteProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: string;
}

const CampoLote: FunctionComponent<ICampoLoteProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="DetallesFormulario.CampoLote.Nombre" />}
        onChange={(event) => manejarCambioCampo('Lote', event.target.value)}
        value={valor !== null? valor.toUpperCase():""}
        required
        id={`CampoLote`}
    />
);

export default CampoLote;
