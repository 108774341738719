import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Texto from '@infotrack/presentacion-componentes/texto';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import moment from 'moment';
import EntradaDevolucionCM from './controladorModelo/EntradaDevolucionCM';
interface IModalTablaDocumentoClienteProps {
    idDocumento: string
}

const TablaDocumentoDetallesCliente: FunctionComponent<IModalTablaDocumentoClienteProps> = ({
    idDocumento
}) => {
    const [detallesDocumentoCliente, setDetallesDocumentoCliente] = useState<IDocumentoDetalleConsultaGeneral[]>([]);

    const entradaDevolucionCM = useMemo(() => new EntradaDevolucionCM(), []);
    useEffect(() => {
        consultarDetallesDocumentosCliente(idDocumento)
    }, [idDocumento]);

    const consultarDetallesDocumentosCliente = async (idDocumento: string) => {
        let detallesConsultados = await entradaDevolucionCM.consultarDetallesDocumento({
            DocumentoId: idDocumento,
        });
        setDetallesDocumentoCliente([...detallesConsultados]);
    };
    const obtenerColumnasDetalles = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];

        columnas.push({
            field: 'CodigoEmpresa',
            title: <Texto id="TablaDetalles.CodigoEmpresa" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaDetalles.DescripcionItem" />,
        });
        columnas.push({
            field: 'PesoFraccion',
            title: <Texto id="TablaDetalles.Peso" />,
        });
        columnas.push({
            field: 'CantidadEntregada',
            title: <Texto id="TablaDetalles.CantidadEntregada" />,
        });
        columnas.push({
            field: 'Lote',
            title: <Texto id="TablaDetalles.Lote" />,
        });
        columnas.push({
            field: 'Serial',
            title: <Texto id="TablaDetalles.Serial" />,
        });
        columnas.push({
            field: 'FechaVencimiento',
            title: <Texto id="TablaDetalles.FechaVencimiento" />,
            render: (rowData) =>
                rowData.FechaVencimiento ? moment(rowData.FechaVencimiento).format('DD/MM/YYYY/hh:mm A') : '',
        });
        columnas.push({
            field: 'FechaIngreso',
            title: <Texto id="TablaDetalles.FechaIngreso" />,
            render: (rowData) =>
                rowData.FechaIngreso ? moment(rowData.FechaIngreso).format('DD/MM/YYYY/hh:mm A') : '',
        });
        columnas.push({
            field: 'Costo',
            title: <Texto id="gestionproducto.Costo" />,
        });

        return columnas;
    };

    return (

        <Tabla
            columns={obtenerColumnasDetalles()}
            data={detallesDocumentoCliente}
        />
    );
};

export default TablaDocumentoDetallesCliente;
