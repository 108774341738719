import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import ModeloSalidaInventario from 'Infotrack@Modelos/negocioRefactor/modelos/SalidaInventario/ModeloSalidaInventario';
import IDocumentoBaseConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoBaseConsulta';

class SalidaInventarioCM {
    private modeloSalidaInventario: ModeloSalidaInventario;

    constructor() {
        this.modeloSalidaInventario = new ModeloSalidaInventario();
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        //if (!this.validarDetalle(detalle)) return false;
        const respuesta = await this.modeloSalidaInventario.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async consultarDocumentoBaseSalidaInventario(documentoBase: IDocumentoBaseConsulta) {
        const respuesta = await this.modeloSalidaInventario.consultarDocumentoBaseSalidaInventario(documentoBase);
        return respuesta.data.Resultado;
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloSalidaInventario.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        if (detalles.some((detalle) => !this.validarDetalle(detalle))) return false;
        const respuesta = await this.modeloSalidaInventario.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async guardarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloSalidaInventario.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloSalidaInventario.editarEncabezado(documento);
        return respuesta.data;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloSalidaInventario.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        const respuesta = await this.modeloSalidaInventario.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloSalidaInventario.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloSalidaInventario.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloSalidaInventario.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async cambiarEtapa(documento: IDocumento, idUsuario:string) {
        const respuesta = await this.modeloSalidaInventario.cambiarEtapa(documento, idUsuario);
        return respuesta.data.Resultado;
    }

    public async consultarDocumentoBase(documentoBase: IDocumentoBaseConsulta) {
        const respuesta = await this.modeloSalidaInventario.consultarDocumentoBaseSalidaInventario(documentoBase);
        return respuesta.data.Entidades[0];
    }

    
    public async generarArchivoPDF(documentoId: string, idAplicacion: string) {
        const respuesta = await this.modeloSalidaInventario.generarArchivoPDF(documentoId, idAplicacion);
        return respuesta.data.Entidades[0] ;
    }

    private validarDocumento(documento: IDocumento) {
        if (!documento.TipoDocumentoId) {
            notificacionGlobal('SalidaInventario.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        if (!documento.BodegaOrigen) {
            notificacionGlobal('SalidaInventario.ValidacionNoBodegaOrigen', 3000, 'warning', true);
            return false;
        }
        if (!documento.ClienteId) {
            notificacionGlobal('SalidaInventario.ValidacionNoClienteId', 3000, 'warning', true);
            return false;
        }
        return true;
    }

    private validarDetalle(detalle: IDocumentoDetalle) {
        if (!detalle.DivisionOrigen) {
            notificacionGlobal('ModalRecepcion.ValidacionNoDivisionOrigen', 3000, 'warning', true);
            return false;
        }
        return true;
    }
}
export default SalidaInventarioCM;
