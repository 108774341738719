import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoTipoDocumentoIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof IDocumento, nuevoValor: IDocumento[keyof IDocumento]) => any;
    tiposDocumento: ITipoDocumento[];
    valor: number;
}

const CampoTipoDocumentoId: FunctionComponent<ICampoTipoDocumentoIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    tiposDocumento,
    valor,
}) => (
    <Autocomplete
        disabled={deshabilitado}
        getOptionLabel={(t) => t.TipoDocumentoDescripcion}
        onChange={(_: ChangeEvent<{}>, tipoDocumento: ITipoDocumento | null) =>
            manejarCambioCampo('TipoDocumentoId', tipoDocumento ? tipoDocumento.TipoDocumentoId! : 0)
        }
        options={tiposDocumento}
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                label={<Texto id="FormularioDocumento.CampoTipoDocumentoId.Nombre" />}
                required
            />
        )}
        value={tiposDocumento.find((t) => t.TipoDocumentoId === valor) || null}
        noOptionsText={<Texto id="FormularioDocumento.SinRegistros" />}
        id={'CampoTipoDocumento'}
    />
);

export default CampoTipoDocumentoId;
