import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';

interface ICampoObservacionProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampo: keyof IAuditoriaConteo,
        nuevoValor: IAuditoriaConteo[keyof IAuditoriaConteo]
    ) => any;
    valor: string;
}

const CampoObservacion: FunctionComponent<ICampoObservacionProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="AuditoriaConteo.CampoObservacion.Nombre" />}
        onChange={(event) => manejarCambioCampo('Observacion', event.target.value)}
        value={valor}
        InputLabelProps={{ shrink: true }}
        id={`CampoObservacion-${valor}`}
    />
);

export default CampoObservacion;
