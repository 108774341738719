import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaAlmacenamientoVistaProps } from '../interfaces';
import {
    accionesTablaAlmacenamiento,
    columnasTablaAlmacenamiento,
    opcionesTablaDocumento,
    opcionesTablaDocumentoPadre,
} from './constantes';

const TablaAlmacenamientoVista: React.FunctionComponent<ITablaAlmacenamientoVistaProps & InjectedIntlProps> = ({
    alAbrirFormularioDetalle,
    alCambiarValor,
    alCambiarValorAutocomplete,
    alAnularAlmacenamiento,
    consultaAlmacenamiento,
    documentos,
    documentosFiltro,
    entidadesFiltro,
    intl: { formatMessage },
    recargarTabla,
    refTabla,
}) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container justifyContent="center" spacing={2}>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodega: any) => alCambiarValorAutocomplete('BodegaDestino', bodega)}
                                options={entidadesFiltro.bodegas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={formatMessage({ id: 'almacenamiento.bodega' })}
                                        required
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documentosFiltro.BodegaDestino!== null ?documentosFiltro.BodegaDestino:undefined}
                                id={'CampoBodegaDestino'}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="almacenamiento.numeroDocumento" />}
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={documentosFiltro.CodigoDocumento}
                                variant="outlined"
                                id={`CampoNumeroDocumento-${documentosFiltro.CodigoDocumento}`}
                            />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonLimpiar fullWidth onClick={() => recargarTabla(true)} />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonBuscar fullWidth onClick={() => recargarTabla()} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        {documentos.ListaPadre.length > 0 && (
            <Grid item xs={12}>
                <Tabla
                    columns={columnasTablaAlmacenamiento(formatMessage)}
                    data={documentos.ListaPadre}
                    options={opcionesTablaDocumentoPadre}
                />
            </Grid>
        )}
        <Grid item xs={12}>
            <Tabla
                actions={accionesTablaAlmacenamiento(alAnularAlmacenamiento, formatMessage, alAbrirFormularioDetalle)}
                columns={columnasTablaAlmacenamiento(formatMessage)}
                data={consultaAlmacenamiento}
                options={opcionesTablaDocumento}
                tableRef={refTabla}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaAlmacenamientoVista);
