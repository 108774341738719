import { Card, CardContent, Grid, MenuItem, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaAuditoriasVistaProps } from '../interfaces';
import { accionesAuditorias, columnasAuditorias, opcionesAuditoria } from './constantes';

const TablaAuditoriasVista: React.FunctionComponent<ITablaAuditoriasVistaProps & InjectedIntlProps> = ({
    alAbrirFormularConteo,
    alCambiarValor,
    cargarDocumentos,
    consultaDocumentos,
    entidadesFiltros,
    filtrosDocumentos,
    intl: { formatMessage },
    refTabla,
}) => (
    <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={12}>
                            <TextField
                                label={<Texto id="auditoriasinventario.Bodegas" />}
                                fullWidth
                                select
                                name="BodegaConsulta"
                                required
                                onChange={alCambiarValor}
                                value={filtrosDocumentos.BodegaConsulta}
                                variant="outlined"
                                id={`CampoBodegas-${filtrosDocumentos.BodegaConsulta}`}
                            >
                                {entidadesFiltros.bodegas.length === 0 ? (
                                    <MenuItem value="">
                                        <Texto id="label.sinRegistros" />
                                    </MenuItem>
                                ) : (
                                    entidadesFiltros.bodegas.map(({ BodegaDescripcion, BodegaId }) => (
                                        <MenuItem key={BodegaId} value={BodegaId}>
                                            {BodegaDescripcion}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                label={<Texto id="auditoriasinventario.NumeroDocumento" />}
                                fullWidth
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={filtrosDocumentos.CodigoDocumento}
                                variant="outlined"
                                id={`CampoCodigoDocumento-${filtrosDocumentos.CodigoDocumento}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                label={<Texto id="auditoriasinventario.FechaInicial" />}
                                fullWidth
                                format="DD/MM/YYYY"
                                autoOk
                                variant="inline"
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaInicial', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtrosDocumentos.FechaInicial}
                                inputVariant="outlined"
                                id={`CampoFechaInicial-${filtrosDocumentos.FechaInicial}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <KeyboardDatePicker
                                label={<Texto id="auditoriasinventario.FechaFinal" />}
                                fullWidth
                                format="DD/MM/YYYY"
                                autoOk
                                variant="inline"
                                onChange={(fecha: any) =>
                                    alCambiarValor({
                                        target: { name: 'FechaFinal', value: fecha },
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }
                                value={filtrosDocumentos.FechaFinal}
                                inputVariant="outlined"
                                id={`CampoFechaFinal-${filtrosDocumentos.FechaFinal}`}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                label={<Texto id="auditoriasinventario.EstadoDocumento" />}
                                fullWidth
                                select
                                name="EtapaId"
                                onChange={alCambiarValor}
                                value={filtrosDocumentos.EtapaId}
                                variant="outlined"
                                id={`CampoEstadoDocumento-${filtrosDocumentos.EtapaId}`}
                            >
                                {entidadesFiltros.etapas.length === 0 ? (
                                    <MenuItem value="">
                                        <Texto id="label.sinRegistros" />
                                    </MenuItem>
                                ) : (
                                    entidadesFiltros.etapas.map(({ EtapaDescripcion, EtapaId }) => (
                                        <MenuItem key={EtapaId} value={EtapaId}>
                                            {EtapaDescripcion}
                                        </MenuItem>
                                    ))
                                )}
                            </TextField>
                        </Grid>
                        <Grid item md xs={12}>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item md="auto" xs={6}>
                                    <BotonLimpiar onClick={() => cargarDocumentos(true)} id="boton-Limpiar"/>
                                </Grid>
                                <Grid item md="auto" xs={6}>
                                    <BotonBuscar onClick={() => cargarDocumentos()} id="boton-Buscar"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={accionesAuditorias(alAbrirFormularConteo, cargarDocumentos, formatMessage)}
                columns={columnasAuditorias(formatMessage)}
                data={consultaDocumentos}
                tableRef={refTabla}
                options={opcionesAuditoria}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaAuditoriasVista);
