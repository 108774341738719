import React from 'react';
import { injectIntl } from 'react-intl';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import paginacionRemota from '@infotrack/presentacion-componentes/tabla/paginacionRemota';
import MovimientoCM from '../controladorModelo/movimientoCM';
import { acciones, IEstadoConsultaInventario } from '../reductorConsultaInventario';
import TablaConsultaInventarioVista from '../vista/tablaConsultaInventarioVista';
import moment from 'moment';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';
import IMovimientoCompuesto from 'Infotrack@Modelos/smartStock/movimientos/entidades/movimientoCompuesta';

const movimientoCM = new MovimientoCM();
const TablaConsultaInventarioCV: React.FunctionComponent<{ intl: any }> = ({ intl }) => {
    const [{ estadoFormularioConsulta, filtros }, dispatch] = useProveedor<IEstadoConsultaInventario>();
    const refTabla = React.createRef<any>();

    React.useEffect(() => {
        if (refTabla.current) cargarMovimientos();
    }, [refTabla.current]);

    const consultaMovimientosConSaldo = paginacionRemota(
        movimientoCM.consultarSaldoCompuestoFiltro,
        filtros,
        'FechaMovimiento'
    );

    const alCerrarDialogo = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        dispatch({ type: acciones.LIMPIAR_FILTROS });
    };

    const cargarMovimientos = () => {
        refTabla.current!.onQueryChange({ page: 0, pageSize: 10, orderDirection: 'desc', orderBy: null });
    };

    const consultarMovimientosParaDescarga = async () => {
        const paginador: IPaginador = {
          Pagina: 0,
          PropiedadesDeOrdenamiento: [{ NombrePropiedad: "FechaMovimiento", Orden: 1 }],
          Registros: refTabla.current.state.query.totalCount
        };
        const consulta: IMovimientoCompuesto[] = await (await movimientoCM.consultarSaldoCompuestoFiltro(paginador, filtros)).Entidades;
        return consulta.map(x => {
            return {
                "Bodega": x.BodegaDescripcion,
                "División": x.DivisionDescripcion,
                "Producto": x.DescripcionItem,
                "Cantidad Total": x.CantidadTotal,
                "Serial": x.Serial,
                "Lote": x.Lote,
                "Fecha De Vencimiento": x.FechaVencimiento != null ? moment(x.FechaVencimiento).format('YYYY-MM-DD HH:mm') : "",
                "Fecha de Ingreso": x.FechaIngreso != null ? moment(x.FechaIngreso).format('YYYY-MM-DD HH:mm') : "",
                "Valor": x.Valor,
                " Codigo Producto":x.CodigoEmpresa,
                "Peso": x.Peso
            }
        });  
    }

    return (
        <TablaConsultaInventarioVista
            alCerrarDialogo={alCerrarDialogo}
            consultaMovimientosConSaldo={consultaMovimientosConSaldo}
            estadoDialogo={estadoFormularioConsulta}
            refTabla={refTabla}
            consultarMovimientosParaDescarga={consultarMovimientosParaDescarga}
        />
    );
};

export default injectIntl(TablaConsultaInventarioCV); 
