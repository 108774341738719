import { IRespuesta } from "@infotrack/presentacion-transversales/interfacesComunes";
import IMaximosMinimos from "./entidades/IMaximosMinimos";
import IBodegaMaxMinFiltros from "./entidades/bodegaMaxMinFiltros";
import manejadorRest from "@infotrack/presentacion-utilitarios/manejadorRest";
import { negocioProceso } from "Infotrack@Modelos/conexiones";

export default class ModeloMaximosMinimos {

  public ConsultarListaMaximoMinimoPorBodega(BodegaId: number) {
    return manejadorRest<IRespuesta<IMaximosMinimos>>(
        () => negocioProceso.get(`MaximoMinimo/ConsultarListaMaximoMinimoPorBodega/${BodegaId}`),
        true
    );
  }

  public eliminarMaximoMinimo(maxMin: IMaximosMinimos) {
    return manejadorRest<IRespuesta<IMaximosMinimos>>(() =>
    negocioProceso.delete('MaximoMinimo/EliminarMaximoMinimo', maxMin)
    );
  }

  public crearMaximoMinimo(maxMin: IMaximosMinimos) {
    return manejadorRest<IRespuesta<IMaximosMinimos>>(() =>
    negocioProceso.post('MaximoMinimo/GuardarMaximoMinimo', maxMin)
    );
  }

  public editarMaximoMinimo(maxMin: IMaximosMinimos) {
    return manejadorRest<IRespuesta<IMaximosMinimos>>(() =>
    negocioProceso.put('MaximoMinimo/EditarMaximoMinimo', maxMin)
    );

  }
}
