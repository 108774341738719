import React, { FunctionComponent, useState } from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Compare from '@material-ui/icons/Compare';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { useHistory } from 'react-router-dom';

import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaConteo from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IAuditoriaConteo';
import TipoConteoAuditoria from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TipoConteoAuditoria';

import ModalComparacionConteo from '../../ModalComparacionConteo/ModalComparacionConteo';

interface IAuditoriaConteoTablaProps {
    recargarTabla: () => any;
    listaConteo: IAuditoriaConteo[];
    deshabilitar: boolean;
}

const AuditoriaConteoTabla: FunctionComponent<IAuditoriaConteoTablaProps> = ({
    recargarTabla,
    listaConteo,
    deshabilitar,
}) => {
    let history = useHistory();

    const [modalAbierto, setEstadoModal] = useState<boolean>(false);
    const [conteoSeleccionado, setPrimerConteo] = useState<string>('');
    const [listadoConteos, setListadoConteos] = useState<IAuditoriaConteo[]>([]);

    const abrirDetalleConteo = (conteo: IAuditoriaConteo) => {
        window.open(`Conteo/${conteo.AuditoriaId}/${conteo.AuditoriaConteoId}`, '_blank');
    };

    const compararConteo = (conteo: IAuditoriaConteo) => {
        let conteos = [];
        if (conteo.IdTipoConteo!.valueOf() == TipoConteoAuditoria.Conteo) {
            conteos = listaConteo.filter(
                (x) => x.ConteoPredecesorId == conteo.AuditoriaConteoId || x.IdTipoConteo == TipoConteoAuditoria.Conteo
            );
            setListadoConteos(conteos);
        }
        if (conteo.IdTipoConteo!.valueOf() == TipoConteoAuditoria.Reconteo) {
            conteos = listaConteo.filter(
                (x) =>
                    x.AuditoriaConteoId == conteo.ConteoPredecesorId || x.AuditoriaConteoId == conteo.AuditoriaConteoId
            );
            setListadoConteos(conteos);
        }
        if (conteos.length > 1) {
            setPrimerConteo(conteo.AuditoriaConteoId);
            setEstadoModal(true);
        } else window.open(`Conteo/ComparacionConteo?primerConteo=${conteo.AuditoriaConteoId}`, '_blank');
    };

    return (
        <>
            <Tabla
                actions={[{ icon: 'refresh', isFreeAction: true, onClick: recargarTabla }]}
                columns={[
                    {
                        field: 'DescripcionConteo',
                        title: <Texto id="AuditoriaConteoTabla.DescripcionConteo" />,
                    },

                    {
                        field: 'Observacion',
                        title: <Texto id="AuditoriaConteoTabla.Observacion" />,
                    },
                    {
                        title: <Texto id="AuditoriaConteoTabla.ConteoFinalizado" />,
                        field: 'ConteoFinalizado',
                        type: 'boolean',
                    },
                    {
                        render: (conteo: IAuditoriaConteo) => (
                            <Grid container wrap="nowrap">
                                <Grid item>
                                    <Tooltip title={<Texto id="AuditoriaConteoTabla.BotonGestionConteo" />}>
                                        <IconButton
                                            disabled={deshabilitar}
                                            onClick={() => abrirDetalleConteo(conteo)}
                                            size="small"
                                        >
                                            <FormatListNumberedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ),
                        title: <Texto id="AuditoriaConteoTabla.Conteo" />,
                    },
                ]}
                data={listaConteo}
                options={{ padding: 'dense' }}
            />
            {modalAbierto ? (
                <ModalComparacionConteo
                    abierto={modalAbierto}
                    cerrar={() => setEstadoModal(false)}
                    conteos={listadoConteos}
                    primerConteo={conteoSeleccionado}
                />
            ) : (
                undefined
            )}
        </>
    );
};

export default AuditoriaConteoTabla;
