import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

interface ICampoDocumentoRelacionadoProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor: string;
    esTraslado : boolean;
}

const CampoDocumentoRelacionado: FunctionComponent<ICampoDocumentoRelacionadoProps> = ({
    manejarCambioFiltro,
    valor,
    esTraslado
}) => (
    <TextField
        fullWidth
        label={esTraslado?<Texto id="Documentos.CampoDocumentoRelacionado.NumeroTicket" />: <Texto id="Documentos.CampoDocumentoRelacionado.Nombre" />}
        onChange={(event) => manejarCambioFiltro('DocumentoRelacionado', event.target.value)}
        value={valor}
        id={`CampoDocumentoRelacionado-${valor}`}
    />
);

export default CampoDocumentoRelacionado;
