import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import React from 'react';
import { connect } from 'react-redux';
import DocumentoDetalleCM from '../controladorModelo/documentoDetalleCM';
import {
    /*IFormularioDocumentoDetalleCVProps,*/ IInputsDocumentoDetalle,
    IInputsDocumentoDetalles,
} from '../interfaces';
import { acciones, IEstadoDespachos } from '../reductorDespachos';
import FormularioDocumentosDetalleVista from '../vista/formularioDocumentoDetalleVista';
import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';

interface IFormularioDocumentoDetalleCVProps {
    abierto: boolean;
    documentoDetalle: IDocumentoDetalleCompuesto | undefined;
    cerrarModal: () => void;
    refrescarDocumentoDetalle: (refrescar: boolean) => void;
    tipoFormulario: TipoFormulario | undefined;
    IdEmpresa: string;
}

const valorInicialFormulario: IInputsDocumentoDetalle = {
    CantidadEntregada: '',
    CantidadRecibida: '',
    CantidadSolicitada: '',
    DescripcionItem: '',
};

const estadoInicialDocumentoDetalle: IDocumentoDetalleCompuesto = {
    CodigoBarras: '',
    CodigoDocumento: '',
    DescripcionItem: '',
    DescripcionProducto: '',
    DescripcionDivisionDestino: '',
    DescripcionDivisionOrigen: '',
    EmpresaId: '',
    Lote: '',
    Serial: '',
    DescripcionEstadoDetalle: TiposEstadosDetalles.Alistado,
};

const documentoDetalleCM = new DocumentoDetalleCM();

const FormularioDocumentoDetalleCV: React.FunctionComponent<IFormularioDocumentoDetalleCVProps> = ({
    IdEmpresa,
    abierto,
    cerrarModal,
    documentoDetalle,
    refrescarDocumentoDetalle,
    tipoFormulario,
}) => {
    const { setValor, reiniciar, valor, alCambiarValor } = useInputState<IInputsDocumentoDetalles>(
        estadoInicialDocumentoDetalle
    );
    const [
        { estadoFormularioDetalle, entidadDocumentoDetalle, tipoFormularioDocumentoDetalle },
        dispatch,
    ] = useProveedor<IEstadoDespachos>();

    React.useEffect(() => {
        if (documentoDetalle) {
            setValor(mapearDocumentoDetalleInput(documentoDetalle));
        }
    }, [documentoDetalle]);
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        reiniciar();
    };

    const mapearDocumentoDetalleInput = (
        documentoDetalleCompuesto: IDocumentoDetalleCompuesto
    ): IInputsDocumentoDetalles => ({
        ...documentoDetalleCompuesto,
        CantidadRecibidaActual: documentoDetalleCompuesto.CantidadRecibida,
    });

    const alGuardarDocumentoDetalle = () => {
        const nuevoDocumentoDetalle = mapearDetalle();
        if (documentoDetalleCM.validarDocumentoDetalle(nuevoDocumentoDetalle)) {
            if (tipoFormulario === TipoFormulario.Creacion)
                dispatch({ type: acciones.AGREGAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
            else dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
            alCerrarFormulario();
        }
        refrescarDocumentoDetalle(true);
        CerrarFormularioDocumentoDetalle();
    };
    const CerrarFormularioDocumentoDetalle = () => {
        refrescarDocumentoDetalle(true);
        cerrarModal();
        reiniciar();
    };
    const mapearDetalle = (): IDocumentoDetalleCompuesto => {
        const { CantidadEntregada } = valor;
        return {
            ...valor,
            CantidadEntregada: Number(CantidadEntregada),
            EmpresaId: IdEmpresa,
        } as any;
    };
    const alEditarDocumentoDetalle = async () => {
        const nuevoDocumentoDetalle = mapearDetalle();
        if (tipoFormulario === TipoFormulario.Creacion) {
            if (tipoFormulario === TipoFormulario.Creacion) {
                dispatch({ type: acciones.AGREGAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
            } else {
                dispatch({ type: acciones.EDITAR_DOCUMENTO_DETALLE, payload: nuevoDocumentoDetalle });
            }
        }
        refrescarDocumentoDetalle(true);
        CerrarFormularioDocumentoDetalle();
    };

    return (
        <FormularioDocumentosDetalleVista
            alCambiarValor={alCambiarValor}
            alCerrarFormulario={CerrarFormularioDocumentoDetalle}
            documentoDetalle={valor}
            estadoFormulario={abierto}
            esMovil={esMovil}
            tipoFormularioDocumentoDetalle={tipoFormularioDocumentoDetalle}
            alEditarDocumentoDetalle={alEditarDocumentoDetalle}
        />
    );
};



export default connect<IFormularioDocumentoDetalleCVProps, null, any, IEstadoGlobal>(
    null
)(FormularioDocumentoDetalleCV);
