import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from '../../conexiones';
import IBodega from './entidades/bodega';
 
import IBodegaCompuesta from './entidades/bodegaCompuesta';
import IBodegaFiltro from './entidades/bodegaFiltro';
import IBodegaPersonaFiltro from './entidades/bodegaPersonaFiltro';
import IBodegaPropiedades from './entidades/bodegaPropiedades';
 
export default class ModeloBodegas {
    private NOMBRE_CONTROLADOR = 'Bodega';

    public consultarListaBodegasCompuesta(IdAgencia: string|null) {
        return manejadorRest<IRespuesta<IBodegaCompuesta>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaBodegaCompuesta/${IdAgencia}`),
            true
        );
    }

    public async consultarListaBodegasCompuestaFiltro(bodegaFiltro: IBodegaFiltro) {
        return manejadorRest<IRespuesta<IBodegaCompuesta>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaBodegasCompuestaFiltro`, bodegaFiltro),
            true
        );
    }

    public consultarBodegasPersonasDoc(bodegaPersonaFiltro: IBodegaPersonaFiltro) {
        return manejadorRest<IRespuesta<IBodega>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarBodegaPersonaDoc`, bodegaPersonaFiltro),
            true
        );
    }

    public guardarBodegaCompuesta(bodegaCompuesta: IBodegaPropiedades) {
        return manejadorRest<IRespuesta<IBodegaPropiedades>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/GuardarBodegaCompuestaPropiedades`, bodegaCompuesta),
            true
        );
    }

    public editarBodegaCompuesta(bodegaCompuesta: IBodegaPropiedades) {
        return manejadorRest<IRespuesta<IBodegaPropiedades>>(
            () => negocioConfiguracion.put(`${this.NOMBRE_CONTROLADOR}/EditarBodegaCompuestaPropiedades`, bodegaCompuesta),
            true
        );
    }

    public eliminarBodegaCompuesto(bodega: IBodega) {
        return manejadorRest<IRespuesta<IBodegaPropiedades>>(
            () => negocioConfiguracion.delete(`${this.NOMBRE_CONTROLADOR}/EliminarBodegaCompuesto`, bodega),
            true
        );
    }

    public consultarListaBodegasFiltro(bodegaFiltro: IBodegaFiltro) {
        return manejadorRest<IRespuesta<IBodega>>(() =>
            negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, bodegaFiltro)
        );
    }
}
