import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Toolbar,
} from '@material-ui/core';
import React, { FunctionComponent, RefObject, useEffect, useMemo, useState } from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import IEtiqueta from 'Infotrack@Modelos/smartStock/etiquetas/entidades/etiqueta';
import EntidadesImpresionCM from './controladorModelo/entidadesImpresionCM';
import IItems from 'Infotrack@Modelos/smartStock/items/entidades/IItems';
import InformacionProductos from './informacionProducto';
import { useSelector } from 'react-redux';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IEtiquetaItem from 'Infotrack@Modelos/smartStock/items/entidades/IEtiquetaItem';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

interface ITablaEtiquetaItemsProps {
    estado: boolean;
    cerrar:() => Promise<void>;
    etiquetaSeleccionada: IEtiqueta | undefined;
    itemsEtiquetas: IItems[];
}
const TablaEtiquetaItems: FunctionComponent<ITablaEtiquetaItemsProps> = ({
    estado,
    cerrar,
    etiquetaSeleccionada,
    itemsEtiquetas,
}) => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const entidadesDocumentoCM = useMemo(() => new EntidadesImpresionCM(), []);
    const [itemsDisponibles, setItemsDisponibles] = useState<IItems[]>([]);
    const [itemEtiquetas, setItemEtiquetas] = useState<IItems[]>([]);
    useEffect(() => {
        if (estado) {
            consultarItemsSinEtiquetas(itemsEtiquetas);
            setItemEtiquetas(itemsEtiquetas);
        }
    }, [estado]);

    const agregarItemEtiqueta = async (item: IItems) => {
        let copiaItem = [...itemEtiquetas];
        copiaItem.push(item);
        setItemEtiquetas([...copiaItem]);
        setItemsDisponibles(itemsDisponibles.filter((i) => i.ItemId !== item.ItemId));
    };

    const eliminarItemEtiqueta = async (item: IItems) => {
        let copiaItem = [...itemsDisponibles];
        copiaItem.push(item);
        setItemsDisponibles([...copiaItem]);
        setItemEtiquetas(itemEtiquetas.filter((i) => i.ItemId !== item.ItemId));
    };

    const consultarItemsSinEtiquetas = async (itemsEtiquetas: IItems[]) => {
        let idsItemsEtiqueta = itemsEtiquetas.map((x) => {
            return x.ItemId;
        });

        const items = await entidadesDocumentoCM.consultarItemsSinEtiqueta(idEmpresa, idsItemsEtiqueta);
        setItemsDisponibles(items);
    };
    const columnas = (): Array<Column<ItemDetalle>> => [
        {
            field: 'DescripcionItem',
            title: <Texto id="TablaEtiquetaItems.DescripcionItem" />,
        },
        {
            field: 'CodigoEmpresaItem',
            title: <Texto id="TablaEtiquetaItems.CodigoEmpresaItem" />,
        },
        {
            field: 'Unidad',
            title: <Texto id="TablaEtiquetaItems.Unidad" />,
        },
        {
            field: 'Clasificacion',
            title: <Texto id="TablaEtiquetaItems.Clasificacion" />,
        },
    ];
    const confirmarGuardarItemsEtiqueta = async () => {
        if (itemEtiquetas.length === 0) {
            notificacionGlobal("TablaEtiquetaItems.RequeridosProductoEtiqueta", 6000, 'warning', true);
        } else {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => guardarItemsEtiqueta(),
                estado: true,
                mensaje: <Texto id={"TablaEtiquetaItems.ConfirmacionProductoEtiqueta" } />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };
    const guardarItemsEtiqueta = async () => {
        manejadorDialogoGlobal({ estado: false })
        let itemsGuardar: IEtiquetaItem[] = [];
        itemEtiquetas.map((i) => {
            itemsGuardar.push({ EtiquetaId: etiquetaSeleccionada!.EtiquetaId, ItemId: i.ItemId });
        });
        const respuesta = await entidadesDocumentoCM.guardarItemsEtiqueta(itemsGuardar);
        if (respuesta.Resultado) {
            cerrar();
        }
       
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={estado}>
            <DialogTitle style={{ padding: 0 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container justify="center">
                            <Texto align="center" color="inherit" id={'TablaEtiquetaItems.EtiquetaItems'} />
                        </Grid>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent>
                <Card>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <InformacionProductos mostrarBoton={false} etiqueta={etiquetaSeleccionada!} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Tabla
                                columns={columnas()}
                                actions={[
                                    {
                                        icon: () => <AddIcon color="action" id="Icono-Agregar"/>,
                                        onClick: (_, value) => agregarItemEtiqueta(value as IItems),
                                    },
                                ]}
                                data={itemsDisponibles}
                               title={"Producto disponibles"}
                            />
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Tabla
                                columns={columnas()}
                                actions={[
                                    {
                                        icon: () => <DeleteIcon id="Icono-Eliminar" color="action" />,
                                        onClick: (_, value) => eliminarItemEtiqueta(value as IItems),
                                    },
                                ]}
                                data={itemEtiquetas}
                                title={"Producto asociados"}
                            />
                        </CardContent>
                    </Card>
                </Card>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs="auto">
                        <Button color="secondary" fullWidth onClick={cerrar} variant="contained">
                            <Texto id="boton.cancelar" />
                        </Button>
                    </Grid>
                    <Grid item xs="auto">
                        <Button
                            color="primary"
                            fullWidth
                            onClick={() => confirmarGuardarItemsEtiqueta()}
                            variant="contained"
                        >
                            <Texto id="boton.guardar" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
export default TablaEtiquetaItems;
