import { IPaginador } from "@infotrack/presentacion-transversales/interfacesComunes";
import ModeloCostos from "Infotrack@Modelos/smartStock/costos";
import ICostos from "Infotrack@Modelos/smartStock/costos/entidades/ICostos";
import IFiltroCostos from "Infotrack@Modelos/smartStock/costos/entidades/IFiltroCostos";
import IItems from "Infotrack@Modelos/smartStock/items/entidades/IItems";


export default class CostosCM {
    private modeloCostos: ModeloCostos;

    constructor() {
        this.modeloCostos = new ModeloCostos();
    }
    public consultarListaCostosPorfiltro = (paginador: IPaginador, filtros: IItems) =>
        this.modeloCostos
            .consultarListaCostosPorfiltro({ paginador, items: filtros })
            .then(({ data }) => data);
}
