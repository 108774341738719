import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioProceso } from 'Infotrack@Modelos/conexiones';
import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IInformacionAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IInformacionAuditoria';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

class ModeloAuditoria {
    private NOMBRE_CONTROLADOR = 'Auditoria';

    public async crearAuditoria(auditoria: IInformacionAuditoria) {
        return manejadorRest<IRespuesta<IAuditoriaCompuesta>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/Crear`, auditoria),
            true
        );
    }

    public async consultarAuditoria(filtro: Partial<IAuditoriaCompuesta>) {
        return manejadorRest<IRespuesta<IAuditoriaCompuesta>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/Consultar`, filtro),
            true
        );
    }

    public async consultarListaAuditoria(filtro: Partial<IInformacionAuditoria>) {
        return manejadorRest<IRespuesta<IAuditoriaCompuesta>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/ConsultarLista`, filtro),
            true
        );
    }

    public async anularAuditoria(auditoriaId: string) {
        return manejadorRest<IRespuesta<IAuditoriaCompuesta>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/Anular/${auditoriaId}`, {}),
            true
        );
    }

    public async cambiarEtapa(documentoId: string, etapaId: number, idUsuario:string) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () =>
                negocioProceso.put(
                    `${this.NOMBRE_CONTROLADOR}/CambiarEtapa/${documentoId}/${etapaId}`,
                    {},
                    {
                        headers: {
                            'IdUsuario': idUsuario
                        }
                    }
                ),
            true
        )

    }

    public async consultarEtapas(documentoId: string) {
        return manejadorRest<IRespuesta<IEtapas>>(() =>
        negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/ConsultarEtapas/${documentoId}`)
        );
    }
}

export default ModeloAuditoria;
