import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import ModeloTipoDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloTipoDocumento';
import ModeloBodegas from 'Infotrack@Modelos/smartStock/bodegas';
import ModeloEtapas from 'Infotrack@Modelos/smartStock/etapas';
import ModeloProveedores from 'Infotrack@Modelos/smartStock/proveedores';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import ModeloArchivo from 'Infotrack@Modelos/administracion/archivo';
import IArchivo from 'Infotrack@Modelos/administracion/archivo/entidades/archivo';
import ModeloAccionEtapaDocumento from 'Infotrack@Modelos/negocioRefactor/modelos/Administracion/ModeloAccionEtapaDocumento';
class EntidadesDocumentoCM {
    private modeloTipoDocumento: ModeloTipoDocumento;
    private modeloEtapa: ModeloEtapas;
    private modeloProveedor: ModeloProveedores;
    private modeloBodega: ModeloBodegas;
    private modeloArchivo: ModeloArchivo;
    private modeloAccionEtapaDocumento: ModeloAccionEtapaDocumento

    constructor() {
        this.modeloTipoDocumento = new ModeloTipoDocumento();
        this.modeloEtapa = new ModeloEtapas();
        this.modeloProveedor = new ModeloProveedores();
        this.modeloBodega = new ModeloBodegas();
        this.modeloArchivo = new ModeloArchivo();
        this.modeloAccionEtapaDocumento = new ModeloAccionEtapaDocumento();
    }

    public async consultarEntidadesDocumento(idEmpresa: string, idAgencia: string, idUsuario: string,estado?: number) {
        const respuestas = await Promise.all([
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa,  Estado: estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado: estado,
            }),
        ]);
        return {
            etapas: respuestas[0].data.Entidades,
            proveedores: respuestas[1].data.Entidades,
            bodegasDestino: respuestas[2].data.Entidades,
        };
    }

    public async consultarEntidadesDocumentoEdicion(
        idBodega: number,
        idEmpresa: string,
        idAgencia: string,
        idUsuario: string,
        tipoProcesoId: number,
        estado?: number
    ) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                Estado: estado,
                TipoProcesoId:tipoProcesoId
            }),
            this.modeloEtapa.ConsultarListaEtapasPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloProveedor.consultarListaProveedoresPorFiltro({ EmpresaId: idEmpresa,Estado:estado }),
            this.modeloBodega.consultarBodegasPersonasDoc({
                AgenciaId: idAgencia,
                UsuarioId: idUsuario,
                EmpresaId: idEmpresa,
                Estado:estado
            }),
        ]);
        return {
            tiposDocumento: respuestas[0].data.Entidades,
            etapas: respuestas[1].data.Entidades,
            proveedores: respuestas[2].data.Entidades,
            bodegasDestino: respuestas[3].data.Entidades,
        };
    }

    public async consultarTipoDocumento(idBodega: number,estado?: number) {
        const respuestas = await Promise.all([
            this.modeloTipoDocumento.consultarTiposDocumentoFiltro({
                BodegaId: idBodega,
                TipoProcesoId: TiposProceso.ORDEN_DE_ENTRADA,
                Estado: estado
            }),
        ]);
        return respuestas[0].data.Entidades;
    }

    public async guardarFirmaArchivo(archivoFirma: Partial<IArchivo>,
        archivo: File) {
        debugger
        const respuesta = await this.modeloArchivo.GuardarDocumentoEvidencia(archivoFirma, archivo);
         return respuesta;
    }

    public async consultarAccionEtapaDocumentoFiltro(idTipoDocumento: number, idEtapa: number) {
        const respuesta = await this.modeloAccionEtapaDocumento.consultarAccionesFiltroDocumento({ TipoDocumentoId: idTipoDocumento, EtapaId: idEtapa });
        return respuesta.data.Entidades;
    }
}

export default EntidadesDocumentoCM;
