import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

interface ICampoDivisionDestinoProps {
    deshabilitado: boolean;
    divisionesDestino?: IBodegaDivisiones[];
    manejarCambioCampo: (
        nombreCampo: keyof IDocumentoDetalle,
        nuevoValor: IDocumentoDetalle[keyof IDocumentoDetalle]
    ) => any;
    valor: number | undefined;
}

const CampoDivisionDestino: FunctionComponent<ICampoDivisionDestinoProps> = ({
    deshabilitado,
    divisionesDestino,
    manejarCambioCampo,
    valor,
}) => (
    <Autocomplete
        disabled={deshabilitado}
        onChange={(_: ChangeEvent<{}>, value: IBodegaDivisiones | null) =>
            manejarCambioCampo('DivisionDestino', value ? value.DivisionId : undefined)
        }
        options={divisionesDestino || []}
        getOptionLabel={(d) => d.DivisionDescripcion}
        renderInput={(params) => (
            <TextField
                {...params}
                required
                fullWidth
                label={<Texto id="DetallesFormulario.CampoDivisionDestino.Nombre" />}
            />
        )}
        value={divisionesDestino ? divisionesDestino.find((d) => d.DivisionId === valor) || null : null}
        id={'CampoDivisionDestino'}
    />
);

export default CampoDivisionDestino;
