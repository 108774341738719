import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral, {
    DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
} from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IBodega from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodega';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormularioTabla/DetallesFormulario';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import DetallesInventario from 'Infotrack@Transversales/componentes/DetallesInventario/DetallesInventario';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';
import FormularioDocumento, {
    IEntidadesFormularioDocumento,
} from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import TablaDetalles from 'Infotrack@Transversales/componentes/TablaDetalles/TablaDetalles';
import TablaDetallesPlantilla from 'Infotrack@Transversales/componentes/TablaDetallesProductos/TablaDetallesPlantilla';

import EntidadesDetalleCM from './controladorModelo/EntidadesDetalleCM';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import ItemCM from './controladorModelo/ItemCM';
import MovimientoCM from './controladorModelo/MovimientoCM';
import TrasladosCM from './controladorModelo/TrasladosCM';
import { EstadosModalTraslados } from './enumeraciones';
import {
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_EDITABLES_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_TABLA_INVENTARIO,
} from './constantes';
import DocumentoValorTotal from 'Infotrack@Transversales/componentes/DocumentoValorTotal/DocumentoValorTotal';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import DocumentoBuscador from 'Infotrack@Transversales/componentes/DocumentoBuscador/DocumentoBuscador';
import DocumentoFirma from 'Infotrack@Transversales/componentes/DocumentoFirma/DocumentoFirma';

interface IModalTrasladoProps {
    cerrar: () => void;
    documentoId: string | null;
    establecerDocumentoId: (documentoId: string) => void;
    estado: EstadosModalTraslados;
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
}

const ENTIDADES_DOCUMENTO_POR_DEFECTO: Partial<IEntidadesFormularioDocumento> = {
    bodegasDestino: [],
    etapas: [],
    proveedores: [],
    tiposDocumento: [],
};

const ModalTraslados: FunctionComponent<IModalTrasladoProps> = ({
    cerrar,
    documentoId,
    establecerDocumentoId,
    estado,
    descargarDocumentoPdf,
}) => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const trasladosCM = useMemo(() => new TrasladosCM(), []);
    const entidadesDetalleCM = useMemo(() => new EntidadesDetalleCM(), []);
    const itemCM = useMemo(() => new ItemCM(), []);
    const movimientoCM = useMemo(() => new MovimientoCM(), []);
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const usuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion);
    const idAplicacion = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdAplicacion);
    const [items, setItems] = useState<IItemsConsulta[]>([]);
    const [detalleInicialEdicion, setDetalleInicialEdicion] = useState<IDocumentoDetalle | null>(null);
    const [DocumentoDetallesBase, setDocumentoDetallesBase] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [documentoDetallesInventario, setDocumentoDetallesInventario] = useState<
        Partial<IDocumentoDetalleConsultaGeneral[]>
    >([]);
    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesFormularioDocumento>>(
        ENTIDADES_DOCUMENTO_POR_DEFECTO
    );
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [refrescarCampoDocumentoBase, setRefrescarCampoDocumentoBase] = useState<boolean>(false);
    const [entidadesDetalle, setEntidadesDetalle] = useState<{
        divisionesOrigen: IBodegaDivisiones[];
        divisionesDestino: IBodegaDivisiones[];
        bodegasOrigen: IBodega[];
    }>({ divisionesOrigen: [], bodegasOrigen: [], divisionesDestino: [] });
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [abrirModalActa, setAbrirModalActa] = useState<boolean>(false);
    const [etapaSeleccionada, setEtapaSeleccionada] = useState<number>(0);
    useEffect(() => {
        alCambiarEstado();
    }, [estado]);

    useEffect(() => {
        consultarDetalles();
        consultarDetallesDocumentoBase();
    }, [ejecutarConsultarDocumentos]);

    useEffect(() => {
        if (documento) {
            alEstablecerDocumento();
            consultarTipoDocumento(documento.BodegaOrigen!, 'BodegaOrigen');
        }
    }, [documento]);

    const alCambiarEstado = async () => {
        if (estado === EstadosModalTraslados.CERRADO) {
            setDocumento(null);
            setDetalles([]);
            setEntidadesDocumento(ENTIDADES_DOCUMENTO_POR_DEFECTO);
            setEtapasSiguientes([]);
            setEntidadesDetalle({ divisionesOrigen: [], bodegasOrigen: [], divisionesDestino: [] });
            setDetalleInicialEdicion(null);
            setDocumentoDetallesBase([]);
        }
        if (estado === EstadosModalTraslados.CREACION) {
            await consultarEntidadesDocumento();
            setDocumentoDetallesBase([]);
        }
        if (estado === EstadosModalTraslados.EDICION || estado === EstadosModalTraslados.VISUALIZACION) {
            await consultarDocumento(documentoId!);
        }
    };

    const consultarEntidadesDocumento = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil! !== "Administrador" ? idUsuario : "",
            estadoActivo
        );

        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarEntidadesDocumentoEdicion = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoEdicion(
            documento!.BodegaOrigen!,
            idEmpresa,
            idAgencia,
            TiposProceso.TRASLADO,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarItemsTabla = async (idItem: number) => {
        const items = await entidadesDetalleCM.consultarItem(
            idItem,
            idEmpresa
        );
        return items.itemsConsulta;
    };

    const consultarItems = async () => {
        const itemsConsultados = await itemCM.consultarListaItemsBodega(documento!.BodegaOrigen!);
        setItems(itemsConsultados);
    };

    const alEstablecerDocumento = async () => {
        if (estado === EstadosModalTraslados.CREACION) {
            if (documento!.DocumentoId) {
                establecerDocumentoId(documento!.DocumentoId);
                await consultarEtapasSiguientes();
                await consultarEntidadesDetalle();
                await consultarDetallesDocumentoBase();
                await consultarItems();
            }
        }
        if (estado === EstadosModalTraslados.EDICION) {
            await consultarEtapasSiguientes();
            await consultarEntidadesDetalle();
            await consultarDetallesDocumentoBase();
            await consultarItems();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
        if (estado === EstadosModalTraslados.VISUALIZACION) {
            await consultarEntidadesDetalle();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesDetalleCM.consultarEntidadesDetalle(
            documento!.BodegaDestino!,
            documento!.TipoDocumentoId,
            documento!.BodegaOrigen!,
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil! !== "Administrador" ? idUsuario : ""
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const consultarDetalles = async () => {
        const detallesConsultados = await trasladosCM.consultarDetallesDocumento({ DocumentoId: documentoId! });
        setDetalles(detallesConsultados);
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguientesConsultadas = await trasladosCM.consultarEtapas(documento!.DocumentoId);
        setEtapasSiguientes(etapasSiguientesConsultadas);
    };

    const crearDocumento = async (documentoCrear: IDocumento) => {
        if (trasladosCM.validarEntidad(documentoCrear)) {
            const respuesta = await trasladosCM.guardarEncabezado(mapearDocumentoCrear(documentoCrear));
            if (respuesta.Resultado) await consultarDocumento(respuesta.Entidades[0].DocumentoId);
        }
    };

    const mapearDocumentoCrear = (documentoCrear: IDocumento): IDocumento => {
        return {
            ...documentoCrear,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
    };

    const editarDocumento = async (documentoEditar: IDocumento) => {
        documentoEditar.ResolucionFacturacionId = undefined;
        const respuesta = await trasladosCM.editarEncabezado(documentoEditar);
        if (respuesta.Resultado) consultarDocumento(documento!.DocumentoId);
    };

    const cambiarEtapa = async (etapaId: number) => {
        if ( await consultasAccionesTipoDocumento(documento!.TipoDocumentoId,etapaId) === false ) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await trasladosCM.cambiarEtapa({ ...documento!, EtapaId: etapaId }, idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
            }
           
        } else {
            setEtapaSeleccionada(etapaId)
        }

    };

    const consultasAccionesTipoDocumento = async (idTipoDocumento: number, idEtapa: number) => {
        debugger
        const acciones = await entidadesDocumentoCM.consultarAccionEtapaDocumentoFiltro(idTipoDocumento, idEtapa);
        if (acciones[0].EtapaDescripcion ==="Finalizado" && acciones[0].GenerarActa) {
            setAbrirModalActa(true)
            return true;
        }
        else {
            return false;
        }
    }

    const confirmarCambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => cambiarEtapa(etapaId),
            estado: true,
            mensaje: <Texto id={'etapas.CambioEtapa'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const editarDetalle = async (detalle: IDocumentoDetalle) => {
        detalle.CantidadRecibida! = detalle.CantidadEntregada!;
        return trasladosCM.editarDetalle(detalle);
    };

    const eliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await trasladosCM.eliminarDetalle(detalle);
        if (respuesta) {
            alEliminarDetalle();
        }
    };

    const confirmarEliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarDetalle(detalle),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const consultarDetallesDocumentoBase = async () => {
        if (documento) {
            const detallesBase = await trasladosCM.consultarDetallesDocumento({
                DocumentoId: documento!.DocumentoPredecesorId,
            });
            setDocumentoDetallesBase(detallesBase.map(mapearDetallesDocumentoBase));
        }
    };

    const mapearDetallesDocumentoBase = (
        detalle: IDocumentoDetalleConsultaGeneral
    ): IDocumentoDetalleConsultaGeneral => {
        return {
            ...detalle,
            DivisionOrigen: detalle.DivisionOrigen ? detalle.DivisionOrigen : detalle.DivisionDestino,
            DescripcionDivisionOrigen: detalle.DivisionOrigen
                ? detalle.DescripcionDivisionOrigen
                : detalle.DescripcionDivisionDestino,
            CantidadEntregada: detalle.CantidadEntregada,
            PesoFraccionRecibido: detalle.PesoFraccionRecibido,
        };
    };

    const consultaDocumentoBase = async (codigoDocumentoBase: string) => {
        const documentoBase = await trasladosCM.consultarDocumentoBase({
            CodigoDocumento: codigoDocumentoBase,
            IdAgencia: usuario!.NombrePerfil!!=="Administrador"? idAgencia:"", 
            IdEmpresa: idEmpresa
        });
        if (documentoBase.length > 0) {
            setDocumento(mapearDocumentoBase(documentoBase[0]));
        }

        return documentoBase;
    };

    const consultarDocumento = async (documentoIdConsultar: string) => {
        const documentoConsultado = await trasladosCM.consultarEncabezadoDocumento({
            DocumentoId: documentoIdConsultar,
        });
        setDocumento(documentoConsultado);
    };

    const mapearDocumentoBase = (documentoBase: IDocumento): IDocumentoConsultaGeneral => {
        return {
            ...DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO,
            BodegaDestino: documentoBase.BodegaDestino,
            BodegaOrigen: documentoBase.BodegaOrigen,
            ClienteId: documentoBase.ClienteId,
            CodigoDocumentoPredecesor: documentoBase.CodigoDocumento,
            CondicionComercial: documentoBase.CondicionComercial,
            DireccionEntrega: documentoBase.DireccionEntrega,
            DocumentoPredecesorId: documentoBase.DocumentoId,
            DocumentoRelacionado: documentoBase.DocumentoRelacionado,
            FechaEntrega: documentoBase.FechaEntrega,
            Observacion: documentoBase.Observacion,
            ProveedorId: documentoBase.ProveedorId,
        };
    };

    const agregarDetalles = async (detallesAgregar: IDocumentoDetalle[]) => {
        const respuesta = trasladosCM.guardarDetalle(
            detallesAgregar.map((detalle) => ({
                ...detalle,
                DocumentoId: documento!.DocumentoId,
                Serial: detalle!.Serial?detalle!.Serial!: "" ,
            }))
        );
        await consultarDetallesDocumentoBase();
        await consultarDetalles();
        setEjecutarConsultarDocumentos((ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual);
        return respuesta;
    };

    const alEliminarDetalle = async () => {
        setDetalleInicialEdicion(null);
        await consultarDetallesDocumentoBase();
        await consultarDetalles();
    };

    const consultarTipoDocumento = async (idBodega: number, nombreNodega?: string) => {
        if (nombreNodega === 'BodegaOrigen') {
            const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega, estadoActivo);
            setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
        }
    };

    const consultarFirmas = () => { }

    const guardarFirmas = async (firma: File) => {

        const respuesta = await entidadesDocumentoCM.guardarFirmaArchivo({ IdAplicacion: idAplicacion, ExtensionArchivo: 2, NombreArchivo: firma.name, RutaAlmacenamiento: `${idAplicacion}/Firma/${documentoId}`, Identificador: documentoId! }, firma)
        if (respuesta) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await trasladosCM.cambiarEtapa({ ...documento!, EtapaId: etapaSeleccionada, PersonaRelacionada:idUsuario}, idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
                setAbrirModalActa(false);
            }
        }

    }

    const cerrarDocumentoFirma = () => {
        setAbrirModalActa(false)
    }

    return (
        <Dialog open={estado !== EstadosModalTraslados.CERRADO} maxWidth="lg">
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalTraslados.CREACION ? (
                        <Texto id="ModalTraslados.TituloCreacion" />
                    ) : estado === EstadosModalTraslados.EDICION ? (
                        <Texto id="ModalTraslados.TituloEdicion" />
                    ) : estado === EstadosModalTraslados.VISUALIZACION ? (
                        <Texto id="ModalTraslados.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {(!documento || !documento.DocumentoId) && (
                            <DocumentoBuscador
                                consultaDocumentoBase={consultaDocumentoBase}
                                refrescarCampo={refrescarCampoDocumentoBase}
                                esEntradaDevolucion={false}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormularioDocumento
                            cambiarEtapa={confirmarCambiarEtapa}
                            camposDeshabilitar={
                                documento && documento.DocumentoPredecesorId && !documento.DocumentoId
                                    ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_DOCUMENTO_BASE
                                    : documento && documento.DocumentoId
                                        ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION
                                        : CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO
                            }
                            camposOcultar={['ClienteId', 'ProveedorId']}
                            editarDocumento={editarDocumento}
                            etapasSiguientes={etapasSiguientes}
                            estado={
                                !documento || !documento.DocumentoId
                                    ? EstadosFormularioDocumento.CREACION
                                    : (estado === EstadosModalTraslados.CREACION && documento.DocumentoId) ||
                                        estado === EstadosModalTraslados.EDICION
                                        ? EstadosFormularioDocumento.EDICION
                                        : EstadosFormularioDocumento.VISUALIZACION
                            }
                            documento={documento}
                            entidadesDocumento={entidadesDocumento}
                            guardarDocumento={crearDocumento}
                            descargarDocumentoPdf={descargarDocumentoPdf}
                            consultarTipoDocumento={consultarTipoDocumento}
                            mostrarModalEvidencia={true}
                            esTraslado={true}
                        />
                    </Grid>
                    {documento && documento.DocumentoPredecesorId && (
                        <Grid item xs={12}>
                            <TablaDetallesPlantilla
                                agregarDetalles={agregarDetalles}
                                camposOcultar={CAMPOS_DESHABILITAR_TABLA_INVENTARIO}
                                camposEditables={['CantidadEntregada', 'DivisionDestino']}
                                deshabilitada={
                                    (estado === EstadosModalTraslados.CREACION && !documento) ||
                                    estado === EstadosModalTraslados.VISUALIZACION
                                }
                                tipoCantidad={'CantidadEntregada'}
                                detalles={DocumentoDetallesBase}
                                entidades={entidadesDetalle}
                                titulo={
                                    <Texto fontWeight="bold" id="ModalTraslados.TituloTablaDetallesDocumentoBase" />
                                }
                                seriadoConsulta={false}
                                manejaPeso={false}
                                mostrarDesplegable={true}
                                bodegaIdOrigen={documento.BodegaOrigen!}
                                alAgregarDetalles={consultarDetalles}
                            />
                        </Grid>
                    )}
                    {(!documento || !documento.DocumentoPredecesorId) && (
                        <Grid item xs={12}>
                            <DetallesInventario
                                agregarDetalles={agregarDetalles}
                                alAgregarDetalles={consultarDetalles}
                                consultarInventario={async (bodegaId: number, itemId: number) =>
                                    movimientoCM.stockItem(bodegaId, itemId)
                                }
                                deshabilitado={
                                    !documento ||
                                    documento.ListaAcciones.AfectaInventario ||
                                    estado === EstadosModalTraslados.VISUALIZACION
                                }
                                documento={documento}
                                items={items}
                                tipoCantidad={'CantidadEntregada'}
                                camposEditables={CAMPOS_EDITABLES_DETALLES_INVENTARIO}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_INVENTARIO}
                                entidades={entidadesDetalle}
                                recibeSerial={true}
                                seriadoConsulta={true}
                                esOrdenTraslado={false}
                                mostrarPesoCampoPeso={false}
                            />
                        </Grid>
                    )}
                    {detalleInicialEdicion && estado !== EstadosModalTraslados.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesFormulario
                                alEditarDetalle={async () => {
                                    setDetalleInicialEdicion(null);
                                    await consultarDetalles();
                                }}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_FORMULARIO}
                                deshabilitado={!detalleInicialEdicion}
                                camposDeshabilitar={[
                                    'ItemId',
                                    'DivisionOrigen',
                                    'Lote',
                                    'FechaVencimiento',
                                    'Serial',
                                    'PesoFraccionRecibido',
                                ]}
                                detalleInicialEdicion={detalleInicialEdicion}
                                documentoId={documento ? documento.DocumentoId : ''}
                                editarDetalle={editarDetalle}
                                entidades={entidadesDetalle}
                                estado={EstadosDetallesFormulario.EDICION}
                                tipoCantidad="CantidadEntregada"
                                seriadoConsulta={false}
                                esEntradaInventario={false}
                                bloquearCantidadSerial={true}
                                esOrdenTraslado={false}
                                consultarItems={consultarItemsTabla}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TablaDetalles
                            camposOcultar={['CantidadSolicitada', 'CantidadRecibida', 'PesoFraccionSolicitado']}
                            detalles={detalles}
                            editarDetalle={(detalle: IDocumentoDetalleConsultaGeneral) =>
                                setDetalleInicialEdicion(detalle)
                            }
                            editarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEditarDetalle : false
                            }
                            eliminarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEliminarDetalle : false
                            }
                            eliminarDetalle={confirmarEliminarDetalle}
                            mostrarAcciones={
                                estado === EstadosModalTraslados.CREACION || estado === EstadosModalTraslados.EDICION
                            }
                            recargarTabla={consultarDetalles}
                            titulo={<Texto fontWeight="bold" id="ModalTraslados.TituloTablaDetalles" />}
                        />
                    </Grid>
                    {abrirModalActa && (
                        <Grid item xs={12}>
                            <DocumentoFirma
                                consultarFirmas={consultarFirmas}
                                guardarFirmas={guardarFirmas}
                                idDocumento={documento ? documento.DocumentoId : ""}
                                cerrarDocumentoFirma={cerrarDocumentoFirma}
                            />
                        </Grid>)

                    }
                    <Grid item xs={12}>
                        <DocumentoValorTotal detalles={detalles} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default ModalTraslados;
