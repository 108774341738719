import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IProveedores from 'Infotrack@Modelos/smartStock/proveedores/entidades/proveedores';

interface ICampoProveedorIdProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    proveedores: IProveedores[];
    valor?: number;
}

const CampoProveedorId: FunctionComponent<ICampoProveedorIdProps> = ({ manejarCambioFiltro, proveedores, valor }) => (
    <Autocomplete
        getOptionLabel={(p) => p.DescripcionProveedor}
        onChange={(_: object, value: IProveedores | null) =>
            manejarCambioFiltro('ProveedorId', value ? value.ProveedorId : 0)
        }
        options={proveedores}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoProveedorId.Nombre" />} />
        )}
        value={proveedores.find((p) => p.ProveedorId === valor) || null}
        id={'CampoProveedor'}
    />
);

export default CampoProveedorId;
