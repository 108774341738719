import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import React from 'react';
import BodegasDivisionesCM from '../controladorModelo/bodegasDivisionesCM';
import { acciones, IEstadoGestionBodegas } from '../reductorGestionBodegas';
import TablaDivisionesBodegaVista from '../vista/tablaDivisionesBodegaVista';

const divisionesBodegaCM = new BodegasDivisionesCM();

const TablaDivisionesBodegaCV: React.FunctionComponent = () => {
    const [{ bodega, recargarTablaBodegaDivisiones }, dispatch] = useProveedor<IEstadoGestionBodegas>();
    const [divisiones, setDivisiones] = React.useState<IBodegaDivisiones[]>([]);

    React.useEffect(() => {
        if (recargarTablaBodegaDivisiones) {
            cargarDivisiones();
            dispatch({ type: acciones.RECARGAR_TABLA_BODEGA_DIVISIONES });
        }
    }, [recargarTablaBodegaDivisiones]);

    const alAbrirFormulario = (bodegaDivision: any) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DIVISIONES_BODEGA });
        dispatch({
            type: acciones.MODIFICAR_TIPO_FORMULARIO_BODEGA_DIVISION,
            payload: bodegaDivision ? TipoFormulario.Edicion : TipoFormulario.Creacion,
        });
        if (bodegaDivision) dispatch({ type: acciones.ESTABLECER_BODEGA_DIVISION, payload: bodegaDivision });
    };

    const cargarDivisiones = async () => {
        setDivisiones(await divisionesBodegaCM.consultarDivisionesFiltro({ BodegaId: Number(bodega.BodegaId) }));
    };

    const confirmarDivision = (division: IBodegaDivisiones) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarBodegaDivision(division),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const eliminarBodegaDivision = (division: IBodegaDivisiones) => {
        divisionesBodegaCM.eliminarDivisionesCompuesto(division).then(() => {
            cargarDivisiones();
        });
        manejadorDialogoGlobal({ estado: false });
    };
    

    return (
        <TablaDivisionesBodegaVista
            alAbrirFormulario={alAbrirFormulario}
            alEliminarDivisiones={confirmarDivision}
            alRecargar={cargarDivisiones}
            divisiones={divisiones}
        />
    );
};

export default TablaDivisionesBodegaCV;
