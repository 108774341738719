import { Card, CardContent, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { BotonBuscar, BotonLimpiar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaDevolucionesVistaProps } from '../interfaces';
import { acciones, columnas } from './constantes';

const TablaDevolucionesVista: React.FunctionComponent<ITablaDevolucionesVistaProps & InjectedIntlProps> = ({
    alAbrirFormulario,
    alCambiarValor,
    consultaDocumentos,
    consultarDocumentos,
    entidadesFiltros,
    intl: { formatMessage },
    refTabla,
    filtros,
}) => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container justifyContent="center" spacing={2}>
                        <Grid item md={true} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodega: any) =>
                                    alCambiarValor({ target: { name: 'BodegaOrigen', value: bodega } } as any)
                                }
                                options={entidadesFiltros.bodegas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={formatMessage({ id: 'devolucion.DescripcionBodega' })}
                                        required
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={filtros.BodegaOrigen!==null?filtros.BodegaOrigen:undefined}
                                id={'CampoBodegaOrigen'}
                            />
                        </Grid>
                        <Grid item md={true} xs={12}>
                            <TextField
                                fullWidth
                                label={<Texto id="devolucion.numeroDocumento" />}
                                name="CodigoDocumento"
                                onChange={alCambiarValor}
                                value={filtros.CodigoDocumento}
                                id={`CampoCodigoDocumento-${filtros.CodigoDocumento}`}
                            />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonLimpiar fullWidth onClick={() => consultarDocumentos(true)} />
                        </Grid>
                        <Grid item md="auto" xs={6}>
                            <BotonBuscar fullWidth onClick={() => consultarDocumentos()} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <Tabla
                actions={acciones(alAbrirFormulario, formatMessage)}
                columns={columnas(formatMessage)}
                data={consultaDocumentos}
                tableRef={refTabla}
            />
        </Grid>
    </Grid>
);

export default injectIntl(TablaDevolucionesVista);
