import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Texto from '@infotrack/presentacion-componentes/texto';

interface ICampoValorProps {
    deshabilitado: boolean;

    valor: number | undefined;
}

const CampoValor: FunctionComponent<ICampoValorProps> = ({ deshabilitado, valor }) => {
    return (
        <TextField
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="DetallesFormulario.CampoValor.Nombre" />}
            value={valor}
            InputLabelProps={{ shrink: true }}
            id={`CampoValor`}
        />
    );
};

export default CampoValor;
