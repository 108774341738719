import { Card, CardContent, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';

import IItemInventario from 'Infotrack@Modelos/negocioRefactor/entidades/negocio/IItemInventario';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import DetallesInventarioFiltroItem from './DetallesInventarioFiltroItem/DetallesInventarioFiltroItem';
import DetallesInventarioFiltroTabla from './DetallesInventarioFiltroTabla/DetallesInventarioFiltroTabla';
import DetallesInventarioTabla from './DetallesInventarioTabla/DetallesInventarioTabla';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';

interface IEntidadesTablaDetallesPlantilla {
    divisionesOrigen?: IBodegaDivisiones[];
    divisionesDestino?: IBodegaDivisiones[];
}

interface IDetallesInventarioProps {
    agregarDetalles: (detalles: IDocumentoDetalle[]) => Promise<boolean>;
    alAgregarDetalles?: () => any;
    consultarInventario: (bodegaId: number, itemId: number) => Promise<IItemInventario[]>;
    deshabilitado?: boolean;
    documento: IDocumento | null;
    items: IItemsConsulta[];
    entidades: Partial<IEntidadesTablaDetallesPlantilla>;
    tipoCantidad: 'CantidadSolicitada' | 'CantidadEntregada';
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    camposEditables?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    recibeSerial: boolean;
    seriadoConsulta: boolean;
    esOrdenTraslado: boolean;
    mostrarPesoCampoPeso:boolean;
}

const FILTRO_TABLA_POR_DEFECTO: IItemInventario = {
    CantidadActual: 0,
    DescripcionDivision: '',
    DescripcionItem: '',
    DivisionId: 0,
    ItemId: 0,
    Lote: '',
    FechaVencimiento: '',
    FechaIngreso: '',
    ItemInventarioId: 0,
    FechaMovimiento: '',
    
};

const DetallesInventario: FunctionComponent<IDetallesInventarioProps> = ({
    agregarDetalles,
    consultarInventario: consultarInventarioProp,
    deshabilitado = false,
    documento,
    items,
    tipoCantidad,
    entidades,
    camposEditables,
    camposOcultar,
    recibeSerial,
    seriadoConsulta,
    esOrdenTraslado,
    mostrarPesoCampoPeso
}) => {
    const idEmpresa = useSelector((estado: IEstadoGlobal) => estado.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((estado: IEstadoGlobal) => estado.estadoAutenticacion.agenciaActual!.IdAgencia);
    const [itemSeleccionado, setItemSeleccionado] = useState<IItemsConsulta | null>(null);
    const [filtroTabla, setFiltroTabla] = useState<IItemInventario>(FILTRO_TABLA_POR_DEFECTO);
    const [itemsInventario, setItemsInventario] = useState<IItemInventario[]>([]);
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);

    const manejadorCambioFiltro = (nombre: keyof IItemInventario, nuevoValor: any) => {
        setFiltroTabla({ ...filtroTabla, [nombre]: nuevoValor });
    };

    const consultarInventario = async () => {
        const itemsInventarioConsultados = await consultarInventarioProp(
            documento!.BodegaOrigen!,
            itemSeleccionado!.ItemId
        );
        debugger 
        setItemsInventario([]);
        setDetalles([]);
        setItemsInventario(itemsInventarioConsultados);
        setDetalles(itemsInventarioConsultados.map((d) => construirDetalle(d)));
    };

    const obtenerDivisiones = () => {
        const divisiones: Array<{ divisionId: number; divisionDescripcion: string }> = [];
        itemsInventario.forEach((itemInventario) => {
            if (!divisiones.find((d) => d.divisionId === itemInventario.DivisionId))
                divisiones.push({
                    divisionId: itemInventario.DivisionId,
                    divisionDescripcion: itemInventario.DescripcionDivision,
                });
        });
        return divisiones;
    };

    const agregarDetalle = async (detalles: IDocumentoDetalle[]) => {
        const creacionExitosa = await agregarDetalles(detalles);
        if (creacionExitosa) {
            setFiltroTabla(FILTRO_TABLA_POR_DEFECTO);
        }
        return creacionExitosa;
    };
    const construirDetalle = (itemInventario: IItemInventario): IDocumentoDetalleConsultaGeneral => {
        return {
            CodigoEmpresa: itemInventario.CodigoEmpresa ? itemInventario.CodigoEmpresa : '',
            DescripcionDivisionDestino: '',
            DescripcionItem: itemInventario.DescripcionItem,
            CodigoBarras: itemInventario.CodigoBarras ? itemInventario.CodigoBarras : '',
            CodigoDocumento: '',
            DescripcionDivisionOrigen: itemInventario.DescripcionDivision,
            DescripcionEstadoDetalle: '',
            DocumentoDetalleId: itemInventario.ItemInventarioId ? itemInventario.ItemInventarioId.toString() : '',
            DocumentoId: '',
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
            Estado: 1,
            Lote: itemInventario.Lote,
            Serial: '',
            FEFO: itemInventario.FEFO,
            FIFO: itemInventario.FIFO,
            Seriado: itemInventario.Seriado,
            Serialconsecutivo: itemInventario.Serialconsecutivo,
            CantidadEntregada: itemInventario.CantidadActual,
            ItemId: itemInventario.ItemId,
            DivisionOrigen: itemInventario.DivisionId,
            FechaVencimiento: itemInventario.FechaVencimiento
                ? moment(itemInventario.FechaVencimiento).format('YYYY-MM-DD')
                : '',
            FechaIngreso: itemInventario.FechaIngreso ? moment(itemInventario.FechaIngreso).format('YYYY-MM-DD') : '',
            Valor: itemInventario.Valor,
            PesoFraccionRecibido: itemInventario.PesoFraccion,
            ManejaPeso: itemInventario.ManejaPeso,
            CantidadDisponible:itemInventario.CantidadDisponible !== undefined? itemInventario.CantidadDisponible: itemInventario.CantidadActual,
            PesoDisponible:itemInventario.PesoDisponible !== undefined? itemInventario.PesoDisponible:itemInventario.PesoFraccion
        };
    };
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Texto id="DetallesInventario.Titulo" fontWeight="bold" />
                    </Grid>
                    <Grid item xs={12}>
                        <DetallesInventarioFiltroItem
                            aplicarFiltroItem={consultarInventario}
                            deshabilitado={deshabilitado}
                            items={items}
                            itemSeleccionado={itemSeleccionado}
                            seleccionarItem={(item: IItemsConsulta | null) => setItemSeleccionado(item)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <Grid container spacing={2}>
                                {!esOrdenTraslado && (
                                    <Grid item xs={12}>
                                        <DetallesInventarioFiltroTabla
                                            deshabilitados={deshabilitado}
                                            divisiones={obtenerDivisiones()}
                                            filtro={filtroTabla}
                                            manejadorCambioFiltro={manejadorCambioFiltro}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <DetallesInventarioTabla
                                        agregarDetalles={agregarDetalle}
                                        entidades={entidades}
                                        deshabilitada={deshabilitado}
                                        detalles={detalles.filter((i) => {
                                            return (
                                                (filtroTabla.Lote !== ''
                                                    ? i.Lote.toLowerCase().includes(filtroTabla.Lote.toLowerCase())
                                                    : true) &&
                                                (filtroTabla.FechaVencimiento !== ''
                                                    ? moment(i.FechaVencimiento).format('YYYY-MM-DD') ===
                                                      filtroTabla.FechaVencimiento
                                                    : true) &&
                                                (filtroTabla.DivisionId !== 0
                                                    ? i.DivisionOrigen === filtroTabla.DivisionId
                                                    : true)
                                            );
                                        })}
                                        tipoCantidad={tipoCantidad}
                                        camposEditables={camposEditables}
                                        camposOcultar={camposOcultar}
                                        titulo={'Detalles Inventario'}
                                        recibeSerial={recibeSerial}
                                        seriadoConsulta={seriadoConsulta}
                                        mostrarPesoCampoPeso={mostrarPesoCampoPeso}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DetallesInventario;
