import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { manejadorDialogoGlobal, manejadorIndicadorCargaGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';

import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import Documentos, { IEntidadesDocumentos } from 'Infotrack@Transversales/componentes/Documentos/Documentos';
import { EstadosModalArchivoPDF } from 'Infotrack@Transversales/componentes/archivoPDF/enumeraciones';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import GenerarPDF from 'Infotrack@Transversales/componentes/archivoPDF/GenerarPDF';

import { CAMPOS_OCULTAR_DOCUMENTOS_FILTROS, CAMPOS_OCULTAR_DOCUMENTOS_TABLA } from './constantes';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import SalidaInventarioCM from './controladorModelo/SalidaInventarioCM';
import { EstadosModalSalidaInventario } from './enumeraciones';
import ModalSalidaInventario from './ModalSalidaInventario';

const SalidaInventario = () => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const salidaInventarioCM = useMemo(() => new SalidaInventarioCM(), []);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const  usuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion);
    const idAplicacion = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdAplicacion);
    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesDocumentos>>({});
    const [estadoModal, setEstadoModal] = useState<EstadosModalSalidaInventario>(EstadosModalSalidaInventario.CERRADO);
    const [estadoArchivoPDF, setEstadoArchivoPDF] = useState<EstadosModalArchivoPDF>(EstadosModalArchivoPDF.CERRADO);
    const [documentoId, setDocumentoId] = useState<string | null>(null);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);
    const [archivoPDF, setArchivoPDF] = useState<string | null>(null);
    const [nombreDocumento, setNombreDocumento] = useState<string >("");

    const esPrimeraCarga = useRef(true);

    useEffect(() => {
        consultarEntidadesFiltros();
    }, []);

    useEffect(() => {
        if (esPrimeraCarga.current) {
            esPrimeraCarga.current = false;
        } else {
            if (estadoModal === EstadosModalSalidaInventario.CERRADO)
                setEjecutarConsultarDocumentos(
                    (ejecutarConsultarDocumentosActual) => !ejecutarConsultarDocumentosActual
                );
        }
    }, [estadoModal]);

    const consultarEntidadesFiltros = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil!!=="Administrador"? idUsuario:""
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };


    const generarArchivoPDF = async (documento: IDocumentoConsultaGeneral) => {
        manejadorIndicadorCargaGlobal(true);

        const respuestaArchivoPDF = await salidaInventarioCM.generarArchivoPDF(
            documento.DocumentoId,
            idAplicacion
        );
        setArchivoPDF(respuestaArchivoPDF);
        manejadorIndicadorCargaGlobal(false);
        setNombreDocumento(documento.CodigoDocumento)
        setEstadoArchivoPDF(EstadosModalArchivoPDF.VISUALIZACION)
    };
    

    const confirmarAnularDocumento = (documentoIdAnular: string) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => anularDocumento(documentoIdAnular),
            estado: true,
            mensaje: <Texto id={'MensajeSalidaInventario.confirmarAnularDocumento'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const anularDocumento = async (documentoIdAnular: string) => {
        const anuladoExitoso = await salidaInventarioCM.anularDocumento(documentoIdAnular);
        if (anuladoExitoso) {
            setEjecutarConsultarDocumentos(!ejecutarConsultarDocumentos);
            manejadorDialogoGlobal({ estado: false });
        }
    };

    const consultarTipoDocumento = async (idBodega: number) => {
        const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega);
        setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
    };

    return (
        <>
            <Documentos
                agregarDocumento={() => setEstadoModal(EstadosModalSalidaInventario.CREACION)}
                anularDocumento={confirmarAnularDocumento}
                camposOcultarFiltros={CAMPOS_OCULTAR_DOCUMENTOS_FILTROS}
                camposOcultarTabla={CAMPOS_OCULTAR_DOCUMENTOS_TABLA}
                consultarDocumentos={salidaInventarioCM.consultarListaDocumentos}
                editarDocumento={(nuevoDocumentoId: string) => {
                    setDocumentoId(nuevoDocumentoId);
                    setEstadoModal(EstadosModalSalidaInventario.EDICION);
                }}
                ejecutarConsultarDocumentos={ejecutarConsultarDocumentos}
                entidadesDocumento={entidadesDocumento}
                verDocumento={(nuevoDocumentoId: string) => {
                    setDocumentoId(nuevoDocumentoId);
                    setEstadoModal(EstadosModalSalidaInventario.VISUALIZACION);
                }}
                descargarDocumentoPdf={generarArchivoPDF}
                consultarTipoDocumentoBodega={consultarTipoDocumento}
                limpiarFiltroTipoDocumento={() => setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: [] })}
                esTraslado={false}
            />
            <ModalSalidaInventario
                cerrar={() => {
                    setEstadoModal(EstadosModalSalidaInventario.CERRADO), setDocumentoId(null);
                }}
                documentoId={documentoId}
                establecerDocumentoId={(nuevoDocumentoId: string) => setDocumentoId(nuevoDocumentoId)}
                estado={estadoModal}
                descargarDocumentoPdf={generarArchivoPDF}
                cambiarEstado={() => EstadosModalSalidaInventario.EDICION}
            />
            {archivoPDF && (
                <GenerarPDF
                    cerrar={() => setEstadoArchivoPDF(EstadosModalArchivoPDF.CERRADO)}
                    archivoPDF={archivoPDF}
                    estado={estadoArchivoPDF}
                    nombreDocumento={nombreDocumento}
                />
            )}
        </>
    );
};

export default SalidaInventario;
