const mensajes = {
    // TiposDocumentoTabla
    'TiposDocumentoTabla.Acciones': 'Acciones',
    'TiposDocumentoTabla.VerTipoDocumento': 'Ver tipo documento',
    'TiposDocumentoTabla.EditarTipoDocumento': 'Editar tipo documento',
    'TiposDocumentoTabla.EliminarTipoDocumento': 'Eliminar tipo documento',
    'TiposDocumentoTabla.TipoDocumentoDescripcion': 'Tipo documento',
    'TiposDocumentoTabla.Abreviatura': 'Abreviatura',
    'TiposDocumentoTabla.Consecutivo': 'Consecutivo',
    'TiposDocumentoTabla.SincronizaMovil': 'Sincroniza móvil',
    'TiposDocumentoTabla.Estado': 'Estado',
    'TiposDocumento.PlantillaImpresion.Nombre':'Plantilla',
    // ModalTipoDocumento
    'ModalTipoDocumento.TituloCreacion': 'Crear Tipo de Documento',
    'ModalTipoDocumento.TituloEdicion': 'Editar Tipo de Documento',
    'ModalTipoDocumento.TituloVisualizacion': 'Ver Tipo de Documento',
    'ModalTipoDocumento.BotonGuardar': 'Guardar',
    'ModalTipoDocumento.BotonEditar': 'Editar',
    'ModalTipoDocumento.EtapaDescripcion': 'Etapa',
    'ModalTipoDocumento.AfectaInventario': 'Afecta inventario',
    'ModalTipoDocumento.PermiteEditarDocumento': 'Permite editar documento',
    'ModalTipoDocumento.PermiteAnularDocumento': 'Permite anular documento',
    'ModalTipoDocumento.PermiteAgregarDetalle': 'Permite agregar detalle',
    'ModalTipoDocumento.PermiteEditarDetalle': 'Permite editar detalle',
    'ModalTipoDocumento.PermiteEliminarDetalle': 'Permite eliminar detalle',
    'ModalTipoDocumento.PermiteCambiarEtapa': 'Permite cambiar etapa',
    'ModalTipoDocumento.AccionMovil': 'Permite gestión móvil',
    'ModalTipoDocumento.EtapaMovil': 'Permite cambiar etapa móvil',
    'ModalTipoDocumento.Cancelar': 'Los datos no guardados se perderán, ¿Desea cerrar la ventana?',
    'ModalTipoDocumento.GenerarActa':'Generar Acta',
    // CampoTipoProcesoId
    'TiposDocumento.CampoTipoProcesoId.Nombre': 'Tipo de proceso',
    // CampoTipoDocumento
    'TiposDocumento.CampoTipoDocumentoDescripcion.Nombre': 'Descripción',
    // CampoAbreviatura
    'TiposDocumento.CampoAbreviatura.Nombre': 'Abreviatura',
    // CampoConsecutivo
    'TiposDocumento.CampoConsecutivo.Nombre': 'Consecutivo',
    // CampoFlujoId
    'TiposDocumento.CampoFlujoId.Nombre': 'Flujo',
    // CampoEstado
    'TiposDocumento.CampoEstado.Nombre': 'Estado',
    // CampoSincronizacionMovil
    'TiposDocumento.CampoSincronizacionMovil.Nombre': 'Sincronización Móvil',
};

export default mensajes;
