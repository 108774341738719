import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';
import ITipoProceso from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoProceso';

interface ICampoTipoProcesoIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    tiposProceso: ITipoProceso[];
    valor: number;
}

const CampoTipoProcesoId: FunctionComponent<ICampoTipoProcesoIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    tiposProceso,
    valor,
}) => (
    <TextField
        disabled={deshabilitado}
        fullWidth
        label={<Texto id="TiposDocumento.CampoTipoProcesoId.Nombre" />}
        onChange={(event) => manejarCambioCampo('TipoProcesoId', event.target.value)}
        required
        select
        value={valor || ''}
        id={`CampoTipoProceso`}
    >
        {tiposProceso.map((tipoProceso) => (
            <MenuItem key={tipoProceso.TipoProcesoId} value={tipoProceso.TipoProcesoId}>
                {tipoProceso.TipoProcesoDescripcion}
            </MenuItem>
        ))}
    </TextField>
);

export default CampoTipoProcesoId;
