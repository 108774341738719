import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';

import AuditoriaConteoDetalleCM from '../controladorModelo/AuditoriaConteoDetalleCM';

import ComparacionConteoDetallesTabla from './ComparacionConteoTabla';

import IAuditoriaConteoDetalleComparacion from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaConteoDetalleComparacion';
import { useQuery } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import { saveAs } from 'file-saver';
import AuditoriaConteoCM from '../controladorModelo/AuditoriaConteoCM';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

const CAMPOS_OCULTAR_CONTEO_DETALLES_TABLA: Array<keyof IAuditoriaConteoDetalleComparacion> = [
    'CantidadSegundoConteo',
    'DiferenciaStockSegundoConteo',
    'DiferenciaConteos',
];
export interface IModalConteoProps {
    idAuditoria: string;
}

const ModalConteo: React.FunctionComponent<IModalConteoProps> = ({ idAuditoria }) => {
    const auditoriaConteoDetalleCM = useMemo(() => new AuditoriaConteoDetalleCM(), []);
    const auditoriaConteoCM = useMemo(() => new AuditoriaConteoCM(), []);

    const [conteoDetallesComparacion, setConteoDetallesComparacion] = useState<IAuditoriaConteoDetalleComparacion[]>(
        []
    );

    const query = useQuery();
    const idPrimerConteo = useMemo(() => query.get('primerConteo'), []);
    const idSegundoConteo = useMemo(() => query.get('segundoConteo'), []);
    const [refrescarDetalles, setRefrescarDetalles] = useState<boolean>(false);
    const [dosConteosIniciales, setDosConteosIniciales] = useState<boolean>(false);
    const [descripcionPrimerConteo, setDescripcionPrimerConteo] = useState<string>('');
    const [descripcionSegundoConteo, setDescripcionSegundoConteo] = useState<string>('');

    useEffect(() => {
        consultarConteoDetalles();
    }, [idAuditoria]);

    useEffect(() => {
        if (refrescarDetalles) {
            setRefrescarDetalles(false);
        }
    }, [refrescarDetalles]);

    const consultarConteoDetalles = async () => {
        if (await consultarConteos()) {
            const conteoDetallesConsultados = await auditoriaConteoDetalleCM.compararConteos(idAuditoria);
            const dosConteos = (
                await auditoriaConteoCM.consultarListaConteo({ AuditoriaId: idAuditoria, IdTipoConteo: 1 })
            ).filter((x) => x.IdTipoConteo === 1);
            setDescripcionPrimerConteo(conteoDetallesConsultados[0].DescripcionPrimerConteo);
            setDescripcionSegundoConteo(
                dosConteos.length === 2 ? conteoDetallesConsultados[0].DescripcionSegundoConteo : ''
            );
            setConteoDetallesComparacion(conteoDetallesConsultados);
            setDosConteosIniciales(dosConteos.length === 1 ? false : true);
        }
    };

    const compararConteoUnico = async () => {
        const compararConteoUnico = await auditoriaConteoDetalleCM.compararConteoUnico(idPrimerConteo!);
        setDescripcionPrimerConteo(compararConteoUnico[0].DescripcionPrimerConteo);
        setConteoDetallesComparacion(compararConteoUnico);
    };

    const consultarConteos = async () => {
        const conteos = await auditoriaConteoCM.consultarListaConteo({ AuditoriaId: idAuditoria });
        if (conteos.length === 0) {
            notificacionGlobal('MensajeAuditoria.SinConteos', 6000, 'warning', true);
            return false;
        }
        return true;
    };

    const convertirArchivoDescargado = async () => {
        const byteArray = await auditoriaConteoDetalleCM.descargarComparacionConteo(idAuditoria);
        const blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, 'ComparacionConteo');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ComparacionConteoDetallesTabla
                    detallesConteo={conteoDetallesComparacion}
                    recargarTabla={consultarConteoDetalles}
                    camposOcultar={!idSegundoConteo ? CAMPOS_OCULTAR_CONTEO_DETALLES_TABLA : []}
                    descripcionPrimerConteo={descripcionPrimerConteo}
                    descripcionSegundoConteo={descripcionSegundoConteo}
                    descargarConteo={convertirArchivoDescargado}
                    dosConteosIniciales={dosConteosIniciales}
                />
            </Grid>
        </Grid>
    );
};

export default ModalConteo;
