import { negocioConfiguracion, negocioProceso } from '../../conexiones';
import IPlantilla from './entidades/IPlantilla';

export default class ModeloPlantilla {
    public DescargarPlantilla(plantilla: IPlantilla) {
        return negocioConfiguracion.post('ProcesarArchivo/DescargarPlantilla', plantilla, { responseType: 'arraybuffer' });
    }

    public DescargarPlantillaMaximoMinimo(plantilla: IPlantilla) {
        return negocioProceso.post('MaximoMinimo/DescargarPlantillaMaximoMinimo', plantilla, { responseType: 'arraybuffer' });
    }
}
