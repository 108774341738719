import React from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoAccion, TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IItemCompuesto from 'Infotrack@Modelos/smartStock/gestionProducto/entidades/itemCompuesto';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { IImagen } from 'Infotrack@Transversales/componentes/inputImagen/interfaces';
import EntidadesItemCM from '../controladorModelo/entidadesItemCM';
import GestionProductoCM from '../controladorModelo/gestionProductoCM';
import ItemImagenCM from '../controladorModelo/itemImagenCM';
import ItemPropiedadesCM from '../controladorModelo/itemPropiedadCM';
import ItemUnidadCM from '../controladorModelo/itemUnidadCM';
import { IEntidadesProducto, IFormularioGestionProductoCVProps, IInputsItem } from '../interfaces';
import { acciones, IEstadoGestionProducto } from '../reductorGestionProducto';
import FormularioGestionProductoVista from '../vista/formularioGestionProductoVista';
import IClasificaciones from 'Infotrack@Modelos/smartStock/clasificaciones/entidades/clasificaciones';
import IClasesTecnicas from 'Infotrack@Modelos/smartStock/clasesTecnicas/entidades/clasesTecnicas';
import IReferencias from 'Infotrack@Modelos/smartStock/referencias/entidades/referencias';
import IMarcas from 'Infotrack@Modelos/smartStock/marcas/entidades/marcas';
import IFabricantes from 'Infotrack@Modelos/smartStock/fabricantes/entidades/fabricantes';

const entidadesItemCM = new EntidadesItemCM();
const gestionProductoCM = new GestionProductoCM();
const itemUnidadCM = new ItemUnidadCM();
const itemPropiedadCM = new ItemPropiedadesCM();
const itemImagenCM = new ItemImagenCM();
const valorInicialFormulario: IInputsItem = {
    Abreviatura: '',
    Alto: 0,
    Ancho: 0,
    ClaseTecnicaId: '',
    ClasificacionId: '',
    CodigoBarras: '',
    Contenedor: false,
    DescripcionItem: '',
    ManejaPeso: false,
    EmpresaId: '',
    Estado: true,
    FabricanteId: '',
    FamiliaId: '',
    Importado: false,
    Largo: 0,
    Lotes: false,
    MarcaId: '',
    Modelo: '',
    Peso: 0,
    ReferenciaId: '',
    Seriado: false,
    Serialconsecutivo: false,
    UnidadId: '',
    FIFO: false,
    FEFO: false,
    RFId: '',
    Imagen: '',
    InventarioCritico:false,
    CodigoClasificacion:"",
};

const FormularioGestionProductoCV: React.FunctionComponent<IFormularioGestionProductoCVProps> = ({ IdEmpresa }) => {
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const [
        { estadoFormulario, item, unidades, tipoFormularioProducto, propiedades, imagenes },
        dispatch,
    ] = useProveedor<IEstadoGestionProducto>();
    const [entidadesProductos, setEntidadesProductos] = React.useState<IEntidadesProducto>({
        clasesTecnicas: [],
        clasificaciones: [],
        fabricantes: [],
        familias: [],
        marcas: [],
        referencias: [],
        unidades: [],
    });
    const { valor, setValor, reiniciar } = useInputState(valorInicialFormulario);

    React.useEffect(() => {
        cargarEntidadesFormulario();
    }, []);

    React.useEffect(() => {
        if (!estadoFormulario && imagenes.length > 0) dispatch({ type: acciones.CARGAR_IMAGENES, payload: [] });
    }, [estadoFormulario]);

    React.useEffect(() => {
        if (tipoFormularioProducto === TipoFormulario.Edicion) setValor(item);
    }, [item]);

    React.useEffect(() => {
        if (tipoFormularioProducto === TipoFormulario.Edicion) {
            cargarItemUnidades();
            cargarItemPropiedades();
            cargarItemImagenes();
            validarDatosInactivos();
        }
    }, [tipoFormularioProducto]);

    const cargarEntidadesFormulario = async () => {
        setEntidadesProductos(await entidadesItemCM.consultarEntidadItem(IdEmpresa));
        entidadesItemCM.consultarTipoPropiedadesItem(IdEmpresa);
    };

    const cargarItemUnidades = async () => {
        dispatch({
            type: acciones.CARGAR_UNIDADES,
            payload: await itemUnidadCM.consultarItemsUnidadesPorUnidad(item.ItemId!),
        });
    };

    const cargarItemPropiedades = async () => {
        dispatch({
            type: acciones.CARGAR_PROPIEDADES,
            payload: await itemPropiedadCM.consultarItemsPropiedades(item.ItemId!),
        });
    };

    const alAgregarImagenes = (nuevasImagenes: IImagen[]) => {
        dispatch({
            type: acciones.CARGAR_IMAGENES,
            payload: nuevasImagenes.map((img) => {
                const existeImagen = Boolean(
                    imagenes.find(({ DescripcionItemImagen }) => DescripcionItemImagen === img.propiedadesImagen.name)
                );
                return {
                    Accion: existeImagen ? TipoAccion.consulta : TipoAccion.guardado,
                    DescripcionItemImagen: img.propiedadesImagen.name,
                    ItemId: item.ItemId,
                    ImagenBase64: img.imagen,
                };
            }),
        });
    };

    const cargarItemImagenes = async () => {
        itemImagenCM.consultaItemImagenes(item.ItemId!).then((data) => {
            dispatch({
                type: acciones.CARGAR_IMAGENES,
                payload: data.map((entidad) => ({ ...entidad, ImagenBase64: entidad.ArchivoImagen })),
            });
        });
    };

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
        reiniciar();
    };

    const validarDatosInactivos = () => {
        if (
            entidadesProductos.familias &&
            !entidadesProductos.familias.find((x) => x.FamiliaId === parseInt(item.FamiliaId))
        ) {
            item.FamiliaId = '0';
        }
        if (
            entidadesProductos.unidades &&
            !entidadesProductos.unidades.find((x) => x.UnidadId === parseInt(item.UnidadId))
        ) {
            item.UnidadId = '0';
        }
        setValor(item);
    };

    const alCambiarValor = ({ target: { checked, name, type, value } }: React.ChangeEvent<HTMLInputElement>) => {
        const nuevoValor = type === 'checkbox' ? checked : value;
        setValor({
            ...valor,
            [name]: nuevoValor,
            Seriado: name === 'Seriado' ? valor.Serialconsecutivo == !nuevoValor : valor.Seriado,
            Serialconsecutivo: name === 'Serialconsecutivo' ? valor.Seriado == !nuevoValor : valor.Serialconsecutivo,
        });
        if (name === 'UnidadId' && value) seleccionarTipoUnidad(Number(value));
    };

    const seleccionarTipoUnidad = (idUnidad: number) => {
        const { TipoUnidadId } = entidadesProductos.unidades.find(({ UnidadId }) => UnidadId === idUnidad)!;
        dispatch({ type: acciones.SELECCIONAR_TIPO_UNIDAD, payload: TipoUnidadId });
    };

    const confirmarCreacionEdicion = () => {
        if (gestionProductoCM.validarEntidad(mapearDatos())) {
            const esEdicion = tipoFormularioProducto === TipoFormulario.Edicion;
            manejadorDialogoGlobal({
                accionConfirmar: () => alEditarGuardarProducto(esEdicion),
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                estado: true,
                titulo: <Texto id="titulo.advertencia" />,
                tipoDialogo: 'Advertencia',
                mensaje: <Texto id={`${esEdicion ? 'alerta.confirmacionEdicion' : 'alerta.confirmacionCreacion'}`} />,
                mostrarCancelar: true,
            });
        }
    };

    const alEditarGuardarProducto = (esEdicion: boolean) => {
        manejadorDialogoGlobal({ estado: false });
        const funcionAEjecutar = esEdicion
            ? gestionProductoCM.editarItemUnidades
            : gestionProductoCM.guardarItemUnidades;
        funcionAEjecutar(mapearDatos()).then(() => {
            alCerrarFormulario();
            dispatch({ type: acciones.RECARGAR_TABLA });
            reiniciar();
        });
    };

    const mapearDatos = (): IItemCompuesto => {
        const {
            Alto,
            Ancho,
            ClaseTecnicaId,
            ClasificacionId,
            Estado,
            FabricanteId,
            FamiliaId,
            Largo,
            MarcaId,
            Peso,
            ReferenciaId,
            UnidadId,
            Valor,
            ManejaPeso,
            InventarioCritico,
            CodigoClasificacion
        } = valor;
        return {
            Item: {
                ...valor,
                Alto: Number(Alto) / 100,
                Ancho: Number(Ancho) / 100,
                Largo: Number(Largo) / 100,
                Peso: Number(Peso),
                ClasificacionId: ClasificacionId && ClasificacionId !== '' ? Number(ClasificacionId) : null,
                ClaseTecnicaId: ClaseTecnicaId && ClaseTecnicaId !== '' ? Number(ClaseTecnicaId) : null,
                Estado: Estado ? 1 : 0,
                FabricanteId: FabricanteId && FabricanteId !== '' ? Number(FabricanteId) : null,
                FamiliaId: Number(FamiliaId),
                EmpresaId: IdEmpresa,
                MarcaId: MarcaId && MarcaId !== '' ? Number(MarcaId) : null,
                ReferenciaId: ReferenciaId && ReferenciaId !== '' ? Number(ReferenciaId) : null,
                UnidadId: Number(UnidadId),
                AgenciaId: idAgencia,
                Valor: Number(Valor),  
                ManejaPeso: ManejaPeso,
                InventarioCritico: InventarioCritico?InventarioCritico: false,
                CodigoClasificacion:CodigoClasificacion,
            },
            Unidades: unidades.map((unid) => ({
                ...unid,
                UnidadId: Number(unid.Unidad ? unid.Unidad.UnidadId : 0),
                Estado: Number(unid.Estado),
                EmpresaId: IdEmpresa,
                Alto: Number(unid.Alto),
                Ancho: Number(unid.Ancho),
                Largo: Number(unid.Largo),
                Peso: Number(unid.Peso),
            })),
            Propiedades: propiedades.map((prop) => ({
                ...prop,
                ItemPropiedadId: Number(prop.ItemPropiedadId),
                Cantidad: Number(prop.Cantidad),
                Estado: Estado ? 1 : 0,
                EmpresaId: IdEmpresa,
                TipoPropiedadId: prop.TipoPropiedad ? Number(prop.TipoPropiedad.ItemTipoPropiedadId) : 0,
            })),
            Imagenes: imagenes.map((img) => ({
                ...img,
                ItemImagenId: Number(img.ItemImagenId),
                DescripcionItemImagen: img.DescripcionItemImagen,
                ArchivoImagen: img.ImagenBase64.slice(23),
                RutaImagen: img.RutaImagen,
            })),
        };
    };

    const alEliminarImagen = (nombreImagen: string) => {
        dispatch({ type: acciones.ELIMINAR_ITEM_IMAGENES, payload: nombreImagen });
    };

    const obtenerImagenes = () =>
        imagenes
            .filter(({ Accion }) => Accion !== TipoAccion.eliminado)
            .map(
                (img) =>
                    ({
                        imagen: img.ImagenBase64,
                        propiedadesImagen: { name: img.DescripcionItemImagen },
                    } as any)
            );
    const cambiarValorAutoCompleteClasificacion = (nuevoValor: IClasificaciones) => {
        setValor({
            ...valor,
            ClasificacionId: nuevoValor !== null ? nuevoValor.ClasificacionId!.toString() : '',
        });
    };
    const cambiarValorAutoCompleteClaseTecnica = (nuevoValor: IClasesTecnicas) => {
        setValor({
            ...valor,
            ClaseTecnicaId: nuevoValor !== null ? nuevoValor.ClaseTecnicaId!.toString() : '',
        });
    };
    const cambiarValorAutoCompleteReferencia = (nuevoValor: IReferencias) => {
        setValor({
            ...valor,
            ReferenciaId: nuevoValor !== null ? nuevoValor.ReferenciaId!.toString() : '',
        });
    };

    const cambiarValorAutoCompleteMarca = (nuevoValor: IMarcas) => {
        setValor({
            ...valor,
            MarcaId: nuevoValor !== null ? nuevoValor.MarcaId!.toString() : '',
        });
    };

    const cambiarValorAutoCompleteFabricante = (nuevoValor: IFabricantes) => {
        setValor({
            ...valor,
            FabricanteId: nuevoValor !== null ? nuevoValor.FabricanteId!.toString() : '',
        });
    };

    return (
        <FormularioGestionProductoVista
            alAgregarImagenes={alAgregarImagenes}
            alCambiarValor={alCambiarValor}
            alCerrarFormulario={alCerrarFormulario}
            alEditarGuardarProducto={confirmarCreacionEdicion}
            alEliminarImagen={alEliminarImagen}
            entidadesProducto={entidadesProductos}
            estado={estadoFormulario}
            item={valor}
            imagenes={obtenerImagenes()}
            tipoFormulario={tipoFormularioProducto}
            cambiarValorAutoCompleteClasificacion={cambiarValorAutoCompleteClasificacion}
            cambiarValorAutoCompleteClaseTecnica={cambiarValorAutoCompleteClaseTecnica}
            cambiarValorAutoCompleteReferencia={cambiarValorAutoCompleteReferencia}
            cambiarValorAutoCompleteMarca={cambiarValorAutoCompleteMarca}
            cambiarValorAutoCompleteFabricante={cambiarValorAutoCompleteFabricante}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion },
}: IEstadoGlobal): IFormularioGestionProductoCVProps => ({ IdEmpresa: usuarioInformacion!.IdEmpresa });

export default connect<IFormularioGestionProductoCVProps, null, any, IEstadoGlobal>(
    estadoAPropiedades,
    null
)(FormularioGestionProductoCV);
