export default{
    "DocumentoFirma.CargarFirma":"Document Signature",
    "DocumentoFirma.BotonFirmar":"Sign Document",
    "DocumentoFirma.CargarFirmar":"Load Signature",
    "DocumentoFirma.FirmaCargada":"Loaded Signature",
    "DocumentoFirma.FirmaObligatoria":"Please enter a signature.",
    "DocumentoFirma.ArchivoNoValido":"Invalid file",
    "DocumentoFirma.BorrarFirma":"Delete signature"
    

}