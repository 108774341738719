import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from 'Infotrack@Modelos/conexiones';
import IFamilia from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IFamilia';

class ModeloFamilia {
    private NOMBRE_CONTROLADOR = 'Familia';

    public async consultarListaFamilia() {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFamilia`),
            true
        );
    }

    public crearFamilias(familia: IFamilia) {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/GuardarFamilia`, familia),
            true
        );
    }

    public editarFamilias(familia: IFamilia) {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.put(`${this.NOMBRE_CONTROLADOR}/EditarFamilia`, familia),
            true
        );
    }

    public eliminarFamilias(familia: IFamilia) {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.delete(`${this.NOMBRE_CONTROLADOR}/EliminarFamilia`, familia),
            true
        );
    }

    public consultarListaFamiliasPorFiltro(familia: Partial<IFamilia>) {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, familia),
            true
        );
    }

    public consultarListaFamiliasBodegas(idBodega : number) {
        return manejadorRest<IRespuesta<IFamilia>>(
            () => negocioConfiguracion.get(`${this.NOMBRE_CONTROLADOR}/ConsultarInventarioBodegaFamilia/${idBodega}`),
            true
        );
    }
}

export default ModeloFamilia;
