import React from 'react';
import { connect, useSelector } from 'react-redux';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';
import Texto from '@infotrack/presentacion-componentes/texto';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';

import IBodegaPropiedades from 'Infotrack@Modelos/smartStock/bodegas/entidades/bodegaPropiedades';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

import BogedaCM from '../controladorModelo/bogedaCM';
import BodegasPropiedadesCM from '../controladorModelo/bodegaPropiedades';
import EntidadesBodegaCM from '../controladorModelo/entidadesBodegaCM';
import { IDatosGeneralesCVProps, IInputsBodega } from '../interfaces';
import { acciones, IEstadoGestionBodegas } from '../reductorGestionBodegas';
import DatosGeneralesVista from '../vista/datosGeneralesVista';

const entidadesBodegaCM = new EntidadesBodegaCM();
const bogedaCM = new BogedaCM();
const bodegasPropiedadesCM = new BodegasPropiedadesCM();

const DatosGeneralesCV: React.FunctionComponent<IDatosGeneralesCVProps> = ({ IdAgencia, IdEmpresa }) => {
    const listaAgencias = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.ListaAgencias
    );

    const [{ bodega, bodegas, propiedadesBodegas, tipoFormulario, tiposBodegas, municipios,agencias }, dispatch] = useProveedor<
        IEstadoGestionBodegas
    >();

    const { setValor, valor } = useInputState<IInputsBodega>(bodega);

    React.useEffect(() => {
        cargarEntidadesFormulario();
    }, []);

    React.useEffect(() => {
        validarDatosInactivos();
    }, [tiposBodegas]);

    React.useEffect(() => {
        if (bodega) setValor(bodega);
    }, [bodega]);

    React.useEffect(() => {
        if (valor.BodegaPadre) setValor({ ...valor, BodegaPadreId: valor.BodegaPadre.BodegaId });
    }, [valor.BodegaPadre]);

    React.useEffect(() => {
        if (tipoFormulario === TipoFormulario.Edicion && municipios.length > 0) {
            const bodegaPadre = bodegas.find(({ BodegaId }) => bodega.BodegaPadreId === BodegaId);
            const ciudad = municipios.find(({ IdMunicipio }) => bodega.CiudadId === IdMunicipio);
            valor.BodegaPadre = bodegaPadre || null;
            valor.Ciudad = ciudad || null;
            setValor(valor);
            dispatch({
                type: acciones.MODIFICAR_BODEGA,
                payload: valor,
            });
        }
    }, [bodegas, municipios]);

    const validarDatosInactivos = () => {
        if (
            tiposBodegas &&
            tiposBodegas.find((x) => x.TipoBodegaId === parseInt(bodega.TipoBodegaId) && x.Estado === 0)
        ) {
            bodega.TipoBodegaId = '0';
        }
        if (bodegas.find((x) => x.BodegaId === bodega.BodegaPadreId && x.Estado === 0)) {
            bodega.BodegaPadreId = 0;
        }
        setValor(bodega);
    };

    const alCambiarValor = ({
        target,
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { checked, name, type, value } = target as EventTarget & HTMLInputElement;
        const valorACAmbiar = type === 'checkbox' ? checked : value;
        setValor({ ...valor, [name]: valorACAmbiar });
    };

    const alCambiarValorAutocomplete = (nuevoValor: IInputsBodega[keyof IInputsBodega], nombreValor: string) => {
        setValor({ ...valor, [nombreValor]: nuevoValor });
    };

    const cargarEntidadesFormulario = () => {
        setTimeout(() => {
            entidadesBodegaCM
                .consultarEntidadBodega(IdEmpresa, IdAgencia)
                .then(({ bodegas: bodegasCargadas, municipios: municipiosCargados, tiposBodega, agencias }) => {
                    dispatch({ type: acciones.ESTABLECER_BODEGAS, payload: bodegasCargadas });
                    dispatch({ type: acciones.ESTABLECER_TIPOS_BODEGAS, payload: tiposBodega });
                    dispatch({ type: acciones.ESTABLECER_MUNICIPIOS, payload: municipiosCargados });
                    dispatch({ type: acciones.ESTABLECER_AGENCIAS, payload: agencias });
                });
        }, 500);
    };

    const confirmarCreacionEdicion = () => {
        if (bogedaCM.validarEntidad(mapearDatos().Bodegas)) {
            const esEdicion = tipoFormulario === TipoFormulario.Edicion;
            manejadorDialogoGlobal({
                accionConfirmar: () => alCrearEditarBodega(esEdicion),
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                estado: true,
                titulo: <Texto id="titulo.advertencia" />,
                tipoDialogo: 'Advertencia',
                mensaje: <Texto id={`${esEdicion ? 'alerta.confirmacionEdicion' : 'alerta.confirmacionCreacion'}`} />,
                mostrarCancelar: true,
            });
        }
    };

    const alCrearEditarBodega = (esEdicion: boolean) => {
        if (esEdicion)
            bogedaCM.editarBodegaCompuesta(mapearDatos()).then((respuesta) => {
                if (respuesta.data.Resultado) {
                    dispatch({ type: acciones.RECARGAR_TABLA });
                    dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO });
                }
                manejadorDialogoGlobal({ estado: false });
            });
        else
            bogedaCM.guardarBodegaCompuesta(mapearDatos()).then((repuesta) => {
                if (repuesta.data.Resultado) {
                    const { BodegaId, AgenciaId } = repuesta.data.Entidades[0].Bodegas;
                    valor.BodegaId = BodegaId;
                    setValor(valor);
                    dispatch({ type: acciones.MODIFICAR_BODEGA, payload: valor });
                    dispatch({ type: acciones.MODIFICAR_TIPO_FORMULARIO, payload: TipoFormulario.Edicion });
                    dispatch({ type: acciones.CAMBIAR_ESTADO_BODEGA_CREADA });
                    dispatch({ type: acciones.RECARGAR_TABLA_DIVISIONES_POR_DEFECTO });
                    dispatch({ type: acciones.RECARGAR_TABLA });
                    dispatch({ type: acciones.RECARGAR_ENTIDADES_DIVISIONES_POR_DEFECTO });
                    bodegasPropiedadesCM
                        .consultaDivisionPropiedadesCompuestaFiltro({
                            BodegaId: Number(BodegaId),
                        })
                        .then((respuestaProp) => {
                            dispatch({
                                type: acciones.MODIFICAR_PROPIEDADES_BODEGA,
                                payload: respuestaProp,
                            });
                        });
                }
                manejadorDialogoGlobal({ estado: false });
            });
    };

    const mapearDatos = (): IBodegaPropiedades => {
        const {
            Alto,
            Ancho,
            BodegaCodigo,
            BodegaDescripcion,
            BodegaPadre,
            Ciudad,
            Estado,
            Largo,
            Latitud,
            Longitud,
            TipoBodegaId,
            Direccion,
            AgenciaId,
        } = valor;
        return {
            Bodegas: {
                ...valor,
                Alto: Number(Alto),
                Ancho: Number(Ancho),
                BodegaCodigo,
                BodegaDescripcion,
                BodegaPadreId: BodegaPadre ? Number(BodegaPadre.BodegaId) : null,
                CiudadId: Ciudad ? Number(Ciudad.IdMunicipio) : 0,
                Estado: Estado ? 1 : 0,
                EmpresaId: IdEmpresa,
                Largo: Number(Largo),
                Latitud: Number(Latitud),
                Longitud: Number(Longitud),
                TipoBodegaId: Number(TipoBodegaId),
                Direccion,
                AgenciaId: AgenciaId,
            },
            BodegasPropiedades: propiedadesBodegas.map((bodegaProps) => ({
                ...bodegaProps,
                Accion: bodegaProps.Accion,
                BodegaPropiedadTexto: bodegaProps.BodegaPropiedadTexto,
                Cantidad: Number(bodegaProps.Cantidad),
                EmpresaId: IdEmpresa,
                Estado: bodegaProps.Estado ? 1 : 0,
                FamiliaId: Number(bodegaProps.FamiliaId),
                ItemId: Number(bodegaProps.ItemId),
                TipoPropiedadId: Number(bodegaProps.TipoPropiedadId),
                UnidadId: Number(bodegaProps.UnidadId),
            })),
        };
    };

    return (
        <DatosGeneralesVista
            alCambiarValor={alCambiarValor}
            alCambiarValorAutocomplete={alCambiarValorAutocomplete}
            alCrearEditarBodega={confirmarCreacionEdicion}
            bodega={valor}
            bodegas={bodegas}
            municipios={municipios}
            tiposBodega={tiposBodegas}
            tipoFormulario={tipoFormulario}
            listaAgencias={agencias}
        />
    );
};

const estadoAPropiedades = ({
    estadoAutenticacion: { usuarioInformacion, agenciaActual },
}: IEstadoGlobal): IDatosGeneralesCVProps => ({
    IdAgencia: agenciaActual!.IdAgencia,
    IdEmpresa: usuarioInformacion!.IdEmpresa,
});

export default connect<IDatosGeneralesCVProps, null, any, IEstadoGlobal>(estadoAPropiedades, null)(DatosGeneralesCV);
