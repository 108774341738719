const mensajes = {
    // DetallesInventario
    'DetallesInventario.Titulo': 'Inventario',
    // CampoItemCodigoBarras
    'DetallesInventario.CampoItemCodigoBarras.Nombre': 'Código barras',
    // CampoItemDescripcion
    'DetallesInventario.CampoItemDescripcion.Nombre': 'Descripción',
    // DetallesInventarioFiltroTabla
    'DetallesInventarioFiltroTabla.Filtros': 'Filtros',
    'DetallesInventarioFiltroTabla.Lote': 'Lote',
    'DetallesInventarioFiltroTabla.FechaVencimiento': 'Fecha vencimiento',
    'DetallesInventarioFiltroTabla.DivisionId': 'División origen',
    // DetallesInventarioTabla
    'DetallesInventarioTabla.DescripcionItem': 'Ítem',
    'DetallesInventarioTabla.DescripcionDivision': 'División origen',
    'DetallesInventarioTabla.CantidadActual': 'Cantidad',
    'DetallesInventarioTabla.Lote': 'Lote',
    'DetallesInventarioTabla.FechaVencimiento': 'Fecha vencimiento',
    'DetallesInventarioTabla.Peso': 'El ítem necesita un valor de peso',
    'DetallesInventario.CampoItemCodigoEmpresa':'Código producto'
};

export default mensajes;
