import { ServiciosRest } from '@infotrack/utilitarios/servicios';
import { obtenerEstado } from 'Infotrack@Reductores/index';

const URL_SERVICIO_SEGURIDAD = process.env.REACT_APP_URL_SEGURIDAD;
const URL_SERVICIO_NEGOCIO = process.env.REACT_APP_URL_NEGOCIO;
const URL_SERVICIO_REPORTES = process.env.REACT_APP_URL_REPORTE;
const URL_MIDDLEWARE_COIMPRESORES = process.env.REACT_APP_URL_MIDDLEWARE_COIMPRESORES;
const URL_NEGOCIO_COIMPRESORES = process.env.REACT_APP_URL_NEGOCIO_COIMPRESORES;
const URL_API_CATALOGO = process.env.REACT_APP_URL_CATALOGO;
const URL_API_WEBHOOK = process.env.REACT_APP_URL_WEBHOOK;
const URL_SERVICIO_NEGOCIO_CONFIGURACION = process.env.REACT_APP_URL_NEGOCIO_CONFIGURACION;
const URL_SERVICIO_NEGOCIO_PROCESOS = process.env.REACT_APP_URL_NEGOCIO_PROCESOS;
const URL_SERVICIO_ARCHIVO = process.env.REACT_APP_URL_ARCHIVOS;

const seguridad = new ServiciosRest(URL_SERVICIO_SEGURIDAD!, '_1nf0tr4ck*');
const seguridadToken = new ServiciosRest(URL_SERVICIO_SEGURIDAD!, '_1nf0tr4ck*', obtenerEstado);
const reportes = new ServiciosRest(URL_SERVICIO_REPORTES!);
const middlewareCoimpresores  = new ServiciosRest(URL_MIDDLEWARE_COIMPRESORES!,undefined, obtenerEstado);
const urlNegocioCoimpresores = new ServiciosRest(URL_NEGOCIO_COIMPRESORES!,undefined, obtenerEstado);
const apiCatalogoToken = new ServiciosRest(URL_API_CATALOGO!, undefined, obtenerEstado);
const apiWebhookToken = new ServiciosRest(URL_API_WEBHOOK!, undefined, obtenerEstado);
const negocioConfiguracion = new ServiciosRest(URL_SERVICIO_NEGOCIO_CONFIGURACION!, undefined, obtenerEstado);
const negocioProceso = new ServiciosRest(URL_SERVICIO_NEGOCIO_PROCESOS!, undefined, obtenerEstado);
const servicioArchivos = new ServiciosRest(URL_SERVICIO_ARCHIVO!, undefined, obtenerEstado);

export { URL_SERVICIO_NEGOCIO, seguridad,  seguridadToken,reportes,middlewareCoimpresores,urlNegocioCoimpresores, apiCatalogoToken, apiWebhookToken,negocioConfiguracion,negocioProceso,servicioArchivos };
