import React, { FunctionComponent, RefObject } from 'react';

import { TextField } from '@material-ui/core';

import Texto from '@infotrack/presentacion-componentes/texto';
import { Autocomplete } from '@material-ui/lab';

interface IInputSerialProps {
    alEnviar: (indice: number) => void;
    deshabilitado: boolean;
    indice: number;
    manejarCambio: (indice: number, nuevoValor: string) => void;
    referencia: RefObject<unknown>;
    serialRepetido: boolean;
    valor: string;
    listaSeriales: string[];
}

const InputSerial: FunctionComponent<IInputSerialProps> = ({
    alEnviar,
    deshabilitado,
    indice,
    manejarCambio,
    referencia,
    serialRepetido,
    valor,
    listaSeriales,
}) => {
    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                alEnviar(indice);
            }}
        >
            <Autocomplete
                onChange={(event: any, newValue: string | null) => {
                    manejarCambio(indice, newValue!);
                }}
                disableClearable
                options={listaSeriales}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        fullWidth
                        required
                        label={<Texto display="inline" id="InputSerial.Serial" />}
                        error={serialRepetido}
                        disabled={deshabilitado}
                        helperText={serialRepetido ? <Texto id="InputSerial.ErrorSerialRepetido" /> : undefined}
                    />
                )}
                noOptionsText={<Texto id="label.sinRegistros" />}
                value={valor}
                id={'CampoInputSerial'}
            />
        </form>
    );
};

export default InputSerial;
