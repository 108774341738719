import IAccionEtapaDocumento from '../repositorio/IAccionEtapaDocumento';
import IDocumento from '../repositorio/IDocumento';

interface IDocumentoConsultaGeneral extends IDocumento {
    DescripcionTipoDocumentoId: string;
    DescripcionEtapaId: string;
    DescipcionProveedorId: string;
    DescripcionBodegaOrigen: string;
    DescripcionBodegaDestino: string;
    CodigoDocumentoPredecesor: string;
    DescripcionClienteId: string;
    DescripcionEstado: string;
    ListaAcciones: IAccionEtapaDocumento;
    PlantillaImpresionId: number;
    OrigenExterno?:boolean;
    Serial?:string;
    NombreProducto?:string;
}

const DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO: IDocumentoConsultaGeneral = {
    CodigoDocumento: '',
    CodigoDocumentoPredecesor: '',
    CondicionComercial: '',
    DescipcionProveedorId: '',
    DescripcionBodegaDestino: '',
    DescripcionBodegaOrigen: '',
    DescripcionClienteId: '',
    DescripcionEstado: '',
    DescripcionEtapaId: '',
    DescripcionTipoDocumentoId: '',
    DireccionEntrega: '',
    DocumentoId: '',
    DocumentoRelacionado: '',
    Estado: 0,
    EtapaId: 0,
    FechaCreacion: '',
    FlujoId: 0,
    ListaAcciones: {
        AccionEtapaDocumentoId: 0,
        AfectaInventario: false,
        Estado: 0,
        EtapaId: 0,
        PermiteAgregarDetalle: true,
        PermiteAnularDocumento: true,
        PermiteCambiarEtapa: true,
        PermiteEditarDetalle: true,
        PermiteEditarDocumento: true,
        PermiteEliminarDetalle: true,
        AccionMovil: false,
        EtapaMovil: false,
        TipoDocumentoId: 0,
        GenerarActa:false
    },
    Observacion: '',
    PersonaRelacionada: '',
    SincronizacionMovil: false,
    TipoDocumentoId: 0,
    PlantillaImpresionId: 0,
};

export { DOCUMENTO_CONSULTA_GENERAL_POR_DEFECTO };

export default IDocumentoConsultaGeneral;
