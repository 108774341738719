import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import IDocumento from 'Infotrack@Modelos/smartStock/documentos/entidades/documento';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { BotonAgregar, BotonRefrescar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const accionesTablaDespachos = (
    alAbrirFormulario: (documento?: IDocumentoCompuestoMovimiento) => void,
    alAnularDocumento: (documento: IDocumento) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    recargarTabla: () => void
): Array<Action<IDocumentoCompuestoMovimiento> | ((rowData: any) => Action<IDocumentoCompuestoMovimiento>)> => [
    {
        component: () => <BotonAgregar onClick={() => alAbrirFormulario()} />,
        icon: () => <AddIcon id="Icono-Agregar"/>,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormulario(),
    },
    (rowData: IDocumentoCompuestoMovimiento) => {
        const deshabilitarEdicion =
            rowData.EtapaDescripcion === TiposEtapas.EntregaConfirmada ||
            rowData.EtapaDescripcion === TiposEtapas.Revertido ||
            rowData.EtapaDescripcion === TiposEtapas.DevolucionParcial ||
            rowData.EtapaDescripcion === TiposEtapas.DevolucionTotal;
        return {
            icon: () => <EditIcon id="Icono-Editar" color={deshabilitarEdicion ? 'disabled' : 'action'} />,
            onClick: (_, valor) => alAbrirFormulario(valor as IDocumentoCompuestoMovimiento),
            tooltip: formatMessage({ id: 'boton.editar' }),
            disabled: deshabilitarEdicion,
        };
    },
    (rowData: IDocumentoCompuestoMovimiento) => {
        const deshabilitarEdicion = rowData.EtapaDescripcion !== TiposEtapas.Creada;
        return {
            icon: () => <CancelIcon id="Icono-cancelar" color={deshabilitarEdicion ? 'disabled' : 'action'} />,
            onClick: (_, value) => alAnularDocumento(value as IDocumentoCompuestoMovimiento),
            tooltip: formatMessage({ id: 'boton.anular' }),
            disabled: deshabilitarEdicion,
        };
    },
];

export const columnasTablaDespachos = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoCompuestoMovimiento>> => [
    { title: formatMessage({ id: 'despachos.documento.CodigoDocumento' }), field: 'CodigoDocumento' },
    {
        title: formatMessage({ id: 'despachos.documento.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData) => (rowData.FechaCreacion ? moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A') : ''),
    },
    { title: formatMessage({ id: 'despachos.documento.Observacion' }), field: 'Observacion' },
    { title: formatMessage({ id: 'despachos.documento.DescripcionCliente' }), field: 'DescripcionCliente' },
    { title: formatMessage({ id: 'despachos.documento.EtapaDescripcion' }), field: 'EtapaDescripcion' },
];

export const accionesTablaDocumentoDetalle = (
    alAbrirFormulario: (documentoDetalle?: IDocumentoDetalleCompuesto) => void
): Array<Action<IDocumentoDetalleCompuesto> | ((rowData: any) => Action<IDocumentoDetalleCompuesto>)> => [
    (rowData: IDocumentoDetalleCompuesto) => ({
        icon: () => (
            <EditIcon
            id="Icono-Editar" color={rowData.DescripcionEstadoDetalle !== TiposEstadosDetalles.Creada ? 'disabled' : 'action'}
            />
        ),
        onClick: (_, value) => alAbrirFormulario(value as IDocumentoDetalleCompuesto),
        disabled: rowData.DescripcionEstadoDetalle !== TiposEstadosDetalles.Creada,
    }),
];

export const columnasTablaDocumentoDetalle = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoDetalleCompuesto>> => [
    { field: 'DescripcionItem', title: formatMessage({ id: 'despachos.documentoDetalle.DescripcionItem' }) },
    { field: 'CodigoBarras', title: formatMessage({ id: 'despachos.documentoDetalle.CodigoBarras' }) },
    {
        title: formatMessage({ id: 'despachos.documentoDetalle.CantidadRecibida' }),
        render: (rowData) => (rowData.CantidadRecibida ? rowData.CantidadRecibida : rowData.CantidadSolicitada),
    },
    {
        field: 'CantidadEntregada',
        title: formatMessage({ id: 'despachos.documentoDetalle.CantidadEntregada' }),
    },
    {
        field: 'DescripcionDivisionDestino',
        title: formatMessage({ id: 'despachos.documentoDetalle.DivisionDestino' }),
    },
];

export const opcionesTablaDocumentoDetalle: Options<IDocumentoDetalleCompuesto> = {
    maxBodyHeight: '30vh',
    showTitle: true,
};
