import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from '../../conexiones';
import IEtapas from './entidades/etapas';

class ModeloEtapas {
    public consultarEtapas() {
        return manejadorRest<IRespuesta<IEtapas>>(() => negocioConfiguracion.get('Etapas/ConsultarListaEtapas'));
    }

    public ConsultarListaEtapasPorFiltro(etapas: Partial<IEtapas>) {
        return manejadorRest<IRespuesta<IEtapas>>(() => negocioConfiguracion.post('Etapas/ConsultarListaEtapasPorFiltro', etapas));
    }

    public crearEtapas(etapas: IEtapas) {
        return manejadorRest<IRespuesta<IEtapas>>(() => negocioConfiguracion.post('Etapas/GuardarEtapas', etapas));
    }     

    public editarEtapas(etapas: IEtapas) {
        return manejadorRest<IRespuesta<IEtapas>>(() => negocioConfiguracion.put('Etapas/EditarEtapas', etapas));
    }

    public eliminarEtapas(etapas: IEtapas) {
        return manejadorRest<IRespuesta<IEtapas>>(() => negocioConfiguracion.delete('Etapas/EliminarEtapas', etapas));
    }
}

export default ModeloEtapas;
