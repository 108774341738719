import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';
import SelectItems from 'Infotrack@Transversales/componentes/selectItems';
import React from 'react';
import { IFormularioDocumentosDetalleVistaProps } from '../interfaces';

const FormularioDocumentoDetalleVista: React.FunctionComponent<IFormularioDocumentosDetalleVistaProps> = ({
    alCambiarValor,
    alCambiarValorAutoComplete,
    alCerrarReiniciarFormulario,
    alGuardarDocumentoDetalle,
    alSeleccionarItem,
    divisiones,
    documentoDetalle,
    estadoFormulario,
    esMovil,
    item,
    tipoFormularioDocumentoDetalle,
    tipoMovimiento,
    usuarios,
}) => (
    <Dialog fullWidth maxWidth={esMovil ? 'lg' : 'sm'} open={estadoFormulario}>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                ? 'gestionoperadores.crearDocumentoUsuario'
                                : 'gestionoperadores.editarDocumentoUsuario'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid alignItems="center" container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ NombrePersona }) => NombrePersona}
                                options={usuarios}
                                onChange={(_: any, usuario: any) => alCambiarValorAutoComplete(usuario, 'Usuario')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        required
                                        label={<Texto id="bodegasPersonas.PersonaId" display="inline" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documentoDetalle.Usuario!}
                                id={`CampoUsuario-${documentoDetalle.Usuario!}`}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                    <Button color="secondary" onClick={() => alCerrarReiniciarFormulario(true)} variant="contained" id="boton-cancelar">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button 
                    color="primary" 
                    onClick={alGuardarDocumentoDetalle} 
                    variant="contained"
                    id={
                        tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                            ? 'boton-asignar'
                            : 'boton-editar'
                    }
                    >
                        <Texto
                            id={
                                tipoFormularioDocumentoDetalle === TipoFormulario.Creacion
                                    ? 'boton.asignar'
                                    : 'boton.editar'
                            }
                        />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioDocumentoDetalleVista;
