import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

import { useProveedor } from '@infotrack/presentacion-componentes/proveedorEstado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { useInputState } from '@infotrack/presentacion-utilitarios/hooks';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoCompuesto from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuesto';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import AbreviaturasTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/abreviaturasTiposDocumento';

import AlmacenamientoCM from '../controladorModelo/almacenamientoCM';
import DocumentoDetallesCM from '../controladorModelo/documentoDetallesCM';
import DocumentosCM from '../controladorModelo/documentosCM';
import { acciones, IEstadoAlmacenamiento } from '../reductorAlmacenamiento';
import ModalDetalleDocumentoVista from '../vista/modalDetalleAlmacenamientoVista';

const almacenamientoCM = new AlmacenamientoCM();
const documentosCM = new DocumentosCM();
const documentoDetallesCM = new DocumentoDetallesCM();
const ModalDetalleAlmacenamientoCV: React.FunctionComponent = () => {
    const [documentoDetalleRecepcion, setDocumentoDetalleRecepcion] = React.useState<IDocumentoDetalleCompuesto[]>([]);
    const estadoInicialDocumentoDetalle: IDocumentoDetalleCompuesto = {
        CodigoBarras: '',
        CodigoDocumento: '',
        DescripcionItem: '',
        DescripcionProducto: '',
        DescripcionDivisionDestino: '',
        DescripcionDivisionOrigen: '',
        EmpresaId: '',
        Lote: '',
        Serial: '',
        DescripcionEstadoDetalle: TiposEstadosDetalles.Alistado,
    };
    const estadoInicialDocumentoCompuesto: IDocumentoCompuesto = {
        CodigoDocumento: '',
        EtapaDescripcion: '',
        DescripcionBodegaDestino: '',
        BodegaDestino: 0,
        Observacion: '',
        EmpresaId: '',
        DescripcionBodegaOrigen: '',
        DescripcionProveedor: '',
        DescripcionResolucionFacturacion: '',
        DocumentoRelacionado: '',
        FlujoDescripcion: '',
        TipoDocumentoId: 0,
        TipoMovimientoDescripcion: '',
    };

    const [documentosDetalles, setDocumentosDetalles] = React.useState<IDocumentoDetalleCompuesto[]>([]);
    const [documentoDetalleModal, setDocumentoDetalleModal] = React.useState<IDocumentoDetalleCompuesto>();
    const [modalDocumentoDetalleAbierto, setModalDocumentoDetalleAbierto] = React.useState<boolean>(false);
    const [{ cargarTablaDocumentoDetalle, documentoId, estadoFormularioDetalle }, dispatch] = useProveedor<
        IEstadoAlmacenamiento
    >();
    const [tipoFormularioDocumentoDetalles, setTipoFormularioDocumentoDetalles] = React.useState<TipoFormulario>();
    const [refrescarDocumentoDetalles, setRefrescarDocumentoDetalle] = React.useState<boolean>();
    const { alCambiarValor, setValor, valor: documento, reiniciar } = useInputState<IDocumentoCompuesto>(
        estadoInicialDocumentoCompuesto
    );
    const abreviaturaTipoDoc = documento ? documento.TipoDocumentoAbreviatura : undefined;
    const theme = useTheme();
    const esMovil = useMediaQuery(theme.breakpoints.down('sm'));
    const [documentoDetalleSeleccionado, setDocumentoDetalle] = React.useState<IDocumentoDetalleCompuesto>(
        estadoInicialDocumentoDetalle
    );

    React.useEffect(() => {
        if ((cargarTablaDocumentoDetalle && estadoFormularioDetalle) || refrescarDocumentoDetalles) {
            cargarDocumentoConDetalles();
            dispatch({ type: acciones.CARGAR_DOCUMENTO_DETALLE });
        }
    }, [cargarTablaDocumentoDetalle, estadoFormularioDetalle, refrescarDocumentoDetalles]);

    React.useEffect(() => {
        if (documento) {
            dispatch({ type: acciones.ESTABLECER_TIPO_DOCUMENTO, payload: documento.TipoDocumentoId });
            dispatch({ type: acciones.ESTABLECER_BODEGA, payload: documento.BodegaDestino });
        }
    }, [documento]);

    const alCerrarFormulario = () => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_DETALLE });
        dispatch({ type: acciones.CARGAR_DOCUMENTO_DETALLE });
    };

    const alAbrirFormularioEdicion = (documentoDetalle: IDocumentoDetalleCompuesto) => {
        dispatch({ type: acciones.CAMBIAR_ESTADO_FORMULARIO_EDICION, payload: documentoDetalle });
    };

    const cargarDocumentoConDetalles = async () => {
        const consultaDocumentoConDetalle = await documentoDetallesCM.consultarDocumentosConDetalleCompuestos(
            documentoId!
        );
        setDocumentosDetalles(consultaDocumentoConDetalle.documentosDetalles);
        setValor(consultaDocumentoConDetalle.documento);
        cargarDocumentoConDetallesRecepcion();
    };
    const cargarDocumentoConDetallesRecepcion = async () => {
        const consultaDocumentoDetalles = await documentoDetallesCM.consultarDocumentoCompuestoDetalles({
            DocumentoId: documentoId,
            IdTipoDocumento: 1,
        });
        if (consultaDocumentoDetalles.length > 0) {
            setDocumentoDetalleRecepcion(consultaDocumentoDetalles[0].documentosDetalles);
        }
    };

    const refrescarDocumentoDetalle = (refrescar: boolean) => {
        setRefrescarDocumentoDetalle(refrescar);
    };
    const confirmarCambioDeEstadoAlmacenaiento = () => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => alModificarEstadoAlmacenamiento(),
            estado: true,
            mensaje: <Texto id={obtenerDescripcionCambiarEtapa(documento!.EtapaDescripcion)} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const setDocumentoDetalleSeleccionado = (documentoSeleccionado: IDocumentoDetalleCompuesto) => {
        setDocumentoDetalle(documentoSeleccionado);
    };

    const obtenerDescripcionCambiarEtapa = (etapa: string) => {
        if (etapa === TiposEtapas.Creada) return 'almacenamiento.iniciarAlistamiento';
        else if (etapa === TiposEtapas.EnAlistamiento) return 'almacenamiento.confirmarAlistamiento';
        else if (etapa === TiposEtapas.Alistado) return 'almacenamiento.iniciarTransito';
        else if (etapa === TiposEtapas.Transito) return 'almacenamiento.iniciarAlmacenamiento';
        else if (etapa === TiposEtapas.EnAlmacenamiento) return 'almacenamiento.confimarAlmacenamiento';
    };

    const cerrarModalDocumentoDetalle = () => {
        setModalDocumentoDetalleAbierto(false);
    };
    const abrirModalDocumentoDetalle = (
        tipoFormulario: TipoFormulario,
        documentoDetalle?: IDocumentoDetalleCompuesto
    ) => {
        if (tipoFormulario === TipoFormulario.Creacion) {
            setDocumentoDetalleModal({ ...documentoDetalleSeleccionado, CantidadRecibida: undefined });
        } else if (tipoFormulario === TipoFormulario.Edicion) {
            setDocumentoDetalleModal(documentoDetalle!);
        }
        setTipoFormularioDocumentoDetalles(tipoFormulario);
        setModalDocumentoDetalleAbierto(true);
    };

    const alModificarEstadoAlmacenamiento = () => {
        manejadorDialogoGlobal({ estado: false });
        almacenamientoCM.ModificarFlujoEtapaAlmacenamiento(documento as any).then(async () => {
            dispatch({ type: acciones.RECARGAR_TABLA });
            cargarDocumentoConDetalles();
            dispatch({ type: acciones.RECARGAR_TABLA });
            setDocumentosDetalles(documentosDetalles);
            setValor(
                (await documentosCM.consultaGeneralDocumentosFiltro({
                    DocumentoId: documento!.DocumentoId,
                })) as any
            );
            if (documento) {
                if (
                    documento.EtapaDescripcion === TiposEtapas.Cerrada ||
                    documento.EtapaDescripcion === TiposEtapas.Almacenado
                ) {
                    alCerrarFormulario();
                }
            }
        });
    };

    return (
        <>
            <ModalDetalleDocumentoVista
                alAbrirFormularioEdicion={alAbrirFormularioEdicion}
                alCerrarFormulario={alCerrarFormulario}
                confirmarCambioDeEstadoAlmacenaiento={confirmarCambioDeEstadoAlmacenaiento}
                documento={{ documento, documentosDetalles }}
                estadoFormulario={estadoFormularioDetalle}
                terminoAlmacenamiento={documento!.EtapaDescripcion === TiposEtapas.Almacenado}
                esMovil={esMovil}
                documentoDetalleRecepcion={documentoDetalleRecepcion}
                documentoDetalleSeleccionado={documentoDetalleSeleccionado}
                setDocumentoDetalleSeleccionado={setDocumentoDetalleSeleccionado}
                abrirModalDocumentoDetalle={abrirModalDocumentoDetalle}
                documentoDetalleModal={documentoDetalleModal}
                refrescarDocumentoDetalles={refrescarDocumentoDetalles}
                refrescarDocumentoDetalle={refrescarDocumentoDetalle}
                modalDocumentoDetalleAbierto={modalDocumentoDetalleAbierto}
                esRecepcion={abreviaturaTipoDoc === AbreviaturasTiposDocumentos.RE}
                tipoFormularioDocumentoDetalles={tipoFormularioDocumentoDetalles}
                terminoRecepcion={Boolean(
                    documento &&
                        documento.EtapaDescripcion === TiposEtapas.Cerrada &&
                        documento.TipoDocumentoAbreviatura === AbreviaturasTiposDocumentos.ALM
                )}
                cerrarModalDocumentoDetalle={cerrarModalDocumentoDetalle}
            />
        </>
    );
};

export default ModalDetalleAlmacenamientoCV;
