import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SearchIcon from '@material-ui/icons/Search';
import Tabla from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ITablaDetallesAuditoriasVistaProps } from '../interfaces';
import { accionesDetallesAuditorias, columnasDetallesAuditorias, opciones } from './constantes';

const TablaDetallesAuditoriasVista: React.FunctionComponent<ITablaDetallesAuditoriasVistaProps & InjectedIntlProps> = ({
    alCambiarValor,
    alAbrirFormularioDetalle,
    alCerrarFormulario,
    alEliminarDetalle,
    consultaDetallesAuditoria,
    consultarDetallesAuditoria,
    divisiones,
    documento,
    estadoFormulario,
    intl: { formatMessage },
    refTabla,
    filtros,
}) => (
    <Dialog fullWidth open={estadoFormulario} maxWidth="lg">
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            documento && documento!.EtapaDescripcion === TiposEtapas.Conteo
                                ? 'auditoriasinventario.titulo.primerConteo'
                                : 'auditoriasinventario.titulo.segundoConteo'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="auditoriasinventario.datosDocumento" fontWeight="bold" />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.bodegaOrigen" />}
                                        InputProps={{ readOnly: true }}
                                        name="BodegaOrigen"
                                        select
                                        variant="outlined"
                                        value={documento!.BodegaOrigen}
                                        id={`campoBodegaOrigen-${documento!.BodegaOrigen}`}
                                    >
                                        <MenuItem value={documento!.BodegaOrigen}>
                                            {documento!.BodegaOrigenDescripcion}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.etapaDescripcion" />}
                                        InputProps={{ readOnly: true }}
                                        name="EtapaDescripcion"
                                        variant="outlined"
                                        value={documento!.EtapaDescripcion}
                                        id={`campoEtapaDescripcion-${documento!.EtapaDescripcion}`}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.observaciones" />}
                                        name="Observacion"
                                        variant="outlined"
                                        value={documento!.Observacion}
                                        id={`campoObservacion-${documento!.Observacion}`}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="auditoriasinventario.busquedaDetalles" fontWeight="bold" />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.Detalles.CodigoBarras" />}
                                        onChange={alCambiarValor}
                                        name="CodigoBarras"
                                        value={filtros.CodigoBarras}
                                        variant="outlined"
                                        id={`campoCodigoBarras-${filtros.CodigoBarras}`}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.Detalles.DescripcionItem" />}
                                        onChange={alCambiarValor}
                                        name="DescripcionItem"
                                        value={filtros.DescripcionItem}
                                        variant="outlined"
                                        id={`campoDescripcionItem-${filtros.DescripcionItem}`}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <TextField
                                        fullWidth
                                        label={<Texto id="auditoriasinventario.Detalles.Division" />}
                                        onChange={alCambiarValor}
                                        name="DivisionId"
                                        select
                                        value={filtros.DivisionId}
                                        variant="outlined"
                                        id={`campoDivision-${filtros.DivisionId}`}
                                    >
                                        {divisiones.map(({ DivisionDescripcion, DivisionId }) => (
                                            <MenuItem key={DivisionId} value={DivisionId}>
                                                {DivisionDescripcion}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                        <Grid item md xs={6}>
                                            <Button
                                                color="secondary"
                                                fullWidth
                                                variant="contained"
                                                onClick={() => consultarDetallesAuditoria(true)}
                                                id="boton-Eliminar"
                                            >
                                                <DeleteSweepIcon />
                                            </Button>
                                        </Grid>
                                        <Grid item md xs={6}>
                                            <Button
                                                color="primary"
                                                fullWidth
                                                variant="contained"
                                                onClick={() => consultarDetallesAuditoria()}
                                                id="boton-Buscar"
                                            >
                                                <SearchIcon id="boton-Buscar"/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Tabla
                        actions={accionesDetallesAuditorias(
                            alAbrirFormularioDetalle,
                            consultarDetallesAuditoria,
                            alEliminarDetalle,
                            formatMessage
                        )}
                        columns={columnasDetallesAuditorias(formatMessage, documento)}
                        data={consultaDetallesAuditoria}
                        tableRef={refTabla}
                        title={<Texto id="auditoriasinventario.detallesDocumento" fontWeight="bold" />}
                        options={opciones}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained" id="boton-cerrar">
                        <Texto id="boton.cerrar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button color="primary" onClick={alCerrarFormulario} variant="contained" id="boton-guardar">
                        <Texto id="boton.guardar" />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default injectIntl(TablaDetallesAuditoriasVista);
