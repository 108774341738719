import React from 'react';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import { esEnteroODecimal } from '@infotrack/utilitarios/expresionesRegulares';

import InputImagen from 'Infotrack@Transversales/componentes/inputImagen';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import FormularioPropiedadesProductoCV from '../controladorVista/formularioPropiedadesProductoCV';
import { IFormularioGestionProductoVistaProps } from '../interfaces';
import { Autocomplete } from '@material-ui/lab';

const CustomTextField = (props: TextFieldProps) => (
    <TextField
        {...props}
        fullWidth
        required={props.required !== undefined ? props.required : true}
        size="small"
        variant="outlined"
        value={props.value ? props.value : ''}
    />
);

const FormularioGestionProductoVista: React.FunctionComponent<IFormularioGestionProductoVistaProps> = ({
    alAgregarImagenes,
    alCambiarValor,
    alCerrarFormulario,
    alEditarGuardarProducto,
    alEliminarImagen,
    entidadesProducto,
    estado,
    item,
    imagenes,
    tipoFormulario,
    cambiarValorAutoCompleteClasificacion,
    cambiarValorAutoCompleteClaseTecnica,
    cambiarValorAutoCompleteFabricante,
    cambiarValorAutoCompleteMarca,
    cambiarValorAutoCompleteReferencia,
}) => (
    <Dialog open={estado} fullWidth maxWidth="lg">
        <ModalEncabezado
            cerrar={alCerrarFormulario}
            titulo={
                <Texto
                    align="center"
                    color="inherit"
                    id={`${
                        tipoFormulario === TipoFormulario.Creacion
                            ? 'gestionproducto.agregarProducto'
                            : 'gestionproducto.editarProducto'
                    }`}
                />
            }
        />
        <DialogContent>
            <Grid container spacing={4}>
                <Grid item md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid alignItems="flex-start" container spacing={2}>
                                        <Grid item xs={12}>
                                            <Texto id="gestionproducto.titulo.datosProducto" fontWeight="bold" />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto display="inline" id="gestionproducto.DescripcionItem" />}
                                                name="DescripcionItem"
                                                onChange={alCambiarValor}
                                                value={item.DescripcionItem}
                                                inputProps={{
                                                    maxLength: 120,
                                                }}
                                                id={`CampoDescripcionItem-${item.DescripcionItem}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="gestionproducto.CodigoEmpresa" />}
                                                required={true}
                                                name="CodigoEmpresa"
                                                onChange={alCambiarValor}
                                                value={item.CodigoEmpresa}
                                                inputProps={{ maxLength: 30 }}
                                                id={`CampoCodigoEmpresa-${item.CodigoEmpresa}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto display="inline" id="gestionproducto.Abreviatura" />}
                                                name="Abreviatura"
                                                onChange={alCambiarValor}
                                                value={item.Abreviatura}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                id={`CampoAbreviatura-${item.Abreviatura}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="gestionproducto.CodigoBarras" />}
                                                required={false}
                                                name="CodigoBarras"
                                                onChange={alCambiarValor}
                                                value={item.CodigoBarras}
                                                id={`CampoCodigoBarras-${item.CodigoBarras}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="gestionproducto.Modelo" />}
                                                name="Modelo"
                                                onChange={alCambiarValor}
                                                required={false}
                                                value={item.Modelo}
                                                id={`CampoModelo-${item.Modelo}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="gestionproducto.RfId" />}
                                                name="RFId"
                                                onChange={alCambiarValor}
                                                required={false}
                                                value={item.RFId}
                                                id={`CampoRFId-${item.RFId}`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto display="inline" id="gestionproducto.Familia" />}
                                                name="FamiliaId"
                                                onChange={alCambiarValor}
                                                select
                                                value={item.FamiliaId}
                                                id={`CampoFamilia-${item.FamiliaId}`}
                                                helperText={
                                                    tipoFormulario === TipoFormulario.Edicion &&
                                                    item.FamiliaId !== null &&
                                                    !entidadesProducto.familias.find(
                                                        (x) => x.FamiliaId === parseInt(item.FamiliaId)
                                                    ) ? (
                                                        <Texto id="gestionproducto.FamiliaInactivo" />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                error={
                                                    tipoFormulario === TipoFormulario.Edicion &&
                                                    item.FamiliaId !== null &&
                                                    !entidadesProducto.familias.find(
                                                        (x) => x.FamiliaId === parseInt(item.FamiliaId)
                                                    )
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {entidadesProducto.familias.length === 0 ? (
                                                    <MenuItem value="">
                                                        <Texto id="label.sinRegistros" />
                                                    </MenuItem>
                                                ) : (
                                                    entidadesProducto.familias.map(
                                                        ({ FamiliaDescripcion, FamiliaId }) => (
                                                            <MenuItem key={FamiliaId} value={FamiliaId}>
                                                                {FamiliaDescripcion}
                                                            </MenuItem>
                                                        )
                                                    )
                                                )}
                                            </CustomTextField>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Autocomplete
                                                getOptionLabel={({ ClasificacionDescripcion }) =>
                                                    ClasificacionDescripcion
                                                }
                                                onChange={(_: any, clasificacion: any) =>
                                                    cambiarValorAutoCompleteClasificacion(clasificacion)
                                                }
                                                options={entidadesProducto.clasificaciones}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={<Texto id="gestionproducto.Clasificacion" />}
                                                        helperText={
                                                            tipoFormulario === TipoFormulario.Edicion &&
                                                            item.ClasificacionId !== null &&
                                                            !entidadesProducto.clasificaciones.find(
                                                                (x) =>
                                                                    x.ClasificacionId === parseInt(item.ClasificacionId)
                                                            ) ? (
                                                                <Texto id="gestionproducto.ClasificacionInactivo" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                                noOptionsText={<Texto id="label.sinRegistros" />}
                                                value={entidadesProducto.clasificaciones.find(
                                                    (x) => x.ClasificacionId === parseInt(item.ClasificacionId)
                                                )}
                                                id={`CampoClasificacion`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Autocomplete
                                                getOptionLabel={({ ClaseTecnicaDescripcion }) =>
                                                    ClaseTecnicaDescripcion
                                                }
                                                onChange={(_: any, clasificacion: any) =>
                                                    cambiarValorAutoCompleteClaseTecnica(clasificacion)
                                                }
                                                options={entidadesProducto.clasesTecnicas}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={<Texto id="gestionproducto.ClaseTecnica" />}
                                                        helperText={
                                                            tipoFormulario === TipoFormulario.Edicion &&
                                                            item.ClaseTecnicaId !== null &&
                                                            item.ClaseTecnicaId !== '' &&
                                                            !entidadesProducto.clasesTecnicas.find(
                                                                (x) =>
                                                                    x.ClaseTecnicaId === parseInt(item.ClaseTecnicaId)
                                                            ) ? (
                                                                <Texto id="gestionproducto.ClaseTecnicaInactivo" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                                noOptionsText={<Texto id="label.sinRegistros" />}
                                                value={entidadesProducto.clasesTecnicas.find(
                                                    (x) => x.ClaseTecnicaId === parseInt(item.ClaseTecnicaId)
                                                )}
                                                id={`CampoClaseTecnica`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Autocomplete
                                                getOptionLabel={({ ReferenciaDescripcion }) => ReferenciaDescripcion}
                                                onChange={(_: any, Referencia: any) =>
                                                    cambiarValorAutoCompleteReferencia(Referencia)
                                                }
                                                options={entidadesProducto.referencias}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={<Texto id="gestionproducto.Referencia" />}
                                                        helperText={
                                                            tipoFormulario === TipoFormulario.Edicion &&
                                                            item.ReferenciaId !== null &&
                                                            item.ReferenciaId !== '' &&
                                                            !entidadesProducto.referencias.find(
                                                                (x) => x.ReferenciaId === parseInt(item.ReferenciaId)
                                                            ) ? (
                                                                <Texto id="gestionproducto.ReferenciaInactivo" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                                noOptionsText={<Texto id="label.sinRegistros" />}
                                                value={entidadesProducto.referencias.find(
                                                    (x) => x.ReferenciaId === parseInt(item.ReferenciaId)
                                                )}
                                                id={`CampoReferencia`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Autocomplete
                                                getOptionLabel={({ MarcaDescripcion }) => MarcaDescripcion}
                                                onChange={(_: any, Marca: any) => cambiarValorAutoCompleteMarca(Marca)}
                                                options={entidadesProducto.marcas}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={<Texto id="gestionproducto.Marca" />}
                                                        helperText={
                                                            tipoFormulario === TipoFormulario.Edicion &&
                                                            item.MarcaId !== null &&
                                                            item.MarcaId !== '' &&
                                                            !entidadesProducto.marcas.find(
                                                                (x) => x.MarcaId === parseInt(item.MarcaId)
                                                            ) ? (
                                                                <Texto id="gestionproducto.MarcaInactivo" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                                noOptionsText={<Texto id="label.sinRegistros" />}
                                                value={entidadesProducto.marcas.find(
                                                    (x) => x.MarcaId === parseInt(item.MarcaId)
                                                )}
                                                id={`CampoMarca`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Autocomplete
                                                getOptionLabel={({ FabricanteDescripcion }) => FabricanteDescripcion}
                                                onChange={(_: any, Fabricante: any) =>
                                                    cambiarValorAutoCompleteFabricante(Fabricante)
                                                }
                                                options={entidadesProducto.fabricantes}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        label={<Texto id="gestionproducto.Fabricante" />}
                                                        helperText={
                                                            tipoFormulario === TipoFormulario.Edicion &&
                                                            item.FabricanteId !== null &&
                                                            item.FabricanteId !== '' &&
                                                            !entidadesProducto.fabricantes.find(
                                                                (x) => x.FabricanteId === parseInt(item.FabricanteId)
                                                            ) ? (
                                                                <Texto id="gestionproducto.FabricanteInactivo" />
                                                            ) : (
                                                                ''
                                                            )
                                                        }
                                                    />
                                                )}
                                                noOptionsText={<Texto id="label.sinRegistros" />}
                                                value={entidadesProducto.fabricantes.find(
                                                    (x) => x.FabricanteId === parseInt(item.FabricanteId)
                                                )}
                                                id={`CampoFabricante`}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <CustomTextField
                                                label={<Texto id="gestionproducto.Valor" />}
                                                name="Valor"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Texto id="gestionproducto.Valor.Monto" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                    esEnteroODecimal(e.target.value, {
                                                        cantidadDecimales: 2,
                                                        cantidadEnteros: 9,
                                                    }) && alCambiarValor(e)
                                                }
                                                value={item.Valor}
                                                id={`CampoValor-${item.Valor}`}
                                            />
                                        </Grid>
                                        
                                        {tipoFormulario === TipoFormulario.Edicion && (
                                            <Grid item md={4} xs={12}>
                                                <CustomTextField
                                                    label={
                                                        <Texto display="inline" id="gestionproducto.CodigoClasificacion" />
                                                    }
                                                    name="CodigoClasificacion"
                                                    onChange={alCambiarValor}
                                                    value={item.CodigoClasificacion}
                                                    inputProps={{
                                                        maxLength: 50,
                                                    }}
                                                    disabled={true}
                                                    id={`CodigoClasificacion-${item.CodigoClasificacion}`}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item md={4} xs={12}>
                                            <InputImagen
                                                alCargarImagen={alAgregarImagenes}
                                                alEliminarImagen={alEliminarImagen}
                                                limiteImagenes={5}
                                                estadoImagen={imagenes}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid alignItems="center" container spacing={2}>
                                        <Grid item xs={12}>
                                            <Texto
                                                id="gestionproducto.titulo.configuracionProducto"
                                                fontWeight="bold"
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.Lotes}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.Lotes" display="inline" />}
                                                name="Lotes"
                                                id={`ManejaLotes-${item.Lotes}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.ManejaPeso}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.ManejaPeso" display="inline" />}
                                                name="ManejaPeso"
                                                id={`ManejaPeso-${item.ManejaPeso}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.Seriado}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.Seriado" display="inline" />}
                                                name="Seriado"
                                                id={`Seriado-${item.Seriado}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.Serialconsecutivo}
                                                    />
                                                }
                                                label={
                                                    <Texto id="gestionproducto.Serialconsecutivo" display="inline" />
                                                }
                                                name="Serialconsecutivo"
                                                id={`Serialconsecutivo-${item.Serialconsecutivo}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.FIFO}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.FIFO" display="inline" />}
                                                name="FIFO"
                                                id={`FIFO-${item.FIFO}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.FEFO}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.FEFO" display="inline" />}
                                                name="FEFO"
                                                id={`FEFO-${item.FEFO}`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={Boolean(item.Estado)}
                                                    />
                                                }
                                                label={<Texto id="gestionproducto.Estado" display="inline" />}
                                                name="Estado"
                                                id={`Estado`}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={alCambiarValor}
                                                        checked={item.InventarioCritico}
                                                    />
                                                }
                                                label={
                                                    <Texto id="gestionproducto.InventarioCritico" display="inline" />
                                                }
                                                name="InventarioCritico"
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Card>
                        <CardContent>
                            <Grid alignItems="center" container spacing={2}>
                                <Grid item xs={12}>
                                    <Texto id="gestionproducto.titulo.dimensionesProducto" fontWeight="bold" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={<Texto display="inline" id="gestionproducto.Ancho" />}
                                        name="Ancho"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Texto id="gestionproducto.medido.dimensiones" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            esEnteroODecimal(e.target.value, {
                                                cantidadDecimales: 2,
                                                cantidadEnteros: 6,
                                            }) && alCambiarValor(e)
                                        }
                                        value={item.Ancho}
                                        id={`CampoAncho-${item.Ancho}`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={<Texto display="inline" id="gestionproducto.Alto" />}
                                        name="Alto"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Texto id="gestionproducto.medido.dimensiones" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            esEnteroODecimal(e.target.value, {
                                                cantidadDecimales: 2,
                                                cantidadEnteros: 6,
                                            }) && alCambiarValor(e)
                                        }
                                        value={item.Alto}
                                        id={`CampoAlto-${item.Alto}`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={<Texto display="inline" id="gestionproducto.Largo" />}
                                        name="Largo"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Texto id="gestionproducto.medido.dimensiones" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            esEnteroODecimal(e.target.value, {
                                                cantidadDecimales: 2,
                                                cantidadEnteros: 6,
                                            }) && alCambiarValor(e)
                                        }
                                        value={item.Largo}
                                        id={`CampoLargo-${item.Largo}`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={<Texto display="inline" id="gestionproducto.Peso" />}
                                        name="Peso"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Texto id="gestionproducto.medido.peso" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            esEnteroODecimal(e.target.value, {
                                                cantidadDecimales: 5,
                                                cantidadEnteros: 7,
                                            }) && alCambiarValor(e)
                                        }
                                        value={item.Peso}
                                        id={`CampoPeso-${item.Peso}`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label={<Texto display="inline" id="gestionproducto.Unidad" />}
                                        name="UnidadId"
                                        onChange={alCambiarValor}
                                        select
                                        value={item.UnidadId}
                                        id={`CampoUnidad-${item.UnidadId}`}
                                        helperText={
                                            tipoFormulario === TipoFormulario.Edicion &&
                                            !entidadesProducto.unidades.find(
                                                (x) => x.UnidadId === parseInt(item.UnidadId)
                                            ) ? (
                                                <Texto id="gestionproducto.UnidadInactivo" />
                                            ) : (
                                                ''
                                            )
                                        }
                                    >
                                        {entidadesProducto.unidades.length === 0 ? (
                                            <MenuItem value="">
                                                <Texto id="label.sinRegistros" />
                                            </MenuItem>
                                        ) : (
                                            entidadesProducto.unidades.map(({ DescripcionUnidad, UnidadId }) => (
                                                <MenuItem key={UnidadId} value={UnidadId}>
                                                    {DescripcionUnidad}
                                                </MenuItem>
                                            ))
                                        )}
                                    </CustomTextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                    <FormularioPropiedadesProductoCV />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button onClick={alCerrarFormulario} color="secondary" variant="contained" id="boton-cancelar">
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={alEditarGuardarProducto}
                        color="primary"
                        variant="contained"
                        id={`${tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'}`}
                    >
                        <Texto
                            id={`${tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'}`}
                        />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioGestionProductoVista;
