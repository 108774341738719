import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { negocioConfiguracion } from '../../conexiones';
import IItemPropiedadAccion from './entidades/itemPropiedadAccion';

export default class ModeloItemPropiedad {
    public consultarItemsPropiedades(ItemId: number) {
        return manejadorRest<IRespuesta<IItemPropiedadAccion>>(
            () => negocioConfiguracion.post('ItemsPropiedades/ConsultarItemsPropiedadesCompuestaFiltro', { ItemId }),
            true
        );
    }
}
