import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from '../../conexiones';
import IFiltrosProveedores from './entidades/filtrosProveedores';
import IProveedores from './entidades/proveedores';

class ModeloProveedores {
    public consultarProveedores() {
        return manejadorRest<IRespuesta<IProveedores>>(() => negocioConfiguracion.get('Proveedor/ConsultarListaProveedor'));
    }

    public crearProveedores(proveedores: IProveedores) {
        return manejadorRest<IRespuesta<IProveedores>>(() => negocioConfiguracion.post('Proveedor/GuardarProveedor', proveedores));
    }

    public editarProveedores(proveedores: IProveedores) {
        return manejadorRest<IRespuesta<IProveedores>>(() => negocioConfiguracion.put('Proveedor/EditarProveedor', proveedores));
    }

    public eliminarProveedores(proveedores: IProveedores) {
        return manejadorRest<IRespuesta<IProveedores>>(() =>
            negocioConfiguracion.delete('Proveedor/EliminarProveedores', proveedores)
        );
    }

    public consultarListaProveedoresPorFiltro(filtrosProveedores: IFiltrosProveedores) {
        return manejadorRest<IRespuesta<IProveedores>>(() =>
            negocioConfiguracion.post('Proveedor/ConsultarListaProveedorePorFiltro', filtrosProveedores)
        );
    }
}

export default ModeloProveedores;
