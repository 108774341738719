import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Action, Column, Options } from '@infotrack/presentacion-componentes/tabla';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import IDocumentoCompuestoMovimiento from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoCompuestoMovimiento';
import IDocumentoConsulta from 'Infotrack@Modelos/smartStock/documentos/entidades/documentoConsulta';
import IDocumentoDetalleCompuesto from 'Infotrack@Modelos/smartStock/documentosDetalle/entidades/documentoDetalleCompuesto';
import TiposEstadosDetalles from 'Infotrack@Modelos/smartStock/estadosDetalles/entidades/tiposEstadosDetalles';
import TiposEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/tiposEtapas';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const columnas = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoConsulta>> => [
    { title: formatMessage({ id: 'devolucion.CodigoDocumento' }), field: 'CodigoDocumento' },
    { title: formatMessage({ id: 'devolucion.DescripcionBodegaOrigen' }), field: 'DescripcionBodegaOrigen' },
    {
        title: formatMessage({ id: 'devolucion.TipoDocumentoDescripcion' }),
        field: 'TipoDocumentoDescripcion',
    },
    { title: formatMessage({ id: 'devolucion.EtapaDescripcion' }), field: 'EtapaDescripcion' },
    {
        title: formatMessage({ id: 'devolucion.FechaCreacion' }),
        field: 'FechaCreacion',
        render: (rowData) => (rowData.FechaCreacion ? moment(rowData.FechaCreacion).format('DD/MM/YYYY/hh:mm A') : ''),
    },
];

export const columnasDetalles = (
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<Column<IDocumentoDetalleCompuesto>> => [
    { field: 'DescripcionItem', title: formatMessage({ id: 'devolucion.documentoDetalle.DescripcionItem' }) },
    { field: 'CodigoBarras', title: formatMessage({ id: 'devolucion.documentoDetalle.CodigoBarras' }) },
    {
        title: formatMessage({ id: 'devolucion.documentoDetalle.CantidadRecibida' }),
        render: (rowData) => (rowData.CantidadRecibida ? rowData.CantidadRecibida : rowData.CantidadSolicitada),
    },
    {
        field: 'CantidadEntregada',
        title: formatMessage({ id: 'devolucion.documentoDetalle.CantidadEntregada' }),
    },
    {
        field: 'DescripcionDivisionOrigen',
        title: formatMessage({ id: 'devolucion.documentoDetalle.DivisionDestino' }),
    },
];

export const acciones = (
    alAbrirFormulario: (documento?: IDocumentoConsulta) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string
): Array<
    | Action<IDocumentoCompuestoMovimiento>
    | ((rowData: IDocumentoCompuestoMovimiento) => Action<IDocumentoCompuestoMovimiento>)
> => [
    {
        component: BotonAgregar,
        icon: () => <AddIcon id="Icono-Agregar"/>,
        isFreeAction: true,
        tooltip: formatMessage({ id: 'boton.agregar' }),
        onClick: () => alAbrirFormulario(),
    },
    (rowData) => ({
        disabled: rowData.EtapaDescripcion === TiposEtapas.Cerrada,
        icon: () => <EditIcon id="Icono-Editar" color={rowData.EtapaDescripcion === TiposEtapas.Cerrada ? 'disabled' : 'action'} />,
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_, documento) => alAbrirFormulario(documento as any),
    }),
];

export const accionesDetalle = (
    alAbrirFormulario: (documento: IDocumentoDetalleCompuesto) => void,
    formatMessage: (messageDescriptor: FormattedMessage.MessageDescriptor) => string,
    tipoFormulario: TipoFormulario
): Array<
    Action<IDocumentoDetalleCompuesto> | ((rowData: IDocumentoDetalleCompuesto) => Action<IDocumentoDetalleCompuesto>)
> => [
    (rowData) => ({
        disabled: rowData.DescripcionEstadoDetalle === TiposEstadosDetalles.Cerrada,
        icon: () => (
            <EditIcon
            id="Icono-Editar" color={rowData.DescripcionEstadoDetalle === TiposEstadosDetalles.Cerrada ? 'disabled' : 'action'}
            />
        ),
        tooltip: formatMessage({ id: 'boton.editar' }),
        onClick: (_, detalle) => alAbrirFormulario(detalle as any),
        hidden: tipoFormulario === TipoFormulario.Creacion,
    }),
];

export const opcionesDetalles: Options<IDocumentoDetalleCompuesto> = {
    showTitle: true,
};
