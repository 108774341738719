import { Remove as RemoveIcon } from '@material-ui/icons';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';

import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import CampoCantidad from './Campos/CampoCantidad';
import CampoPesoSolicitado from './Campos/CampoPesoSolicitado';
import CampoDivisionDestino from './Campos/CampoDivisionDestino';
import CampoDivisionOrigen from './Campos/CampoDivisionOrigen';
import CampoFechaVencimiento from './Campos/CampoFechaVencimiento';
import { Card, CardHeader } from '@material-ui/core';
import { BotonAgregar } from 'Infotrack@Transversales/componentes/botonesAcciones';
import ModalSeriales from 'Infotrack@Transversales/componentes/ModalSeriales/ModalSeriales';
import BotonAbrirModalSeriales from 'Infotrack@Transversales/componentes/TablaDetallesPlantilla/BotonAbrirModalSeriales';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import CampoFechaIngreso from './Campos/CampoFechaIngreso';
import moment from 'moment';

interface IEntidadesTablaDetallesPlantilla {
    divisionesOrigen: IBodegaDivisiones[];
    divisionesDestino: IBodegaDivisiones[];
}

interface IDetallesInventarioTablaProps {
    agregarDetalles: (detalles: IDocumentoDetalleConsultaGeneral[]) => Promise<boolean>;
    camposOcultar?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    camposEditables?: Array<keyof IDocumentoDetalleConsultaGeneral>;
    deshabilitada: boolean;
    detalles: IDocumentoDetalleConsultaGeneral[];
    entidades: Partial<IEntidadesTablaDetallesPlantilla>;
    tipoCantidad: 'CantidadRecibida' | 'CantidadEntregada' | 'CantidadSolicitada';
    titulo: ReactNode;
    recibeSerial: boolean;
    seriadoConsulta: boolean;
    mostrarPesoCampoPeso: boolean;
}

const IDetallesInventarioTablaProps: FunctionComponent<IDetallesInventarioTablaProps> = ({
    agregarDetalles,
    camposOcultar = [],
    camposEditables = [],
    deshabilitada,
    detalles: detallesProp,
    entidades,
    tipoCantidad,
    titulo,
    recibeSerial,
    seriadoConsulta,
    mostrarPesoCampoPeso,
}) => {
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [diccionarioCantidades, setDiccionarioCantidades] = useState<Record<string, string>>({});
    const [detalleModalSeriales, setDetalleModalSeriales] = useState<IDocumentoDetalleConsultaGeneral | null>(null);
    const [diccionarioDetalleSeriales, setDiccionarioDetalleSeriales] = useState<Record<string, string[]>>({});
    const [diccionarioDivisionDestino, setDiccionarioDivisionDestino] = useState<Record<string, number>>({});

    useEffect(() => {
        console.log(detallesProp,"detallesProp");
        setDetalles(detallesProp.map((d) => ({ ...d })));
        construirDiccionarioCantidades();
        construirDiccionarioDivisionesDestino();
    }, [detallesProp]);

    useEffect(() => {
        construirDiccionarioDetalleSeriales();
    }, [diccionarioCantidades]);

    const obtenerColumnas = () => {
        const columnas: Array<Column<IDocumentoDetalleConsultaGeneral>> = [];
        columnas.push({
            field: 'CodigoEmpresa',
            title: <Texto id="TablaDetallesPlantilla.CodigoEmpresa" />,
        });
        columnas.push({
            field: 'DescripcionItem',
            title: <Texto id="TablaDetallesPlantilla.DescripcionItem" />,
        });
        if (!camposOcultar.includes('PesoFraccionRecibido')) {
            columnas.push({
                field: 'PesoFraccionRecibido',
                title: <Texto id="TablaDetallesPlantilla.PesoFraccion" />,
            });
        }

        if (!camposOcultar.includes('PesoFraccionStock')) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => (detalle.ManejaPeso ? detalle.PesoDisponible : <RemoveIcon id="Icono-Remove"/>),
                field: 'PesoFraccionRecibido',
                title: <Texto id="TablaDetallesPlantilla.PesoFraccionStock" />,
            });
        }
        if (mostrarPesoCampoPeso) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                    if (detalle.ManejaPeso) {
                        return (
                            <CampoPesoSolicitado
                                deshabilitado={deshabilitada}
                                detalle={detalle}
                                manejarCambioCampo={manejarCambioCampo}
                            />
                        );
                    } else {
                        return <RemoveIcon id="Icono-Remove"/>;
                    }
                },
                title: <Texto id="TablaDetallesPlantilla.CampoPesoSolicitado" />,
            });
        }

        if (!camposOcultar.includes('DivisionOrigen'))
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    !camposEditables.includes('DivisionOrigen') ? (
                        detalle.DescripcionDivisionOrigen
                    ) : (
                        <CampoDivisionOrigen
                            deshabilitado={deshabilitada}
                            detalle={detalle}
                            divisiones={entidades.divisionesOrigen || []}
                            manejarCambioCampo={manejarCambioCampo}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionOrigen" />,
            });
        if (!camposOcultar.includes('DivisionDestino')) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    !camposEditables.includes('DivisionDestino') ? (
                        detalle.DescripcionDivisionDestino
                    ) : (
                        <CampoDivisionDestino
                            deshabilitado={deshabilitada}
                            divisiones={
                                entidades.divisionesDestino!.filter(
                                    (division) => division.DivisionId! !== detalle.DivisionOrigen!
                                ) || []
                            }
                            manejarCambio={(nuevoValor: any) => {
                                setDiccionarioDivisionDestino((diccionarioDivisionDestinoActual) => ({
                                    ...diccionarioDivisionDestinoActual,
                                    [detalle.DocumentoDetalleId]: nuevoValor,
                                }));
                            }}
                            valor={diccionarioDivisionDestino[detalle.DocumentoDetalleId]}
                        />
                    ),
                title: <Texto id="TablaDetallesPlantilla.DivisionDestino" />,
            });
        }

        if (!camposOcultar.includes('CantidadRecibida'))
            columnas.push({
                field: 'CantidadRecibida',
                title: <Texto id="TablaDetallesPlantilla.CantidadRecibida" />,
            });
        if (!camposOcultar.includes('CantidadEntregada'))
            columnas.push({
                field: 'CantidadEntregada',
                title: <Texto id="TablaDetallesPlantilla.CantidadEntregada" />,
            });

        if (!camposOcultar.includes('CantidadDisponible'))
            columnas.push({
                field: 'CantidadDisponible',
                title: <Texto id="TablaDetallesPlantilla.CantidadStock" />,
            });

        if (mostrarPesoCampoPeso) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                    if (detalle.ManejaPeso) {
                        return <RemoveIcon id="Icono-Remove"/>;
                    } else {
                        return (
                            <CampoCantidad
                                manejarCambio={(nuevoValor: string) => {
                                    setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                                        ...diccionarioCantidadesActual,
                                        [detalle.DocumentoDetalleId]: nuevoValor,
                                    }));
                                }}
                                valor={diccionarioCantidades[detalle.DocumentoDetalleId]}
                            />
                        );
                    }
                },
                title: <Texto id="TablaDetallesPlantilla.Cantidad" />,
            });
        }

        if (!mostrarPesoCampoPeso) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                    return (
                        <CampoCantidad
                            manejarCambio={(nuevoValor: string) => {
                                setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                                    ...diccionarioCantidadesActual,
                                    [detalle.DocumentoDetalleId]: nuevoValor,
                                }));
                            }}
                            valor={diccionarioCantidades[detalle.DocumentoDetalleId]}
                        />
                    );
                },
                title: <Texto id="TablaDetallesPlantilla.Cantidad" />,
            });
        }

        if (!camposOcultar.includes('Lote')) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => (detalle.Lote ? detalle.Lote : <RemoveIcon id="Icono-Remove"/>),
                title: <Texto id="DetallesInventarioTabla.Lote" />,
            });
        }
   
        if (!camposOcultar.includes('FechaVencimiento')) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                    if (!detalle.FEFO) {
                        return <RemoveIcon id="Icono-Remove"/>;
                    } else {
                        if (!camposEditables.includes('FechaVencimiento'))
                            return detalle.FechaVencimiento !== '' ? (
                                moment(detalle.FechaVencimiento).format('DD/MM/YYYY')
                            ) : (
                                <RemoveIcon id="Icono-Remove"/>
                            );
                        else
                            return (
                                <CampoFechaVencimiento
                                    deshabilitado={deshabilitada}
                                    detalle={detalle}
                                    manejarCambioCampo={manejarCambioCampo}
                                />
                            );
                    }
                },
                title: <Texto id="TablaDetallesPlantilla.FechaVencimiento" />,
            });
        }
        if (!camposOcultar.includes('FechaIngreso')) {
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) => {
                    if (!detalle.FIFO) {
                        return <RemoveIcon id="Icono-Remove"/>;
                    } else {
                        if (!camposEditables.includes('FechaIngreso'))
                            return detalle.FechaIngreso !== '' ? (
                                moment(detalle.FechaIngreso).format('DD/MM/YYYY')
                            ) : (
                                <RemoveIcon id="Icono-Remove"/>
                            );
                        else
                            return (
                                <CampoFechaIngreso
                                    deshabilitado={deshabilitada}
                                    detalle={detalle}
                                    manejarCambioCampo={manejarCambioCampo}
                                />
                            );
                    }
                },
                title: <Texto id="TablaDetallesPlantilla.FechaIngreso" />,
            });
        }


        if (recibeSerial)
            columnas.push({
                render: (detalle: IDocumentoDetalleConsultaGeneral) =>
                    detalle.Seriado || detalle.Serialconsecutivo ? (
                        <BotonAbrirModalSeriales
                            abrirModalSeriales={() => setDetalleModalSeriales(detalle)}
                            deshabilitado={deshabilitada}
                        />
                    ) : (
                        <RemoveIcon id="Icono-Remove"/>
                    ),
                title: <Texto id="TablaDetallesPlantilla.AsignarSeriales" />,
            });
        columnas.push({
            render: (detalle: IDocumentoDetalleConsultaGeneral) => (
                <BotonAgregar disabled={deshabilitada} onClick={() => agregarDetalleDetalles(detalle)}/>
            ),
        });
        return columnas;
    };

    const construirDiccionarioCantidades = () => {
        const nuevoDiccionarioCantidades: Record<string, string> = {};

        detallesProp.forEach((detalle) => {
            nuevoDiccionarioCantidades[detalle.DocumentoDetalleId] = diccionarioCantidades[detalle.DocumentoDetalleId]
                ? diccionarioCantidades[detalle.DocumentoDetalleId]
                : '0';
        });
        setDiccionarioCantidades(nuevoDiccionarioCantidades);
    };

    const construirDiccionarioDivisionesDestino = () => {
        const nuevoDiccionarioDivisionesDestino: Record<string, number> = {};
        detallesProp.forEach((detalle) => {
            nuevoDiccionarioDivisionesDestino[detalle.DocumentoDetalleId] = diccionarioDivisionDestino[
                detalle.DocumentoDetalleId
            ]
                ? diccionarioDivisionDestino[detalle.DocumentoDetalleId]
                : 0;
        });
        setDiccionarioDivisionDestino(nuevoDiccionarioDivisionesDestino);
    };

    const construirDiccionarioDetalleSeriales = () => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        Object.keys(diccionarioCantidades).forEach((detalleId) => {
            const detalle = detalles.find((d) => d.DocumentoDetalleId === detalleId)!;
            if (detalle.Seriado || detalle.Serialconsecutivo) {
                const serialesDetalle = copiaDiccionarioDetalleSeriales[detalleId];
                const cantidadDetalle = Number(diccionarioCantidades[detalleId]);
                if (!serialesDetalle || serialesDetalle.length !== cantidadDetalle)
                    copiaDiccionarioDetalleSeriales[detalleId] = new Array(cantidadDetalle).fill('');
            }
        });

        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const guardarSerialesDetalle = (seriales: string[]) => {
        const copiaDiccionarioDetalleSeriales = { ...diccionarioDetalleSeriales };
        copiaDiccionarioDetalleSeriales[detalleModalSeriales!.DocumentoDetalleId] = seriales;
        setDiccionarioDetalleSeriales(copiaDiccionarioDetalleSeriales);
    };

    const manejarCambioCampo = (
        DocumentoDetalleId: string,
        nombreCampo: keyof IDocumentoDetalleConsultaGeneral,
        nuevoValor: any
    ) => {
        const copiaDetalles = [...detalles];
        const detalle = copiaDetalles.find((d) => d.DocumentoDetalleId === DocumentoDetalleId)!;
        detalle[nombreCampo] = (nuevoValor as unknown) as never;
        setDetalles(copiaDetalles);
    };

    const validarDetalle = (detalle: IDocumentoDetalleConsultaGeneral) => {
        
        const cantidadDetalle = Number(diccionarioCantidades[detalle.DocumentoDetalleId]);
        if ( !mostrarPesoCampoPeso&& (isNaN(cantidadDetalle) || cantidadDetalle <= 0)) {
            notificacionGlobal('ModalRecepcion.ValidacionNoCantidad', 3000, 'warning', true);
            return false;
        }
        if (detalle.ManejaPeso === false && cantidadDetalle > detalle.CantidadRecibida!) {
            notificacionGlobal('Cantidad Entregada no puede ser mayor a la del Stock', 3000, 'warning', true);
            return false;
        }
        if (detalle.ManejaPeso === false && cantidadDetalle > detalle.CantidadEntregada!) {
            notificacionGlobal('Cantidad Entregada no puede ser mayor a la del Stock', 3000, 'warning', true);
            return false;
        }
        if (detalle.ManejaPeso === false && detalle.Lotes && detalle.Lote === '') {
            notificacionGlobal('ModalRecepcion.ValidacionNoLote', 3000, 'warning', true);
            return false;
        }
        if (detalle.ManejaPeso === false && detalle.FEFO && !detalle.FechaVencimiento) {
            notificacionGlobal('TablaDetallesPlantilla.ValidacionNoFechaVencimiento', 3000, 'warning', true);
            return false;
        }
        if (
            recibeSerial &&
            (detalle.Seriado || detalle.Serialconsecutivo) &&
            diccionarioDetalleSeriales[detalle.DocumentoDetalleId].some((serial) => serial === '')
        ) {
            notificacionGlobal('ModalRecepcion.ValidacionSerialesIncompletos', 3000, 'warning', true);
            return false;
        }
        if (mostrarPesoCampoPeso && detalle.ManejaPeso && (detalle.PesoFraccionSolicitado=== undefined || detalle.PesoFraccionSolicitado ===0 || detalle.PesoFraccionSolicitado ===    null )) {
            notificacionGlobal('ModalRecepcion.ValidacionNoPeso', 3000, 'warning', true);
            return false;
        }
        return true;
    };

    const agregarDetalleDetalles = async (detalle: IDocumentoDetalleConsultaGeneral) => {
        if (!validarDetalle(detalle)) return;
        let agregadoExitoso;
        if (!recibeSerial || (!detalle.Seriado && !detalle.Serialconsecutivo)) {
            agregadoExitoso = await agregarDetalles([
                {
                    ...detalle,
                    [tipoCantidad]: Number(diccionarioCantidades[detalle.DocumentoDetalleId]),
                    DivisionDestino: diccionarioDivisionDestino[detalle.DocumentoDetalleId] || detalle.DivisionDestino,
                },
            ]);
        } else {
            agregadoExitoso = await agregarDetalles(
                diccionarioDetalleSeriales[detalle.DocumentoDetalleId].map((serial) => ({
                    ...detalle,
                    [tipoCantidad]: 1,
                    Serial: serial,
                    DivisionDestino: diccionarioDivisionDestino[detalle.DocumentoDetalleId] || detalle.DivisionDestino,
                }))
            );
        }
        if (agregadoExitoso) {
            const copiaDetalles = [...detalles];
            const indiceDetalleTabla = detalles.findIndex((d) => d.DocumentoDetalleId === detalle.DocumentoDetalleId);
            copiaDetalles[indiceDetalleTabla] = { ...detallesProp[indiceDetalleTabla] };
            setDetalles(copiaDetalles);
            setDiccionarioCantidades((diccionarioCantidadesActual) => ({
                ...diccionarioCantidadesActual,
                [detalle.DocumentoDetalleId]: '0',
            }));
            setDiccionarioDivisionDestino((diccionarioDivisionesActuales) => ({
                ...diccionarioDivisionesActuales,
                [detalle.DocumentoDetalleId]: 0,
            }));
        }
    };

    return (
        <>
            <Card variant="outlined">
                <CardHeader title={titulo} titleTypographyProps={{ variant: 'body1' }} />
                <Tabla columns={obtenerColumnas()} data={detalles} options={{ padding: 'dense' }} />
            </Card>
            {recibeSerial && (
                <ModalSeriales
                    abierto={Boolean(detalleModalSeriales)}
                    cerrar={() => setDetalleModalSeriales(null)}
                    guardarSeriales={guardarSerialesDetalle}
                    serialesConsecutivos={
                        detalleModalSeriales && detalleModalSeriales.Serialconsecutivo
                            ? detalleModalSeriales.Serialconsecutivo
                            : false
                    }
                    serialesIniciales={
                        detalleModalSeriales ? diccionarioDetalleSeriales[detalleModalSeriales.DocumentoDetalleId] : []
                    }
                    detalle={detalleModalSeriales}
                    seriadoConsulta={seriadoConsulta}
                    mostrarPeso={false}
                />
            )}
        </>
    );
};

export { IEntidadesTablaDetallesPlantilla };

export default IDetallesInventarioTablaProps;
