import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioConfiguracion } from 'Infotrack@Modelos/conexiones';
import IDivision from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDivision';

class ModeloDivision {
    private NOMBRE_CONTROLADOR = 'Division';

    public async consultarListaDivisionPorFiltro(division: Partial<IDivision>) {
        return manejadorRest<IRespuesta<IDivision>>(
            () => negocioConfiguracion.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaFiltro`, division),
            true
        );
    }
}

export default ModeloDivision;
