import { IRespuesta, IRespuestaPaginada } from '@infotrack/presentacion-transversales/interfacesComunes';
import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';

import { negocioProceso } from 'Infotrack@Modelos/conexiones';
import IDocumentoFiltroPaginador from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltroPaginador';
import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

class ModeloOrdenSalida {
    private NOMBRE_CONTROLADOR = 'OrdenSalida';

    public async guardarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/GuardarEncabezado`, documento),
            true
        );
    }

    public async editarEncabezado(documento: IDocumento) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/EditarEncabezado`, documento),
            true
        );
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/GuardarDetalle`, detalles),
            true
        );
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocioProceso.put(`${this.NOMBRE_CONTROLADOR}/EditarDetalle`, detalle),
            true
        );
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        return manejadorRest<IRespuesta<IDocumentoDetalle>>(
            () => negocioProceso.delete(`${this.NOMBRE_CONTROLADOR}/EliminarDetalle`, detalle),
            true
        );
    }

    public async cambiarEtapa(documento: IDocumento, idUsuario:string) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () =>
                negocioProceso.put(
                    `${this.NOMBRE_CONTROLADOR}/CambiarEtapa`,
                    documento,
                    {
                        headers: {
                            'IdUsuario': idUsuario
                        }
                    }
                ),
            true
        );
		
		}

    public async anularDocumento(documentoId: string) {
        return manejadorRest<IRespuesta<IDocumento>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/AnularDocumento/${documentoId}`, {}),
            true
        );
    }

    public async consultarListaDocumentos(documentoFiltroPaginador: IDocumentoFiltroPaginador) {
        return manejadorRest<IRespuestaPaginada<IDocumentoConsultaGeneral>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/ConsultarListaDocumentos`, documentoFiltroPaginador),
            true
        );
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        return manejadorRest<IRespuesta<IDocumentoConsultaGeneral>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/ConsultarEncabezadoDocumento`, filtro),
            true
        );
    }

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        return manejadorRest<IRespuesta<IDocumentoDetalleConsultaGeneral>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/ConsultarDetallesDocumento`, filtro),
            true
        );
    }

    public async consultarEtapas(documentoId: string) {
        return manejadorRest<IRespuesta<IEtapas>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/ConsultarEtapas/${documentoId}`),
            true
        );
    }


    public async generarArchivoPDF(documentoId: string, idAplicacion: string) {

        return manejadorRest<IRespuesta<string>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/GenerarPdfDocumento/${documentoId}/${idAplicacion}`),
            true
        );
        
    }
}

export default ModeloOrdenSalida;
