import React, { FunctionComponent, useEffect, useState } from 'react';

import IPlantillaImpresion from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IPlantillaImpresion';
import { EstadosModalPlantillaImpresion } from '../enumeraciones';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tooltip,
} from '@material-ui/core';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import Texto from '@infotrack/presentacion-componentes/texto';
import CampoPlantillaImpresionDescripcion from './Campos/CampoPlantillaImpresionDescripcion';
import CampoComando from './Campos/CampoComando';
import { HelpOutline } from '@material-ui/icons';
import { PARAMETROS_IMPRESION } from '../constantes';

interface IModalPlantillaImpresion {
    cerrar: () => any;
    plantillaImpresionInicial: IPlantillaImpresion | null;
    estado: EstadosModalPlantillaImpresion;
    crearPlantillaImpresion: (plantillaImpresion: IPlantillaImpresion) => any;
    editarPlantillaImpresion: (plantillaImpresion: IPlantillaImpresion) => any;
}

const PLANTILLA_IMPRESION_DEFECTO: IPlantillaImpresion = {
    PlantillaImpresionId: 0,
    TipoPlantillaId: 0,
    PlantillaImpresionDescripcion: '',
    Comando: '',
    EmpresaId: '',
};

const ModalPlantillaImpresion: FunctionComponent<IModalPlantillaImpresion> = ({
    cerrar,
    plantillaImpresionInicial,
    estado,
    crearPlantillaImpresion,
    editarPlantillaImpresion,
}) => {
    const [plantillaImpresion, setPlantillaImpresion] = useState<IPlantillaImpresion>(PLANTILLA_IMPRESION_DEFECTO);

    useEffect(() => {
        if (plantillaImpresionInicial) setPlantillaImpresion(plantillaImpresionInicial);
    }, [plantillaImpresionInicial]);

    useEffect(() => {
        if (estado === EstadosModalPlantillaImpresion.CERRADO) {
            setPlantillaImpresion(PLANTILLA_IMPRESION_DEFECTO);
        }
    }, [estado]);

    const manejarCambioCampo = (
        nombreCampo: keyof IPlantillaImpresion,
        nuevoValor: IPlantillaImpresion[keyof IPlantillaImpresion]
    ) => {
        setPlantillaImpresion((plantillaImpresionActual) => ({
            ...plantillaImpresionActual,
            [nombreCampo]: nuevoValor,
        }));
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Dialog fullWidth maxWidth="xs" open={estado !== EstadosModalPlantillaImpresion.CERRADO}>
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalPlantillaImpresion.CREACION ? (
                        <Texto id="ModalPlantillaImpresion.TituloCreacion" />
                    ) : estado === EstadosModalPlantillaImpresion.EDICION ? (
                        <Texto id="ModalPlantillaImpresion.TituloEdicion" />
                    ) : estado === EstadosModalPlantillaImpresion.VISUALIZACION ? (
                        <Texto id="ModalPlantillaImpresion.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CampoPlantillaImpresionDescripcion
                            deshabilitado={estado == EstadosModalPlantillaImpresion.VISUALIZACION}
                            manejarCambioCampo={manejarCambioCampo}
                            valor={plantillaImpresion!.PlantillaImpresionDescripcion}
                        />
                    </Grid>
                    <Grid item xs={12} container justifyContent="flex-end">
                        <Tooltip title={'Valores Parámetros Impresión'}>
                            <IconButton onClick={handleClick} size="small" color="primary" id="boton-ayuda">
                                <HelpOutline />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <List disablePadding>
                                {PARAMETROS_IMPRESION.map((value) => {
                                    const labelId = value;
                                    return (
                                        <ListItem draggable key={value}>
                                            <ListItemText id={labelId} primary={`${value}`} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Popover>
                    </Grid>
                    <Grid item xs={12}>
                        <CampoComando
                            deshabilitado={estado == EstadosModalPlantillaImpresion.VISUALIZACION}
                            manejarCambioCampo={manejarCambioCampo}
                            valor={plantillaImpresion!.Comando}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        {estado === EstadosModalPlantillaImpresion.CREACION && (
                            <Button
                                color="primary"
                                onClick={() => crearPlantillaImpresion(plantillaImpresion!)}
                                size="small"
                                variant="contained"
                                id="boton-guardar"
                            >
                                <Texto id="ModalPlantillaImpresion.BotonGuardar" />
                            </Button>
                        )}
                        {estado === EstadosModalPlantillaImpresion.EDICION && (
                            <Button
                                color="primary"
                                onClick={() => editarPlantillaImpresion(plantillaImpresion!)}
                                size="small"
                                variant="contained"
                                id="boton-editar"
                            >
                                <Texto id="ModalPlantillaImpresion.BotonEditar" />
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ModalPlantillaImpresion;
