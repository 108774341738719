import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Texto from '@infotrack/presentacion-componentes/texto';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

import IDocumentoConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoConsultaGeneral';
import IDocumentoDetalleConsultaGeneral from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/IDocumentoDetalleConsultaGeneral';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import DetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormularioTabla/DetallesFormulario';
import EstadosDetallesFormulario from 'Infotrack@Transversales/componentes/DetallesFormulario/enumeraciones';
import DetallesInventario from 'Infotrack@Transversales/componentes/DetallesInventario/DetallesInventario';
import DocumentoValorTotal from 'Infotrack@Transversales/componentes/DocumentoValorTotal/DocumentoValorTotal';
import { EstadosFormularioDocumento } from 'Infotrack@Transversales/componentes/FormularioDocumento/enumeraciones';
import FormularioDocumento, {
    IEntidadesFormularioDocumento,
} from 'Infotrack@Transversales/componentes/FormularioDocumento/FormularioDocumento';
import ModalEncabezado from 'Infotrack@Transversales/componentes/ModalEncabezado/ModalEncabezado';
import TablaDetalles from 'Infotrack@Transversales/componentes/TablaDetalles/TablaDetalles';

import {
    CAMPOS_DESHABILITAR_DETALLES_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO,
    CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION,
    CAMPOS_OCULTAR_DETALLES_FORMULARIO,
    CAMPOS_OCULTAR_DETALLES_TABLA,
    CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO,
    ENTIDADES_DOCUMENTO_POR_DEFECTO,
    CAMPOS_EDITABLES_DETALLES_INVENTARIO,
    CAMPOS_OCULTAR_DETALLES_INVENTARIO,
} from './constantes';
import EntidadesDetalleCM from './controladorModelo/EntidadesDetalleCM';
import EntidadesDocumentoCM from './controladorModelo/EntidadesDocumentoCM';
import ItemCM from './controladorModelo/ItemCM';
import MovimientoCM from './controladorModelo/MovimientoCM';
import OrdenTrasladosCM from './controladorModelo/OrdenTrasladoCM';
import { EstadosModalOrdenTraslado } from './enumeraciones';
import { estadoActivo } from 'Infotrack@Transversales/constantes/ConstantesEstados';
import DocumentoFirma from 'Infotrack@Transversales/componentes/DocumentoFirma/DocumentoFirma';

interface IModalOrdenTrasladosProps {
    cerrar: () => void;
    documentoId: string | null;
    establecerDocumentoId: (documentoId: string) => void;
    estado: EstadosModalOrdenTraslado;  
    descargarDocumentoPdf: (documento: IDocumentoConsultaGeneral) => any;
}

const ModalOrdenTraslados: FunctionComponent<IModalOrdenTrasladosProps> = ({
    cerrar,
    documentoId,
    establecerDocumentoId,
    estado,
    descargarDocumentoPdf,
}) => {
    const entidadesDocumentoCM = useMemo(() => new EntidadesDocumentoCM(), []);
    const ordenTrasladosCM = useMemo(() => new OrdenTrasladosCM(), []);
    const itemCM = useMemo(() => new ItemCM(), []);
    const movimientoCM = useMemo(() => new MovimientoCM(), []);
    const entidadesDetalleCM = useMemo(() => new EntidadesDetalleCM(), []);
    const [ejecutarConsultarDocumentos, setEjecutarConsultarDocumentos] = useState<boolean>(false);

    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    const idAgencia = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.agenciaActual!.IdAgencia);
    const idUsuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdUsuario);
    const idAplicacion = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdAplicacion);
    const  usuario = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion);

    const [entidadesDocumento, setEntidadesDocumento] = useState<Partial<IEntidadesFormularioDocumento>>(
        ENTIDADES_DOCUMENTO_POR_DEFECTO
    );
    const [documento, setDocumento] = useState<IDocumentoConsultaGeneral | null>(null);
    const [etapasSiguientes, setEtapasSiguientes] = useState<IEtapas[]>([]);
    const [items, setItems] = useState<IItemsConsulta[]>([]);
    const [entidadesDetalle, setEntidadesDetalle] = useState<{
       
        divisionesOrigen: IBodegaDivisiones[];
    }>({  divisionesOrigen: [] });
    const [detalles, setDetalles] = useState<IDocumentoDetalleConsultaGeneral[]>([]);
    const [detalleInicialEdicion, setDetalleInicialEdicion] = useState<IDocumentoDetalle | null>(null);
    const [abrirModalActa, setAbrirModalActa] = useState<boolean>(false);
    const [etapaSeleccionada, setEtapaSeleccionada] = useState<number>(0);

    useEffect(() => {
        alCambiarEstado();
    }, [estado]);

    useEffect(() => {
        if (documento) alEstablecerDocumento();
    }, [documento]);
    useEffect(() => {
        consultarDetalles();
    }, [ejecutarConsultarDocumentos]);

    const alCambiarEstado = async () => {
        if (estado === EstadosModalOrdenTraslado.CERRADO) {
            setDocumento(null);
            setDetalles([]);
            setEntidadesDocumento(ENTIDADES_DOCUMENTO_POR_DEFECTO);
            setEtapasSiguientes([]);
            setEntidadesDetalle({ divisionesOrigen: [] });
            setDetalleInicialEdicion(null);
            return;
        }
        if (estado === EstadosModalOrdenTraslado.CREACION) {
            await consultarEntidadesDocumento();
        }
        if (estado === EstadosModalOrdenTraslado.EDICION || estado === EstadosModalOrdenTraslado.VISUALIZACION) {
            await consultarDocumento(documentoId!);
        }
    };

    const consultarEntidadesDocumento = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumento(
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil!!=="Administrador"? idUsuario:"",
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarEntidadesDocumentoEdicion = async () => {
        const entidadesDocumentoConsultadas = await entidadesDocumentoCM.consultarEntidadesDocumentoEdicion(
            documento!.BodegaOrigen!,
            idEmpresa,
            idAgencia,
            usuario!.NombrePerfil!!=="Administrador"? idUsuario:"",
            TiposProceso.ORDEN_TRASLADO,
            estadoActivo
        );
        setEntidadesDocumento(entidadesDocumentoConsultadas);
    };

    const consultarDocumento = async (documentoIdConsultar: string) => {
        const documentoConsultado = await ordenTrasladosCM.consultarEncabezadoDocumento({
            DocumentoId: documentoIdConsultar,
        });
        setDocumento(documentoConsultado);
    };

    const alEstablecerDocumento = async () => {
        if (estado === EstadosModalOrdenTraslado.CREACION) {
            establecerDocumentoId(documento!.DocumentoId);
            await consultarEtapasSiguientes();
            await consultarItems();
            await consultarEntidadesDetalle();
        }
        if (estado === EstadosModalOrdenTraslado.EDICION) {
            await consultarEtapasSiguientes();
            await consultarItems();
            await consultarEntidadesDetalle();
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
        if (estado === EstadosModalOrdenTraslado.VISUALIZACION) {
            await consultarDetalles();
            await consultarEntidadesDocumentoEdicion();
        }
    };

    const consultarItems = async () => {
        const itemsConsultados = await itemCM.consultarListaItemsBodega(documento!.BodegaOrigen!);
        setItems(itemsConsultados);
    };

    const consultarEntidadesDetalle = async () => {
        const entidadesDetalleConsultadas = await entidadesDetalleCM.consultarEntidadesDetalle(
            documento!.BodegaOrigen!,
            documento!.TipoDocumentoId,
            documento!.EmpresaId!
        );
        setEntidadesDetalle(entidadesDetalleConsultadas);
    };

    const consultarDetalles = async () => {
        const detallesConsultados = await ordenTrasladosCM.consultarDetallesDocumento({ DocumentoId: documentoId! });
        setDetalles(detallesConsultados);
    };

    const consultarEtapasSiguientes = async () => {
        const etapasSiguientesConsultadas = await ordenTrasladosCM.consultarEtapas(documento!.DocumentoId);
        setEtapasSiguientes(etapasSiguientesConsultadas);
    };

    const crearDocumento = async (documentoCrear: IDocumento) => {
        const respuesta = await ordenTrasladosCM.guardarEncabezado(mapearDocumentoCrear(documentoCrear));
        if (respuesta.Resultado) await consultarDocumento(respuesta.Entidades[0].DocumentoId);
    };

    const mapearDocumentoCrear = (documentoCrear: IDocumento): IDocumento => {
        return {
            ...documentoCrear,
            EmpresaId: idEmpresa,
            AgenciaId: idAgencia,
        };
    };

    const editarDocumento = async (documentoEditar: IDocumento) => {
        const respuesta = await ordenTrasladosCM.editarEncabezado(documentoEditar);
        if (respuesta.Resultado) await consultarDocumento(documento!.DocumentoId);
    };


    const cambiarEtapa = async (etapaId: number) => {
        if ( await consultasAccionesTipoDocumento(documento!.TipoDocumentoId,etapaId) === false ) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await ordenTrasladosCM.cambiarEtapa({ ...documento!, EtapaId: etapaId },idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
            }
        } else {
            setEtapaSeleccionada(etapaId)
        }

    };

    const consultasAccionesTipoDocumento = async (idTipoDocumento: number, idEtapa: number) => {
        debugger
        const acciones = await entidadesDocumentoCM.consultarAccionEtapaDocumentoFiltro(idTipoDocumento, idEtapa);
        if (acciones[0].EtapaDescripcion ==="Finalizado" && acciones[0].GenerarActa) {
            setAbrirModalActa(true)
            return true;
        }
        else {
            return false;
        }
    }

    const confirmarCambiarEtapa = async (etapaId: number) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => cambiarEtapa(etapaId),
            estado: true,
            mensaje: <Texto id={'etapas.CambioEtapa'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const agregarDetalles = async (detallesAgregar: IDocumentoDetalle[]) => {
        const respueta = await ordenTrasladosCM.guardarDetalle(
            detallesAgregar.map((detalle) => ({
                ...detalle,
                DocumentoDetalleId: '',
                DocumentoDetallePredecesorId: detalle.DocumentoDetalleId,
                DocumentoId: documento!.DocumentoId,
                CantidadEntregada: 0,
            }))
        );
        await consultarDetalles();
        return respueta;
    };

    const editarDetalle = async (detalle: IDocumentoDetalle) => {
        return ordenTrasladosCM.editarDetalle(detalle);
    };

    const eliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await ordenTrasladosCM.eliminarDetalle(detalle);
        if (respuesta) {
            consultarDetalles();
        }
    };

    const confirmarEliminarDetalle = async (detalle: IDocumentoDetalle) => {
        manejadorDialogoGlobal({
            accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
            accionConfirmar: () => eliminarDetalle(detalle),
            estado: true,
            mensaje: <Texto id={'alerta.confirmacionEliminacion'} />,
            mostrarCancelar: true,
            tipoDialogo: 'Advertencia',
            titulo: <Texto id="titulo.advertencia" />,
        });
    };

    const consultarItemsTabla = async (idItem: number) => {
        const items = await entidadesDetalleCM.consultarItem(
            idItem,
            idEmpresa
        );
        return items.itemsConsulta;
    };

    const consultarTipoDocumento = async (idBodega: number, nombreNodega?: string) => {
        if (nombreNodega === 'BodegaOrigen') {
            const tiposDocumentosBodega = await entidadesDocumentoCM.consultarTipoDocumento(idBodega, estadoActivo);
            setEntidadesDocumento({ ...entidadesDocumento, tiposDocumento: tiposDocumentosBodega });
        }
    };

    const consultarFirmas = () => { }
    const guardarFirmas = async (firma: File) => {

        const respuesta = await entidadesDocumentoCM.guardarFirmaArchivo({ IdAplicacion: idAplicacion, ExtensionArchivo: 2, NombreArchivo: firma.name, RutaAlmacenamiento: `${idAplicacion}/Firma/${documentoId}`, Identificador: documentoId! }, firma)
        if (respuesta) {
            manejadorDialogoGlobal({ estado: false });
            const cambioEtapaExitoso = await ordenTrasladosCM.cambiarEtapa({ ...documento!, EtapaId: etapaSeleccionada,PersonaRelacionada:idUsuario }, idUsuario);
            if (cambioEtapaExitoso) {
                await consultarDocumento(documento!.DocumentoId);
                setAbrirModalActa(false);
            }
        }

    }

    const cerrarDocumentoFirma = () => {
        setAbrirModalActa(false)
    }
    return (
        <Dialog open={estado !== EstadosModalOrdenTraslado.CERRADO} maxWidth="lg">
            <ModalEncabezado
                cerrar={cerrar}
                titulo={
                    estado === EstadosModalOrdenTraslado.CREACION ? (
                        <Texto id="ModalOrdenTraslado.TituloCreacion" />
                    ) : estado === EstadosModalOrdenTraslado.EDICION ? (
                        <Texto id="ModalOrdenTraslado.TituloEdicion" />
                    ) : estado === EstadosModalOrdenTraslado.VISUALIZACION ? (
                        <Texto id="ModalOrdenTraslado.TituloVisualizacion" />
                    ) : (
                        undefined
                    )
                }
            />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormularioDocumento
                            cambiarEtapa={confirmarCambiarEtapa}
                            camposDeshabilitar={
                                (estado === EstadosModalOrdenTraslado.CREACION && documento) ||
                                estado === EstadosModalOrdenTraslado.EDICION
                                    ? CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO_EDICION
                                    : CAMPOS_DESHABILITAR_DOCUMENTO_FORMULARIO
                            }
                            camposOcultar={CAMPOS_OCULTAR_DOCUMENTO_FORMULARIO}
                            editarDocumento={editarDocumento}
                            estado={
                                !documento
                                    ? EstadosFormularioDocumento.CREACION
                                    : (estado === EstadosModalOrdenTraslado.CREACION && documento) ||
                                      estado === EstadosModalOrdenTraslado.EDICION
                                    ? EstadosFormularioDocumento.EDICION
                                    : EstadosFormularioDocumento.VISUALIZACION
                            }
                            etapasSiguientes={etapasSiguientes}
                            documento={documento}
                            entidadesDocumento={entidadesDocumento}
                            guardarDocumento={crearDocumento}
                            descargarDocumentoPdf={descargarDocumentoPdf}
                            consultarTipoDocumento={consultarTipoDocumento}
                            mostrarModalEvidencia={false}
                            esTraslado={false}
                        />
                    </Grid>
                    {estado !== EstadosModalOrdenTraslado.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesInventario
                                agregarDetalles={agregarDetalles}
                                alAgregarDetalles={consultarDetalles}
                                consultarInventario={async (bodegaId: number, itemId: number) =>
                                    movimientoCM.stockItemDisponible(bodegaId, itemId)
                                }
                                deshabilitado={!documento || !documento.ListaAcciones.PermiteAgregarDetalle}
                                documento={documento}
                                items={items}
                                tipoCantidad={'CantidadSolicitada'}
                                entidades={entidadesDetalle}
                                camposEditables={CAMPOS_EDITABLES_DETALLES_INVENTARIO}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_INVENTARIO}
                                recibeSerial={false}
                                seriadoConsulta={false}
                                esOrdenTraslado={true}
                                mostrarPesoCampoPeso={true}
                            />
                        </Grid>
                    )}
                    {detalleInicialEdicion !== null && estado !== EstadosModalOrdenTraslado.VISUALIZACION && (
                        <Grid item xs={12}>
                            <DetallesFormulario
                                alEditarDetalle={async () => {
                                    setDetalleInicialEdicion(null);
                                    await consultarDetalles();
                                }}
                                camposDeshabilitar={CAMPOS_DESHABILITAR_DETALLES_FORMULARIO}
                                camposOcultar={CAMPOS_OCULTAR_DETALLES_FORMULARIO}
                                detalleInicialEdicion={detalleInicialEdicion}
                                documentoId={documento ? documento.DocumentoId : ''}
                                deshabilitado={!detalleInicialEdicion}
                                editarDetalle={editarDetalle}
                                entidades={entidadesDetalle}
                                estado={EstadosDetallesFormulario.EDICION}
                                tipoCantidad="CantidadSolicitada"
                                seriadoConsulta={false}
                                bloquearCantidadSerial={false}
                                esEntradaInventario={false}
                                esOrdenTraslado={true}
                                consultarItems={consultarItemsTabla}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TablaDetalles
                            camposOcultar={CAMPOS_OCULTAR_DETALLES_TABLA}
                            detalles={detalles}
                            editarDetalle={(detalle: IDocumentoDetalleConsultaGeneral) =>
                                setDetalleInicialEdicion(detalle)
                            }
                            editarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEditarDetalle : false
                            }
                            eliminarDetalle={confirmarEliminarDetalle}
                            eliminarDetalleDeshabilitado={
                                documento ? !documento.ListaAcciones.PermiteEliminarDetalle : false
                            }
                            mostrarAcciones={
                                (estado === EstadosModalOrdenTraslado.CREACION && Boolean(documento)) ||
                                estado === EstadosModalOrdenTraslado.EDICION
                            }
                            recargarTabla={consultarDetalles}
                            titulo={<Texto fontWeight="bold" id="ModalOrdenSalida.TituloTablaDetalles" />}
                        />
                    </Grid>
                    {abrirModalActa && (
                        <Grid item xs={12}>
                            <DocumentoFirma
                                consultarFirmas={consultarFirmas}
                                guardarFirmas={guardarFirmas}
                                idDocumento={documento ? documento.DocumentoId : ""}
                                cerrarDocumentoFirma={cerrarDocumentoFirma}
                            />
                        </Grid>)

                    }
                    <Grid item xs={12}>
                        <DocumentoValorTotal detalles={detalles} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ModalOrdenTraslados;
