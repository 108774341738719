import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';

interface ICampoEtapaIdProps {
    etapas: IEtapas[];
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor: number;
}

const CampoEtapaId: FunctionComponent<ICampoEtapaIdProps> = ({ etapas, manejarCambioFiltro, valor }) => (
    <Autocomplete
        getOptionLabel={(e) => e.EtapaDescripcion}
        onChange={(_: object, value: IEtapas | null) => manejarCambioFiltro('EtapaId', value ? value.EtapaId : 0)}
        options={etapas}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoEtapaId.Nombre" />} />
        )}
        value={etapas.find((e) => e.EtapaId === valor) || null}
        id={`CampoEtapa-${etapas.find((e) => e.EtapaId === valor) || null}`}
    />
);

export default CampoEtapaId;
