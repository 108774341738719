import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';

interface ICampoCodigoDocumentoProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    valor: string;
}

const CampoCodigoDocumento: FunctionComponent<ICampoCodigoDocumentoProps> = ({ manejarCambioFiltro, valor }) => (
    <TextField
        fullWidth
        label={<Texto id="Documentos.CampoCodigoDocumento.Nombre" />}
        onChange={(event) => manejarCambioFiltro('CodigoDocumento', event.target.value)}
        value={valor}
        id={`CampoCodigoDocumento-${valor}`}
    />
);

export default CampoCodigoDocumento;
