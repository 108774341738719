import { negocioProceso } from "Infotrack@Modelos/conexiones";
import ICostos from "./entidades/ICostos";
import { IRespuestaPaginada } from "@infotrack/presentacion-transversales/interfacesComunes";
import manejadorRest from "@infotrack/presentacion-utilitarios/manejadorRest";
import IParametrosCostos from "./entidades/parametrosCostos";


export default class ModeloCostos  {
    public consultarListaCostosPorfiltro(parametros: IParametrosCostos) {
        return manejadorRest<IRespuestaPaginada<ICostos>>(
            () => negocioProceso.post('ItemBodegaCosto/ConsultarListaItemBodegaCostoPorFiltro', parametros),
            true
        );
    }

}
