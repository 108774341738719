import manejadorRest from '@infotrack/presentacion-utilitarios/manejadorRest';
import { IRespuesta } from '@infotrack/presentacion-transversales/interfacesComunes';
import { negocioProceso } from '../../../conexiones';
import IPlantillaImpresion from '../../../negocioRefactor/entidades/repositorio/IPlantillaImpresion';

export default class ModeloPlantillaImpresion {
    private NOMBRE_CONTROLADOR = 'PlantillaImpresion';

    public consultarListaPlantillaImpresionWeb(idEmpresa: string) {
        return manejadorRest<IRespuesta<IPlantillaImpresion>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaPlantillaImpresionWeb/${idEmpresa}`),
            true
        );
    }

    public consultarListaPlantillaImpresionMovil(idEmpresa: string) {
        return manejadorRest<IRespuesta<IPlantillaImpresion>>(
            () => negocioProceso.get(`${this.NOMBRE_CONTROLADOR}/ConsultarListaPlantillaImpresionMovil/${idEmpresa}`),
            true
        );
    }

    public crearPlantillaImpresion(plantillaImpresion: IPlantillaImpresion) {
        return manejadorRest<IRespuesta<IPlantillaImpresion>>(
            () => negocioProceso.post(`${this.NOMBRE_CONTROLADOR}/GuardarPlantillaImpresion`, plantillaImpresion),
            true
        );
    }

    public editarPlantillaImpresion(plantillaImpresion: IPlantillaImpresion) {
        return manejadorRest<IRespuesta<IPlantillaImpresion>>(
            () => negocioProceso.put(`${this.NOMBRE_CONTROLADOR}/EditarPlantillaImpresion`, plantillaImpresion),
            true
        );
    }

    public eliminarPlantillaImpresion(filtro: Partial<IPlantillaImpresion>) {
        return manejadorRest<IRespuesta<IPlantillaImpresion>>(
            () => negocioProceso.delete(`${this.NOMBRE_CONTROLADOR}/EliminarPlantillaImpresion`, filtro),
            true
        );
    }
}
