import { Button, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import Texto from '@infotrack/presentacion-componentes/texto';
import IEtapas from 'Infotrack@Modelos/smartStock/etapas/entidades/etapas';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import IFlujoEtapaDocumento from '../../../../modelos/smartStock/flujoEtapaDocumento/entidades/flujoEtapaDocumento';
import { EstadosFlujo, EstadosFlujoEtapasFormulario, EstadosModalFlujos } from '../enumeraciones';
interface FormularioFlujoEtapa {
    etapas: IEtapas[];
    guardarEtapas: (flujoDocumento: IFlujoEtapaDocumento) => void;
    editarEtapas: (flujoDocumento: IFlujoEtapaDocumento) => void;
    flujoDocumento: IFlujoEtapaDocumento;
    estado: EstadosFlujoEtapasFormulario;
    refrescarCampos: boolean;
    manejarCambioCampoFlujoEtapa: (
        nombreCampo: keyof IFlujoEtapaDocumento,
        nuevoValor: IFlujoEtapaDocumento[keyof IFlujoEtapaDocumento],
        idFlujoEtapaDocumento: number
    ) => Promise<void>;
    estadoFormularioFlujo: EstadosFlujo;
}

const FormularioFlujoEtapa: FunctionComponent<FormularioFlujoEtapa> = ({
    editarEtapas,
    etapas,
    guardarEtapas,
    flujoDocumento,
    estado,
    refrescarCampos,
    manejarCambioCampoFlujoEtapa,
    estadoFormularioFlujo,
}) => {
    const [flujoEtapaDocumento, setFlujoflujoEtapaDocumento] = useState<IFlujoEtapaDocumento>(flujoDocumento);
    useEffect(() => {
        setFlujoflujoEtapaDocumento((flujoEtapasActual) => ({
            ...flujoEtapasActual,
            EtapaActual: 0,
            EtapaSiguiente: 0,
        }));
    }, [refrescarCampos]);
    useEffect(() => {
        setFlujoflujoEtapaDocumento(flujoDocumento);
    }, [flujoDocumento]);

    const guardarEtapaFlujo = () => {
        if (flujoEtapaDocumento.EtapaActual && flujoEtapaDocumento.EtapaSiguiente) {
            if (flujoEtapaDocumento.EtapaActual !== flujoEtapaDocumento.EtapaSiguiente) {
                guardarEtapas(flujoEtapaDocumento);
            } else {
                notificacionGlobal('FlujoEtapa.EtapasIguales', 6000, 'warning', true);
            }
        } else {
            notificacionGlobal('Por favor ingrese todos los campos requeridos (*)', 6000, 'warning', true);
        }
    };

    const editarEtapaFlujo = () => {
        if (
            flujoEtapaDocumento.EtapaActual &&
            flujoEtapaDocumento.EtapaActual !== 0 &&
            flujoEtapaDocumento.EtapaSiguiente != 0
        ) {
            if (flujoEtapaDocumento.EtapaActual !== flujoEtapaDocumento.EtapaSiguiente) {
                editarEtapas(flujoEtapaDocumento);
            } else {
                notificacionGlobal('FlujoEtapa.EtapasIguales', 6000, 'warning', true);
            }
        } else {
            notificacionGlobal('Por favor ingrese todos los campos requeridos (*)', 6000, 'warning', true);
        }
    };

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid alignItems="center" container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionLabel={(t) => t.EtapaDescripcion}
                            onChange={(_: ChangeEvent<{}>, etapa: IEtapas | null) =>
                                manejarCambioCampoFlujoEtapa(
                                    'EtapaActual',
                                    etapa ? etapa.EtapaId! : undefined,
                                    flujoEtapaDocumento.FlujoEtapaDocumentoId
                                )
                            }
                            options={etapas}
                            disabled={estadoFormularioFlujo === EstadosFlujo.CREACION}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={<Texto id="FlujoEtapa.EtapaActual" />}
                                    required
                                    disabled={estadoFormularioFlujo === EstadosFlujo.CREACION}
                                    helperText={
                                        estado === EstadosFlujoEtapasFormulario.EDICION &&
                                        flujoEtapaDocumento.EtapaActual !== undefined &&
                                        flujoEtapaDocumento.EtapaActual !== 0 &&
                                        !etapas.find((x) => x.EtapaId === flujoEtapaDocumento.EtapaActual) ? (
                                            <Texto id="etapas.EtapaInactivo" />
                                        ) : (
                                            ''
                                        )
                                    }
                                    error={
                                        estado === EstadosFlujoEtapasFormulario.EDICION &&
                                        flujoEtapaDocumento.EtapaActual !== undefined &&
                                        flujoEtapaDocumento.EtapaActual !== 0 &&
                                        !etapas.find((x) => x.EtapaId === flujoEtapaDocumento.EtapaActual)
                                            ? true
                                            : false
                                    }
                                />
                            )}
                            value={etapas.find((t) => t.EtapaId === flujoEtapaDocumento.EtapaActual) || null}
                            id={`CampoEtapaActual`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionLabel={(t) => t.EtapaDescripcion}
                            onChange={(_: ChangeEvent<{}>, etapa: IEtapas | null) =>
                                manejarCambioCampoFlujoEtapa(
                                    'EtapaSiguiente',
                                    etapa ? etapa.EtapaId! : undefined,
                                    flujoEtapaDocumento.FlujoEtapaDocumentoId
                                )
                            }
                            options={etapas}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label={<Texto id="FlujoEtapa.EtapaSiguiente" />}
                                    required
                                    helperText={
                                        estado === EstadosFlujoEtapasFormulario.EDICION &&
                                        flujoEtapaDocumento.EtapaSiguiente !== undefined &&
                                        flujoEtapaDocumento.EtapaSiguiente !== 0 &&
                                        !etapas.find((x) => x.EtapaId === flujoEtapaDocumento.EtapaSiguiente) ? (
                                            <Texto id="etapas.EtapaInactivo" />
                                        ) : (
                                            ''
                                        )
                                    }
                                    error={
                                        estado === EstadosFlujoEtapasFormulario.EDICION &&
                                        flujoEtapaDocumento.EtapaSiguiente !== undefined &&
                                        flujoEtapaDocumento.EtapaSiguiente !== 0 &&
                                        !etapas.find((x) => x.EtapaId === flujoEtapaDocumento.EtapaSiguiente)
                                            ? true
                                            : false
                                    }
                                />
                            )}
                            value={etapas.find((t) => t.EtapaId === flujoEtapaDocumento.EtapaSiguiente) || null}
                            id={`CampoEtapaSiguiente`}
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={flujoEtapaDocumento.Estado === 1}
                                    onChange={(_, checked) =>
                                        manejarCambioCampoFlujoEtapa(
                                            'Estado',
                                            checked ? 1 : 0,
                                            flujoEtapaDocumento.FlujoEtapaDocumentoId
                                        )
                                    }
                                />
                            }
                            label={<Texto id="FlujoEtapa.Estado" display="inline" />}
                            id={`Estado`}
                            name="Estado"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        {estado === EstadosFlujoEtapasFormulario.CREACION && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    endIcon={<EditIcon id="Icono-Editar" />}
                                    onClick={() => guardarEtapaFlujo()}
                                    size="small"
                                    variant="contained"
                                    id="boton-guardar"
                                >
                                    <Texto id="FlujoEtapa.Guardar" />
                                </Button>
                            </Grid>
                        )}

                        {estado === EstadosFlujoEtapasFormulario.EDICION && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    endIcon={<EditIcon id="Icono-Editar" />}
                                    onClick={() => editarEtapaFlujo()}
                                    size="small"
                                    variant="contained"
                                    id="boton-editar"
                                >
                                    <Texto id="FlujoEtapa.Editar" />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default FormularioFlujoEtapa;
