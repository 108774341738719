import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ModalEncabezado from '../ModalEncabezado/ModalEncabezado';
import Texto from '@infotrack/presentacion-componentes/texto';
import { Gesture as GestureIcon, ImageSearch as ImagenIcon } from '@material-ui/icons';
import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';

interface IDocumentoFirmaProps {
  consultarFirmas: () => void;
  guardarFirmas: (file: File) => void;
  idDocumento: string;
  cerrarDocumentoFirma: () => void;
}

const iconoEstilo = { fontSize: '2rem' };

const DocumentoFirma: FunctionComponent<IDocumentoFirmaProps> = ({ consultarFirmas, guardarFirmas, idDocumento, cerrarDocumentoFirma }) => {
  const [file, setFile] = useState<File | null>(null);
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [firmar, setFirmar] = useState<boolean>(true);
  const [imagen, setImagen] = useState<boolean>(false);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setFileUrl(null);
    }
  }, [file]);

  const cargarArchivoFirma = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      const validExtensions = ['jpg', 'jpeg', 'png', 'gif'];

      if (validExtensions.includes(fileExtension!.toLocaleLowerCase() || '')) {
        const newFileName = `${idDocumento}.${fileExtension}`;
        const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });
        setFile(newFile);
      } else {
        setFileUrl(null);
        notificacionGlobal('DocumentoFirma.ArchivoNoValido', 6000, 'warning', true);
        event.target.value = '';
      }
    }
  };

  const borrarArchivo = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
    setSignatureFile(null);
    setFile(null);
  };

  const guardarArchivo = () => {
    debugger
    if (file) {
      guardarFirmas(file);
    } else {
      notificacionGlobal('DocumentoFirma.FirmaObligatoria', 6000, 'warning', true);
    }
  };

  const alCambiarFirma = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      sigCanvas.current.getTrimmedCanvas().toBlob(blob => {
        if (blob) {
          const file = new File([blob], `${idDocumento}.png`, { type: "image/png" });
          setFile(file);
        }
      });
    }
  };

  return (
    <Dialog open={true} maxWidth="lg">
      <ModalEncabezado
        cerrar={() => cerrarDocumentoFirma()}
        titulo={<Texto id="DocumentoFirma.CargarFirma" />}
      />
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              onClick={() => { setFirmar(true); setImagen(false); borrarArchivo(); }}
              color="secondary"
              variant="contained"
              id="boton-firmar"
              startIcon={<GestureIcon style={iconoEstilo} />}
              size="large"
              style={{ fontSize: '1rem', padding: '0.5rem 1rem' }}
            >
              <Texto id="DocumentoFirma.BotonFirmar" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => { setFirmar(false); setImagen(true); borrarArchivo(); }}
              color="secondary"
              variant="contained"
              id="boton-insertar"
              startIcon={<ImagenIcon style={iconoEstilo} />}
              size="large"
              style={{ fontSize: '1rem', padding: '0.5rem 1rem' }}
            >
              <Texto id="DocumentoFirma.CargarFirmar" />
            </Button>
          </Grid>
          <Grid item>
            <div>
              {firmar && (
                <div>
                  <Button variant="contained" color="primary" onClick={borrarArchivo} style={{ marginLeft: '8px' }}>
                    <Texto id='DocumentoFirma.BorrarFirma' />
                  </Button>
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ width: 1000, height: 200, className: 'sigCanvas' }}
                    onEnd={alCambiarFirma}
                  />

                </div>
              )}
              {imagen && fileUrl && (
                <div>
                  <h3><Texto id="DocumentoFirma.FirmaCargada" /></h3>
                  <img src={fileUrl} alt="Imagen cargada" style={{ width: '100%', height: 'auto' }} />
                </div>
              )}
              {imagen && (
                <div style={{ width: 1000, height: 200 }}>
                  <label>Subir imagen:</label>
                  <input type="file" accept="image/*" onChange={cargarArchivoFirma} />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                onClick={guardarArchivo}
                color="primary"
                variant="contained"
                id="boton-editar"
              >
                <Texto id="boton.guardar" />
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => cerrarDocumentoFirma()} color="secondary" variant="contained" id="boton-cancelar">
                <Texto id="boton.cancelar" />
              </Button>
            </Grid>
          </Grid>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentoFirma;
