import general from '@infotrack/presentacion-transversales/recursos/idiomas/es_ES';
import maximosMinimos from './maximosMinimos'
import pedidos from './alistamiento';
import almacenamiento from './almacenamiento';
import aplicacionEstilo from './aplicacionEstilo';
import auditoria from './auditoria';
import auditoriaConteo from './auditoriaConteo';
import auditoriasInventario from './auditoriasInventario';
import banco from './banco';
import barraLateral from './barraLateral';
import base from './base';
import bodegasTiposPropiedades from './bodegasTiposPropiedades';
import clasesTecnicas from './clasesTecnicas';
import clasificaciones from './clasificaciones';
import clientes from './clientes';
import columnas from './columnas';
import consultaInventario from './consultaInventario';
import crearRecalada from './crearRecaladas';
import despachos from './despachos';
import detallesFormulario from './detallesFormulario';
import detallesInventario from './detallesInventario';
import devoluciones from './devoluciones';
import devolucionProveedor from './devolucionProveedor';
import divisionesTiposPropiedades from './divisionesTiposPropiedades';
import documentoBuscador from './documentoBuscador';
import documentoDetalle from './documentoDetalle';
import documentos from './documentos';
import documentoValorTotal from './documentoValorTotal';
import entradaDevolucion from './entradaDevolucion';
import entradaInventario from './entradaInventario';
import estadosDetalles from './estadosDetalles';
import etapas from './etapas';
import fabricantes from './fabricantes';
import familias from './familias';
import filas from './filas';
import flujos from './flujos';
import flujosEtapas from './flujosEtapas';
import formularioDocumento from './formularioDocumento';
import gestionAuditorias from './gestionAuditorias';
import gestionBodegas from './gestionBodegas';
import gestionCompras from './gestionCompras';
import gestionMovimientos from './gestionMovimientos';
import gestionOperador from './gestionOperador';
import gestionPedidos from './gestionPedidos';
import gestionProducto from './gestionProducto';
import grupoActividadEconomica from './grupoActividadEconomica';
import informes from './informes';
import marcas from './marcas';
import modalSeriales from './modalSeriales';
import modulo from './modulo';
import monitorInventarios from './monitorInventarios';
import monitorPedidos from './monitorPedidos';
import ordenEntrada from './ordenEntrada';
import ordenSalida from './ordenSalida';
import pais from './pais';
import perfil from './perfil';
import permisos from './permisos';
import persona from './persona';
import pisos from './pisos';
import productos from './productos';
import proveedores from './proveedores';
import recepcion from './recepcion';
import referencias from './referencias';
import resolucionesFacturaciones from './resolucionesFacturaciones';
import reversion from './reversion';
import salidaDevolucion from './salidaDevolucion';
import salidaInventario from './salidaInventario';
import tablaDetalles from './tablaDetalles';
import tablaDetallesPlantilla from './tablaDetallesPlantilla';
import tablaDocumentos from './tablaDocumentos';
import tipoContribuyente from './tipoContribuyente';
import tipoCuentaBancaria from './tipoCuentaBancaria';
import tipoIdentificacion from './tipoIdentificacion';
import tipoRegimen from './tipoRegimen';
import tiposBodegas from './tiposBodegas';
import tiposClientes from './tiposClientes';
import tipoConteoAuditoria from './tipoConteoAuditoria';
import tiposDivisiones from './tiposDivisiones';
import tiposDocumento from './tiposDocumento';
import tiposMovimientos from './tiposMovimientos';
import tipoSociedad from './tipoSociedad';
import tiposUnidades from './tiposUnidades';
import traslados from './traslados';
import unidades from './unidades';
import usuario from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/usuariosPersona';
import empresa from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/empresas';
import aplicacion from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/aplicaciones';
import permisosPerfil from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/permisosPerfil';
import modalFlujos from './modalFlujos';
import flujoFormulario from './flujoFormulario';
import formularioFlujoEtapas from './formularioFlujoEtapas';
import flujoEtapasTabla from './flujosEtapasTabla';
import flujoTabla from './flujoTabla';
import datosPersonales from './datosPersonales';
import cargo from './cargo';
import cambioContrasenia from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/cambioContrasenia';
import olvidoContrasenia from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/olvidoContrasenia';
import perfiles from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/perfiles';
import agencia from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/agencias';
import plantillaImpresion from './plantillaImpresion';
import documentoEvidencias from './documentoEvidencias';
import impresion from './impresion';
import etiquetaItems from './etiquetaItems';
import validacionContrasenia from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/validacionContrasenia'
import cargaMasiva from './cargaMasiva';
import ordenTraslado from './ordenTraslados'
import catalogo from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/catalogo';
import DocumentoFirma from './DocumentoFirma';
import generarPDF from './generarPDF';

export default {
  ...maximosMinimos,
  ...agencia,
  ...almacenamiento,
  ...aplicacionEstilo,
  ...auditoria,
  ...auditoriaConteo,
  ...auditoriasInventario,
  ...banco,
  ...barraLateral,
  ...base,
  ...bodegasTiposPropiedades,
  ...cambioContrasenia,
  ...clasesTecnicas,
  ...clasificaciones,
  ...clientes,
  ...columnas,
  ...consultaInventario,
  ...crearRecalada,
  ...datosPersonales,
  ...despachos,
  ...detallesFormulario,
  ...detallesInventario,
  ...devoluciones,
  ...devolucionProveedor,
  ...divisionesTiposPropiedades,
  ...documentoBuscador,
  ...documentoDetalle,
  ...documentos,
  ...documentoValorTotal,
  ...entradaDevolucion,
  ...entradaInventario,
  ...estadosDetalles,
  ...etapas,
  ...fabricantes,
  ...familias,
  ...filas,
  ...flujos,
  ...flujosEtapas,
  ...formularioDocumento,
  ...general,
  ...gestionAuditorias,
  ...gestionBodegas,
  ...gestionCompras,
  ...gestionMovimientos,
  ...gestionOperador,
  ...gestionPedidos,
  ...gestionProducto,
  ...grupoActividadEconomica,
  ...informes,
  ...marcas,
  ...modalSeriales,
  ...modulo,
  ...monitorInventarios,
  ...monitorPedidos,
  ...olvidoContrasenia,
  ...ordenEntrada,
  ...ordenSalida,
  ...pais,
  ...pedidos,
  ...perfil,
  ...perfiles,
  ...permisos,
  ...persona,
  ...permisosPerfil,
  ...pisos,
  ...productos,
  ...proveedores,
  ...recepcion,
  ...referencias,
  ...resolucionesFacturaciones,
  ...reversion,
  ...salidaDevolucion,
  ...salidaInventario,
  ...tablaDetalles,
  ...tablaDetallesPlantilla,
  ...tablaDocumentos,
  ...tipoContribuyente,
  ...tipoCuentaBancaria,
  ...tipoIdentificacion,
  ...tipoRegimen,
  ...tiposBodegas,
  ...tiposClientes,
  ...tipoConteoAuditoria,
  ...tiposDivisiones,
  ...tiposDocumento,
  ...tiposMovimientos,
  ...tipoSociedad,
  ...tiposUnidades,
  ...traslados,
  ...unidades,
  ...usuario,
  ...modalFlujos,
  ...flujoFormulario,
  ...formularioFlujoEtapas,
  ...flujoEtapasTabla,
  ...flujoTabla,
  ...empresa,
  ...aplicacion,
  ...cargo,
  ...plantillaImpresion,
  ...documentoEvidencias,
  ...impresion,
  ...etiquetaItems,
  ...validacionContrasenia,
  ...cargaMasiva,
  ...ordenTraslado,
  ...cargaMasiva,
  ...catalogo,
  ...DocumentoFirma,
  ...generarPDF
};
