import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import ModeloOrdenEntrada from 'Infotrack@Modelos/negocioRefactor/modelos/OrdenEntrada/ModeloOrdenEntrada';

class OrdenEntradaCM {
    private modeloOrdenEntrada: ModeloOrdenEntrada;

    constructor() {
        this.modeloOrdenEntrada = new ModeloOrdenEntrada();
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloOrdenEntrada.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloOrdenEntrada.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        if (detalles.some((d) => !this.validarDetalle(d))) return false;
        const respuesta = await this.modeloOrdenEntrada.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async guardarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloOrdenEntrada.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloOrdenEntrada.editarEncabezado(documento);
        return respuesta.data;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloOrdenEntrada.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        const respuesta = await this.modeloOrdenEntrada.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloOrdenEntrada.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloOrdenEntrada.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloOrdenEntrada.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async cambiarEtapa(documento: IDocumento, idUsuario:string) {
        const respuesta = await this.modeloOrdenEntrada.cambiarEtapa(documento, idUsuario);
        return respuesta.data.Resultado;
    }

    public async generarArchivoPDF(documentoId: string, idAplicacion: string) {
        const respuesta = await this.modeloOrdenEntrada.generarArchivoPDF(documentoId, idAplicacion);
        return respuesta.data.Entidades[0] ;
    }

    private validarDocumento(documento: IDocumento) {
        if (!documento.TipoDocumentoId) {
            notificacionGlobal('OrdenEntrada.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        if (!documento.ProveedorId) {
            notificacionGlobal('OrdenEntrada.ValidacionNoProveedorId', 3000, 'warning', true);
            return false;
        }
        if (!documento.BodegaDestino) {
            notificacionGlobal('OrdenEntrada.ValidacionNoBodegaDestino', 3000, 'warning', true);
            return false;
        }
        return true;
    }

    private validarDetalle(detalle: IDocumentoDetalle) {
              const valor = Number(detalle.Valor);
     
        return true;
    }
}

export default OrdenEntradaCM;
