import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoTipoDocumentoIdProps {
    manejarCambioFiltro: (
        nombreFiltro: keyof IDocumentoFiltro,
        nuevoValor: IDocumentoFiltro[keyof IDocumentoFiltro]
    ) => any;
    tiposDocumento: ITipoDocumento[];
    valor: number;
}

const CampoTipoDocumentoId: FunctionComponent<ICampoTipoDocumentoIdProps> = ({
    manejarCambioFiltro,
    tiposDocumento,
    valor,
}) => (
    <Autocomplete
        getOptionLabel={(t) => t.TipoDocumentoDescripcion}
        onChange={(_: object, value: ITipoDocumento | null) =>
            manejarCambioFiltro('TipoDocumentoId', value ? value.TipoDocumentoId : 0)
        }
        options={tiposDocumento}
        renderInput={(params) => (
            <TextField {...params} fullWidth label={<Texto id="Documentos.CampoTipoDocumentoId.Nombre" />} />
        )}
        value={tiposDocumento.find((t) => t.TipoDocumentoId === valor) || null}
        noOptionsText={<Texto id="Documentos.SinRegistros"/>}
        id={'CampoTipoDocumento'}
    />
);

export default CampoTipoDocumentoId;
