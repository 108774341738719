import { MenuItem, TextField } from '@material-ui/core';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { Autocomplete } from '@material-ui/lab';

import Texto from '@infotrack/presentacion-componentes/texto';

import IAuditoriaCompuesta from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IAuditoriaCompuesta';
import IItem from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IItem';
import IItemAuditoria from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/auditoria/IItemAuditoria';
import IItemsConsulta from 'Infotrack@Modelos/smartStock/items/entidades/IItemsConsulta';

interface ICampoItemIdProps {
    deshabilitado: boolean;
    manejarCambioCampo: (
        nombreCampor: keyof IAuditoriaCompuesta,
        nuevoValor: IAuditoriaCompuesta[keyof IAuditoriaCompuesta]
    ) => any;
    items: IItemsConsulta[];
    itemsSeleccionados: IItemAuditoria[];
}

const CampoItemId: FunctionComponent<ICampoItemIdProps> = ({
    deshabilitado,
    manejarCambioCampo,
    items,
    itemsSeleccionados,
}) => (
    <Autocomplete
    noOptionsText={<Texto id="Auditoria.SeleccioneBodega" />}
    multiple
    disabled={deshabilitado}
    getOptionLabel={(d: IItemsConsulta) => `${d.DescripcionItem} (${d.CodigoEmpresa!})`}
    filterOptions={(options, state) => 
        options.filter((option) =>
            option.DescripcionItem.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            option.CodigoEmpresa!.toLowerCase().includes(state.inputValue.toLowerCase())
        )
    }
    onChange={(_: ChangeEvent<{}>, items: IItemsConsulta[] | null) => {
        if (items) {
            manejarCambioCampo(
                'Items',
                items.map((item) => ({
                    ItemId: item.ItemId,
                    DescripcionItem: item.DescripcionItem,
                    CodigoEmpresa: item.CodigoEmpresa!,
                }))
            );
        }
    }}
    options={items}
    renderInput={(params) => (
        <TextField {...params} fullWidth label={<Texto id="Auditoria.CampoItemId.Nombre" />} />
    )}
    value={
        items.length > 0
            ? items.filter((item) =>
                  itemsSeleccionados.find((itemSeleccionado) => itemSeleccionado.ItemId === item.ItemId)
              )
            : []
    }
    id={`CampoItem`}
/>

);

export default CampoItemId;
