import { MenuItem, TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import IBodegaDivisiones from 'Infotrack@Modelos/smartStock/bodegasDivisiones/entidades/bodegasDivisiones';

import useStyles from '../tablaDetallesPlantillaEstilos';

interface ICampoDivisionOrigenProps {
    deshabilitado: boolean;
    detalle: IDocumentoDetalle;
    divisiones: IBodegaDivisiones[];
    manejarCambioCampo: (detalleId: string, nombreCampo: keyof IDocumentoDetalle, nuevoValor: any) => void;
}

const CampoDivisionOrigen: FunctionComponent<ICampoDivisionOrigenProps> = ({
    deshabilitado,
    detalle,
    divisiones,
    manejarCambioCampo,
}) => {
    const classes = useStyles();
    return (
        <TextField
            classes={{ root: classes.campo }}
            disabled={deshabilitado}
            fullWidth
            label={<Texto id="TablaDetallesPlantilla.DivisionOrigen" />}
            onChange={(event) => manejarCambioCampo(detalle.DocumentoDetalleId, 'DivisionOrigen', event.target.value)}
            select
            size="small"
            value={detalle.DivisionOrigen || ''}
            id={`CampoDivisionOrigen-${detalle.DivisionOrigen || ''}`}
        >
            {divisiones.map((division) => (
                <MenuItem key={division.DivisionId} value={division.DivisionId}>
                    {division.DivisionDescripcion}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default CampoDivisionOrigen;
