import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import Tabla, { Column } from '@infotrack/presentacion-componentes/tabla';
import Texto from '@infotrack/presentacion-componentes/texto';
import { Button, Card, CardContent, Checkbox, Grid, TextField } from '@material-ui/core';
import { Refresh as RefreshIcon, Remove as RemoveIcon } from '@material-ui/icons';
import IEtiqueta from 'Infotrack@Modelos/smartStock/etiquetas/entidades/etiqueta';
import RefresIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import EntidadesImpresionCM from './controladorModelo/entidadesImpresionCM';
import ImpresionCM from './controladorModelo/impresionCM';
import IItems from 'Infotrack@Modelos/smartStock/items/entidades/IItems';
import IImpresora from 'Infotrack@Modelos/smartStock/impresora/entidades/impresoras';
import TablaEtiquetaItems from './tablaEtiquetaItems';
import InformacionProductos from './informacionProducto';
import IFiltroImpresion from 'Infotrack@Modelos/smartStock/impresora/entidades/filtro';
import { InputEtiquetaItem } from './interfaces';
import moment from 'moment';
import ImprimirItemEtiqueta from 'Infotrack@Modelos/smartStock/impresion/entidades/IImprimirItemEtiqueta';
import IItemMovimiento from 'Infotrack@Modelos/smartStock/items/entidades/IItemMovimiento';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

const TablaImpresion: FunctionComponent = () => {
    const estadoInicialFiltro: IFiltroImpresion = {
        EtiquetaId: 0,
        ItemId: 0,
        SerialFin: '',
        SerialInicio: '',
    };
    const estadoInicialimprimirItemEtiqueta: ImprimirItemEtiqueta = {
        IdEtiqueta: 0,
        CantidadPallet: 0,
        IdImpresora: 0,
        ItemsMovimiento: [],
        NumeroCopias: 0,
    };
    const entidadesDocumentoCM = useMemo(() => new EntidadesImpresionCM(), []);
    const impresionCM = useMemo(() => new ImpresionCM(), []);
    const [entidadesImpresion, setEntidadesImpresion] = useState<{
        etiquetas: IEtiqueta[];
        impresoras: IImpresora[];
    }>({ etiquetas: [], impresoras: [] });
    const [estadoTablaEtiquetaItems, setEstadoTablaEtiquetaItems] = useState<boolean>(false);
    const [entidadesItems, setEntidadesItems] = useState<IItems[]>([]);
    const [itemEtiquetas, setItemEtiquetas] = useState<InputEtiquetaItem[]>();
    const [etiquetaSeleccionada, setEtiquetaSeleccionada] = useState<IEtiqueta>();
    const [filtro, setFiltro] = useState<IFiltroImpresion>(estadoInicialFiltro);
    const [imprimirItemEtiqueta, setImprimirItemEtiqueta] = useState<ImprimirItemEtiqueta>(
        estadoInicialimprimirItemEtiqueta
    );
    const [idItemsImprimir, setIdItemsImprimir] = useState<IItemMovimiento[]>([]);
    const [imprimirTodos, setimprimirTodos] = useState<boolean>(false);

    useEffect(() => {
        consultarEntidades();
    }, []);

    useEffect(() => {
        if (!estadoTablaEtiquetaItems && etiquetaSeleccionada) {
            consultarItemsEtiquetas(etiquetaSeleccionada.EtiquetaId.toString());
            consultarItemsEtiquetasTabla();
        }
    }, [estadoTablaEtiquetaItems]);
    const consultarEntidades = async () => {
        const entidadesConsultadas = await entidadesDocumentoCM.consultaEntidadesImpresion();
        setEntidadesImpresion(entidadesConsultadas);
    };

    const consultarItemsEtiquetas = async (idEtiqueta: string) => {
        const items = await entidadesDocumentoCM.consultaItemsEtiqueta(idEtiqueta);
        setEntidadesItems(items);
    };

    const consultarItemsEtiquetasTabla = async () => {
        setimprimirTodos(false);
        const items = await entidadesDocumentoCM.consultaItemsEtiquetaTabla(filtro);
        setItemEtiquetas(items);
    };

    const confirmarGuardarItemsEtiqueta = async () => {
        imprimirItemEtiqueta.ItemsMovimiento = idItemsImprimir;
        imprimirItemEtiqueta.IdEtiqueta = etiquetaSeleccionada ? etiquetaSeleccionada.EtiquetaId : 0;
        if (
            impresionCM.validarEntidadImpresion(
                imprimirItemEtiqueta,
                etiquetaSeleccionada ? etiquetaSeleccionada.Pallet : false
            )
        ) {
            manejadorDialogoGlobal({
                accionCancelar: () => manejadorDialogoGlobal({ estado: false }),
                accionConfirmar: () => imprimirEtiquetas(imprimirItemEtiqueta),
                estado: true,
                mensaje: <Texto id={'TablaImpresion.ConfirmacionImpresion'} />,
                mostrarCancelar: true,
                tipoDialogo: 'Advertencia',
                titulo: <Texto id="titulo.advertencia" />,
            });
        }
    };

    const imprimirEtiquetas = async (imprimirItems: ImprimirItemEtiqueta) => {
        manejadorDialogoGlobal({ estado: false });
        const respuesta = await impresionCM.ImprimirItems(imprimirItems);
        if (respuesta.Resultado) {
            setImprimirItemEtiqueta(estadoInicialimprimirItemEtiqueta);
            setFiltro(estadoInicialFiltro);
            setEtiquetaSeleccionada(undefined);
            setIdItemsImprimir([]);
        }
    };

    const cambiarValorFiltro = async (nombreFiltro: string, valor: number | string) => {
        setFiltro((filtro) => ({ ...filtro, [nombreFiltro]: valor }));
        if (nombreFiltro === 'EtiquetaId') {
            valor === 0 ? setEntidadesItems([]) : consultarItemsEtiquetas(valor as string);
            setEtiquetaSeleccionada(entidadesImpresion.etiquetas.find((i) => i.EtiquetaId === valor));
            setIdItemsImprimir([]);
            setItemEtiquetas([]);
            setImprimirItemEtiqueta(estadoInicialimprimirItemEtiqueta);
        }
    };

    const cambiarValorImpresion = async (nombreValor: string, valor: number | string) => {
        setImprimirItemEtiqueta((filtro) => ({ ...filtro, [nombreValor]: valor }));
    };

    const alCambiarCheck = (item: InputEtiquetaItem, seleccionado: boolean) => {
        let copiaIds = idItemsImprimir;
        if (seleccionado) {
            copiaIds.push({ ItemId: item.ItemId, MovimientoId: item.Movimientoid });
            setIdItemsImprimir([...copiaIds]);
            if (!imprimirTodos && copiaIds.length === itemEtiquetas!.length) setimprimirTodos(true);
        } else {
            setIdItemsImprimir(
                idItemsImprimir.filter((i) => i.MovimientoId + '' + i.ItemId !== item.Movimientoid + '' + item.ItemId)
            );
            if (imprimirTodos) setimprimirTodos(false);
        }
    };

    const cerrarItemsEtiquetaModal = async () => {
        setEstadoTablaEtiquetaItems(false);
    };

    const CambiarSeleccionTodo = (seleccionarTodo: boolean) => {
        let idImprimir: IItemMovimiento[] = [];
        if (seleccionarTodo && itemEtiquetas) {
            itemEtiquetas.map((i) => {
                idImprimir.push({ ItemId: i.ItemId, MovimientoId: i.Movimientoid });
            });
            setIdItemsImprimir(idImprimir);
            setimprimirTodos(true);
        } else {
            setIdItemsImprimir([]);
            setimprimirTodos(false);
        }
    };

    const columnas = (): Array<Column<InputEtiquetaItem>> => [
        {
            render: (rowData) => (
                <Checkbox
                    color="primary"
                    checked={
                        idItemsImprimir.find(
                            (i) => i.MovimientoId + '' + i.ItemId === rowData.Movimientoid + '' + rowData.ItemId
                        ) !== undefined
                    }
                    onChange={(_, checked) => alCambiarCheck(rowData, checked)}
                />
            ),
            title: (
                <Checkbox
                    color="primary"
                    checked={imprimirTodos}
                    onChange={(_, checked) => CambiarSeleccionTodo(checked)}
                    disabled={itemEtiquetas ? itemEtiquetas.length <= 0 : true}
                />
            ),
        },
        {
            field: 'ItemId',
            title: <Texto id="TablaImpresion.CodigoInterno" />,
        },
        {
            field: 'CodigoEmpresaItem',
            title: <Texto id="TablaImpresion.CodigoEmpresaItem" />,
        },
        {
            field: 'ItemDescripcion',
            title: <Texto id="TablaImpresion.Producto" />,
        },
        {
            field: 'UnidadDescripcion',
            title: <Texto id="TablaImpresion.Unidad" />,
        },
        {
            field: 'Lote',
            title: <Texto id="TablaImpresion.Lote" />,
        },
        {
            field: 'Serial',
            title: <Texto id="TablaImpresion.Serial" />,
            render: (detalle) =>
            detalle.Serial !== "" ? detalle.Serial : <RemoveIcon />,
        },
        {
            field: 'FechaIngreso',
            title: <Texto id="TablaImpresion.FechaIngreso" />,
            render: (rowData) =>
                rowData.FechaIngreso ? moment(rowData.FechaIngreso).format('DD/MM/YYYY/hh:mm A') : '',
        },
        {
            field: 'FechaVencimiento',
            title: <Texto id="TablaImpresion.FechaVencimiento" />,
            render: (rowData) =>
                rowData.FechaVencimiento ? moment(rowData.FechaVencimiento).format('DD/MM/YYYY/hh:mm A') : '',
        },
        {
            field: 'Peso',
            title: <Texto id="TablaImpresion.Peso" />,
            render: (detalle) =>
            detalle.Peso > 0 ? detalle.Peso : <RemoveIcon />,
        },
        {
            field: 'Manifiesto',
            title: <Texto id="TablaImpresion.CodigoDocumento" />,
            render:(detalle)=>
            detalle.Manifiesto !== null ?detalle.Manifiesto:"AD"
        },
    ];
    return (
        <div>
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Texto id="TablaImpresion.Filtros" />
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} xs={12}>
                                    <Autocomplete
                                        onChange={(_: ChangeEvent<{}>, value: IEtiqueta | null) =>
                                            cambiarValorFiltro('EtiquetaId', value !== null ? value.EtiquetaId : 0)
                                        }
                                        options={entidadesImpresion.etiquetas}
                                        getOptionLabel={(e) => e.NombreEtiqueta}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<Texto id="TablaImpresion.Etiqueta" />}
                                                required
                                            />
                                        )}
                                        value={
                                            entidadesImpresion.etiquetas.find(
                                                (i) => i.EtiquetaId === filtro.EtiquetaId
                                            ) || null
                                        }
                                        id={`CampoEtiqueta`}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Autocomplete
                                        onChange={(_: ChangeEvent<{}>, value: IItems | null) =>
                                            cambiarValorFiltro('ItemId', value !== null ? value.ItemId : 0)
                                        }
                                        options={entidadesItems}
                                        getOptionLabel={(e) => e.DescripcionItem}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<Texto id="TablaImpresion.Producto" />}
                                            />
                                        )}
                                        value={entidadesItems.find((i) => i.ItemId === filtro.ItemId) || null}
                                        id={`CampoProducto`}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Autocomplete
                                        onChange={(_: ChangeEvent<{}>, value: IItems | null) =>
                                            cambiarValorFiltro('ItemId', value !== null ? value.ItemId : 0)
                                        }
                                        options={entidadesItems}
                                        getOptionLabel={(e) =>
                                            e.CodigoEmpresaItem !== null ? e.CodigoEmpresaItem : ''
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<Texto id="TablaImpresion.CodigoEmpresaItem" />}
                                            />
                                        )}
                                        value={entidadesItems.find((i) => i.ItemId === filtro.ItemId) || null}
                                        id={`CampoCodigoEmpresa`}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <TextField
                                        onChange={(event) => cambiarValorFiltro('SerialInicio', event.target.value)}
                                        inputProps={{ maxLength: 30 }}
                                        fullWidth
                                        label={<Texto id="TablaImpresion.SerialInicio" />}
                                        value={filtro.SerialInicio}
                                        id={`CampoSerialInicio-${filtro.SerialInicio}`}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={6} xs={12}>
                                    <TextField
                                        onChange={(event) => cambiarValorFiltro('SerialFin', event.target.value)}
                                        inputProps={{ maxLength: 30 }}
                                        fullWidth
                                        label={<Texto id="TablaImpresion.SerialFin" />}
                                        value={filtro.SerialFin}
                                        id={`CampoSerialFin-${filtro.SerialFin}`}
                                    />
                                </Grid>
                                <Grid item lg={1} md={3} sm={6} xs={12}>
                                    <Button
                                        color="primary"
                                        endIcon={<SearchIcon id="boton-Buscar"/>}
                                        onClick={() => consultarItemsEtiquetasTabla()}
                                        size="small"
                                        variant="contained"
                                        style={{ marginLeft: '1rem' }}
                                        id="boton-Buscar"
                                    >
                                        <Texto id="boton.Buscar" />
                                    </Button>
                                </Grid>
                                <Grid item lg={1} md={3} sm={6} xs={12}>
                                    <Button
                                        color="primary"
                                        endIcon={<RefresIcon />}
                                        onClick={() => {
                                            setFiltro(estadoInicialFiltro),
                                                setEtiquetaSeleccionada({
                                                    Clasificacion: '',
                                                    EtiquetaId: 0,
                                                    Pallet: false,
                                                    PapelTamanio: '',
                                                    NombreEtiqueta: '',
                                                    Margenes: '',
                                                });
                                            setIdItemsImprimir([]);
                                            setItemEtiquetas([]);
                                            setEntidadesItems([]);
                                            setimprimirTodos(false);
                                        }}
                                        style={{ marginLeft: '50%' }}
                                        size="small"
                                        variant="contained"
                                        id="boton-Limpiar"
                                    >
                                        <Texto id="boton.Limpiar" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <InformacionProductos
                                    mostrarBoton={true}
                                    abrirTablaEtiquetasItems={() => setEstadoTablaEtiquetaItems(true)}
                                    etiqueta={etiquetaSeleccionada!}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} xs={12}>
                                    <Autocomplete
                                        onChange={(_: ChangeEvent<{}>, value: IImpresora | null) =>
                                            cambiarValorImpresion('IdImpresora', value !== null ? value.ImpresoraID : 0)
                                        }
                                        options={entidadesImpresion.impresoras}
                                        getOptionLabel={(i) => i.NombreImpresora}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<Texto id="TablaImpresion.Impresora" />}
                                                required
                                            />
                                        )}
                                        value={
                                            entidadesImpresion.impresoras.find(
                                                (i) => i.ImpresoraID === imprimirItemEtiqueta.IdImpresora
                                            ) || null
                                        }
                                        id={`CampoImpresora`}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        onChange={(event) => {
                                            if (/^\d*$/.test(event.target.value)) {
                                                cambiarValorImpresion('NumeroCopias', event.target.value);
                                            }
                                        }}
                                        label={<Texto id="TablaImpresion.NumeroCopias" />}
                                        inputProps={{ maxLength: 3 }}
                                        value={
                                            imprimirItemEtiqueta.NumeroCopias === 0
                                                ? ''
                                                : imprimirItemEtiqueta.NumeroCopias
                                        }
                                        id={`CampoNumeroCopias`}
                                    />
                                </Grid>
                                <Grid item lg={2} md={1} sm={6} xs={8}>
                                    <TextField
                                        onChange={(event) => {
                                            if (/^\d*$/.test(event.target.value)) {
                                                cambiarValorImpresion('CantidadPallet', event.target.value);
                                            }
                                        }}
                                        fullWidth
                                        required={etiquetaSeleccionada && etiquetaSeleccionada.Pallet}
                                        label={<Texto id="TablaImpresion.CantidadPallet" />}
                                        disabled={etiquetaSeleccionada && !etiquetaSeleccionada.Pallet}
                                        inputProps={{ maxLength: 3 }}
                                        value={
                                            imprimirItemEtiqueta.CantidadPallet === 0
                                                ? ''
                                                : imprimirItemEtiqueta.CantidadPallet
                                        }
                                        id={`CampoCantidadPallet`}
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Button
                                        color="primary"
                                        endIcon={<PrintIcon />}
                                        onClick={confirmarGuardarItemsEtiqueta}
                                        size="small"
                                        variant="contained"
                                        id="boton.Imprimir"
                                    >
                                        <Texto id="boton.Imprimir" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Tabla columns={columnas()} data={itemEtiquetas ? itemEtiquetas : []} />
            <TablaEtiquetaItems
                estado={estadoTablaEtiquetaItems}
                cerrar={cerrarItemsEtiquetaModal}
                etiquetaSeleccionada={etiquetaSeleccionada}
                itemsEtiquetas={entidadesItems}
            />
        </div>
    );
};
export default TablaImpresion;
