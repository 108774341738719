import {
    AppBar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Toolbar,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Texto from '@infotrack/presentacion-componentes/texto';
import { TipoFormulario } from '@infotrack/presentacion-transversales/interfacesComunes';
import React from 'react';
import { IFormularioGestionAuditoriasVistaProps } from '../interfaces';

const FormularioGestionAuditoriasVista: React.FunctionComponent<IFormularioGestionAuditoriasVistaProps> = ({
    alCambiarValor,
    alCambiarValorAutoComplete,
    alCerrarFormulario,
    alGuardarDocumento,
    documento,
    entidadesDocumento,
    esMovil,
    estadoFormulario,
    tipoFormulario,
}) => (
    <Dialog fullWidth maxWidth={esMovil ? 'lg' : 'sm'} open={estadoFormulario}>
        <AppBar position="static">
            <Toolbar>
                <Grid container justifyContent="center">
                    <Texto
                        align="center"
                        id={
                            tipoFormulario === TipoFormulario.Creacion
                                ? 'gestionauditorias.titulo.crearAuditoria'
                                : 'gestionauditorias.titulo.editarAuditoria'
                        }
                    />
                </Grid>
            </Toolbar>
        </AppBar>
        <DialogContent>
            <Card>
                <CardContent>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                disableClearable
                                getOptionLabel={({ BodegaDescripcion }) => BodegaDescripcion}
                                onChange={(_: any, bodega: any) => {
                                    alCambiarValorAutoComplete(bodega, 'Bodega');
                                }}
                                options={entidadesDocumento.bodegas}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="gestionauditorias.BodegaId" display="inline" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documento.Bodega!}
                                id={`CampoBodega-${documento.BodegaId}`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ DivisionDescripcion }) => DivisionDescripcion}
                                onChange={(_: any, division: any) => {
                                    alCambiarValorAutoComplete(division, 'Division');
                                }}
                                options={entidadesDocumento.divisiones}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="gestionauditorias.DivisionId" display="inline" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documento.Division}
                                id={`CampoDivision-${documento.DivisionId}`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ FamiliaDescripcion }) => FamiliaDescripcion}
                                onChange={(_: any, familia: any) => {
                                    alCambiarValorAutoComplete(familia, 'Familia');
                                }}
                                options={entidadesDocumento.familias}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="gestionauditorias.FamiliaId" display="inline" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documento.Familia}
                                id={`CampoFamilia`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                getOptionLabel={({ DescripcionItem }) => DescripcionItem}
                                onChange={(_: any, item: any) => {
                                    alCambiarValorAutoComplete(item, 'Item');
                                }}
                                options={entidadesDocumento.items}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<Texto id="gestionauditorias.ItemId" display="inline" />}
                                    />
                                )}
                                noOptionsText={<Texto id="label.sinRegistros" />}
                                value={documento.Item}
                                id={`CampoDescripcionItem`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                label={<Texto id="gestionauditorias.DocumentoRelacionado" />}
                                fullWidth
                                name="DocumentoRelacionado"
                                onChange={alCambiarValor}
                                value={documento.DocumentoRelacionado}
                                variant="outlined"
                                id={`CampoDocumentoRelacionado-${documento.DocumentoRelacionado}`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                label={<Texto id="gestionauditorias.observaciones" />}
                                fullWidth
                                name="Observaciones"
                                onChange={alCambiarValor}
                                value={documento.Observaciones}
                                variant="outlined"
                                id={`CampoObservaciones-${documento.Observaciones}`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControlLabel
                                control={<Checkbox onChange={alCambiarValor} 
                                value={documento.ConteoUnico} />}
                                label={<Texto id="gestionauditorias.ConteoUnico" />}
                                name="ConteoUnico"
                                id={`CampoConteoUnico-${documento.ConteoUnico}`}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={alCambiarValor}
                                        value={documento.GenerarSaldoActual}
                                    />
                                }
                                label={<Texto id="gestionauditorias.GenerarSaldoActual" />}
                                name="GenerarSaldoActual"
                                id={`CampoGenerarSaldoActual-${documento.GenerarSaldoActual}`}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="secondary" onClick={alCerrarFormulario} variant="contained" id="boton-cancelar" >
                        <Texto id="boton.cancelar" />
                    </Button>
                </Grid>
                <Grid item>
                    <Button 
                    color="primary" 
                    onClick={alGuardarDocumento} 
                    variant="contained"
                    id={tipoFormulario === TipoFormulario.Creacion ? 'boton-guardar' : 'boton-editar'}
                    >
                        <Texto id={tipoFormulario === TipoFormulario.Creacion ? 'boton.guardar' : 'boton.editar'} />
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
);

export default FormularioGestionAuditoriasVista;
