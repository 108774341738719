import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import { IPaginador } from '@infotrack/presentacion-transversales/interfacesComunes';

import IDocumentoFiltro from 'Infotrack@Modelos/negocioRefactor/entidades/consulta/documento/IDocumentoFiltro';
import IDocumentoAplicacion from 'Infotrack@Modelos/negocioRefactor/entidades/negocio/IDocumentoAplicacion';
import IDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumento';
import IDocumentoDetalle from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/IDocumentoDetalle';
import TiposProceso from 'Infotrack@Modelos/negocioRefactor/enumeraciones/TiposProceso';
import ModeloTraslado from 'Infotrack@Modelos/negocioRefactor/modelos/Traslados/ModeloTraslados';
import enumTiposDocumentos from 'Infotrack@Modelos/smartStock/tiposDocumentos/entidades/enumTiposDocumentos';
import EnumTrasladosEstado from 'Infotrack@Modelos/smartStock/traslados/entidades/enumTrasladosEstado';

class OrdenSalidaCM {
    private modeloTraslado: ModeloTraslado;

    constructor() {
        this.modeloTraslado = new ModeloTraslado();
    }

    public async guardarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloTraslado.guardarEncabezado(documento);
        return respuesta.data;
    }

    public async editarEncabezado(documento: IDocumento) {
        if (!this.validarDocumento(documento)) return { Entidades: [], Resultado: false };
        const respuesta = await this.modeloTraslado.editarEncabezado(documento);
        return respuesta.data;
    }

    public async guardarDetalle(detalles: IDocumentoDetalle[]) {
        const respuesta = await this.modeloTraslado.guardarDetalle(detalles);
        return respuesta.data.Resultado;
    }

    public async editarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloTraslado.editarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async eliminarDetalle(detalle: IDocumentoDetalle) {
        const respuesta = await this.modeloTraslado.eliminarDetalle(detalle);
        return respuesta.data.Resultado;
    }

    public async cambiarEtapa(documento: Partial<IDocumentoAplicacion>,idUsuario:string) {
        const respuesta = await this.modeloTraslado.cambiarEtapa(documento, idUsuario);
        return respuesta.data.Resultado;
    }

    public async anularDocumento(documentoId: string) {
        const respuesta = await this.modeloTraslado.anularDocumento(documentoId);
        return respuesta.data.Resultado;
    }

    public consultarListaDocumentos = async (paginador: IPaginador, filtro: IDocumentoFiltro) => {
        filtro.idTipoProceso = TiposProceso.ORDEN_TRASLADO
        const respuesta = await this.modeloTraslado.consultarListaDocumentos({
            Filtro: filtro,
            Paginador: paginador,
        });
        return respuesta.data;
    };

    public async consultarEncabezadoDocumento(filtro: Partial<IDocumento>) {
        const respuesta = await this.modeloTraslado.consultarEncabezadoDocumento(filtro);
        return respuesta.data.Entidades[0];
    }

    public async consultarDetallesDocumento(filtro: Partial<IDocumentoDetalle>) {
        const respuesta = await this.modeloTraslado.consultarDetallesDocumento(filtro);
        return respuesta.data.Entidades;
    }

    public async consultarEtapas(documentoId: string) {
        const respuesta = await this.modeloTraslado.consultarEtapas(documentoId);
        return respuesta.data.Entidades;
    }

    public async generarArchivoPDF(documentoId: string, idAplicacion: string) {
        const respuesta = await this.modeloTraslado.generarArchivoPDF(documentoId, idAplicacion);
        return respuesta.data.Entidades[0] ;
    }

    private validarDocumento(documento: IDocumento) {
        if (!documento.TipoDocumentoId) {
            notificacionGlobal('OrdenSalida.ValidacionNoTipoDocumentoId', 3000, 'warning', true);
            return false;
        }
        if (!documento.BodegaOrigen) {
            notificacionGlobal('OrdenSalida.ValidacionNoBodegaOrigen', 3000, 'warning', true);
            return false;
        }
        // if (!documento.ClienteId) {
        //     notificacionGlobal('OrdenSalida.ValidacionNoClienteId', 3000, 'warning', true);
        //     return false;
        // }
        return true;
    }
}

export default OrdenSalidaCM;
