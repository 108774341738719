import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Texto from '@infotrack/presentacion-componentes/texto';

import ITipoDocumento from 'Infotrack@Modelos/negocioRefactor/entidades/repositorio/ITipoDocumento';

interface ICampoEstadoProps {
    deshabilitado: boolean;
    manejarCambioCampo: (nombreCampo: keyof ITipoDocumento, nuevoValor: ITipoDocumento[keyof ITipoDocumento]) => any;
    valor: number;
}

const CampoEstado: FunctionComponent<ICampoEstadoProps> = ({ deshabilitado, manejarCambioCampo, valor }) => (
    <FormControlLabel
        checked={valor === 1}
        control={<Checkbox />}
        disabled={deshabilitado}
        label={<Texto id="TiposDocumento.CampoEstado.Nombre" />}
        onChange={(_, checked) => manejarCambioCampo('Estado', checked ? 1 : 0)}
        id={`Estado`}
    />
);

export default CampoEstado;
